export enum AmazonBusinessModel {
    CROSS_BORDER_ARBITRAGE = 0,
    DROPSHIPPING = 1,
    WHOLESALE = 2
  }
  
  export const convertToAmazonBusinessModel = (value: string | null): AmazonBusinessModel | undefined  => {
    if (value !== null && !isNaN(Number(value))) {
      const numericValue = Number(value);
      if (numericValue in AmazonBusinessModel) {
        return numericValue as AmazonBusinessModel;
      }
    }
    return undefined;
  }