import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row, UncontrolledAlert} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {BsrHistoryChart} from "./Charts/BsrHistoryChart";
import {getToday} from "helpers/utilities";
import {SummaryCard} from "pages/Searches/SearchResults/Item/Components/SummaryCard";
import RenderNumber from "Components/Common/RenderNumber";
import moment from "moment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import NoData from "Components/Common/NoData";

const SalesAnalysis = () => {
  const {t} = useTranslation();
  const [filterDay, setFilterDay] = useState<"ALL" | "7DAY" | "30DAY" | "90DAY">("90DAY");
  const [bsrHistorySeries, setBsrHistorySeries] = useState<any[]>([]);
  const [bsrHistoryCategories, setBsrHistoryCategories] = useState<string[]>([]);

  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
      pageUrl: state.ProductDetails.pageUrl,
    }),
  );
  const {details} = useSelector(productDetailsData);

  const handleFilterChange = useCallback(() => {
    if (details.salesAnalysis) {
      if (details.salesAnalysis?.bsrHistory) {
        var sortedList = details.salesAnalysis.bsrHistory && details.salesAnalysis.bsrHistory.length > 0 ? [...details.salesAnalysis.bsrHistory] : [];
        sortedList.sort((a, b) => {
          if (a.date && b.date) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
          }
          return 0;
        });

        if (filterDay === "ALL") {
        } else if (filterDay === "7DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(7, "days")));
        } else if (filterDay === "30DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(30, "days")));
        } else if (filterDay === "90DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(90, "days")));
        }

        let series: any[] = sortedList.map((item) => item.bsr);
        let categories: any[] = sortedList.map((item) => item.date);
        setBsrHistorySeries(series);
        setBsrHistoryCategories(categories);
      }
    }
  }, [details, filterDay]);

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  return (
    <Card className="card-animate">
      <CardBody>
        <h5 className="fs-15">{t("ProductDetails.SalesAnalysis.Title")}</h5>
        <Row className="mt-4">
          <Col xl={8} sm={12}>
            <Card className="mb-0">
              <CardHeader className="align-items-center d-flex">
                <h5 className="fs-15 card-title mb-0 flex-grow-1">{t("ProductDetails.SalesAnalysis.Card.BsrHistoryChart.Title")}</h5>
                <div className="flex-shrink-0">
                  <div className="d-flex justify-content-end gap-1">
                    <div role="group" className="gap-2 btn-group">
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "ALL" && "text-decoration-underline "}`}
                        onClick={() => setFilterDay("ALL")}
                      >
                        {t("ProductDetails.SalesAnalysis.Button.AllTime")}
                      </button>
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "7DAY" && "text-decoration-underline "}`}
                        onClick={() => setFilterDay("7DAY")}
                      >
                        {t("ProductDetails.SalesAnalysis.Button.7Days")}
                      </button>
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "30DAY" && "text-decoration-underline "}`}
                        onClick={() => setFilterDay("30DAY")}
                      >
                        {t("ProductDetails.SalesAnalysis.Button.30Days")}
                      </button>
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "90DAY" && "text-decoration-underline "}`}
                        onClick={() => setFilterDay("90DAY")}
                      >
                        {t("ProductDetails.SalesAnalysis.Button.90Days")}
                      </button>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className="card-body">
                {bsrHistorySeries.length > 0 ? (
                  <BsrHistoryChart dataColors='["--vz-secondary"]' categories={bsrHistoryCategories} series={bsrHistorySeries} />
                ) : (
                  <NoData icon="mdi mdi-note-search-outline" />
                )}
              </div>
            </Card>
          </Col>

          <Col xl={4} sm={12}>
            <SummaryCard
              icon="mdi mdi-finance"
              label={t("ProductDetails.AnalysisSummary.Label.SaleCount")}
              cardClassName="mb-3"
              content={
                <div className="d-flex align-items-center">
                  <>
                    <span>{details?.analysisSummary?.saleCount}</span>
                    <small className="fs-13 text-muted">/{t("month")}</small>
                    <i
                      id="PopoverPrecisionSummary"
                      className={`ms-1 mdi ${
                        details?.salesAnalysis?.hasAnomalies === true
                          ? "mdi mdi-alert fs-20 text-warning"
                          : details?.salesAnalysis?.precision === "VeryClose"
                          ? "mdi-check-circle text-secondary"
                          : details?.salesAnalysis?.precision === "Medium"
                          ? "mdi-progress-check text-secondary fs-18"
                          : details?.salesAnalysis?.precision === "Prediction"
                          ? "mdi-progress-clock text-danger fs-18"
                          : ""
                      }`}
                    ></i>
                    <DefaultUncontrolledTooltip target={"PopoverPrecisionSummary"}>
                      {details?.salesAnalysis?.hasAnomalies === true ? (
                        <>{t("SearchResults.Summary.SaleCount.Anomalies")}</>
                      ) : (
                        <>
                          {"["}
                          {t("SearchResults.Summary.SaleCount.Precision")}: {t(details?.analysisSummary.precision || "")}
                          {"] "}
                          {t(`SearchResults.Summary.SaleCount.Precisions.${details?.analysisSummary?.precision}`)}
                        </>
                      )}
                    </DefaultUncontrolledTooltip>
                  </>
                </div>
              }
            />
            {details?.salesAnalysis.hasAnomalies ? (
              <UncontrolledAlert color={"warning"} className="alert-additional mb-4" toggle={null}>
                <div className="alert-body">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <i className="ri-error-warning-line fs-16 align-middle"></i>
                    </div>
                    {details?.salesAnalysis?.precision && (
                      <div className="flex-grow-1">
                        <p className="mb-0">{t(`SearchResults.Summary.SaleCount.Anomalies`)}</p>
                      </div>
                    )}
                  </div>
                </div>
              </UncontrolledAlert>
            ) : (
              details?.salesAnalysis?.precision && (
                <UncontrolledAlert
                  color={details?.salesAnalysis?.precision === "Medium" ? "warning" : details?.salesAnalysis?.precision === "VeryClose" ? "success" : ""}
                  className="alert-additional mb-4"
                  toggle={null}
                >
                  <div className="alert-body">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <i className="ri-error-warning-line fs-16 align-middle"></i>
                      </div>
                      {details?.salesAnalysis?.precision && (
                        <div className="flex-grow-1">
                          <h5 className="alert-heading">
                            {t("ProductDetails.SalesAnalysis.Label.Precision")}: <span className="fw-medium">{t(`ProductDetails.SalesAnalysis.Alert.${details?.salesAnalysis.precision}.Title`)}</span>
                          </h5>
                          <p className="mb-0">{t(`ProductDetails.SalesAnalysis.Alert.${details?.salesAnalysis.precision}.Description`)}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </UncontrolledAlert>
              )
            )}
            <Card>
              <CardBody>
                <div className="fs-15">
                  <div className="d-flex pb-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-package-variant align-middle text-primary me-2"></i>
                        {t("ProductDetails.SalesAnalysis.Label.SaleCount")} <i className="text-muted small">{t("ProductDetails.SalesAnalysis.Label.30Days")}</i>
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0 fw-medium">
                        <RenderNumber value={details?.salesAnalysis.saleCount} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                      </p>
                    </div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-chart-line align-middle text-primary me-2"></i>
                        {t("ProductDetails.SalesAnalysis.Label.SaleTrend")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0 fw-medium">
                        <RenderNumber value={details?.salesAnalysis.saleTrendRate} prefix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                      </p>
                    </div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="bx bxs-category-alt align-middle text-primary me-2"></i>
                        {t("ProductDetails.SalesAnalysis.Label.Category")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      {details?.salesAnalysis.categoryName ? <p className="mb-0">{details?.salesAnalysis.categoryName}</p> : <span className="text-muted">{t("NA")}</span>}
                    </div>
                  </div>
                  <div className="d-flex pt-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-trophy align-middle text-primary me-2"></i>
                        {t("ProductDetails.SalesAnalysis.Label.AverageBsr")} <i className="text-muted small">{t("ProductDetails.SalesAnalysis.Label.30Days")}</i>
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0">
                        <RenderNumber value={details?.salesAnalysis.averageBSR} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SalesAnalysis;
