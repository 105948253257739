import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner, FormGroup, Card, CardHeader, CardBody} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {CreateSubUserForm} from "../forms";
import {useState} from "react";
import {createSubUserInvitation} from "slices/account/thunk";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import Checkbox from "Components/Common/Checkbox";
import * as Yup from "yup";
import { loadLoggedUser } from "slices/thunks";

interface ICreateSubUserProps {
  show: boolean;
  toggle: () => void;
}

const CreateSubUser = (props: ICreateSubUserProps) => {
  const {t} = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      analyzer: false,
      accounting: false,
      reporting: false,
    },
    validationSchema: Yup.object<CreateSubUserForm>({
      email: Yup.string()
        .email(t("Account.Team.Validation.Email"))
        .required(t("Account.Team.Validation.Email")),
      analyzer: Yup.boolean(),
      accounting: Yup.boolean(),
      reporting: Yup.boolean(),
    }),
    onSubmit: (values: CreateSubUserForm) => {
      const roleSelected = values.accounting || values.analyzer || values.reporting;
      setHasError(!roleSelected);
      if (roleSelected) {
        const savePromise = createSubUserInvitation(values)(dispatch);
        savePromise.then((isSuccess) => {
          if (isSuccess) {
            loadLoggedUser()(dispatch)
            props.toggle();
            validation.resetForm();
          }
        });
      }
    },
  });

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
    }),
  );

  const {loading} = useSelector(searchResultsData);

  const toggle = () => {
    props.toggle();
    validation.resetForm();
  };
  return (
    <>
      <Modal backdrop="static" id="showCreateSubUserModal" isOpen={props.show} toggle={toggle} fade={true} centered={true}>
        <Form onSubmit={validation.handleSubmit}>
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {t("Account.Team.Dialog.Create.Title")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="emailAddresses">{t("Account.Team.Label.Email")}</Label>
                  <ValidatedInput validation={validation} type="email" field={"email"} maxLength={100} placeholder={t("Email")} disableValidationUI />
                </FormGroup>
                <Card>
                  <CardHeader className="bg-light">{t("Account.Team.SelectRoleText")}</CardHeader>
                  <CardBody>
                    <ValidationWrapper validation={validation} field="accounting">
                      <Checkbox>
                        <span>{t("Roles.Accounting")}</span>
                      </Checkbox>
                    </ValidationWrapper>

                    <ValidationWrapper validation={validation} field="analyzer">
                      <Checkbox>
                        <span>{t("Roles.Analyzer")}</span>
                      </Checkbox>
                    </ValidationWrapper>

                    <ValidationWrapper validation={validation} field="reporting">
                      <Checkbox>
                        <span>{t("Roles.Reporting")}</span>
                      </Checkbox>
                    </ValidationWrapper>
                  </CardBody>
                </Card>
                {hasError && <div className="text-danger">{t("Account.Team.Validation.Role")}</div>}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.save}>
                {t("Account.Team.Dialog.Create.CancelButton")}
              </Button>
              <Button type="submit" color="success" disabled={loading.save}>
                {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
                {t("Account.Team.Dialog.Create.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CreateSubUser;
