import React, {CSSProperties, useCallback, useEffect, useRef, useState} from "react";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import {Carrier, RateMode} from "models/enums/carrier";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getCostAndFeeOption, resetDefaultCostAndFees, updateCostAndFeeSettings} from "slices/settings/thunk";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/useProfile";
import {formatBytes} from "helpers/utilities";
import {saveAs} from "file-saver";
import {read, utils} from "xlsx";
import {Workbook} from "exceljs";
import {useTranslation} from "react-i18next";
import {useAmazonMarketplace} from "Components/Hooks/useAmazonMarketplace";
import classnames from "classnames";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Loader from "Components/Common/Loader";
import Select from "react-select";
import ErrorPopover from "Components/Common/ErrorPopover";
import ValidatedInput from "Components/Common/ValidatedInput";
import Dropzone from "react-dropzone";
import FedexExpressLogo from "assets/images/carrier/fedex-express.svg";
import FedexGroundLogo from "assets/images/carrier/fedex-ground.svg";
import UpsStandardLogo from "assets/images/carrier/ups-standard.png";
import UpsEconomyLogo from "assets/images/carrier/ups-economy.png";
import PurolatorGroundLogo from "assets/images/carrier/purolator-ground.svg";
import ApcLogo from "assets/images/carrier/apc.png";
import * as Yup from "yup";
import {UpdateCostAndFeeItemCommand} from "api/command";

const PAGE_IDENTIFIER: ConstantPage = "settings.costAndFees";
const CostAndFeesEdit = () => {
  const PREP_AND_SHIP_TAB = "prepAndShipTab";
  const CROSS_BORDER_ARBITRAGE_TAB = "crossBorderArbitrageTab";
  const WHOLESALE_TAB = "wholesaleTab";
  const DROPSHIPPING_TAB = "dropshippingTab";
  const TAXES_TAB = "taxesTab";
  const FEES_TAB = "feesTab";
  const OTHER_COSTS_TAB = "otherCostsTab";
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const {activeMarketplaces} = useAmazonMarketplace();

  const [activeTab, setActiveTab] = useState(PREP_AND_SHIP_TAB);
  const [prepAndShipActiveTab, setPrepAndShipActiveTab] = useState(CROSS_BORDER_ARBITRAGE_TAB);
  const [crossBorderTabHasError, setCrossBorderTabHasError] = useState<boolean>(false);
  const [wholesaleTabHasError, setWholesaleTabHasError] = useState<boolean>(false);
  const [dropshippingTabHasError, setDropshippingTabHasError] = useState<boolean>(false);
  const [prepAndShipHasError, setPrepAndShipHasError] = useState(false);
  const [taxesHasError, setTaxesHasError] = useState(false);
  const [feesHasError, setFeesHasError] = useState(false);
  const [otherCostsHasError, setOtherCostsHasError] = useState(false);
  const [settingsOptions, setSettingsOptions] = useState<SelectOptionsType[]>();
  const [selectedSettingOption, setSelectedSettingOption] = useState<SelectOptionsType>();
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const {userSettingCostAndFeeOptionId} = useParams();
  const dispatch: any = useDispatch();
  const resetDialogRef = useRef<DialogRef>(null);

  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      currentCostAndFeeOption: state.Settings.currentCostAndFeeOption,
      costAndFeesList: state.Settings.costAndFeesList,
      defaultCarrierMinRates: state.Settings.defaultCarrierMinRates,
    }),
  );
  const {loading, currentCostAndFeeOption, costAndFeesList, defaultCarrierMinRates} = useSelector(settingsPageData);

  const [selectedRateMode, setSelectedRateMode] = useState<RateMode>(currentCostAndFeeOption.rateMode);
  const [selectedCarrier, setSelectedCarrier] = useState<Carrier>(currentCostAndFeeOption.selectedCarrier);
  const [customRateFile, setCustomRateFile] = useState<File>();

  useEffect(() => {
    if (userSettingCostAndFeeOptionId && hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getCostAndFeeOption(userSettingCostAndFeeOptionId));
    }
  }, [dispatch, userSettingCostAndFeeOptionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const prepAndShipTabChange = (tab: any) => {
    if (prepAndShipActiveTab !== tab) setPrepAndShipActiveTab(tab);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentCostAndFeeOption?.name,
      isDefault: currentCostAndFeeOption?.isDefault,
      shippingCostPerLbs: currentCostAndFeeOption?.shippingCostPerLbs,
      labelingCost: currentCostAndFeeOption?.labelingCost,
      productAcceptanceCost: currentCostAndFeeOption?.productAcceptanceCost,
      minPrepAndShipPrice: currentCostAndFeeOption?.minPrepAndShipPrice,
      customsRate: currentCostAndFeeOption?.customsRate,
      stateSalesTaxRate: currentCostAndFeeOption?.stateSalesTaxRate,
      marketplaceTaxRate: currentCostAndFeeOption?.marketplaceTaxRate,
      marketplaceTaxExceptionRate: currentCostAndFeeOption?.marketplaceTaxExceptionRate,
      fbaFeeTaxRate: currentCostAndFeeOption?.fbaFeeTaxRate,
      referralFeeTaxRate: currentCostAndFeeOption?.referralFeeTaxRate,
      bankTransferCommissionRate: currentCostAndFeeOption?.bankTransferCommissionRate,
      estimatedRefundRate: currentCostAndFeeOption?.estimatedRefundRate,
      currencyExchangeLossRate: currentCostAndFeeOption?.currencyExchangeLossRate,
      userDefinedRates: currentCostAndFeeOption?.userDefinedRates,
      rateMode: currentCostAndFeeOption?.rateMode,
      selectedCarrier: currentCostAndFeeOption?.selectedCarrier,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Settings.CostAndFees.Validation.Name")),
      isDefault: Yup.boolean(),
      shippingCostPerLbs: Yup.number().required(t("Settings.CostAndFees.Validation.ShippingCostPerLbs")),
      labelingCost: Yup.number().required(t("Settings.CostAndFees.Validation.LabelingCost")),
      productAcceptanceCost: Yup.number().required(t("Settings.CostAndFees.Validation.ProductAcceptanceCost")),
      minPrepAndShipPrice: Yup.number().required(t("Settings.CostAndFees.Validation.MinPrepAndShipPrice")),
      customsRate: Yup.number().required(t("Settings.CostAndFees.Validation.CustomsRate")),
      stateSalesTaxRate: Yup.number().required(t("Settings.CostAndFees.Validation.StateSalesTaxRate")),
      marketplaceTaxRate: Yup.number().required(t("Settings.CostAndFees.Validation.MarketplaceTaxRate")),
      marketplaceTaxExceptionRate: Yup.number().required(t("Settings.CostAndFees.Validation.MarketplaceTaxExceptionRate")),
      fbaFeeTaxRate: Yup.number().required(t("Settings.CostAndFees.Validation.FbaFeeTaxRate")),
      referralFeeTaxRate: Yup.number().required(t("Settings.CostAndFees.Validation.ReferralFeeTaxRate")),
      bankTransferCommissionRate: Yup.number().required(t("Settings.CostAndFees.Validation.BankTransferCommissionRate")),
      estimatedRefundRate: Yup.number().required(t("Settings.CostAndFees.Validation.EstimatedRefundRate")),
      currencyExchangeLossRate: Yup.number().required(t("Settings.CostAndFees.Validation.CurrencyExchangeLossRate")),
      rateMode: Yup.mixed<RateMode>(),
      selectedCarrier: Yup.mixed<Carrier>().when("rateMode", ([rateMode], schema) => {
        if (rateMode === RateMode.PRE_DEFINED)
          return schema.required(t("Settings.CostAndFees.Validation.SelectedCarrier")).notOneOf([Carrier.CUSTOM], t("Settings.CostAndFees.Validation.SelectedCarrier"));
        else return schema;
      }),
      userDefinedRates: Yup.string().when("rateMode", ([rateMode], schema) => {
        if (rateMode === RateMode.CUSTOM) return schema.required(t("Settings.CostAndFees.Validation.UserDefinedRates"));
        else return schema;
      }),
    }),
    onSubmit: (values) => {
      const payload: UpdateCostAndFeeItemCommand = {
        userSettingCostAndFeeOptionId: userSettingCostAndFeeOptionId as string,
        isDefault: values.isDefault,
        name: values.name,
        shippingCostPerLbs: values.shippingCostPerLbs,
        labelingCost: values.labelingCost,
        productAcceptanceCost: values.productAcceptanceCost,
        minPrepAndShipPrice: values.minPrepAndShipPrice,
        customsRate: values.customsRate,
        stateSalesTaxRate: values.stateSalesTaxRate,
        marketplaceTaxRate: values.marketplaceTaxRate,
        marketplaceTaxExceptionRate: values.marketplaceTaxExceptionRate,
        fbaFeeTaxRate: values.fbaFeeTaxRate,
        referralFeeTaxRate: values.referralFeeTaxRate,
        bankTransferCommissionRate: values.bankTransferCommissionRate,
        estimatedRefundRate: values.estimatedRefundRate,
        currencyExchangeLossRate: values.currencyExchangeLossRate,
        rateMode: values.rateMode,
        selectedCarrier: values.selectedCarrier,
        userDefinedRates: values.userDefinedRates,
      };
      const updatePromise = updateCostAndFeeSettings(payload)(dispatch);
      updatePromise.then((success) => {
        if (success) {
          validation.resetForm();
          setCustomRateFile(undefined);
        }
      });
    },
  });

  const onValidationErrorsChange = useCallback(() => {
    if (validation.errors) {
      const errors = validation.errors;
      if (errors.shippingCostPerLbs || errors.minPrepAndShipPrice || errors.labelingCost || errors.productAcceptanceCost) setPrepAndShipHasError(true);
      else setPrepAndShipHasError(false);

      if (errors.marketplaceTaxRate || errors.marketplaceTaxExceptionRate || errors.stateSalesTaxRate || errors.referralFeeTaxRate || errors.fbaFeeTaxRate) setTaxesHasError(true);
      else setTaxesHasError(false);

      if (errors.bankTransferCommissionRate) setFeesHasError(true);
      else setFeesHasError(false);

      if (errors.estimatedRefundRate || errors.currencyExchangeLossRate) setOtherCostsHasError(true);
      else setOtherCostsHasError(false);

      if (errors.shippingCostPerLbs || errors.minPrepAndShipPrice || errors.labelingCost || errors.productAcceptanceCost) setCrossBorderTabHasError(true);
      else setCrossBorderTabHasError(false);

      if ((errors.minPrepAndShipPrice || errors.labelingCost || errors.productAcceptanceCost) && currentCostAndFeeOption.marketplace === "US") setWholesaleTabHasError(true);
      else setWholesaleTabHasError(false);

      if (errors.userDefinedRates || errors.selectedCarrier) setDropshippingTabHasError(true);
      else setDropshippingTabHasError(false);
    }
  }, [validation.errors]); // eslint-disable-line

  const onCostAndFeesListChange = useCallback(() => {
    if (costAndFeesList.length > 0) {
      const optionArray: SelectOptionsType[] = [];
      costAndFeesList.forEach((item) => {
        optionArray.push({value: item.userSettingCostAndFeeOptionId, label: item.name});
      });
      setSettingsOptions(optionArray);

      const selectedOption = optionArray.find((option) => option.value === userSettingCostAndFeeOptionId);
      if (selectedOption) setSelectedSettingOption(selectedOption);

      setSelectedRateMode(currentCostAndFeeOption.rateMode);
      setSelectedCarrier(currentCostAndFeeOption.selectedCarrier);
    }
  }, [costAndFeesList, currentCostAndFeeOption]); // eslint-disable-line

  const onSelectedRateModeChange = useCallback(() => {
    validation.setFieldValue("userDefinedRates", "");
    validation.setFieldValue("rateMode", selectedRateMode);
  }, [selectedRateMode]); // eslint-disable-line

  const onSelectedCarrierChange = useCallback(() => {
    validation.setFieldValue("selectedCarrier", selectedCarrier);
  }, [selectedCarrier]); // eslint-disable-line

  const onLoading = useCallback(() => {
    if (currentCostAndFeeOption) {
      if (currentCostAndFeeOption.marketplace === "US") {
        setPrepAndShipActiveTab(WHOLESALE_TAB);
      } else {
        setPrepAndShipActiveTab(CROSS_BORDER_ARBITRAGE_TAB);
      }
    }
  }, [currentCostAndFeeOption]);

  useEffect(() => {
    onValidationErrorsChange();
  }, [onValidationErrorsChange]);

  useEffect(() => {
    onCostAndFeesListChange();
  }, [onCostAndFeesListChange]);

  useEffect(() => {
    onSelectedRateModeChange();
  }, [onSelectedRateModeChange]);

  useEffect(() => {
    onSelectedCarrierChange();
  }, [onSelectedCarrierChange]);

  useEffect(() => {
    onLoading();
  }, [onLoading]);

  const disabledStyle: CSSProperties = {
    pointerEvents: "none",
    opacity: 0.5,
  };

  const handleUserRateFile = (file: File) => {
    if (file instanceof Blob) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          const arrayBuffer = e.target.result as ArrayBuffer;
          const data = new Uint8Array(arrayBuffer);
          const workbook = read(data, {
            type: "array",
          });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          const headers = ["Desi", "Rate"];
          const sheetData: any[][] = utils.sheet_to_json(worksheet, {header: 1});
          const excelHeaders: string[] = sheetData[0] as string[];
          const headersMatch = headers.every((header) => excelHeaders.includes(header));
          const excelData = utils.sheet_to_json(worksheet, {header: 1});
          const RATE_INDEX = excelHeaders.indexOf(headers[1]);
          const DESI_INDEX = excelHeaders.indexOf(headers[0]);
          const desiCount = excelData.slice(1).length;

          let isDesiSequenceValid = true;
          const desiValues = excelData.slice(1).map((row: any) => row[DESI_INDEX]);

          for (let i = 0; i < desiValues.length; i++) {
            if (desiValues[i] !== i + 1 || desiCount < 1) {
              isDesiSequenceValid = false;
              break;
            }
          }

          if (headersMatch && isDesiSequenceValid) {
            const list: string[] = excelData.slice(1).map((row: any) => row[RATE_INDEX]);
            const value: string = list.join(";");
            validation.setFieldValue("userDefinedRates", value);
            setCustomRateFile(file);
          } else {
            validation.setFieldError("userDefinedRates", t("Invalid file format"));
            validation.setFieldTouched("userDefinedRates", true, false);
            setCustomRateFile(undefined);
          }
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleDownloadRates = async () => {
    const data: {[key: string]: string} = JSON.parse(currentCostAndFeeOption.userDefinedRates);

    const key: string = Object.keys(data)[0];
    const userDefinedRates = data[key];

    if (!userDefinedRates) {
      console.error("No user-defined rates available.");
      return;
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("User Rates");

    // Define the headers explicitly
    const headers = ["Desi", "Rate"];

    // Add headers to the worksheet
    const headerRow = worksheet.addRow(headers);

    // Make headers bold
    headerRow.eachCell((cell) => {
      cell.font = {bold: true};
    });

    // Enable autofilter for the header row
    worksheet.autoFilter = {
      from: {row: 1, column: 1},
      to: {row: 1, column: headers.length},
    };

    // Split the userDefinedRates string into an array
    const ratesArray: string[] = userDefinedRates.split(";");

    // Iterate through the ratesArray and create the data rows
    ratesArray.forEach((rate: string, index: number) => {
      const desi: number = index + 1;
      worksheet.addRow([desi, parseFloat(rate)]);
    });

    // Adjust column width for better readability
    worksheet.columns.forEach((column) => {
      column.width = 15;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `user_rates.xlsx`);
  };

  const handleCarrierShow = (element: JSX.Element, carrier: Carrier): JSX.Element => {
    if (currentCostAndFeeOption.marketplace === "CA") {
      if (carrier === Carrier.FEDEX_EXPRESS || carrier === Carrier.FEDEX_GROUND || carrier === Carrier.UPS_STANDARD || carrier === Carrier.PUROLATOR_GROUND) {
        return element;
      }
    } else if (currentCostAndFeeOption.marketplace === "GB") {
      if (carrier === Carrier.FEDEX_EXPRESS || carrier === Carrier.APC) {
        return element;
      }
    } else if (currentCostAndFeeOption.marketplace === "MX") {
      if (carrier === Carrier.UPS_ECONOMY) {
        return element;
      }
    } else if (currentCostAndFeeOption.marketplace === "AU") {
      if (carrier === Carrier.FEDEX_EXPRESS) {
        return element;
      }
    }

    return <></>;
  };

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/settings/cost-and-fees",
    },
    {
      label: t("Settings.CostAndFees.Title"),
      url: "/account/settings/cost-and-fees",
    },
    {
      label: currentCostAndFeeOption.name,
      url: "",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={validation.dirty} navigateTo={redirectUrl}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card style={{boxShadow: "none", marginBottom: "0px !important"}}>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle className="fw-medium">
                        <Row>
                          <Col>
                            {" "}
                            <i className="mdi mdi-currency-jpy fs-18 align-middle me-1"></i>
                            {t("Settings.CostAndFees.Title")} {">"} {currentCostAndFeeOption.name}
                          </Col>
                          <Col xs="auto">
                            {activeMarketplaces().map(
                              (marketplaceInfo) =>
                                marketplaceInfo.marketplace === currentCostAndFeeOption.marketplace && (
                                  <>
                                    <span>
                                      <span className="hstack gap-2">
                                        <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                          <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                                        </div>
                                        {`${t(marketplaceInfo.countryName)} (${marketplaceInfo.marketplace})`}
                                      </span>
                                    </span>
                                  </>
                                ),
                            )}
                          </Col>
                        </Row>
                      </CardTitle>
                      <Row className="mt-3">
                        <Col xs={12} md={4}>
                          <Label className="form-label" htmlFor="name">
                            {t("Settings.CostAndFees.Name")}
                          </Label>
                          <ValidatedInput validation={validation} type="text" field={"name"} placeholder={t("Settings.CostAndFees.NamePlaceholder")} disableValidationUI />
                          <FormFeedback>{validation.errors.name}</FormFeedback>
                        </Col>
                        <Col xs={12} md={4} className="mt-3 mt-md-0">
                          <Label className="form-label" htmlFor="selectSettings">
                            {t("Settings.CostAndFees.SelectedSettings")}
                          </Label>
                          <Select
                            id="selectSettings"
                            name="selectSettings"
                            options={settingsOptions}
                            onChange={(e: any) => {
                              const url: string = `/account/settings/cost-and-fees/edit/${e.value}`;
                              setRedirectUrl(url);
                            }}
                            value={selectedSettingOption}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader>
                  <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === PREP_AND_SHIP_TAB})} onClick={() => tabChange(PREP_AND_SHIP_TAB)}>
                        <Row>
                          {!loading.list && prepAndShipHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.PrepAndShip")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === TAXES_TAB})} onClick={() => tabChange(TAXES_TAB)} type="button">
                        <Row>
                          {!loading.list && taxesHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.Taxes")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === FEES_TAB})} onClick={() => tabChange(FEES_TAB)} type="button">
                        <Row>
                          {!loading.list && feesHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.Fees")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === OTHER_COSTS_TAB})} onClick={() => tabChange(OTHER_COSTS_TAB)} type="button">
                        <Row>
                          {!loading.list && otherCostsHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.OtherCosts")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  {loading.list ? (
                    <Loader />
                  ) : (
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={PREP_AND_SHIP_TAB}>
                        <Row>
                          <Col xs="auto">
                            <Nav className="mb-3" vertical tabs>
                              {currentCostAndFeeOption.marketplace !== "US" && (
                                <NavItem>
                                  <NavLink
                                    role="button"
                                    className={classnames({active: prepAndShipActiveTab === CROSS_BORDER_ARBITRAGE_TAB})}
                                    onClick={() => prepAndShipTabChange(CROSS_BORDER_ARBITRAGE_TAB)}
                                    type="button"
                                  >
                                    <Row>
                                      <Col>Cross-border Arbitrage</Col>
                                      {crossBorderTabHasError && (
                                        <Col xs="auto" className="text-danger pe-0">
                                          <i className="ri ri-close-circle-fill"></i>
                                        </Col>
                                      )}
                                    </Row>
                                  </NavLink>
                                </NavItem>
                              )}

                              {currentCostAndFeeOption.marketplace === "US" && (
                                <NavItem>
                                  <NavLink role="button" className={classnames({active: prepAndShipActiveTab === WHOLESALE_TAB})} onClick={() => prepAndShipTabChange(WHOLESALE_TAB)} type="button">
                                    <Row>
                                      <Col>Wholesale</Col>
                                      {wholesaleTabHasError && (
                                        <Col xs="auto" className="text-danger pe-0">
                                          <i className="ri ri-close-circle-fill"></i>
                                        </Col>
                                      )}
                                    </Row>
                                  </NavLink>
                                </NavItem>
                              )}
                              {currentCostAndFeeOption.marketplace !== "US" && (
                                <NavItem>
                                  <NavLink
                                    role="button"
                                    className={classnames({active: prepAndShipActiveTab === DROPSHIPPING_TAB})}
                                    onClick={() => prepAndShipTabChange(DROPSHIPPING_TAB)}
                                    type="button"
                                  >
                                    <Row>
                                      <Col>Dropshipping</Col>
                                      {dropshippingTabHasError && (
                                        <Col xs="auto" className="text-danger pe-0">
                                          <i className="ri ri-close-circle-fill"></i>
                                        </Col>
                                      )}
                                    </Row>
                                  </NavLink>
                                </NavItem>
                              )}
                            </Nav>
                          </Col>
                          <Col>
                            <TabContent activeTab={prepAndShipActiveTab}>
                              <TabPane tabId={CROSS_BORDER_ARBITRAGE_TAB}>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="shippingCostPerLbs" className="form-label">
                                            {t("Settings.CostAndFees.ShippingCostPerLbs")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.ShippingCostPerLbsDescription")}</p>
                                          <InputGroup>
                                            <Input
                                              id="shippingCostPerLbs"
                                              type="number"
                                              step="any"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.shippingCostPerLbs}
                                              invalid={validation.touched.shippingCostPerLbs && validation.errors.shippingCostPerLbs ? true : false}
                                            />
                                            <InputGroupText>lbs</InputGroupText>
                                            <FormFeedback>{validation.errors.shippingCostPerLbs}</FormFeedback>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="lastnameInput" className="form-label">
                                            {t("Settings.CostAndFees.MinPrepFee")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.MinPrepFeeDescription")}</p>
                                          <Input
                                            id="minPrepAndShipPrice"
                                            type="number"
                                            step="any"
                                            placeholder="USD$"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.minPrepAndShipPrice}
                                            invalid={validation.touched.minPrepAndShipPrice && validation.errors.minPrepAndShipPrice ? true : false}
                                          />
                                          <FormFeedback>{validation.errors.minPrepAndShipPrice}</FormFeedback>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="lastnameInput" className="form-label">
                                            {t("Settings.CostAndFees.LabelingCost")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.LabelingCostDescription")}</p>
                                          <InputGroup>
                                            <Input
                                              id="labelingCost"
                                              type="number"
                                              step="any"
                                              placeholder="USD$"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.labelingCost}
                                              invalid={validation.touched.labelingCost && validation.errors.labelingCost ? true : false}
                                            />
                                            <InputGroupText>item</InputGroupText>
                                            <FormFeedback>{validation.errors.labelingCost}</FormFeedback>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="emailInput" className="form-label">
                                            {t("Settings.CostAndFees.CustomsRate")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.CustomsRateDescription")}</p>
                                          <InputGroup>
                                            <Input
                                              id="customsRate"
                                              type="number"
                                              step="any"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.customsRate}
                                              invalid={validation.touched.customsRate && validation.errors.customsRate ? true : false}
                                            />
                                            <InputGroupText>%</InputGroupText>
                                            <FormFeedback>{validation.errors.customsRate}</FormFeedback>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="designationInput" className="form-label">
                                            {t("Settings.CostAndFees.ProductAcceptanceCost")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.ProductAcceptanceCostDescription")}</p>
                                          <InputGroup>
                                            <Input
                                              id="productAcceptanceCost"
                                              type="number"
                                              step="any"
                                              placeholder="USD$"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.productAcceptanceCost}
                                              invalid={validation.touched.productAcceptanceCost && validation.errors.productAcceptanceCost ? true : false}
                                            />
                                            <InputGroupText>item</InputGroupText>
                                            <FormFeedback>{validation.errors.productAcceptanceCost}</FormFeedback>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </TabPane>

                              <TabPane tabId={WHOLESALE_TAB}>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="shippingCostPerLbs" className="form-label">
                                            {t("Settings.CostAndFees.ShippingCostPerLbs")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.ShippingCostPerLbsDescription")}</p>
                                          <InputGroup>
                                            <Input
                                              id="shippingCostPerLbs"
                                              type="number"
                                              step="any"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.shippingCostPerLbs}
                                              invalid={validation.touched.shippingCostPerLbs && validation.errors.shippingCostPerLbs ? true : false}
                                            />
                                            <InputGroupText>lbs</InputGroupText>
                                            <FormFeedback>{validation.errors.shippingCostPerLbs}</FormFeedback>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="minPrepAndShipPrice" className="form-label">
                                            {t("Settings.CostAndFees.MinPrepFee")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.MinPrepFeeDescription")}</p>
                                          <Input
                                            id="minPrepAndShipPrice"
                                            placeholder="USD$"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.minPrepAndShipPrice}
                                            invalid={validation.touched.minPrepAndShipPrice && validation.errors.minPrepAndShipPrice ? true : false}
                                          />
                                          <FormFeedback>{validation.errors.minPrepAndShipPrice}</FormFeedback>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="labelingCost" className="form-label">
                                            {t("Settings.CostAndFees.LabelingCost")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.LabelingCostDescription")}</p>
                                          <InputGroup>
                                            <Input
                                              id="labelingCost"
                                              type="number"
                                              step="any"
                                              placeholder="USD$"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.labelingCost}
                                              invalid={validation.touched.labelingCost && validation.errors.labelingCost ? true : false}
                                            />
                                            <InputGroupText>item</InputGroupText>
                                            <FormFeedback>{validation.errors.labelingCost}</FormFeedback>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-3">
                                          <Label htmlFor="productAcceptanceCost" className="form-label">
                                            {t("Settings.CostAndFees.ProductAcceptanceCost")}
                                          </Label>
                                          <p className="text-muted">{t("Settings.CostAndFees.ProductAcceptanceCostDescription")}</p>
                                          <InputGroup>
                                            <Input
                                              id="productAcceptanceCost"
                                              type="number"
                                              step="any"
                                              placeholder="USD$"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.productAcceptanceCost}
                                              invalid={validation.touched.productAcceptanceCost && validation.errors.productAcceptanceCost ? true : false}
                                            />
                                            <InputGroupText>item</InputGroupText>
                                            <FormFeedback>{validation.errors.productAcceptanceCost}</FormFeedback>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </TabPane>

                              <TabPane tabId={DROPSHIPPING_TAB}>
                                <Card>
                                  <CardBody>
                                    <Row className="d-flex align-items-start">
                                      <p>{t("Settings.CostAndFees.Label.SelectRate")}</p>

                                      <Col
                                        md={6}
                                        className="same-height"
                                        style={{cursor: selectedRateMode !== RateMode.PRE_DEFINED ? "pointer" : "unset"}}
                                        onClick={selectedRateMode !== RateMode.PRE_DEFINED ? () => setSelectedRateMode(RateMode.PRE_DEFINED) : undefined}
                                      >
                                        <Card className={`rounded-4  mb-0 ${selectedRateMode === RateMode.PRE_DEFINED ? "shadow-lg card-item-checked-lg" : "shadow-sm"}`}>
                                          <CardBody>
                                            <div className="mb-1">
                                              <input
                                                type="radio"
                                                id="preDefined"
                                                name="rateOption"
                                                value="preDefined"
                                                checked={selectedRateMode === RateMode.PRE_DEFINED}
                                                onChange={() => setSelectedRateMode(RateMode.PRE_DEFINED)}
                                              />
                                              <label htmlFor="preDefined" className="ms-2">
                                                {t("Settings.CostAndFees.Label.UsePredefinedRates.Title")}
                                              </label>
                                            </div>
                                            <p
                                              className="text-muted"
                                              dangerouslySetInnerHTML={{
                                                __html: t("Settings.CostAndFees.Label.UsePredefinedRates.Description"),
                                              }}
                                            ></p>

                                            {validation.errors.selectedCarrier && <span className="text-danger">{validation.errors.selectedCarrier}</span>}
                                            <div style={selectedRateMode !== RateMode.PRE_DEFINED ? disabledStyle : {}}>
                                              {handleCarrierShow(
                                                <div className="mt-4">
                                                  <input
                                                    type="radio"
                                                    id="fedexExpress"
                                                    name="carrierOption"
                                                    value={Carrier.FEDEX_EXPRESS}
                                                    checked={selectedCarrier === Carrier.FEDEX_EXPRESS}
                                                    onChange={() => setSelectedCarrier(Carrier.FEDEX_EXPRESS)}
                                                  />
                                                  <label htmlFor="fedexExpress" className="ms-2">
                                                    <img src={FedexExpressLogo} alt="FedEx Express Logo" className="me-2" style={{maxWidth: "70px"}} />
                                                    FedEx Express{" "}
                                                    <span className="text-muted">{t("Settings.CostAndFees.Label.PredefinedCarrierMinPrice", {value: defaultCarrierMinRates.fedexExpress})}</span>
                                                  </label>
                                                </div>,
                                                Carrier.FEDEX_EXPRESS,
                                              )}

                                              {handleCarrierShow(
                                                <div className="mt-4">
                                                  <input
                                                    type="radio"
                                                    id="ups"
                                                    name="carrierOption"
                                                    value={Carrier.FEDEX_GROUND}
                                                    checked={selectedCarrier === Carrier.FEDEX_GROUND}
                                                    onChange={() => setSelectedCarrier(Carrier.FEDEX_GROUND)}
                                                  />
                                                  <label htmlFor="ups" className="ms-2">
                                                    <img src={FedexGroundLogo} alt="UPS Logo" className="me-2" style={{maxWidth: "70px"}} />
                                                    Fedex Ground{" "}
                                                    <span className="text-muted">{t("Settings.CostAndFees.Label.PredefinedCarrierMinPrice", {value: defaultCarrierMinRates.fedexGround})}</span>
                                                  </label>
                                                </div>,
                                                Carrier.FEDEX_GROUND,
                                              )}

                                              {handleCarrierShow(
                                                <div className="mt-4">
                                                  <input
                                                    type="radio"
                                                    id="ups-standard"
                                                    name="carrierOption"
                                                    value={Carrier.UPS_STANDARD}
                                                    checked={selectedCarrier === Carrier.UPS_STANDARD}
                                                    onChange={() => setSelectedCarrier(Carrier.UPS_STANDARD)}
                                                  />
                                                  <label htmlFor="ups-standard" className="ms-2">
                                                    <img src={UpsStandardLogo} alt="UPS Standard Logo" className="me-2" style={{maxWidth: "70px"}} />
                                                    UPS Standard{" "}
                                                    <span className="text-muted">{t("Settings.CostAndFees.Label.PredefinedCarrierMinPrice", {value: defaultCarrierMinRates.upsStandard})}</span>
                                                  </label>
                                                </div>,
                                                Carrier.UPS_STANDARD,
                                              )}

                                              {handleCarrierShow(
                                                <div className="mt-4">
                                                  <input
                                                    type="radio"
                                                    id="ups-economy"
                                                    name="carrierOption"
                                                    value={Carrier.UPS_ECONOMY}
                                                    checked={selectedCarrier === Carrier.UPS_ECONOMY}
                                                    onChange={() => setSelectedCarrier(Carrier.UPS_ECONOMY)}
                                                  />
                                                  <label htmlFor="ups-economy" className="ms-2">
                                                    <img src={UpsEconomyLogo} alt="UPS Economy Logo" className="me-2" style={{maxWidth: "70px"}} />
                                                    UPS Economy{" "}
                                                    <span className="text-muted">{t("Settings.CostAndFees.Label.PredefinedCarrierMinPrice", {value: defaultCarrierMinRates.upsEconomy})}</span>
                                                  </label>
                                                </div>,
                                                Carrier.UPS_ECONOMY,
                                              )}

                                              {handleCarrierShow(
                                                <div className="mt-4">
                                                  <input
                                                    type="radio"
                                                    id="purolator-ground"
                                                    name="carrierOption"
                                                    value={Carrier.PUROLATOR_GROUND}
                                                    checked={selectedCarrier === Carrier.PUROLATOR_GROUND}
                                                    onChange={() => setSelectedCarrier(Carrier.PUROLATOR_GROUND)}
                                                  />
                                                  <label htmlFor="purolator-ground" className="ms-2">
                                                    <img src={PurolatorGroundLogo} alt="Purolator Ground Logo" className="me-2" style={{maxWidth: "70px"}} />
                                                    Purolator Ground{" "}
                                                    <span className="text-muted">{t("Settings.CostAndFees.Label.PredefinedCarrierMinPrice", {value: defaultCarrierMinRates.purolatorGround})}</span>
                                                  </label>
                                                </div>,
                                                Carrier.PUROLATOR_GROUND,
                                              )}

                                              {handleCarrierShow(
                                                <div className="mt-4">
                                                  <input
                                                    type="radio"
                                                    id="apc"
                                                    name="carrierOption"
                                                    value={Carrier.APC}
                                                    checked={selectedCarrier === Carrier.APC}
                                                    onChange={() => setSelectedCarrier(Carrier.APC)}
                                                  />
                                                  <label htmlFor="apc" className="ms-2">
                                                    <img src={ApcLogo} alt="APC Logo" className="me-2" style={{maxWidth: "70px"}} />
                                                    APC <span className="text-muted">{t("Settings.CostAndFees.Label.PredefinedCarrierMinPrice", {value: defaultCarrierMinRates.apc})}</span>
                                                  </label>
                                                </div>,
                                                Carrier.APC,
                                              )}
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </Col>

                                      <Col
                                        md={6}
                                        className="same-height"
                                        style={{cursor: selectedRateMode !== RateMode.CUSTOM ? "pointer" : "unset"}}
                                        onClick={() => setSelectedRateMode(RateMode.CUSTOM)}
                                      >
                                        <Card className={`rounded-4  mb-0 ${selectedRateMode === RateMode.CUSTOM ? "shadow-lg card-item-checked-lg" : "shadow-sm"}`}>
                                          <CardBody>
                                            <div className="mb-1">
                                              <input
                                                type="radio"
                                                id="customUpload"
                                                name="rateOption"
                                                value="customUpload"
                                                checked={selectedRateMode === RateMode.CUSTOM}
                                                onChange={() => setSelectedRateMode(RateMode.CUSTOM)}
                                              />
                                              <label htmlFor="customUpload" className="ms-2">
                                                {t("Settings.CostAndFees.Label.UploadCustomRates.Title")}
                                              </label>
                                            </div>
                                            <p className="text-muted">{t("Settings.CostAndFees.Label.UploadCustomRates.Description")}</p>
                                            <div className="acitivity-timeline acitivity-main mt-2">
                                              <div className="acitivity-item d-flex pb-5" style={selectedRateMode !== RateMode.CUSTOM ? disabledStyle : {}}>
                                                <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                  <div className="avatar-title bg-secondary-subtle text-secondary rounded-circle">
                                                    <i className="ri-download-2-line"></i>
                                                  </div>
                                                </div>
                                                {currentCostAndFeeOption.selectedCarrier !== Carrier.CUSTOM ? (
                                                  <div className="flex-grow-1 ms-3">
                                                    <h6 className="mb-2 lh-base">{t("Settings.CostAndFees.Label.UploadCustomRates.DownloadSampleRateFile")}</h6>
                                                    <div className="lh-base">
                                                      <Link to="/templates/RateTemplate.xlsx" target="_blank" className="btn btn-soft-info waves-effect waves-light" download>
                                                        {" "}
                                                        {/* TODO */}
                                                        {t("Download")}
                                                      </Link>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="flex-grow-1 ms-3">
                                                    <h6 className="mb-2 lh-base">{t("Settings.CostAndFees.Label.UploadCustomRates.DownloadYourCurrentRates")}</h6>
                                                    <div className="lh-base">
                                                      <Button type="button" className="btn btn-soft-info waves-effect waves-light" onClick={handleDownloadRates}>
                                                        {" "}
                                                        {/* TODO */}
                                                        {t("Settings.CostAndFees.Label.UploadCustomRates.DownloadRates")}
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="acitivity-item d-flex" style={selectedRateMode !== RateMode.CUSTOM ? disabledStyle : {}}>
                                                <div className="flex-shrink-0 avatar-sm acitivity-avatar">
                                                  <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                                    <i className="ri-upload-cloud-2-fill"></i>
                                                  </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                  <h6 className="mb-2 lh-base">{t("Settings.CostAndFees.Label.UploadYourUpdatedRates.Title")}</h6>
                                                  <Dropzone
                                                    maxFiles={1}
                                                    onDrop={(acceptedFiles: any) => {
                                                      handleUserRateFile(acceptedFiles[0]); // Handle the first file only
                                                    }}
                                                    accept={{
                                                      "text/csv": [".csv"],
                                                      "application/vnd.ms-excel": [".xls"],
                                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                                                    }}
                                                  >
                                                    {({getRootProps, getInputProps}) => (
                                                      <div className={"dz-clickable"}>
                                                        <div className="dz-message needsclick" {...getRootProps()}>
                                                          <div className="alert alert-light d-flex align-items-center mb-0">
                                                            <div className="flex-shrink-0">
                                                              <i className="ri-upload-cloud-2-fill text-success align-bottom display-6"></i>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                              <p className="text-muted mb-0">{t("Settings.CostAndFees.Label.UploadYourUpdatedRates.Dropzone")}</p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Dropzone>
                                                  {validation.touched.userDefinedRates && validation.errors.userDefinedRates && (
                                                    <p className="mt-2 text-danger">{validation.errors.userDefinedRates.toString()}</p>
                                                  )}
                                                  <div className="list-unstyled mb-0" id="file-previews">
                                                    {customRateFile && (
                                                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                        <div className="p-2">
                                                          <Row className="align-items-center">
                                                            <Col>
                                                              <Link to="#" className="text-muted font-weight-bold">
                                                                {customRateFile?.name}
                                                              </Link>
                                                              <p className="mb-0">
                                                                <strong>{formatBytes(customRateFile?.size)}</strong>
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      </Card>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId={TAXES_TAB}>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.StateSalesTaxRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.StateSalesTaxRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="stateSalesTaxRate"
                                  type="number"
                                  step="any"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.stateSalesTaxRate}
                                  invalid={validation.touched.stateSalesTaxRate && validation.errors.stateSalesTaxRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.stateSalesTaxRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          {currentCostAndFeeOption.marketplace !== "US" && (
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="emailInput" className="form-label">
                                  {t("Settings.CostAndFees.MarketplaceTaxExceptionRate")}
                                </Label>
                                <p className="text-muted">{t("Settings.CostAndFees.MarketplaceTaxExceptionRateDescription")}</p>
                                <InputGroup>
                                  <Input
                                    id="marketplaceTaxExceptionRate"
                                    type="number"
                                    step="any"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.marketplaceTaxExceptionRate}
                                    invalid={validation.touched.marketplaceTaxExceptionRate && validation.errors.marketplaceTaxExceptionRate ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                  <FormFeedback>{validation.errors.marketplaceTaxExceptionRate}</FormFeedback>
                                </InputGroup>
                              </div>
                            </Col>
                          )}

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.MarketplaceTaxRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.MarketplaceTaxRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="marketplaceTaxRate"
                                  type="number"
                                  step="any"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.marketplaceTaxRate}
                                  invalid={validation.touched.marketplaceTaxRate && validation.errors.marketplaceTaxRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.marketplaceTaxRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.ReferralFeeTaxRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.ReferralFeeTaxRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="referralFeeTaxRate"
                                  type="number"
                                  step="any"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.referralFeeTaxRate}
                                  invalid={validation.touched.referralFeeTaxRate && validation.errors.referralFeeTaxRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.referralFeeTaxRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.FbaFeeTaxRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.FbaFeeTaxRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="fbaFeeTaxRate"
                                  type="number"
                                  step="any"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.fbaFeeTaxRate}
                                  invalid={validation.touched.fbaFeeTaxRate && validation.errors.fbaFeeTaxRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.fbaFeeTaxRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId={FEES_TAB}>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.BankTransferCommissionRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.BankTransferCommissionRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="bankTransferCommissionRate"
                                  type="number"
                                  step="any"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.bankTransferCommissionRate}
                                  invalid={validation.touched.bankTransferCommissionRate && validation.errors.bankTransferCommissionRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.bankTransferCommissionRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          <Col></Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId={OTHER_COSTS_TAB}>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.EstimatedRefundRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.EstimatedRefundRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="estimatedRefundRate"
                                  type="number"
                                  step="any"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.estimatedRefundRate}
                                  invalid={validation.touched.estimatedRefundRate && validation.errors.estimatedRefundRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.estimatedRefundRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.CurrencyExchangeLoss")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.CurrencyExchangeLossDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="currencyExchangeLossRate"
                                  type="number"
                                  step="any"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.currencyExchangeLossRate}
                                  invalid={validation.touched.currencyExchangeLossRate && validation.errors.currencyExchangeLossRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.currencyExchangeLossRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  )}
                </CardBody>
              </Card>
              {!loading.list && (
                <Row>
                  <Col xs={12} md className="mb-3 mb-md-0">
                    <i className="ri-arrow-go-back-line align-middle me-1"></i>
                    <Link
                      color="link"
                      className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={() => resetDialogRef.current?.show()}
                      to={""}
                    >
                      {t("Settings.CostAndFees.Button.RestoreDefaultSettings")}
                    </Link>
                  </Col>
                  <Col xs={12} md="auto" className="d-flex align-items-center">
                    <Col className="me-2 d-flex justify-content-end">
                      <div className="form-check mb-0">
                        <Input
                          id="isDefault"
                          type="checkbox"
                          className="form-check-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.isDefault ? "checked" : ""}
                          checked={validation.values.isDefault}
                        />
                        <Label className="form-check-label" for="isDefault">
                          {t("Settings.CostAndFees.Button.SetAsDefault")}
                        </Label>
                      </div>
                    </Col>
                    <Col xs="auto">
                      {Object.keys(validation.errors).length > 0 && <ErrorPopover key={currentCostAndFeeOption?.userSettingCostAndFeeOptionId} validationErrors={validation.errors} />}
                    </Col>
                    <Col xs="auto" className="text-end">
                      <Button type="submit" color="success" disabled={loading.update || Object.keys(validation.errors).length > 0}>
                        {loading.update && <Spinner size="sm" className="me-2"></Spinner>}
                        {t("Settings.CostAndFees.Button.SaveChanges")}
                      </Button>
                    </Col>
                  </Col>
                </Row>
              )}
            </Form>
          </SettingsLayout>
        </Container>
      </div>
      <Dialog
        ref={resetDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-arrow-go-back-line"
        message={t("Settings.CostAndFees.Dialog.RestoreDefaultSettings.Description")}
        title={t("Settings.CostAndFees.Dialog.RestoreDefaultSettings.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await resetDefaultCostAndFees(currentCostAndFeeOption?.userSettingCostAndFeeOptionId)(dispatch);
            await getCostAndFeeOption(currentCostAndFeeOption?.userSettingCostAndFeeOptionId)(dispatch);
            setCustomRateFile(undefined);
          }
          resetDialogRef.current?.hide();
        }}
      />
    </React.Fragment>
  );
};

export default CostAndFeesEdit;
