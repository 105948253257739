import {useState, useMemo, useCallback} from "react";
import {formatCurrency} from "helpers/utilities";
import AmazonMarketplaceInfos from "./AmazonMarketplaceInfos";
import CountUp from "react-countup";
import _ from "lodash";

interface DisplayPriceProps {
  source?: string;
  value?: number;
  decimals: number;
  countUp?: boolean;
  prefix?: string;
  suffix?: string;
  className?: string;
  renderOnEmpty?: JSX.Element;
  notation: "decimal" | "abbreviated";
}

const DisplayPrice = (props: DisplayPriceProps) => {
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const getCurrencySymbol = useMemo((): string => {
    return amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === props.source)?.priceSign || "";
  }, [props.source, amazonMarketplaceInfos]);

  const formattingFn = useCallback(
    (value: number) => {
      return formatCurrency(getCurrencySymbol, props.decimals, props.notation, value, props.source);
    },
    [getCurrencySymbol, props.decimals, props.source, props.notation],
  );

  return (
    <span className={`text-nowrap ${props.className || ""}`}>
      {props.source &&
        (props.countUp ? (
          !_.isNil(props.value) ? (
            <CountUp
              start={0}
              prefix={`${props.prefix || ""}${getCurrencySymbol}`}
              suffix={` ${props.source}${props.suffix || ""}`}
              decimals={props.decimals}
              duration={2}
              end={props.value}
              formattingFn={formattingFn}
            />
          ) : (
            props.renderOnEmpty
          )
        ) : !_.isNil(props.value) ? (
          `${props.prefix || ""}${formatCurrency(getCurrencySymbol, props.decimals, props.notation, props.value, props.source)}${props.suffix || ""}`
        ) : (
          props.renderOnEmpty
        ))}
    </span>
  );
};

export default DisplayPrice;
