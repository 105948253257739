import { apiError, loading, markAllNotificationsAsRead, setUnreadCount, setUserWebNotifications, setUserWebNotificationsForDropdown, updateNotificationItem } from "./reducer";
import { ResponseModel } from "models/response_model";
import { PagedList } from "helpers/types";
import { postGetUserWebNotifications, postMarkAllAsRead, postReadUserWebNotification } from "services/notification_service";
import { generateError } from "helpers/utilities";
import { WebNotification, WebNotificationQuery } from "models/web_notification";

export const getUserWebNotifications = (payload: WebNotificationQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]))
    const result: ResponseModel = await postGetUserWebNotifications(payload);
    const pagedResult: PagedList<WebNotification> = result.data;
    const unreadCount: number = parseInt(result.message) as number;
    dispatch(setUserWebNotifications(pagedResult));
    dispatch(setUnreadCount(unreadCount as number));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const getUserWebNotificationsForDropdown = (payload: WebNotificationQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["dropdown", true]))
    const result: ResponseModel = await postGetUserWebNotifications(payload);
    const pagedResult: PagedList<WebNotification> = result.data;
    const unreadCount: number = parseInt(result.message) as number;
    dispatch(setUserWebNotificationsForDropdown(pagedResult));
    dispatch(setUnreadCount(unreadCount as number));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["dropdown", false]));
  }
};


export const readUserWebNotification = (userWebNotificationId: string) => async (dispatch: any) => {
  try {
    const result: ResponseModel = await postReadUserWebNotification({notificationHistoryId: userWebNotificationId});
    const userWebNotification: WebNotification = result.data;
    const unreadCount: number = parseInt(result.message) as number;
    dispatch(updateNotificationItem(userWebNotification));
    dispatch(setUnreadCount(unreadCount as number))
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  }
}
export const markAllAsRead = () => async (dispatch: any) => {
  try {
    dispatch(loading(["markAllRead", true]))
    await postMarkAllAsRead();
    dispatch(markAllNotificationsAsRead());
    dispatch(setUnreadCount(0))
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally{
    dispatch(loading(["markAllRead", false]))
  }
}