import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
//import {useProfile} from "Components/Hooks/useProfile";
import {useCallback, useEffect, useState} from "react";
import {UserStore} from "models/user_stores";
//import Moment from "moment-timezone";
import AlertComponent from "./AlertComponent";

const DisconnectedStoreAlert = () => {
  //const {userProfile} = useProfile();
  const [disconnectedStores, setDisconnectedStores] = useState<UserStore[]>();

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Common.loading,
      userStores: state.Common.userStores,
    }),
  );
  const {loading, userStores} = useSelector(commonData);

  const loadDisconnectedUserStores = useCallback(
    () => {
      if (userStores) {
        // const userTimezone = userProfile?.timezone || "UTC";
        // const twoHoursAgo = Moment()
        //   .tz(userTimezone)
        //   .subtract(2, "hours");

        const disconnectedStoreList = userStores.filter((store) => {
          // const updateDate = Moment(store.updateDate).tz(userTimezone);
          return store.status === false; //&& updateDate.isBefore(twoHoursAgo);
        });

        setDisconnectedStores(disconnectedStoreList);
      }
    },
    [userStores], // eslint-disable-line
  );

  useEffect(() => {
    loadDisconnectedUserStores();
  }, [loadDisconnectedUserStores]);

  if (loading.userStore) {
    return null;
  }

  return disconnectedStores && disconnectedStores.length > 0 ? (
    <AlertComponent
      alertType={{color: "danger", icon: "ri-alert-line", translationKey: "DisconnectedStoreAlert"}}
      replaceVariables={{storeNames: disconnectedStores.map((store) => store.name).join(", ")}}
    />
  ) : (
    ""
  );
};

export default DisconnectedStoreAlert;
