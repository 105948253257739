import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {ISetUserShipmentDetailsDto, UserShipmentStatus} from "models/user_shipment";
import {Link, useNavigate} from "react-router-dom";
import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import Moment from "react-moment";
import i18n from "../../../../i18n";
import img from "../../../../assets/images/amazon-package.png";

import "moment/locale/tr";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {setUserShipmentDetails} from "slices/thunks";
import EditShipment from "../../Shipments/Modal/EditShipment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

export type ListRef = {
  reload: VoidFunction;
};
const ShipmentInfo = () => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const [status, setStatus] = useState<UserShipmentStatus>();
  const [isEditing, setIsEditing] = useState(false);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();
  const implementSettingsDialogRef = useRef<DialogRef>(null);
  const listRef = useRef<ListRef>(null);
  const navigate = useNavigate();

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {loading, currentUserShipment} = useSelector(shipmentsData);
  useEffect(() => {
    if (currentUserShipment) {
      let source = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === "US");
      let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setStatus(Number(currentUserShipment.status));
      setSourceMarketplace(source);
      setDestinationMarketplace(destination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const handleUpdateSettings = () => {
    let newShipmentDetails: ISetUserShipmentDetailsDto = {
      userShipmentId: currentUserShipment.userShipmentId,
      userInventoryId: "",
      userShipmentItemId: "",
      field: "ShipmentSettings",
      newValue: 0,
      shipDate: "",
      amazonShipmentId: "",
      from: "Shipments",
    };

    setUserShipmentDetails(newShipmentDetails, "implementSettings")(dispatch);
  };

  return (
    <React.Fragment>
      <Card className="mx-n4 mb-n5">
        <div className={status === UserShipmentStatus.SHIPPED ? "bg-success-subtle" : status === UserShipmentStatus.COMPLETED ? "bg-secondary-subtle" : "bg-warning-subtle"}>
          <CardBody className="pb-4 mb-5">
            <Row>
              <Col xs="auto">
                <div className="avatar-md mb-md-0 mb-4">
                  <div className="avatar-title bg-white rounded-circle">
                    <img src={img} alt="" className="avatar-sm" />
                  </div>
                </div>
              </Col>
              <Col>
                <Row className="g-3">
                  <Col xs={"auto"} className="flex-nowrap text-nowrap">
                    <h4 className="fw-semibold  mb-0">
                      <span>{"#" + currentUserShipment.nameId + " - " + currentUserShipment.name}</span>
                      <Link
                        to=""
                        id={`Edit1`}
                        className="btn btn-ghost-secondary px-1 py-0 fs-16"
                        style={{marginLeft: 10}}
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        <i className="ri-pencil-fill"></i>
                      </Link>
                      <DefaultUncontrolledTooltip target={`Edit1`}>{t("Edit")}</DefaultUncontrolledTooltip>
                    </h4>
                  </Col>
                  <Col xs={12} md={7}>
                    <div className="hstack">
                      <i className="bx bxs-location-plus text-success fs-20 me-1 common-css top--2"></i>{" "}
                      <span>
                        <span className="text-nowrap">{sourceMarketplace?.countryName} </span>
                        <img src={sourceMarketplace?.flag} alt={`${sourceMarketplace?.countryName} Flag`} className="rounded-circle" height="20" />
                      </span>
                      <span className="mx-1">
                        <i className="ri-arrow-right-line fs-20 align-bottom"></i>
                      </span>
                      <span className="text-nowrap">
                        <img src={destinationMarketplace?.flag} alt={`${t(destinationMarketplace?.countryName ?? "")} Flag`} className="rounded-circle" height="20" />{" "}
                        {t(destinationMarketplace?.countryName ?? "")}
                        {" - "}
                        <span className="text-nowrap">{currentUserShipment.userStore.name}</span>
                      </span>
                    </div>
                    <div className="d-none d-md-block ">
                      <div className="vr"></div>
                    </div>
                  </Col>
                </Row>
                <Col xs="12">
                  <Row className="d-flex align-items-center">
                    <Col xs="auto">
                      <div>
                        <i className=" ri-calendar-2-line align-bottom me-2"></i>
                        {(status === UserShipmentStatus.SHIPPED ? t("Shipped Date") : status === UserShipmentStatus.COMPLETED ? t("Completed Date") : t("Create Date")) + ": "}
                        <span className="fw-medium">
                          <Moment locale={i18n.language} format="DD/MM/Y">
                            {currentUserShipment.createDate}
                          </Moment>
                          {" ("}
                          <Moment className="text-primary" locale={i18n.language} fromNow>
                            {currentUserShipment.createDate}
                          </Moment>
                          {")"}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className="hstack gap-4 flex-wrap">
                        <div>
                          <i className="ri-flight-takeoff-line me-2"></i>
                          <span className="me-1 text-nowrap">{t("Shipments.AmazonShipmentId")}:</span>
                          <span className="fw-medium">
                            {currentUserShipment.amazonShipmentId !== "" && currentUserShipment.amazonShipmentId !== null ? (
                              currentUserShipment.amazonShipmentId
                            ) : (
                              <i className="las la-ellipsis-h align-bottom"></i>
                            )}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col xs="auto">
                <Button
                  size="sm"
                  id={`AnalyzeShipmentItems`}
                  className="btn btn-secondary fw-semibold"
                  onClick={() => navigate(`/shipments/shipment-details/${currentUserShipment.userShipmentId}/analyze`)}
                >
                  <i className="mdi mdi-chart-timeline-variant-shimmer align-middle me-1"></i>
                  {t("Shipments.ShipmentDetails.Button.ReanalyzeTheProducts")}
                </Button>
                <DefaultUncontrolledTooltip target={`AnalyzeShipmentItems`}>{t("Shipments.ShipmentDetails.Info.ReAnalyze")}</DefaultUncontrolledTooltip>
              </Col>
              <Col xs="auto">
                <div className={"badge rounded-pill fs-12 bg-warning p-2"} id="shipment-status">
                  {"Draft"}
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Card>
      <Dialog
        ref={implementSettingsDialogRef}
        color="info"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Yes"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("Close"),
            },
          };
        }}
        busy={loading.implementSettings}
        iconClass="mdi mdi-download"
        message={t("Shipments.ShipmentDetails.Dialog.ImplementSettings.Description")}
        title={t("Shipments.ShipmentDetails.Dialog.ImplementSettings.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            handleUpdateSettings();
            listRef.current?.reload();
          }
          implementSettingsDialogRef.current?.hide();
        }}
      />
      <EditShipment isOpen={isEditing} onReload={() => listRef.current?.reload()} toggle={() => setIsEditing(!isEditing)} from="ShipmentDetails" shipment={currentUserShipment} />
    </React.Fragment>
  );
};

export default ShipmentInfo;
