import {Card, CardBody, Col, Row} from "reactstrap";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {useProfile} from "Components/Hooks/useProfile";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import React from "react";
import DisplayDate from "Components/Common/DisplayDate";

interface StatsProps {
  busy: boolean;
}
const Stats = (props: StatsProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const searchLogsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      stats: state.AdminSearchLogs.stats,
    }),
  );
  const {stats} = useSelector(searchLogsData);
  return (
    <React.Fragment>
      <Row>
        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Admin.SearchLogs.Stats.Label.TotalAsins")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-18 fw-semibold ff-secondary mb-4">{stats.totalAsins}</h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-success-subtle"}>
                    <i className={`text-success  ri-file-list-line`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Admin.SearchLogs.Stats.Label.StartDate")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-18 fw-semibold ff-secondary mb-4">
                    <DisplayDate id={"DisplayDateStatsStartDate"} value={stats.startDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-secondary-subtle"}>
                    <i className={`text-secondary ri-calendar-line`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Admin.SearchLogs.Stats.Label.Duration")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-18 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">{`${stats.duration} ${t("min")}`}</span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-secondary-subtle"}>
                    <i className={`text-secondary ri-history-fill`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Admin.SearchLogs.Stats.Label.EndDate")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-18 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">
                      <DisplayDate id={`DisplayDateStatsEndDate`} value={stats.endDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-info-subtle"}>
                    <i className={`text-info ri-calendar-line`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Admin.SearchLogs.Stats.Label.Completed")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-18 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">{stats.completed}</span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-success-subtle"}>
                    <i className={`text-success bx bx-check-circle`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Admin.SearchLogs.Stats.Label.InProgress")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-18 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">{stats.inProgress}</span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-warning-subtle"}>
                    <i className={`text-warning ri-loader-2-line`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Stats;
