import React from "react";
import {UncontrolledTooltip} from "reactstrap";

interface DefaultUncontrolledTooltipProps {
  children: React.ReactNode;
  autohide?: boolean;
  fade?: boolean;
  placement?: any;
  target: string;
  placeholder?: any;
  popperClassName?: string;
  delay?: {show: number; hide: number};
}

const DefaultUncontrolledTooltip = ({
  children,
  placement = "top",
  placeholder,
  target,
  popperClassName = "tooltip-safe-distance",
  autohide = false,
  fade = false,
  delay = {show: 300, hide: 0},
  ...props
}: DefaultUncontrolledTooltipProps) => {
  return (
    <UncontrolledTooltip target={target} placement={placement} delay={delay} fade={fade} autohide={autohide} popperClassName={popperClassName} placeholder={placeholder} {...props}>
      {children}
    </UncontrolledTooltip>
  );
};

export default DefaultUncontrolledTooltip;
