import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {changeRenameModalVisibility, renameFavoriteList} from "slices/thunks";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

const Rename = () => {
  const {t} = useTranslation();

  const dispatch: any = useDispatch();
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Favorites.loading,
      selectedFavoriteList: state.Favorites.selectedFavoriteList,
      renameModalVisibility: state.Favorites.renameModalVisibility,
    }),
  );

  const {loading, selectedFavoriteList, renameModalVisibility} = useSelector(searchResultsData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {name: selectedFavoriteList.name},
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Favorites.Validation.ListName")),
    }),
    onSubmit: (values: any) => {
      const renamePromise = renameFavoriteList(selectedFavoriteList.userFavoriteId, values.name)(dispatch);
      renamePromise.then((success) => {
        if (success) {
          validation.resetForm();
          toggle();
        }
      });
    },
  });
  const toggle = () => {
    validation.resetForm();
    dispatch(changeRenameModalVisibility(!renameModalVisibility));
  };
  return (
    <>
      <Modal backdrop="static" isOpen={renameModalVisibility} toggle={toggle} fade={true} centered={true}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {t("Favorites.Rename")}
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
              <Col>
                <Label className="form-label" htmlFor="name">
                {t("Favorites.ListName")}
                </Label>
                <ValidatedInput validation={validation} field="name" maxLength={100} placeholder={t("Favorites.ListName")} disableValidationUI />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={toggle}>
                <i className="ri-close-line label-icon align-middle fs-16 me-2"></i>
                {t("Favorites.Dialog.Edit.CancelButton")}
              </Button>
              <Button type="submit" color="success" disabled={loading.rename || validation.values.name === selectedFavoriteList.name}>
                {loading.rename && <Spinner size="sm" className="me-2"></Spinner> }
                {t("Favorites.Dialog.Edit.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Rename;
