import { store } from "index";
import { UserStore } from "models/user_stores";
import {Option} from "react-multi-select-component";
import {Input, Label} from "reactstrap";
import AmazonMarketplaceInfos from "../AmazonMarketplaceInfos";
import i18n from "i18n";
import { TagColor } from "models/enums/tag_color";
import { Tag } from "models/tag";

export interface DefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const defaultItemRenderer = ({checked, option, onClick, disabled}: DefaultItemRendererProps) => (
  <div className={`d-flex align-items-center item-renderer ${disabled ? "disabled" : ""}`}>
    <Input
      id={`Option-${option.value}`}
      className="option-checkbox-style"
      type="checkbox"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
      <span className="ellipsis-for-option" title={option.label}>
        {option.label}
      </span>
    </Label>
  </div>
);

export const countryItemRenderer = ({checked, option, onClick, disabled}: DefaultItemRendererProps) => {
  const SELECT_ALL = "";
  let countryCode = option.value;
  if (option.value.length > 2) { // If value is user store id
    countryCode = store.getState().Common.userStores.find((store: UserStore) => store.userStoreId === option.value)!.marketplace;
  } else { // If value is marketplace
    countryCode = option.value;
  }
  const marketplace = AmazonMarketplaceInfos().find((info) => info.marketplace === countryCode)!;

  return (
    <div className={`d-flex align-items-center item-renderer ${disabled ? "disabled" : ""}`}>
      <Input
        id={`Option-${option.value}`}
        className="option-checkbox-style"
        type="checkbox"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      {option.value !== SELECT_ALL && <img src={marketplace.flag} alt={i18n.t(marketplace.countryName)} className="rounded-circle" width={15} height={15} />}
      <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
        <span className="ellipsis-for-option" title={option.label}>
          {option.label}
        </span>
      </Label>
    </div>
  );
};


export const tagColorItemRenderer = ({checked, option, onClick, disabled}: DefaultItemRendererProps) => {
  const tag: Tag |undefined = store.getState().Common.tags?.find((tag) => tag.tagId === option.value);
  const SELECT_ALL = "";
    let color = "";
  if (tag?.color === TagColor.PRIMARY) {
    color = "--vz-primary";
  } else if (tag?.color === TagColor.SECONDARY) {
    color = "--vz-secondary";
  } else if (tag?.color === TagColor.SUCCESS) {
    color = "--vz-success";
  } else if (tag?.color === TagColor.DANGER) {
    color = "--vz-danger";
  } else if (tag?.color === TagColor.WARNING) {
    color = "--vz-warning";
  } else if (tag?.color === TagColor.INFO) {
    color = "--vz-info";
  } else if (tag?.color === TagColor.DARK) {
    color = "--vz-dark";
  } else {
    color = "";
  }
  return (
    <div className={`d-flex align-items-center item-renderer ${disabled ? "disabled" : ""}`}>
      <Input
        id={`Option-${option.value}`}
        className="option-checkbox-style"
        type="checkbox"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      {option.value !== SELECT_ALL && <div style={{height: "20px", width: "30px", backgroundColor: `var(${color})`}}/>}
      <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
        <span className="ellipsis-for-option" title={option.label}>
          {option.label}
        </span>
      </Label>
    </div>
  );
};
