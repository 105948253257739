import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserFavorite} from "models/user_favorites";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import { renameFavoriteList } from "slices/search-result/thunk";

interface IRenameFavoriteListProps {
  isOpen: boolean;
  toggle: () => void;
  selectedFavoriteList?: UserFavorite;
}
const RenameFavoriteList = (props: IRenameFavoriteListProps) => {
  const {t} = useTranslation();

  const dispatch: any = useDispatch();
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.SearchResult.loading,
    }),
  );

  const {loading} = useSelector(searchResultsData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {name: props.selectedFavoriteList?.name},
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("SearchResults.Dialog.AddToFavorites.Validation.ListName")),
    }),
    onSubmit: (values: any) => {
      const renamePromise = renameFavoriteList(props.selectedFavoriteList?.userFavoriteId!, values.name)(dispatch);
      renamePromise.then((success) => {
        if (success) {
          validation.resetForm();
          props.toggle();
        }
      });
    },
  });

  return (
    <>
      <Modal backdrop="static" isOpen={props.isOpen} toggle={props.toggle} fade={true} centered={true}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalHeader className="bg-light p-3" toggle={props.toggle}>
            {t("SearchResults.Dialog.AddToFavorites.Dialog.Rename.Title")}
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
              <Col>
                <Label className="form-label" htmlFor="name">
                  {t("SearchResults.Dialog.AddToFavorites.ListName")}
                </Label>
                <ValidatedInput validation={validation} field={"name"} maxLength={100} placeholder={t("SearchResults.Dialog.AddToFavorites.ListName")} disableValidationUI />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={props.toggle}>
                {t("SearchResults.Dialog.AddToFavorites.Dialog.Rename.Button.Close")}
              </Button>
              <Button type="submit" color="success" disabled={loading.renameFavoriteList || validation.values.name === props.selectedFavoriteList?.name}>
                {loading.renameFavoriteList && <Spinner size="sm" className="me-2"></Spinner>}
                {t("SearchResults.Dialog.AddToFavorites.Dialog.Rename.Button.Save")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default RenameFavoriteList;
