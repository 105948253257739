import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getUserSubscriptionPackage} from "slices/account/thunk";
import {useNavigate} from "react-router-dom";
import {RootState} from "slices";
import NewSubscriptionPage from "./Page";
import Loader from "Components/Common/Loader";

const NewSubscription = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getUserSubscriptionPackage()(dispatch);
  }, [dispatch]);

  const profileData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
      userSubscription: state.Account.userSubscription,
    }),
  );

  const {loading, userSubscription} = useSelector(profileData);

  useEffect(() => {
    if (userSubscription && userSubscription.userSubscriptionId) {
      navigate("/dashboard");
    }
  }, [t, userSubscription, navigate]);

  document.title = t("PageTitles.Subscription");

  return <>{loading.userSubscription ? <Loader /> : <NewSubscriptionPage />}</>;
};

export default NewSubscription;
