//empty

import {ResponseModel} from "models/response_model";
import {
  apiError,
  appendToAnalyzeList,
  appendToCostAndFeesList,
  loading,
  removeFromAnalyzeList,
  removeFromCostAndFeesList,
  reset,
  setAnalyzeList,
  setAsDefaultAnalyzeItem,
  setAsDefaultCostAndFeeItem,
  setCostAndFeesList,
  setCurrentAnalyzeOption,
  setCurrentCostAndFeeOption,
  setDefaultCarrierMinRates,
  setDefaultCurrencies,
  setExchangeRates,
} from "./reducer";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {
  deleteAnalyzeItem,
  deleteCostAndFeeItem,
  postDuplicateCostAndFeeItem,
  postGetAnalyzeItem,
  postGetAnalyzeOptions,
  postGetCostAndFeesItem,
  postGetCostAndFees,
  postGetExchangeRates,
  postResetDefaultAnalyzeItem,
  postResetDefaultCostAndFeeItem,
  postSaveAnalyzeItem,
  postSaveCostAndFeeItem,
  postSetAsDefaultAnalyzeItem,
  postSetAsDefaultCostAndFeeItem,
  postUpdateAnalyzeItem,
  postUpdateCostAndFeeItem,
  postUpdateExchangeRates,
} from "services/identity_service";
import {UserSettingCostAndFeeOptions} from "models/user_setting_cost_and_fee_options";
import {IUpdateAnalyzeItemDto, UserSettingAnalyzeOptions} from "models/user_setting_analyze_options";
import {ExchangeOptionItem, UserSettingExchangeOptions} from "models/user_setting_exchange_options";
import {Currencies} from "models/currencies";
import i18n from "i18n";
import { CarrierRate } from "models/carrier_rate";
import { UpdateCostAndFeeItemCommand } from "api/command";

export const resetSettings = () => async (dispatch: any) => {
  dispatch(reset());
};
//#region Cost and Fees
export const getCostAndFeesList = (marketplace?: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetCostAndFees({marketplace});
    dispatch(setCostAndFeesList(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const getCostAndFeeOption = (userSettingCostAndFeeOptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetCostAndFeesItem({userSettingCostAndFeeOptionId});
    const costAndFeeOption = result.data.costAndFeeOption as UserSettingCostAndFeeOptions;
    const costAndFeeList = result.data.costAndFeeList as UserSettingCostAndFeeOptions[];
    const minRates = result.data.defaultCarrierMinRates as CarrierRate;
    dispatch(setCurrentCostAndFeeOption(costAndFeeOption));
    dispatch(setCostAndFeesList(costAndFeeList));
    dispatch(setDefaultCarrierMinRates(minRates));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const createCostAndFeesSettings = (name: string, marketplace: string, copyFromCostAndFeeSettingId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["create", true]));
    const result: ResponseModel = await postSaveCostAndFeeItem({name, marketplace, copyFromCostAndFeeSettingId});
    const newItem: UserSettingCostAndFeeOptions = result.data;
    renderSuccessToast(i18n.t("Settings.CostAndFees.Toast.SettingsCreated"));
    dispatch(appendToCostAndFeesList(newItem));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["create", false]));
  }
};

export const updateCostAndFeeSettings = (data: UpdateCostAndFeeItemCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postUpdateCostAndFeeItem(data);
    const updatedItem: UserSettingCostAndFeeOptions = result.data;
    dispatch(setCurrentCostAndFeeOption(updatedItem));
    renderSuccessToast(i18n.t("Settings.CostAndFees.Toast.SettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const duplicateCostAndFeeSettings = (userSettingCostAndFeeOptionId: string, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["duplicate", true]));
    const result: ResponseModel = await postDuplicateCostAndFeeItem({userSettingCostAndFeeOptionId, name});
    const newItem: UserSettingCostAndFeeOptions = result.data;
    dispatch(appendToCostAndFeesList(newItem));
    renderSuccessToast(i18n.t("Settings.CostAndFees.Toast.SettingsDuplicateCreated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["duplicate", false]));
  }
};

export const setAsDefaultCostAndFees = (userSettingCostAndFeeOptionId: string, marketplace: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postSetAsDefaultCostAndFeeItem({userSettingCostAndFeeOptionId, marketplace});
    const updatedItem: UserSettingCostAndFeeOptions = result.data;
    dispatch(setAsDefaultCostAndFeeItem(updatedItem));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const resetDefaultCostAndFees = (userSettingCostAndFeeOptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postResetDefaultCostAndFeeItem({userSettingCostAndFeeOptionId});
    const updatedItem: UserSettingCostAndFeeOptions = result.data;
    dispatch(setCurrentCostAndFeeOption(updatedItem));
    renderSuccessToast(i18n.t("Settings.CostAndFees.Toast.SettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const deleteCostAndFeeSettings = (userSettingCostAndFeeOptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await deleteCostAndFeeItem({userSettingCostAndFeeOptionId});
    const deletedItem: UserSettingCostAndFeeOptions = result.data;
    dispatch(removeFromCostAndFeesList(deletedItem));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};
//#endregion

//#region Analyze Options
export const getAnalyzeOptions = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetAnalyzeOptions();
    dispatch(setAnalyzeList(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const getAnalyzeOption = (userSettingAnalyzeOptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetAnalyzeItem({userSettingAnalyzeOptionId});
    const analyzeOption = result.data.analyzeOption as UserSettingAnalyzeOptions;
    const analyzeList = result.data.analyzeList as UserSettingAnalyzeOptions[];
    dispatch(setCurrentAnalyzeOption(analyzeOption));
    dispatch(setAnalyzeList(analyzeList));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const createAnalyzeOptionSettings = (name: string, copyFromAnalyzeSettingId: string, history: any) => async (dispatch: any) => {
  try {
    dispatch(loading(["create", true]));
    const result: ResponseModel = await postSaveAnalyzeItem({name, copyFromAnalyzeSettingId});
    const newItem: UserSettingAnalyzeOptions = result.data;
    dispatch(appendToAnalyzeList(newItem));
    renderSuccessToast(i18n.t("Settings.AnalyzeOptions.Toast.SettingsCreated"));
    history("/account/settings/analyze-options/edit/" + newItem.userSettingAnalyzeOptionId);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["create", false]));
  }
};

export const updateAnalyzeSettings = (data: IUpdateAnalyzeItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    await postUpdateAnalyzeItem(data);
    renderSuccessToast(i18n.t("Settings.AnalyzeOptions.Toast.SettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const setAsDefaultAnalyzeOptions = (userSettingAnalyzeOptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postSetAsDefaultAnalyzeItem({userSettingAnalyzeOptionId});
    const updatedItem: UserSettingAnalyzeOptions = result.data;
    dispatch(setAsDefaultAnalyzeItem(updatedItem));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const deleteAnalyzeOptionSettings = (userSettingAnalyzeOptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await deleteAnalyzeItem({userSettingAnalyzeOptionId});
    const deletedItem: UserSettingAnalyzeOptions = result.data;
    dispatch(removeFromAnalyzeList(deletedItem));
    dispatch(setCurrentAnalyzeOption({} as UserSettingAnalyzeOptions));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const resetDefaultAnalyze = (userSettingAnalyzeOptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postResetDefaultAnalyzeItem({userSettingAnalyzeOptionId});
    const updatedItem: UserSettingAnalyzeOptions = result.data;
    dispatch(setCurrentAnalyzeOption(updatedItem));
    renderSuccessToast(i18n.t("Settings.AnalyzeOptions.Toast.SettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

//#endregion

//#region Exchange Rates
export const getExchangeRates = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetExchangeRates();
    const userExchangeOptions: UserSettingExchangeOptions[] = result.data.userExchangeOptions;
    const defaultCurrencies: Currencies[] = result.data.defaultExchangeOptions;
    dispatch(setExchangeRates(userExchangeOptions));
    dispatch(setDefaultCurrencies(defaultCurrencies));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const updateExchangeRates = (exchanges: ExchangeOptionItem[]) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postUpdateExchangeRates({exchanges});
    const updatedItem: UserSettingExchangeOptions[] = result.data;
    dispatch(setExchangeRates(updatedItem));
    renderSuccessToast(i18n.t("Settings.ExchangeRates.Toast.SettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};
//#endregion

