import SelectOptions, {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AdminSearchesListRef} from "..";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useProfile} from "Components/Hooks/useProfile";
import {getSelectUserQuery} from "services/admin_service";
import {UserSelectOptions} from "Components/Common/Select/User";
import {AdminUserSearchesQuery} from "api/query";
import ValidateDate from "Components/Common/ValidatedDate";
import ValidatedAsyncSelect from "Components/Common/ValidatedAsyncSelect";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import moment from "moment";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import * as Yup from "yup";

interface FiltersProps {
  listRef: RefObject<AdminSearchesListRef>;
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: AdminUserSearchesQuery) => void;
  marketplaceSelectOptionsWithStores: SelectOptionsType[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const {searchStatusSelectOptions} = SelectOptions();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const start = queryParams.get("start");
    const end = queryParams.get("end");

    var payload: AdminUserSearchesQuery = {
      page: 1,
      pageSize: props.pageSize,
      filtering: true,
    };
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
      validation.setFieldValue("startDate", new Date(start));
      validation.setFieldValue("endDate", new Date(end));
      payload = {
        ...payload,
        startDate: moment
          .tz(start, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(end, userProfile?.timezone!)
          .endOf("day")
          .tz("Etc/GMT")
          .toDate(),
      };
    } else {
      setStartDate(getRelativeDateByTimezone(30, userProfile?.timezone));
      setEndDate(getToday().toDate());
      validation.setFieldValue("startDate", getRelativeDateByTimezone(30, userProfile?.timezone));
      validation.setFieldValue("endDate", getToday().toDate());
      payload = {
        ...payload,
        startDate: getRelativeDateByTimezone(30, userProfile?.timezone),
        endDate: getToday()
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
      };
    }

    props.handleFilter(payload);
  }, []); // eslint-disable-line

  // validation
  const validation = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
      marketplace: undefined,
      status: undefined,
      userId: "",
    } as AdminUserSearchesQuery,
    validationSchema: Yup.object<AdminUserSearchesQuery>({
      startDate: Yup.date()
        .min(new Date("2023-01-01"), t("Admin.Searches.Filters.Validation.StartDateMin"))
        .max(Yup.ref("endDate"), t("Admin.Searches.Filters.Validation.StartDateMax"))
        .typeError(t("Admin.Searches.Filters.Validation.InvalidDateType")),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), t("Admin.Searches.Filters.Validation.EndDateMin"))
        .max(getToday().toDate(), t("Admin.Searches.Filters.Validation.EndDateMax"))
        .typeError(t("Admin.Searches.Filters.Validation.InvalidDateType")),
      marketplace: Yup.array<String>().notRequired(),
      userId: Yup.string().notRequired(),
      status: Yup.array<String>().notRequired(),
    }),
    onSubmit: (values) => {
      const payload: AdminUserSearchesQuery = {
        page: 1,
        pageSize: props.pageSize,
        startDate: moment
          .tz(values.startDate, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(values.endDate, userProfile?.timezone!)
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
        marketplace: values.marketplace || undefined,
        status: values.status || undefined,
        filtering: true,
        userId: values.userId,
      };
      props.handleFilter(payload);
    },
  });

  const loadUserOptions = async (inputValue: string): Promise<UserSelectOptions[]> => {
    if (inputValue === "") return [];

    const response = await getSelectUserQuery({query: inputValue});
    const data: UserSelectOptions[] = await response.data;

    return data.map((item: UserSelectOptions) => ({
      label: item.label,
      value: item.value,
      avatar: item.avatar,
      email: item.email,
    }));
  };

  return (
    <PlaceholderGlow busy={false}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form onSubmit={validation.handleSubmit}>
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Admin.Searches.Filters.Title")}</Label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="startDate">{t("Admin.Searches.Filters.Label.StartDate")}</Label>
                  <ValidateDate field="startDate" validation={validation} value={validation.values.startDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="endDate">{t("Admin.Searches.Filters.Label.EndDate")}</Label>
                  <ValidateDate field="endDate" validation={validation} value={validation.values.endDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="marketplace">{t("Admin.Searches.Filters.Label.Marketplace")}</Label>
                  <ValidatedMultiSelect
                    className="common-css filter-input"
                    options={props.marketplaceSelectOptionsWithStores}
                    validation={validation}
                    field="marketplace"
                    placeholder={t("Admin.Searches.Filters.Label.SelectPlaceholder")}
                    value={validation.values.marketplace}
                    optionStyle="country"
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="user">{t("Admin.Searches.Filters.Label.User")}</Label>
                  <ValidatedAsyncSelect
                    className="common-css filter-input"
                    loadOptions={loadUserOptions}
                    validation={validation}
                    field="userId"
                    placeholder={t("Admin.Searches.Filters.Label.SelectPlaceholder")}
                    noOptionsMessage={t("Admin.Searches.Filters.Label.TypeToFindUser")}
                    loadingMessage={t("Admin.Searches.Filters.Label.UserSearching")}
                    value={validation.values.userId}
                    errorStyle="solid"
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="status">{t("Admin.Searches.Filters.Label.Status")}</Label>
                  <ValidatedMultiSelect
                    className="common-css filter-input"
                    options={searchStatusSelectOptions}
                    validation={validation}
                    field="status"
                    placeholder={t("Admin.Searches.Filters.Label.SelectPlaceholder")}
                    value={validation.values.status}
                  />
                </Col>
                <Col sm={2}>
                  <div className="common-css top-5 mt-4">
                    <Button type="submit" className="btn bg-primary" disabled={props.busy}>
                      {props.busy ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("Admin.Searches.Filters.Button.Apply")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
