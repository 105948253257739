import getChartColorsArray from "Components/Common/ChartsDynamicColor";
import i18n from "i18n";
import ReactApexChart from "react-apexcharts";

export type SaleHistorySeries = {
  id:string;
  name: string;
  data: number[];
  type: "line" | "area" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "rangeArea";
};
interface SaleHistoryChartProps {
  dataColors: any;
  series: SaleHistorySeries[];
  days: Date[];
  currency?: string;
}
export const SaleHistoryChart = (props: SaleHistoryChartProps) => {
  var lineChartCustomerColors = getChartColorsArray(props.dataColors);

  const soldItemsCountSeries = props.series.find(series => series.id === "SoldItemsCount");
  const revenueSeries = props.series.find(series => series.id === "Revenue");
  const profitSeries = props.series.find(series => series.id === "Profit");
  const maxRevenue = revenueSeries ? Math.max(...revenueSeries.data) : 0;
  const maxProfit = profitSeries ? Math.max(...profitSeries.data) : 0;

  const maxSoldItemsCount = soldItemsCountSeries ? Math.max(...soldItemsCountSeries.data) : 0;
  const maxRevenueAndProfit = maxRevenue > maxProfit ? maxRevenue * 1.1 : maxProfit * 1.1;

  const yAxisMax_SOLD_ITEMS_COUNT = maxSoldItemsCount > 0 ? maxSoldItemsCount * 1.1 : 10; // Adding 10% padding or default to 10 if no data
  const yAxisMax_REVENUE_AND_PROFIT = maxRevenueAndProfit > 0 ? maxRevenueAndProfit * 1.1 : 10; // Adding 10% padding or default to 10 if no data


  var options: ApexCharts.ApexOptions = {
    chart: {
      height: 370,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
        type: "x",
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0, 0],
    },
    fill: {
      opacity: [0.1, 0.9, 1],
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: props.days.map((day) => {
        const options: Intl.DateTimeFormatOptions = {day: "2-digit", month: "short"};
        return new Intl.DateTimeFormat(i18n.language, options).format(day);
      }),
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
      },
    },
    yaxis: [
      {
        show: true,
        showAlways: true,
        tickAmount: yAxisMax_SOLD_ITEMS_COUNT,
        title: {
          text: i18n.t("Sold Items Count"),
          offsetX: -5,
        },
        axisBorder: {
          show: true,
          color: "#405189",
          offsetX: -25,
        },
        labels: {
          formatter: function(val: any) {
            return val?.toFixed(0);
          },
        },
        max: yAxisMax_SOLD_ITEMS_COUNT + 5,
      },
      {
        show: true,
        showAlways: true,
        opposite: true,
        title: {
          text: i18n.t("Revenue & Profit"),
          offsetX: 5,
        },
        axisBorder: {
          show: true,
          color: "#20b8a5",
          offsetX: -55,
        },
        labels: {
          formatter: function(val: any) {
            return val.toFixed(2);
          },
        },
      },
      {
        show: false,
        showAlways: true,
        opposite: true,
        axisBorder: {
          show: true,
          color: "#f06548",
          offsetX: -55,
        },
        labels: {
          formatter: function(val: any) {
            return val.toFixed(2);
          },
        },
        max: yAxisMax_REVENUE_AND_PROFIT
      },
    ],
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 9,
        height: 9,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        barHeight: "70%",
      },
    },
    colors: lineChartCustomerColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function(y: any) {
            if (typeof y !== "undefined") {
              return y && y.toFixed(0);
            }
            return y;
          },
        },
        {
          formatter: function(y: any) {
            if (typeof y !== "undefined" && y) {
              return "$" + y && y.toFixed(2) + " " + props.currency;
            }
            return y;
          },
        },
        {
          formatter: function(y: any) {
            if (typeof y !== "undefined") {
              return y && y.toFixed(2) + " " + props.currency;
            }
            return y;
          },
        },
      ],
    },
  };
  return (
    <>
      <ReactApexChart dir="ltr" options={options} series={props.series} type="line" height="410" className="apex-charts" />
    </>
  );
};
