import React, {useEffect} from "react";
import {Row, Col, CardBody, Card, Alert, Container, Label, Form, Button, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {forgotPasswordUser, resetForgotPasswordFlag} from "../../slices/thunks";
import {useSelector, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import logoTransparent from "../../assets/images/logo-dark-transparent.png";
import ParticlesAuth from "./ParticlesAuth";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

const ForgotPassword = () => {
  const dispatch: any = useDispatch();
  const {t} = useTranslation();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("ForgotPassword.Validation.Email"))
        .email(t("ForgotPassword.Validation.Email")),
    }),
    onSubmit: (values) => {
      dispatch(forgotPasswordUser(values));
    },
  });

  const forgotPasswordPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ForgotPassword.loading,
      status: state.ForgotPassword.status,
      error: state.ForgotPassword.error,
    }),
  );

  // Inside your component
  const {loading, status, error} = useSelector(forgotPasswordPageData);

  useEffect(() => {
    return () => {
      dispatch(resetForgotPasswordFlag());
    };
    // eslint-disable-next-line
  }, []);

  document.title = t("PageTitles.ForgotPassword");

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoTransparent} alt="" height="50" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("ForgotPassword.Title")}</h5>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="needs-validation"
                        action="#"
                      >
                        {status === "success" ? (
                          <Alert color="success" className="mb-0">
                            {t("ForgotPassword.Message.CheckYourEmailForResetPassword")}
                          </Alert>
                        ) : (
                          status === "failed" && (
                            <Alert color="danger" className="mb-0">
                              {t(error?.message as string)}
                            </Alert>
                          )
                        )}

                        {status !== "success" && (
                          <Row className="g-3">
                            <Col lg={12}>
                              <Label htmlFor="email" className="form-label">
                                {t("ForgotPassword.Email")} <span className="text-danger">*</span>
                              </Label>
                              <ValidatedInput
                                validation={validation}
                                type="email"
                                field={"email"}
                                maxLength={100}
                                placeholder={t("ForgotPassword.EmailPlaceholder")}
                                tabIndex={1}
                                disableValidationUI
                              />
                            </Col>
                            <Col lg={12} className="mt-4">
                              <Button color="success" className="btn btn-success w-100" type="submit" tabIndex={2} disabled={loading.forgot}>
                                {loading.forgot ? <Spinner size="sm" className="me-2"></Spinner> : null}
                                {t("ForgotPassword.SubmitButton")}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    <Link to="/account/login" className="fw-semibold text-primary text-decoration-underline">
                      {t("ForgotPassword.SignIn")}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default ForgotPassword;
