import React from "react";
import {Row, Col, CardBody, Card, Container, Alert} from "reactstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logoTransparent from "../../assets/images/logo-dark-transparent.png";
import ParticlesAuth from "./ParticlesAuth";

const RegistrationSuccessful = () => {
  const {t} = useTranslation();

  document.title = t("PageTitles.RegistrationSuccessful");

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoTransparent} alt="" height="50" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("Register.Message.RegistrationSuccessful")}</h5>
                      <p className="text-muted">{t("Register.Message.ThankYouForRegistering")}</p>
                    </div>
                    <div className="p-2 mt-4">
                      <Alert color="success" className="border-0 border-0 material-shadow mb-0">
                        {t("Register.Message.CheckEmailVerification")}
                      </Alert>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default RegistrationSuccessful;
