import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";

type RegisterLoadingStates = LoadingStates<"register" | "verify">;
type RegisterStatus = "empty" | "success" | "failed" | "success-team";

export type RegisterState = {
  loading: RegisterLoadingStates;
  status: RegisterStatus;
  emailRef: string;
  error?: ApiError;
};

const initialState: RegisterState = {
  loading: {
    register: false,
    verify: false,
  },
  status: "empty",
  emailRef: "",
  error: {} as ApiError,
};

const registerSlice = createSlice({
  name: "Register",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<RegisterStatus>) {
      state.status = action.payload;
    },
    setEmailRef(state, action: PayloadAction<string>) {
      state.emailRef = action.payload;
    },
    loading(state, action: PayloadAction<[keyof RegisterLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {setStatus, setEmailRef, loading, apiError} = registerSlice.actions;

export default registerSlice.reducer;
