import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserFavorite} from "models/user_favorites";

type FavoritesLoadingState = LoadingStates<"list" | "analyze" | "delete" | "rename">;

export type FavoritesState = {
  loading: FavoritesLoadingState;
  list: Partial<PagedList<UserFavorite>>;
  error: ApiError;
  selectedFavoriteList: UserFavorite;
  analyzeModalVisibility: boolean;
  renameModalVisibility: boolean;
};

const initialState: FavoritesState = {
  loading: {
    list: false,
    analyze: false,
    delete: false,
    rename: false,
  },
  analyzeModalVisibility: false,
  renameModalVisibility: false,
  selectedFavoriteList: {} as UserFavorite,
  list: {items: []},
  error: {} as ApiError,
};

const FavoritesSlice = createSlice({
  name: "Favorites",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<PagedList<UserFavorite>>) {
      state.list = action.payload;
    },
    loading(state, action: PayloadAction<[keyof FavoritesLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    renameItem(state, action: PayloadAction<UserFavorite>) {
      state.list.items = (state.list.items || []).map((item) => {
        if (item.userFavoriteId === action.payload.userFavoriteId) {
          return {...item, name: action.payload.name};
        } else {
          return item;
        }
      });
    },
    setSelectedUserFavoriteList(state, action: PayloadAction<UserFavorite>) {
      state.selectedFavoriteList = action.payload;
    },
    setSendToAnalyzeModalVisibility(state, action) {
      state.analyzeModalVisibility = action.payload;
    },
    setRenameModalVisibility(state, action) {
      state.renameModalVisibility = action.payload;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {setList, loading, setSendToAnalyzeModalVisibility, setRenameModalVisibility, renameItem, setSelectedUserFavoriteList, apiError} = FavoritesSlice.actions;

export default FavoritesSlice.reducer;
