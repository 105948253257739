import {apiError, loading, reset, setDetails, setSystemMonitoring} from "./reducer";
import {ResponseModel} from "models/response_model";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {postAddProduct, postClearTopics, postGetSystemMonitoring, postGetSystemMonitoringDetails, postRebalanceOrchestrationService} from "services/admin_service";
import {SystemMonitoring} from "models/system_monitoring";
import i18n from "i18n";
import {SystemMonitoringDetails} from "models/system_monitoring_details";
import {AddProductCommand, ReBalanceOrchestrationServiceCommand} from "api/command";

export const getSystemMonitoring = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const response: ResponseModel = await postGetSystemMonitoring();
    const result: SystemMonitoring = response.data;
    dispatch(setSystemMonitoring(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const getSystemMonitoringDetails = (serviceId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["details", true]));
    const response: ResponseModel = await postGetSystemMonitoringDetails({serviceId});
    const result: SystemMonitoringDetails = response.data;
    dispatch(setDetails(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["details", false]));
  }
};

export const clearTopics = (topicNames: string[]) => async (dispatch: any) => {
  try {
    dispatch(loading(["clearTopic", true]));
    await postClearTopics({topicNames});
    if (topicNames.length > 1) {
      renderSuccessToast(i18n.t("Admin.SystemMonitoring.Dialog.ServiceDetails.Toast.AllTopicsCleared"));
    } else {
      renderSuccessToast(i18n.t("Admin.SystemMonitoring.Dialog.ServiceDetails.Toast.TopicCleared", {value: topicNames[0]}));
    }
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["clearTopic", false]));
  }
};

export const rebalanceOrchestrationService = (command: ReBalanceOrchestrationServiceCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["rebalance", true]));
    const response: ResponseModel = await postRebalanceOrchestrationService({isUnassigned: command.isUnassigned});
    renderSuccessToast(i18n.t(response.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["rebalance", false]));
  }
};

export const addProduct = (command: AddProductCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["add", true]));
    const response: ResponseModel = await postAddProduct(command);
    renderSuccessToast(i18n.t(response.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["add", false]));
  }
}

export const resetAdminSystemMonitoringState = () => async (dispatch: any) => {
  dispatch(reset());
};
