import {useMemo, useState} from "react";
import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "./AmazonMarketplaceInfos";
import _ from "lodash";

interface DomainToFlagProps {
  marketplace: string;
}

const DomainToFlag = (props: DomainToFlagProps) => {
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const marketplace = useMemo(() => {
    const value = _.toUpper(props.marketplace);
    return amazonMarketplaceInfos.find((amazonMarketplaceInfo: AmazonMarketplaceInfosType) => amazonMarketplaceInfo.marketplace === value);
  }, [amazonMarketplaceInfos, props.marketplace]);

  return <img src={marketplace ? marketplace.flag : ""} alt={marketplace ? `${marketplace.countryName} Flag` : ""} className="me-1" height="14" />;
};

export default DomainToFlag;
