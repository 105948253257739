import {ResponseModel} from "models/response_model";
import {CouponDetails, apiError, loading, setCouponDetails, setCouponStatus, setList} from "./reducer";
import {postGetSubscriptions, postInitializeSubscription, postValidateCoupon} from "services/payment_service";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {getLoggedUser, LOCAL_STORAGE, removeSubscriptionLocalData} from "helpers/local_storage";
import i18n from "i18n";
import {InitializeSubscriptionCommand} from "api/command";

export const getAllSubscriptionList = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetSubscriptions();
    dispatch(setList({data: result.data}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const validateCoupon = (couponCode: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["coupon", true]));
    const result: ResponseModel = await postValidateCoupon({couponCode});
    dispatch(apiError(null));
    dispatch(setCouponStatus("applied"));
    const responseData: CouponDetails = result.data;
    dispatch(setCouponDetails(responseData));
    renderSuccessToast(i18n.t("Account.Subscription.Toast.CouponAppliedSuccessfully"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["coupon", false]));
  }
};

export const removeCoupon = () => async (dispatch: any) => {
  try {
    dispatch(loading(["coupon", true]));
    dispatch(setCouponStatus("empty"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["coupon", false]));
  }
};



export const initializeSubscription = (subscriptionDto: InitializeSubscriptionCommand, price: number) => async (dispatch: any) => {
  try {
    dispatch(loading(["create", true]));
    await postInitializeSubscription(subscriptionDto);
    const user = getLoggedUser();
    if (user) {
      user.hasSubscription = true;
      localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(user));

      // Redirect to intermediary page with subscription details before getting-started page.
      window.location.href = `/subscription-redirect?subscribed=true&subscriptionValue=${price}`;
    }
    removeSubscriptionLocalData();
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["create", false]));
  }
};
