import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {UserSearchProduct} from "models/user_search_product";
import {preventScrollUp} from "helpers/utilities";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

interface RestrictionCheckProps {
  item: UserSearchProduct;
}
const RestrictionCheck = ({item}: RestrictionCheckProps) => {
  const {t} = useTranslation();

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      result: state.SearchResult.result,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );

  const {pageUrl, result} = useSelector(searchResultsData);

  const getCurrentStoreName = (searchId: string) => {
    const stores = result.stores;

    const store = stores.find((product) => product.userSearchId === searchId);
    return store?.userStoreName;
  };

  const RestrictionCheckCodesLink = ({items}: any) => {
    const {t} = useTranslation();
    return items.length === 1 ? t("SearchResults.Summary.Reason", {count: items.length}) : t("SearchResults.Summary.ReasonPlural", {count: items.length});
  };

  const storeName = getCurrentStoreName(item.userSearchId);

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.RestrictionCheck.Title")}</h5>
        <div>
          {storeName ? (
            <>
              {" "}
              <p
                className="text-muted"
                dangerouslySetInnerHTML={{
                  __html: t("SearchResults.Analyzes.RestrictionCheck.Subtitle", {
                    storeName: storeName,
                  }),
                }}
              ></p>
              <ul className="list-group d-flex flex-group gap-1">
                <li className="d-flex justify-content-between">
                  <div>
                    <span>{t("SearchResults.Analyzes.RestrictionCheck.CanYouAddThisProduct")}</span>
                  </div>
                  {item.spapiRestrictionCheckResultDate === null ? (
                    <span className="d-flex align-items-center text-muted">
                      <i id={`RestrictionCheckTooltip-${item.userSearchProductId}`} className="bx bxs-time-five mdi-clock-time-twelve fs-16 text-warning"></i>
                      <DefaultUncontrolledTooltip target={`RestrictionCheckTooltip-${item.userSearchProductId}`}>{t("Searches.RestrictionChecksInProgressMessageShort")}</DefaultUncontrolledTooltip>
                    </span>
                  ) : item.summary?.hasRestriction ? (
                    <span className="d-flex align-items-center text-nowrap">
                      <span className="badge bg-warning">{t("No")}</span>
                      <Link
                        to={`${pageUrl}/restriction-check?asin=${item.asin}`}
                        id={`PopoverRestrictionReasonCodes-${item.userSearchProductId}`}
                        className="ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                        onClick={() => preventScrollUp()}
                      >
                        <RestrictionCheckCodesLink items={item.summary?.ineligibilityReasonList?.split(",")} />
                      </Link>
                      <DefaultUncontrolledTooltip target={`PopoverRestrictionReasonCodes-${item.userSearchProductId}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                    </span>
                  ) : item.summary?.hasRestriction === false ? (
                    <span className="text-success">{t("Yes")}</span>
                  ) : (
                    <span>{t("NA")}</span>
                  )}
                </li>
              </ul>
            </>
          ) : (
            <p className="mb-0 mt-2 text-muted">
              {t("SearchResults.Analyzes.RestrictionCheck.RestrictionCheckFailed")}
              <Link to="/stores/connect" className="fs-13 fw-medium link-secondary ms-1" target="_blank">
                {t("ConnectYourStore")}
                <i className="ri-arrow-right-s-line align-bottom"></i>
              </Link>
            </p>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default RestrictionCheck;
