import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {useProfile} from "Components/Hooks/useProfile";
import {Badge, Button, Card, CardBody, CardHeader, Container} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {downloadUserPayment, getUserPaymentHistory} from "slices/account/thunk";
import {UserPayment} from "models/user_payment";
import {ColumnDef} from "@tanstack/react-table";
import {ConstantPage} from "helpers/permission_helper";
import {GetPaymentsQuery} from "api/query";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import DisplayDate from "Components/Common/DisplayDate";

export type PaymentHistoryListRef = {
  reload: VoidFunction;
};
const PAGE_IDENTIFIER: ConstantPage = "account.paymentHistory";
const PaymentHistory = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<PaymentHistoryListRef>(null);

  const [query, updateQuery] = useImmer<GetPaymentsQuery>({
    page: 1,
    pageSize: 10,
  });

  const debouncedLoadList = useDebounce(() => {
    getUserPaymentHistory(query)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const profilePageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
      paymentHistoryList: state.Account.paymentHistoryList,
    }),
  );

  // Inside your component
  const {loading, paymentHistoryList} = useSelector(profilePageData);

  const columns = useMemo<ColumnDef<UserPayment, any>[]>(
    () => [
      {
        header: t("Account.PaymentHistory.TableColumn.SubscriptionName"),
        size: 150,
        accessorKey: "subscriptionName",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return <>{`${row.subscriptionName} ${row.billingPeriodMonth === 0 ? `(${t("Trial")})` : `(${row.billingPeriodMonth} ${t("Monthly")})`}`}</>;
        },
      },
      {
        header: t("Account.PaymentHistory.TableColumn.InvoiceNumber"),
        size: 100,
        accessorKey: "stripeInvoiceNumber",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return <>{row.stripeInvoiceNumber}</>;
        },
      },
      {
        header: t("Account.PaymentHistory.TableColumn.Price"),
        size: 30,
        accessorKey: "price",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return <>${row.price}</>;
        },
      },
      {
        header: t("Account.PaymentHistory.TableColumn.Date"),
        size: 100,
        accessorKey: "date",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return (
            <>
              <DisplayDate id={`DisplayDatePaymentHistoryPaymentDate-${row.userPaymentId}`} value={row.date} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("Account.PaymentHistory.TableColumn.Status"),
        size: 80,
        accessorKey: "isSuccess",
        sortingFn: "alphanumericCaseSensitive",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return (
            <>
              {row.isSuccess ? (
                <Badge color="success" className="fs-12">
                  {t("Account.PaymentHistory.Badge.Paid")}
                </Badge>
              ) : (
                <Badge color="danger" className="fs-12">
                  {t("Account.PaymentHistory.Badge.Unpaid")}
                </Badge>
              )}
            </>
          );
        },
      },
      {
        header: t("Actions"),
        size: 30,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return (
            <>
              <Button
                color="link"
                className="btn btn-ghost-success px-1 py-0 fs-18"
                onClick={async () => {
                  await dispatch(downloadUserPayment(row.userPaymentId));
                }}
              >
                <i className="mdi mdi-download fs-17 lh-1 align-middle"></i>
              </Button>
            </>
          );
        },
      },
    ],
    [t, dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  document.title = t("PageTitles.PaymentHistory");
  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/payment-history",
    },
    {
      label: t("Account.PaymentHistory.Title"),
      url: "",
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
          <ProfileLayout hasAnyChanges={false} navigateTo="">
            <PlaceholderGlow busy={loading.list}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0 align-items-center d-flex">
                    <i className="mdi mdi-history fs-18 me-1"></i>
                    {t("Account.PaymentHistory.Title")}
                  </h4>
                </CardHeader>
                <CardBody>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={paymentHistoryList?.items || []}
                    totalDataLength={paymentHistoryList?.totalCount}
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                      })
                    }
                    hovered
                  />
                </CardBody>
              </Card>
            </PlaceholderGlow>
          </ProfileLayout>
        </Container>
      </div>
    </Restricted>
  );
};

export default PaymentHistory;
