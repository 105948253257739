import {t} from "i18next";

interface NoDataProps {
  icon: string;
  text?: string;
  style?: React.CSSProperties;
}
const NoData = (props: NoDataProps) => {
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center" style={props.style || {minHeight: 300}}>
      <div>
        <i className={`${props.icon} display-6 text-muted`}></i>
      </div>
      <h6 className="mb-0 align-items-center d-flex text-muted">{props.text ?? t("NoDataToDisplay")}</h6>
    </div>
  );
};

export default NoData;
