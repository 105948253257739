import {Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Label, Row, Spinner, UncontrolledDropdown} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {UserSearchesQuery} from "models/user_search";
import {SearchesListRef} from "..";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useProfile} from "Components/Hooks/useProfile";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import SelectOptions, {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import ValidateDate from "Components/Common/ValidatedDate";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import moment from "moment";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import * as Yup from "yup";
import ValidatedSelect from "Components/Common/ValidatedSelect";

interface FiltersProps {
  listRef: RefObject<SearchesListRef>;
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: UserSearchesQuery) => void;
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const navigate = useNavigate();
  const {searchStatusSelectOptions, multiSelectTranslations, searchTypeSelectOptions} = SelectOptions();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [teamUserOptions, setTeamUserOptions] = useState<SelectOptionsType[]>([]);
  const searchData = createSelector(
    (state: RootState) => state,
    (state) => ({
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );
  const {activeUserStoreOptions, activeMarketplaceOptions} = useSelector(searchData);

  const onLoad = useCallback(
    () => {
      const queryParams = new URLSearchParams(window.location.search);
      const start = queryParams.get("start");
      const end = queryParams.get("end");

      var payload: UserSearchesQuery = {
        page: 1,
        pageSize: props.pageSize,
        filtering: true,
      };
      if (start && end) {
        setStartDate(new Date(start));
        setEndDate(new Date(end));
        validation.setFieldValue("startDate", new Date(start));
        validation.setFieldValue("endDate", new Date(end));
        payload = {
          ...payload,
          startDate: moment
            .tz(start, userProfile?.timezone!)
            .startOf("day")
            .tz("Etc/GMT")
            .toDate(),
          endDate: moment
            .tz(end, userProfile?.timezone!)
            .endOf("day")
            .tz("Etc/GMT")
            .toDate(),
        };
      } else {
        setStartDate(getRelativeDateByTimezone(30, userProfile?.timezone));
        setEndDate(getToday().toDate());
        validation.setFieldValue("startDate", getRelativeDateByTimezone(30, userProfile?.timezone));
        validation.setFieldValue("endDate", getToday().toDate());
        payload = {
          ...payload,
          startDate: getRelativeDateByTimezone(30, userProfile?.timezone),
          endDate: getToday()
            .tz(userProfile?.timezone!)
            .endOf("day")
            .toDate(),
        };
      }

      props.handleFilter(payload);

      if (userProfile?.teamUsers) {
        let ownerOption: SelectOptionsType[] = [{value: userProfile.userId, label: userProfile.fullName}];
        const teamUserOptions = userProfile.teamUsers.map((user) => ({
          label: user.fullName,
          value: user.userId,
        }));
        setTeamUserOptions([...ownerOption, ...teamUserOptions]);
      }
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  // validation
  const validation = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
      marketplace: [] as string[],
      status: [] as string[],
      searchType: undefined,
      teamUserIds: undefined,
    } as UserSearchesQuery,
    validationSchema: Yup.object<UserSearchesQuery>({
      startDate: Yup.date()
        .min(new Date("2023-01-01"), t("Searches.Filters.Validation.StartDateMin"))
        .max(Yup.ref("endDate"), t("Searches.Filters.Validation.StartDateMax"))
        .typeError(t("Searches.Filters.Validation.InvalidDateType")),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), t("Searches.Filters.Validation.EndDateMin"))
        .max(getToday().toDate(), t("Searches.Filters.Validation.EndDateMax"))
        .typeError(t("Searches.Filters.Validation.InvalidDateType")),
      marketplace: Yup.array<String>().notRequired(),
      statusFilter: Yup.string().notRequired(),
      searchType: Yup.string().notRequired(),
      teamUserIds: Yup.array<String>().notRequired(),
    }),
    onSubmit: (values: UserSearchesQuery) => {
      const payload: UserSearchesQuery = {
        page: 1,
        pageSize: props.pageSize,
        startDate: moment
          .tz(values.startDate, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(values.endDate, userProfile?.timezone!)
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
        marketplace: values.marketplace || undefined,
        status: values.status || undefined,
        searchType: values.searchType,
        teamUserIds: values.teamUserIds,
        filtering: true,
      };
      props.handleFilter(payload);
    },
  });

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Searches.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs="auto" align="right">
                  <Row className="g-0">
                    <Col>
                      <Button color="warning" className="me-2" onClick={() => navigate("/favorites")}>
                        <i className="ri-star-line align-bottom me-1"></i>
                        {t("Searches.Filters.Button.Favorites")}
                      </Button>
                    </Col>
                    <Col>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn-info" caret>
                          <i className="ri-add-line align-bottom me-1"></i> {t("Searches.Filters.Button.NewSearch")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => navigate("/searches/new/bulk")}>
                            <i className="bx bx-list-ul me-1"></i>
                            {t("Searches.Filters.Button.BulkSearch")}
                          </DropdownItem>
                          <DropdownItem onClick={() => navigate("/searches/new/single")}>
                            <i className="bx bx-bracket me-1"></i>
                            {t("Searches.Filters.Button.SingleSearch")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="startDate">{t("Searches.Filters.StartDate")}</Label>
                  <ValidateDate field="startDate" validation={validation} value={validation.values.startDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="endDate">{t("Searches.Filters.EndDate")}</Label>
                  <ValidateDate field="endDate" validation={validation} value={validation.values.endDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="marketplace">{t("Searches.Filters.Marketplace")}</Label>
                  <ValidatedMultiSelect
                    className="common-css filter-input"
                    options={[...activeUserStoreOptions, ...activeMarketplaceOptions]}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    translations={{
                      ...multiSelectTranslations,
                      selectSomeItems: t("General.Select.SelectStore"),
                    }}
                    optionStyle="country"
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="status">{t("Searches.Filters.Status")}</Label>
                  <ValidatedMultiSelect className="common-css filter-input" options={searchStatusSelectOptions} validation={validation} field="status" value={validation.values.status} />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="status">{t("Searches.Filters.SearchType")}</Label>
                  <ValidatedSelect className="common-css filter-input" options={searchTypeSelectOptions} validation={validation} field="searchType" value={validation.values.searchType} isClearable />
                </Col>
                {userProfile?.teamUsers && userProfile?.teamUsers.length > 0 && userProfile?.subscription === "Premium" && (
                  <Col sm={2} className="mb-3 searches filters-col">
                    <Label htmlFor="status">{t("User")}</Label>
                    <ValidatedMultiSelect className="common-css filter-input" options={teamUserOptions} validation={validation} field="teamUserIds" value={validation.values.teamUserIds} />
                  </Col>
                )}

                <Col sm={2}>
                  <Label>&nbsp;</Label>
                  <div>
                    <Button type="submit" className="btn bg-primary" disabled={props.busy}>
                      {props.busy ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("Searches.Filters.Button.Apply")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
