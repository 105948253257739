import {Link} from "react-router-dom";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";

import wallet from "../../assets/images/wallet.png";
import money from "../../assets/images/money.png";
import add from "../../assets/images/add.png";

const LimitExceeded = () => {
  const {t} = useTranslation();
  return (
    <div>
      <Container>
        <Card>
          <CardBody className="pt-5 pb-5">
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center mb-3">
                  <h2 className="mb-3 fw-semibold lh-base">{t("ProductDetails.Dialog.LimitExceed.Title")}</h2>
                  <p className="text-muted">{t("ProductDetails.Dialog.LimitExceed.Description")}</p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <Card className="shadow-none">
                  <CardBody>
                    <img src={wallet} alt="" className="avatar-sm" />
                    <h5 className="mt-4">{t("ProductDetails.Dialog.LimitExceed.Box1Title")}</h5>
                    <p className="text-muted fs-14">{t("ProductDetails.Dialog.LimitExceed.Box1Description")}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="shadow-none">
                  <CardBody>
                    <img src={add} alt="" className="avatar-sm" />
                    <h5 className="mt-4">{t("ProductDetails.Dialog.LimitExceed.Box2Title")}</h5>
                    <p className="text-muted fs-14">{t("ProductDetails.Dialog.LimitExceed.Box2Description")}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="shadow-none">
                  <CardBody>
                    <img src={money} alt="" className="avatar-sm" />
                    <h5 className="mt-4">{t("ProductDetails.Dialog.LimitExceed.Box3Title")}</h5>
                    <p className="text-muted fs-14">{t("ProductDetails.Dialog.LimitExceed.Box3Description")}</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Link to="/account/register" className="btn btn-primary btn-label waves-effect waves-light rounded-pill">
                  <i className="ri-user-smile-line label-icon align-middle rounded-pill fs-16 me-2"></i> {t("Register.Title")}
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default LimitExceeded;
