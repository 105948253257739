import {Filtering, PagerQuery} from "helpers/types";
import {UserStore} from "./user_stores";
import {User} from "./user";
import { AmazonBusinessModel } from "./enums/user_search_type";
import { UserSearchProduct } from "./user_search_product";


export enum SearchStatus {
  INITIAL = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
  ERROR = 3,
  CANCELED = 4
}


export const getSearchStatusDescription = (status: SearchStatus) : string =>{
  switch (status) {
    case SearchStatus.INITIAL:
      return "Initial";
    case SearchStatus.IN_PROGRESS:
      return "In Progress";
    case SearchStatus.COMPLETED:
      return "Completed";
    case SearchStatus.ERROR:
      return "Error";
    case SearchStatus.CANCELED:
      return "Canceled";
  }
}
export interface UserSearch {
  userSearchId: string;
  userId: string;
  userStoreId?: string;
  amazonBusinessModel?: AmazonBusinessModel;
  name: string;
  marketplaceSource: string;
  marketplaceTarget: string;
  totalProductCount: number;
  completedProductCount: number;
  sellableProductCount: number;
  notSellableProductCount: number;
  recheckProductCount: number;
  restrictionCheckCompletedProductCount: number;
  deleted: boolean;
  status: SearchStatus;
  statusDate: Date;
  createDate: Date;
  updateDate: Date;
  user: User;
  userStore?: UserStore;
  userSearchSetting:any; // no need
  userSearchProducts: UserSearchProduct[];
}

export type UserSearchesQuery = PagerQuery & Filtering & {
  startDate?: Date;
  endDate?: Date;
  marketplace?: string[];
  status?: string[];
  searchType?: string;
  teamUserIds?: string[];
};

export interface ISaveUserSearchRequest {
  searchName: string;
  marketplace: string;
  identifierList: string[];
  amazonBusinessModel: AmazonBusinessModel;
  categoryPageList?: string[];
}

export interface IGetUserSearchesDto {
  filter: JSON;
  resultLimit: number;
  currentPage: number;
}

export interface ISaveNewUserSearchDto {
  saveNewSearchParameters: any;
}
