// action
import {postGetInvitationInformation, postRegister} from "services/identity_service";
import {loading, setStatus, apiError, setEmailRef} from "./reducer";
import {IRegisterUserDto} from "models/application_user";
import {ApiError} from "helpers/types";
import {ResponseModel} from "models/response_model";

// Is user register successful then direct plot user in redux.
export const registerUser = (user: IRegisterUserDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["register", true]));
    dispatch(setStatus("empty"));
    await postRegister(user);
    if (user.token && user.token !== "") {
      dispatch(setStatus("success-team"));
    } else {
      dispatch(setStatus("success"));
    }
    return true;
  } catch (error : any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error?.response?.data as ApiError));
    return false;
  } finally {
    dispatch(loading(["register", false]));
  }
};

export const getInvitationInformation = (token: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["verify", true]));
    const result: ResponseModel = await postGetInvitationInformation({token});
    dispatch(setEmailRef(result.data));
    dispatch(setStatus("empty"));
    return true;
  } catch (error : any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error?.response?.data as ApiError));
    return false;
  } finally {
    dispatch(loading(["verify", false]));
  }
};

export const resetRegisterFlag = () => (dispatch: any) => {
  try {
    dispatch(setStatus("empty"));
    return true;
  } catch (error : any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error?.response?.data as ApiError));
    return false;
  }
};
