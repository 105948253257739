import React, {useState} from "react";
import {useAmazonMarketplace} from "Components/Hooks/useAmazonMarketplace";
import {Link} from "react-router-dom";
import {t} from "i18next";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import _ from "lodash";

interface CopyAsinWidgetProps {
  asin?: string;
  index?: string;
  marketplace?: string;
  bold?: boolean;
  asLink?: boolean;
  customLink?: string;
  hideCopyIcon?: boolean;
}
const CopyAsinWidget = ({asin, index, marketplace, bold, asLink, hideCopyIcon, customLink}: CopyAsinWidgetProps) => {
  const {activeMarketplaces} = useAmazonMarketplace();
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(asin!);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  let domain = activeMarketplaces().find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === marketplace)?.domain;
  if (asin && index) {
    return (
      <React.Fragment>
        <div className="d-flex align-items-center">
          {asLink ? (
            customLink ? (
              <Link to={customLink} target="_blank" className={`link-secondary link-offset-2 text-decoration-underline me-1 ${bold ? "fw-bold" : ""}`}>
                {asin}
                <i className="ri-arrow-right-up-line"></i>
              </Link>
            ) : (
              <Link to={`https://www.amazon.${domain}/dp/${asin}?th=1&psc=1`} target="_blank" className={`link-secondary link-offset-2 text-decoration-underline me-1 ${bold ? "fw-bold" : ""}`}>
                {asin}
                <i className="ri-arrow-right-up-line"></i>
              </Link>
            )
          ) : (
            <span className={`me-1 ${bold ? "fw-medium" : ""}`}>{asin}</span>
          )}

          {hideCopyIcon ? (
            <></>
          ) : (
            <>
              <i className="ri-file-copy-line cursor-pointer fs-14" id={_.snakeCase(`CopyAsinTooltip-${index}`)} onClick={handleCopyClick}></i>

              <DefaultUncontrolledTooltip delay={{show: 0, hide: 0}} target={_.snakeCase(`CopyAsinTooltip-${index}`)}>
                {copied ? t("Copied") : t("Copy")}
              </DefaultUncontrolledTooltip>
            </>
          )}
        </div>
      </React.Fragment>
    );
  } else return <></>;
};

export default CopyAsinWidget;
