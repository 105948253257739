import React, {useEffect, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {get} from "lodash";

//i18n
import i18n from "../../i18n";
import languages from "../../common/language";
import {updateUIPreferences} from "slices/account/thunk";
import {LAYOUT_MODE_TYPES} from "models/layout";
import {useDispatch} from "react-redux";
import {useProfile} from "Components/Hooks/useProfile";
import {LOCAL_STORAGE} from "helpers/local_storage";

interface LanguageDropdownProps {
  onChangeLanguage?: (mode: string) => void; // Nullable yaptık
}

const LanguageDropdown = ({onChangeLanguage}: LanguageDropdownProps) => {
  const dispatch: any = useDispatch();
  const {userProfile} = useProfile();
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("I18N_LANGUAGE") || "en");
  const [selectedFlag, setSelectedFlag] = useState<any>();
  useEffect(() => {
    const currentLanguage: any = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);

    const browserLanguage = currentLanguage || navigator.language || "en";
    if (browserLanguage.startsWith("tr")) {
      setSelectedFlag(languages.tr.flag);
    } else {
      setSelectedFlag(languages.en.flag);
    }
  }, []);

  const changeLanguageAction = async (lang: any) => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
    setSelectedFlag(get(languages, `${lang}.flag`));
    localStorage.setItem(LOCAL_STORAGE.I18N_LANGUAGE, lang);
    const currentTheme = localStorage.getItem("THEME") as LAYOUT_MODE_TYPES;
    if (userProfile) {
      await updateUIPreferences({language:lang, theme: currentTheme})(dispatch);
    }
  };

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
        <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
          <img src={selectedFlag} alt={`${selectedFlag}`} height="20" className="rounded" />
        </DropdownToggle>
        <DropdownMenu className="notify-item language py-2">
          {Object.keys(languages).map((key) => (
            <DropdownItem
              key={key}
              onClick={() => {
                if (onChangeLanguage) {
                  onChangeLanguage(key);
                }
                changeLanguageAction(key);
              }}
              className={`notify-item ${selectedLang === key ? "active" : "none"}`}
            >
              <img src={get(languages, `${key}.flag`)} alt="Skote" className="me-2 rounded" height="18" />
              <span className="align-middle">{get(languages, `${key}.label`)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
