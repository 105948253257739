import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import { WebNotification } from "models/web_notification";

export type NotificationsLoadingState = LoadingStates<"list" | "update" |"dropdown" | "markAllRead">;

export type NotificationsState = {
  loading: NotificationsLoadingState;
  userWebNotifications: Partial<PagedList<WebNotification>>;
  userWebNotificationsForDropdown: Partial<PagedList<WebNotification>>;
  unreadCount: number,
  error: ApiError;
};

const initialState: NotificationsState = {
  loading: {
    list: false,
    dropdown: false,
    update: false,
    markAllRead: false
  },
  userWebNotifications: {items: [] },
  userWebNotificationsForDropdown: {items: [] },
  unreadCount: 0,
  error: {} as ApiError,
};

const NotificationsSlice = createSlice({
  name: "Notifications",
  initialState,
  reducers: {
    setUserWebNotifications(state, action: PayloadAction<PagedList<WebNotification>>)
    {
      state.userWebNotifications = action.payload;
    },
    setUserWebNotificationsForDropdown(state, action: PayloadAction<PagedList<WebNotification>>)
    {
      state.userWebNotificationsForDropdown = action.payload;
    },
    updateNotificationItem(state, action: PayloadAction<WebNotification>) {
      state.userWebNotifications.items = state.userWebNotifications.items?.map(notification => {
        if(notification.notificationHistoryId === action.payload.notificationHistoryId) {
          return action.payload;
        }
        return notification;
      });

      state.userWebNotificationsForDropdown.items = state.userWebNotificationsForDropdown.items?.map(notification => {
        if(notification.notificationHistoryId === action.payload.notificationHistoryId) {
          return action.payload;
        }
        return notification;
      })
    },
    markAllNotificationsAsRead(state) {
      state.userWebNotifications.items = state.userWebNotifications.items?.map(notification => {
        notification.isRead = true;
        return notification;
      });
      state.userWebNotificationsForDropdown.items = state.userWebNotificationsForDropdown.items?.map(notification => {
        notification.isRead = true;
        return notification;
      })
    },
    setUnreadCount(state, action: PayloadAction<number>) {
      state.unreadCount = action.payload;
    },
    loading(state, action: PayloadAction<[keyof NotificationsLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },

    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    }
  },
});

export const {setUserWebNotifications, setUserWebNotificationsForDropdown, setUnreadCount, updateNotificationItem, markAllNotificationsAsRead, loading, apiError} = NotificationsSlice.actions;

export default NotificationsSlice.reducer;
