import {Link, useNavigate} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";

// Import Images
import error403cover from "../../assets/images/error403-cover.png";
//import { useMetadata } from 'Components/Hooks/MetadataHooks';
import {useTranslation} from "react-i18next";

const Unauthorized = () => {
  //   const { setPageTitle } = useMetadata();
  const {t} = useTranslation();
  const navigate = useNavigate();

  //setPageTitle(t("Unauthorized Access"));

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center ">
            <Container>
              <Row className="justify-content-center">
                <Col xl={7} lg={8}>
                  <div className="text-center">
                    <img src={error403cover} alt="error img" className="img-fluid" />
                    <div className="mt-3">
                      <h3 className="text-uppercase">{t("Unauthorized Access")}</h3>
                      <p className="text-muted mb-4">{t("You don't have permission to view this page!")}</p>
                      <Link to="#" onClick={() => navigate(-1)} className="btn btn-warning">
                        <i className="mdi mdi-home me-1"></i>Go back
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Unauthorized;
