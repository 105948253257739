import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserSearchProduct} from "models/user_search_product";

type AdminSearchesLoadingStates = LoadingStates<"list" | "filter" | "reset">;

export type SearchLogStats = {
  totalAsins: number;
  startDate: Date;
  duration: number;
  endDate: Date;
  completed: number;
  inProgress: number;
};
export type AdminSearchLogsState = {
  list: Partial<PagedList<UserSearchProduct>>;
  loading: AdminSearchesLoadingStates;
  stats: SearchLogStats;
  error?: ApiError;
};

const initialState: AdminSearchLogsState = {
  list: {items: []},
  loading: {
    list: false,
    filter: true,
    reset: false,
  },
  stats: {} as SearchLogStats,
  error: {} as ApiError,
};

const AdminSearchLogsSlice = createSlice({
  name: "AdminSearchLogs",
  initialState,
  reducers: {
    setSearchProductList(state, action: PayloadAction<PagedList<UserSearchProduct>>) {
      state.list = action.payload;
    },
    setStats(state, action: PayloadAction<SearchLogStats>) {
      state.stats = action.payload;
    },
    loading(state, action: PayloadAction<[keyof AdminSearchesLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.list = initialState.list;
      state.error = initialState.error;
    },
  },
});

export const {setSearchProductList, setStats, loading, apiError, reset} = AdminSearchLogsSlice.actions;

export default AdminSearchLogsSlice.reducer;
