import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useMemo, useRef} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {MarketplaceStatus} from "models/system_monitoring";
import {Link} from "react-router-dom";
import {preventScrollUp} from "helpers/utilities";
import CountUp from "react-countup";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DomainToFlag from "Components/Common/DomainToFlag";
import RenderNumber from "Components/Common/RenderNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const MarketplaceProductStatistics = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);

  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const columns = useMemo<ColumnDef<MarketplaceStatus, any>[]>(
    () => [
      {
        header: t("Marketplace"),
        size: 200,
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <div className="d-flex align-items-center">
              <DomainToFlag marketplace={row.marketplace} />
              <span className="ellipsis-single-line">{row.marketplace}</span>
            </div>
          );
        },
        footer: () => {
          return <span className="fw-bold">{t("Total")}</span>;
        },
      },
      {
        header: t("Active"),
        size: 150,
        accessorKey: "active",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <span className="counter-value">
              <CountUp end={row.active} duration={2} />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <RenderNumber className="fw-bold" value={totalCount} notation="decimal" />;
        },
      },
      {
        header: t("Passive"),
        size: 100,
        accessorKey: "passive",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <span className="counter-value">
              <CountUp end={row.passive} duration={2} />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <RenderNumber className="fw-bold" value={totalCount} notation="decimal" />;
        },
      },
      {
        header: t("Total"),
        size: 100,
        accessorKey: "total",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <span className="counter-value">
              <CountUp end={row.total} duration={2} />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <RenderNumber className="fw-bold" value={totalCount} notation="decimal" />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);
  return (
    <PlaceholderGlow busy={loading.list}>
      <Card className="card-animate mb-0">
        <div className="align-items-center d-flex card-header">
          <h4 className="card-title mb-0 flex-grow-1">{t("Admin.SystemMonitoring.Section.Title.MarketplaceProductStatistics")}</h4>
          <div className="flex-shrink-0">
            <Link id="AddProductButton" to={"/admin/system-monitoring/add-product"} className="btn btn-soft-secondary btn-sm px-1 py-0 fs-16" onClick={() => preventScrollUp()}>
              <i className="ri ri-add-line"></i>
            </Link>
            <DefaultUncontrolledTooltip target="AddProductButton">{t("Admin.SystemMonitoring.Button.AddProduct")}</DefaultUncontrolledTooltip>
          </div>
        </div>
        <CardBody>
          <DataTable
            ref={tableRef}
            busy={loading.list}
            columns={columns}
            data={systemMonitoring?.marketplacesProductsCount || []}
            totalDataLength={systemMonitoring?.marketplacesProductsCount?.length || 0}
            hovered
          />
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default MarketplaceProductStatistics;
