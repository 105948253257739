import {UserSearch} from "models/user_search";
import {apiError, loading, reset, setSearchesList} from "./reducer";
import {ResponseModel} from "models/response_model";
import {PagedList} from "helpers/types";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {postCancelUserSearch, postGetAdminUserSearches, postRestartUserSearch, postUndoDeleteUserSearch} from "services/admin_service";
import {AdminUserSearchesQuery} from "api/query";
import i18n from "i18n";

export const getAdminUserSearchesList = (query: AdminUserSearchesQuery) => async (dispatch: any) => {
  const loadingType = query.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetAdminUserSearches(query);
    const pagedResult: PagedList<UserSearch> = result.data;
    dispatch(setSearchesList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const restartSearch = (userSearch: UserSearch, query: AdminUserSearchesQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["restart", true]));
    var result:ResponseModel = await postRestartUserSearch({userId: userSearch.userId, searchId: userSearch.userSearchId, query});
    var pagedResult: PagedList<UserSearch> = result.data;
    dispatch(setSearchesList(pagedResult));
    renderSuccessToast(i18n.t("Admin.Searches.Toast.RestartSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["restart", false]));
  }
};

export const cancelSearch = (userSearch: UserSearch, query: AdminUserSearchesQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["cancel", true]));
    var result:ResponseModel = await postCancelUserSearch({userId: userSearch.userId, searchId: userSearch.userSearchId, query});
    var pagedResult: PagedList<UserSearch> = result.data;
    dispatch(setSearchesList(pagedResult));
    renderSuccessToast(i18n.t("Admin.Searches.Toast.CancelSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["cancel", false]));
  }
};

export const undoDeleteSearch = (userSearch: UserSearch, query: AdminUserSearchesQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["cancel", true]));
    var result:ResponseModel = await postUndoDeleteUserSearch({userId: userSearch.userId, searchId: userSearch.userSearchId, query});
    var pagedResult: PagedList<UserSearch> = result.data;
    dispatch(setSearchesList(pagedResult));
    renderSuccessToast(i18n.t("Admin.Searches.Toast.UndoDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["cancel", false]));
  }
};

export const resetAdminSearchesState = () => async (dispatch: any) => {
  dispatch(reset());
};
