import {useTranslation} from "react-i18next";

type YesNoBadgeProps = {
  text: "Yes" | "No";
  oppositeColor?: boolean | false;
};

export const YesNoBadge = ({text, oppositeColor}: YesNoBadgeProps) => {
  const {t} = useTranslation();

  // Define color based on text and oppositeColor flag
  const color = text === "Yes" ? (oppositeColor ? "danger" : "success") : text === "No" ? (oppositeColor ? "success" : "danger") : "secondary";

  // return <span className={`badge bg-${color}-subtle text-${color} fs-11`}>{t(text)}</span>;
  return <span className={`text-${color}`}>{t(text)}</span>;
};
