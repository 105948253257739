import { UserSearchProductCheckList } from "models/user_search_product_check_list";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert} from "reactstrap";

interface DecisionsExtendedInfoProps {
  checkList: UserSearchProductCheckList;
  reason: string;
}
const DecisionsExtendedInfo = ({checkList, reason}: DecisionsExtendedInfoProps) => {
  const {t} = useTranslation();
  const [keywordLocation, setKeywordLocation] = useState("");
  const [mainKeyword, setMainKeyword] = useState("");
  const [subKeyword, setSubKeyword] = useState("");

  useEffect(() => {
    const customsCheckReason =
      reason === "CustomsCheckKeywordCheckRecheck" ? checkList.customsCheckKeywordCheckReason : reason === "CustomsCheckCategoryCheckRecheck" ? checkList.customsCheckCategoryCheckReason : "";

    if (customsCheckReason?.startsWith("MAIN:") && customsCheckReason.includes("=> SUB:")) {
      setKeywordLocation("MainAndSub");
      const [mainPart, subPart] = customsCheckReason.split("=> SUB:");
      setMainKeyword(mainPart.replace("MAIN:", "").trim());
      setSubKeyword(subPart.trim());
    } else if (customsCheckReason?.startsWith("MAIN:")) {
      setKeywordLocation("Main");
      setMainKeyword(customsCheckReason.replace("MAIN:", "").trim());
    } else if (customsCheckReason?.startsWith("SUB:")) {
      setKeywordLocation("Sub");
      setSubKeyword(customsCheckReason.replace("SUB:", "").trim());
    }
  }, [checkList.customsCheckKeywordCheckReason, checkList.customsCheckCategoryCheckReason, reason]);

  return (
    <>
      {reason === "SameProductCheckRecheck" ? (
        <Alert color="warning" className="border-0">
          <b>{t(`DecisionReasons.SameProductCheckRecheck.Reasons.${checkList.sameProductCheckReason}.Name`)}:</b>{" "}
          {t(`DecisionReasons.SameProductCheckRecheck.Reasons.${checkList.sameProductCheckReason}.Description`)}
        </Alert>
      ) : (
        <Alert color="warning" className="border-0">
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: t(`DecisionReasons.${reason}.Reasons.${keywordLocation}`, {main: mainKeyword, sub: subKeyword}),
            }}
          ></p>
        </Alert>
      )}
    </>
  );
};

export default DecisionsExtendedInfo;
