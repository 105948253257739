import { WebNotificationQuery } from "models/web_notification";
import {APIClient} from "../helpers/api_helper";
import * as url from "../helpers/url_helper";
import { ReadWebNotificationCommand } from "api/command";

const api = new APIClient();

export const postGetUserWebNotifications = (data: WebNotificationQuery) => api.create(`${url.POST_GET_USER_WEB_NOTIFICATIONS}`, data);

export const postReadUserWebNotification = (data: ReadWebNotificationCommand) => api.create(`${url.POST_READ_USER_WEB_NOTIFICATION}`, data);

export const postMarkAllAsRead = () => api.create(`${url.POST_MARK_ALL_AS_READ}`, []);
