import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useEffect, useState} from "react";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {createCostAndFeesSettings} from "slices/settings/thunk";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";


const CreateCostAndFeeOption = () => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [costAndFeeOptions, setCostAndFeeOptions] = useState<any>();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {name: "", marketplace: null, copyFrom: null},
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Settings.CostAndFees.Validation.Name")),
      marketplace: Yup.string().required(t("Settings.CostAndFees.Validation.Marketplace")),
      copyFrom: Yup.string().required(t("Settings.CostAndFees.Validation.CopyFrom")),
    }),
    onSubmit: (values: any) => {
      const createPromise = createCostAndFeesSettings(values.name, values.marketplace, values.copyFrom)(dispatch);

      createPromise.then((success) => {
        if (success) {
          toggle();
          validation.resetForm();
        }
      });
    },
  });

  const dispatch: any = useDispatch();
  const settingsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      costAndFeesList: state.Settings.costAndFeesList,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );

  const {loading, costAndFeesList, activeMarketplaceOptions} = useSelector(settingsData);

  useEffect(() => {
    if (costAndFeesList.length > 0) {
      const optionArray: SelectOptionsType[] = [];
      costAndFeesList.forEach((item) => {
        optionArray.push({value: item.userSettingCostAndFeeOptionId, label: item.name});
      });
      setCostAndFeeOptions(optionArray);
    }
  }, [costAndFeesList]);

  const toggle = () => {
    validation.resetForm();
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Button type="button" color="primary" onClick={toggle}>
        {t("Settings.CostAndFees.Button.Create")}
      </Button>
      <Modal backdrop="static" id="showCreateCostAndFeeOptionModal" isOpen={isOpen} toggle={toggle} fade={true} centered={true} size="lg">
        <Form onSubmit={validation.handleSubmit}>
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {t("Settings.CostAndFees.Dialog.Create.Title")}
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3 g-3">
              <Col lg={4}>
                <Label className="form-label" htmlFor="name">
                  {t("Settings.CostAndFees.Name")}
                </Label>
                <ValidatedInput validation={validation} type="text" field="name" maxLength={100} placeholder={t("Settings.CostAndFees.NamePlaceholder")} disableValidationUI />
              </Col>
              <Col lg={4}>
                <Label className="form-label" htmlFor="marketplace">
                  {t("Settings.CostAndFees.Marketplace")}
                </Label>
                <ValidatedSelect
                  className={`new-search user-input w-100 ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                  options={activeMarketplaceOptions}
                  validation={validation}
                  field="marketplace"
                  value={validation.values.marketplace}
                  placeholder={t("Settings.CostAndFees.MarketplacePlaceholder")}
                  errorStyle="container"
                  optionStyle="country"
                  valueStyle="country"
                />
              </Col>
              <Col lg={4}>
                <Label className="form-label" htmlFor="copyFrom">
                  {t("Settings.CostAndFees.CopyFrom")}
                </Label>
                <ValidatedSelect
                  className={`new-search user-input w-100 ${validation.errors.copyFrom && validation.touched.copyFrom && "form-control is-invalid form-select-invalid"}`}
                  options={costAndFeeOptions}
                  validation={validation}
                  field="copyFrom"
                  value={validation.values.copyFrom}
                  placeholder={t("Settings.CostAndFees.CopyFromPlaceholder")}
                  errorStyle="container"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={toggle}>
                {t("Settings.CostAndFees.Dialog.Create.CancelButton")}
              </Button>
              <Button type="submit" color="success" disabled={loading.create}>
                {loading.create && <Spinner size="sm" className="me-2"></Spinner>}
                {t("Settings.CostAndFees.Dialog.Create.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CreateCostAndFeeOption;
