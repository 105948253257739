import {generateError, renderSuccessToast} from "helpers/utilities";
import {apiError, loading, setOrder, setShipments, setShipmentsWithoutInfo} from "./reducer";
import {ResponseModel} from "models/response_model";
import {OrderShipments, UserOrder} from "models/user_order";
import {postGetUserOrderDetails, postReassignUserOrderShipment, postUpdateUserOrderCost} from "services/store_service";
import {ReassignParametersCommand} from "api/command";
import i18n from "i18n";

export const getUserOrderDetails = (amazonOrderId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetUserOrderDetails({amazonOrderId});
    const order: UserOrder = result.data.order;
    const shipments: OrderShipments[] = result.data.shipments;
    const shipmentsWithoutInfo: OrderShipments[] = result.data.shipmentsWithoutInfo;
    dispatch(setOrder(order));
    dispatch(setShipments(shipments));
    dispatch(setShipmentsWithoutInfo(shipmentsWithoutInfo));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const getReAssignShipmentOrderDetails = (amazonOrderId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["reAssignShipmentList", true]));
    const result: ResponseModel = await postGetUserOrderDetails({amazonOrderId});
    const order: UserOrder = result.data.order;
    const shipments: OrderShipments[] = result.data.shipments;
    const shipmentsWithoutInfo: OrderShipments[] = result.data.shipmentsWithoutInfo;
    dispatch(setOrder(order));
    dispatch(setShipments(shipments));
    dispatch(setShipmentsWithoutInfo(shipmentsWithoutInfo));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["reAssignShipmentList", false]));
  }
};

export const updateOrderCost = (userOrderId: string, cost: number, currencyCode: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["updateCost", true]));
    const result: ResponseModel = await postUpdateUserOrderCost({userOrderId, cost, currencyCode});
    const order: UserOrder = result.data;
    dispatch(setOrder(order));
    renderSuccessToast(i18n.t("Orders.Toast.CostUpdateSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["updateCost", false]));
  }
};

export const reassignShipment = (data: ReassignParametersCommand[]) => async (dispatch: any) => {
  try {
    dispatch(loading(["reassignShipment", true]));
    const result: ResponseModel = await postReassignUserOrderShipment({data}); //eslint-disable-line
    const order: UserOrder = result.data.order;
    const shipments: OrderShipments[] = result.data.shipments;
    const shipmentsWithoutInfo: OrderShipments[] = result.data.shipmentsWithoutInfo;
    dispatch(setOrder(order));
    dispatch(setShipments(shipments));
    dispatch(setShipmentsWithoutInfo(shipmentsWithoutInfo));
    renderSuccessToast(i18n.t("OrderDetails.Dialog.ReassignShipment.Toast.ReassignShipmentSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["reassignShipment", false]));
  }
};
