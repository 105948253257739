import {useEffect, useMemo, useState} from "react";
import {Button, Col, Form, FormFeedback, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {UserPaymentMethod} from "models/user_payment_methods";
import {RootState} from "slices";
import {updateUserPaymentMethod} from "slices/account/thunk";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import visaLogo from "assets/images/brands/visa.png";
import mastercardLogo from "assets/images/brands/mastercard.png";
import americanExpressLogo from "assets/images/brands/american_express.png";
import discoverLogo from "assets/images/brands/discover.png";
import jcbLogo from "assets/images/brands/jcb.png";
import dinersClubLogo from "assets/images/brands/diners_club.png";
import unionPayLogo from "assets/images/brands/union_pay.png";
import Cleave from "cleave.js/react";
import countryList from "react-select-country-list";
import * as Yup from "yup";
import _ from "lodash";

interface UpdateUserPaymentMethodProps {
  reload: () => void;
  paymentMethod?: UserPaymentMethod;
  isOpen: boolean;
  toggle: () => void;
}
const UpdateUserPaymentMethod = (props: UpdateUserPaymentMethodProps) => {
  const [, setCountry] = useState<any>();
  const [brandLogoUrl, setBrandLogoUrl] = useState<string>("");
  const {t} = useTranslation();
  const countryOptions = useMemo(() => countryList().getData(), []);
  const dispatch: any = useDispatch();

  const updateUserPaymentMethodData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
    }),
  );

  const {loading} = useSelector(updateUserPaymentMethodData);

  const getFieldValue = (field: keyof UserPaymentMethod, defaultValue: string | number = "") => {
    const value = props.paymentMethod?.[field];
    if (typeof value === "number") {
      return value;
    }
    return value ?? defaultValue;
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      expireDate: `${Number(getFieldValue("expireMonth"))
        .toString()
        .padStart(2, "0")}/${Number(getFieldValue("expireYear")) % 100}`,
      countryCode: getFieldValue("countryCode"),
      addressLine1: getFieldValue("addressLine1"),
      addressLine2: getFieldValue("addressLine2"),
      city: getFieldValue("city"),
      state: getFieldValue("state"),
      zipCode: getFieldValue("zipCode"),
    },
    validationSchema: Yup.object({
      expireDate: Yup.string().required(t("Account.Subscription.Validation.ExpiryDate")),
      countryCode: Yup.string().required(t("Account.Subscription.Validation.Country")),
      addressLine1: Yup.string().required(t("Account.Subscription.Validation.AddressLine1")),
      addressLine2: Yup.string(),
      city: Yup.string().required(t("Account.Subscription.Validation.City")),
      state: Yup.string().required(t("Account.Subscription.Validation.State")),
      zipCode: Yup.string().required(t("Account.Subscription.Validation.ZipCode")),
    }),
    onSubmit: async (values) => {
      if (props.paymentMethod) {
        const success = await updateUserPaymentMethod({
          userPaymentMethodId: props.paymentMethod.userPaymentMethodId,
          expireMonth: parseInt(values.expireDate.split("/")[0]),
          expireYear: parseInt(values.expireDate.split("/")[1]),
          countryCode: values.countryCode.toString(),
          addressLine1: values.addressLine1.toString(),
          addressLine2: values.addressLine2.toString(),
          city: values.city.toString(),
          state: values.state.toString(),
          zipCode: values.zipCode.toString(),
        })(dispatch);
        if (success) {
          props.reload();
          props.toggle();
          validation.resetForm();
        }
      }
    },
  });

  useEffect(() => {
    if (props.paymentMethod) {
      const card = props.paymentMethod;
      if (card.brand === "visa") {
        setBrandLogoUrl(visaLogo);
      } else if (card.brand === "mastercard") {
        setBrandLogoUrl(mastercardLogo);
      } else if (card.brand === "amex") {
        setBrandLogoUrl(americanExpressLogo);
      } else if (card.brand === "discover") {
        setBrandLogoUrl(discoverLogo);
      } else if (card.brand === "jcb") {
        setBrandLogoUrl(jcbLogo);
      } else if (card.brand === "diners") {
        setBrandLogoUrl(dinersClubLogo);
      } else if (card.brand === "unionpay") {
        setBrandLogoUrl(unionPayLogo);
      } else {
        setBrandLogoUrl("");
      }

      const currentCountry = countryOptions.find((c) => c.value === card.countryCode);
      setCountry(currentCountry);
    }
  }, [props.paymentMethod, countryOptions]);
  return (
    <>
      <Modal id="EditPaymentMethodModal" isOpen={props.isOpen} toggle={props.toggle} fade={true} centered size="lg">
        <ModalHeader className="bg-light p-3" toggle={props.toggle}>
          {t("Account.Subscription.EditPaymentMethod")}
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            className="needs-validation"
            action="#"
          >
            <Row>
              <Col xs={12} md={6}>
                <h5 className="mb-4">{t("Account.Subscription.BillingAddress")}</h5>
                <div className="clearfix "></div>
                <Row className="mb-3 p-0">
                  <Label htmlFor="country" className="form-label">
                    {t("Account.Subscription.Country")}
                    <span className="text-danger">*</span>
                  </Label>
                  <ValidatedSelect className="w-100" options={countryOptions} validation={validation} field="countryCode" value={validation.values.countryCode} errorStyle="container" />
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="addressLine1" className="form-label">
                      {t("Account.Subscription.AddressLine1")} 1<span className="text-danger">*</span>
                    </Label>
                    <ValidatedInput validation={validation} field="addressLine1" maxLength={60} placeholder={t("Account.Subscription.AddressLine1Placeholder")} upperFirst disableValidationUI />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="addressLine2" className="form-label">
                      {t("Account.Subscription.AddressLine2")}
                    </Label>
                    <ValidatedInput validation={validation} field="addressLine2" maxLength={60} placeholder={t("Account.Subscription.AddressLine2Placeholder")} upperFirst disableValidationUI />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="city" className="form-label">
                      {t("Account.Subscription.City")}
                      <span className="text-danger">*</span>
                    </Label>
                    <ValidatedInput validation={validation} field="city" maxLength={60} placeholder={t("Account.Subscription.CityPlaceholder")} upperFirst disableValidationUI />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label htmlFor="state" className="form-label">
                      {t("Account.Subscription.State")}
                      <span className="text-danger">*</span>
                    </Label>
                    <ValidatedInput validation={validation} field="state" maxLength={20} placeholder={t("Account.Subscription.StatePlaceholder")} upperFirst disableValidationUI />
                  </Col>
                  <Col>
                    <Label htmlFor="zipCode" className="form-label">
                      {t("Account.Subscription.ZipCode")}
                      <span className="text-danger">*</span>
                    </Label>
                    <Cleave
                      name="zipCode"
                      className={"form-control " + (validation.touched.zipCode && validation.errors.zipCode && "is-invalid")}
                      placeholder={t("Account.Subscription.ZipCodePlaceholder")}
                      value={validation.values.zipCode}
                      onChange={validation.handleChange}
                      options={{
                        blocks: [10],
                        numericOnly: false,
                        uppercase: true,
                      }}
                    />
                    {validation.touched.zipCode && validation.errors.zipCode ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.zipCode}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={6} className="mt-4 mt-md-0">
                <h5 className="mb-4">{t("Account.Subscription.CreditCardDetails")}</h5>
                <div className="clearfix "></div>
                <Row className="mb-2">
                  <Col xs="auto">
                    <div className="avatar-md rounded ">
                      <img src={brandLogoUrl} alt="card-img" className="img-fluid d-block" />
                    </div>
                  </Col>
                  <Col className="flex-grow-1">
                    <h5 className="fs-14 mt-3">
                      <span className="text-body">
                        {_.toUpper(props.paymentMethod?.brand)} **** {props.paymentMethod?.last4}
                      </span>
                    </h5>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col xl={6}>
                    <Label htmlFor="expireDate" className="form-label">
                      {t("Account.Subscription.ExpireDate")}
                      <span className="text-danger">*</span>
                    </Label>
                    <Cleave
                      name="expireDate"
                      className={"form-control " + (validation.touched.expireDate && validation.errors.expireDate && "is-invalid")}
                      placeholder={t("Account.Subscription.ExpireDatePlaceholder")}
                      value={validation.values.expireDate}
                      onChange={validation.handleChange}
                      options={{
                        date: true,
                        datePattern: ["m", "y"],
                      }}
                    />
                    {validation.errors.expireDate ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.expireDate}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              type="button"
              className="btn btn-light"
              onClick={() => {
                validation.resetForm();
                props.toggle();
                //props.onIsModal(false);
              }}
            >
              {t("General.Close")}
            </Button>
            <Button
              type="submit"
              className="btn btn-success"
              disabled={loading.paymentMethod}
              onClick={() => {
                if (validation.isValid) {
                  validation.handleSubmit();
                } else {
                  Object.keys(validation.errors).forEach((key) => {
                    validation.setFieldTouched(key, true);
                  });
                }
              }}
            >
              {loading.paymentMethod && <Spinner size="sm" className="me-2"></Spinner>}
              {t("Account.Subscription.Button.SaveChanges")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UpdateUserPaymentMethod;
