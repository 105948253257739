import {apiError, loading, reset, setList} from "./reducer";
import {ResponseModel} from "models/response_model";
import {PagedList} from "helpers/types";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {postGetScans, postSaveNewScan, postExportAsins, postDeleteScan} from "services/scan_and_save_service";
import {ScanAndSave} from "models/scan_and_save";
import {exportAsinsToExcel} from "Components/Common/ExcelExport";
import {refreshRemainingLimits} from "slices/thunks";
import {ScanAndSaveQuery} from "api/query";
import {ExportAsinsCommand, SubmitForAnalysisCommand} from "api/command";
import {postSubmitForAnalysis} from "services/search_service";
import i18n from "i18n";


export const getScanAndSaveList = (query: ScanAndSaveQuery) => async (dispatch: any) => {
  const loadingType = query.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetScans(query);
    const pagedResult: PagedList<ScanAndSave> = result.data;
    dispatch(setList(pagedResult));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const saveScan = (scanName: string, crawlerUrls: string[]) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    await postSaveNewScan({scanName, crawlerUrls});
    dispatch(refreshRemainingLimits());
    renderSuccessToast(i18n.t("ScanAndSave.Toast.SaveSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const sendAnalysis = (analyzeParams: SubmitForAnalysisCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["send", true]));
    const result: ResponseModel = await postSubmitForAnalysis(analyzeParams);
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["send", false]));
  }
};

export const exportAsins = (exportParams: ExportAsinsCommand, fileName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["send", true]));
    const result: ResponseModel = await postExportAsins(exportParams);

    exportAsinsToExcel(result.data, fileName);
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["send", false]));
  }
};

export const deleteScan = (scan: ScanAndSave) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteScan({scanAndSaveId: scan.id});
    renderSuccessToast(i18n.t("ScanAndSave.Toast.DeleteSuccess", {value: scan.searchName}));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const resetScanAndSaveState = () => async (dispatch: any) => {
  dispatch(reset());
};
