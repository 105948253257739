import React from "react";
import ShipmentInfo from "./_ShipmentInfo";
import ShipmentFinance from "./_ShipmentFinance";

const Draft = () => {
  return (
    <div>
      <ShipmentInfo />
      <ShipmentFinance />
    </div>
  );
};

export default Draft;
