import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {ScanAndSave} from "models/scan_and_save";

type ScanAndSaveLoadingStates = LoadingStates<"list" | "update" | "save" | "send" | "delete" | "filter">;

export type ScanAndSaveState = {
  list: Partial<PagedList<ScanAndSave>>;
  loading: ScanAndSaveLoadingStates;
  error?: ApiError;
};

const initialState: ScanAndSaveState = {
  list: {items: []},
  loading: {
    list: false,
    filter: true,
    update: false,
    save: false,
    send: false,
    delete: false,
  },
  error: {} as ApiError,
};

const ScanAndSaveSlice = createSlice({
  name: "ScanAndSave",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<PagedList<ScanAndSave>>) {
      state.list = action.payload;
    },
    loading(state, action: PayloadAction<[keyof ScanAndSaveLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.list = initialState.list;
      state.error = initialState.error;
    },
  },
});

export const {setList, loading, apiError, reset} = ScanAndSaveSlice.actions;

export default ScanAndSaveSlice.reducer;
