// action
import {getLoggedUserFromApi, postEmailVerification} from "services/identity_service";
import {ApplicationUser} from "models/application_user";
import {ResponseModel} from "models/response_model";
import {setAuthorization} from "helpers/api_helper";
import {apiError, loading, setStatus} from "./reducer";
import {ApiError} from "helpers/types";
import {setLoginUser} from "../login/reducer";
import {changeLayoutMode} from "slices/thunks";
import {LOCAL_STORAGE} from "helpers/local_storage";
import i18n from "i18n";

// Is user emailVerification successful then direct plot user in redux.
export const emailVerification = (token: string, history: any) => async (dispatch: any) => {
  try {
    dispatch(loading(["verification", true]));
    dispatch(setStatus("empty"));
    const result: ResponseModel = await postEmailVerification({token});
    const auth: ApplicationUser = result.data;
    setAuthorization(auth.tokenInfo.signature);

    const loggedUserResult: ResponseModel = await getLoggedUserFromApi();
    const loginResult: ApplicationUser = loggedUserResult.data;
    setAuthorization(loginResult.tokenInfo.signature);
    localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(loginResult));
    localStorage.setItem(LOCAL_STORAGE.I18N_LANGUAGE, loginResult.language);
    localStorage.setItem(LOCAL_STORAGE.THEME, loginResult.theme);
    i18n.changeLanguage(loginResult.language);
    dispatch(setLoginUser(loginResult));
    dispatch(changeLayoutMode(loginResult.theme));
    dispatch(setStatus("success"));
    setTimeout(() => {
      history("/")
    }, 3000);
    return true;
  } catch (error:any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error?.response?.data as ApiError));
    return false;
  } finally {
    dispatch(loading(["verification", false]));
  }
};

export const resetEmailVerificationFlag = () => (dispatch: any) => {
  try {
    dispatch(setStatus("empty"));
    return true;
  } catch (error:any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error?.response?.data as ApiError));
    return false;
  }
};
