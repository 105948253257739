import { TagColor } from 'models/enums/tag_color'
import React from 'react'
import { Badge } from 'reactstrap';

interface TagPreviewProps {
    color: TagColor;
    value: string;
    className?: string
}
const TagPreview = ({color, value, className}: TagPreviewProps) => {
    let colorCss = "";
    console.log(typeof color)
    if (color === TagColor.PRIMARY) {
      colorCss = "bg-primary-subtle text-primary";
    } else if (color === TagColor.SECONDARY) {
      colorCss = "bg-secondary-subtle text-secondary";
    } else if (color === TagColor.SUCCESS) {
      colorCss = "bg-success-subtle text-success";
    } else if (color === TagColor.DANGER) {
      colorCss = "bg-danger-subtle text-danger";
    } else if (color === TagColor.WARNING) {
      colorCss = "bg-warning-subtle text-warning";
    } else if (color === TagColor.INFO) {
      colorCss = "bg-info-subtle text-info";
    } else if (color === TagColor.DARK) {
      colorCss = "bg-dark-subtle text-dark";
    } else {
      colorCss = "";
    }
  return (
    <><Badge className={`${colorCss} fw-medium  ${className}`}>{value}</Badge></>
  )
}

export default TagPreview