import React, {useCallback, useEffect} from "react";
import {Row, Col, CardBody, Card, Container, Spinner} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {emailVerification, resetEmailVerificationFlag} from "../../slices/thunks";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/useProfile";
import logoTransparent from "../../assets/images/logo-dark-transparent.png";
import ParticlesAuth from "./ParticlesAuth";

declare global {
  interface Window {
    trackdesk: any;
    TrackdeskObject: any;
  }
}

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const {t} = useTranslation();
  const {userProfile} = useProfile();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    if (!token) {
      navigate("/account/login");
    } else {
      dispatch(emailVerification(token, navigate));
    }
  }, [dispatch, navigate]);

  const emailVerificationPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.EmailVerification.loading,
      status: state.EmailVerification.status,
      error: state.EmailVerification.error,
    }),
  );

  // Inside your component
  const {loading, status, error} = useSelector(emailVerificationPageData);

  const loadAndExecuteTrackdesk = useCallback(() => {
    const script = document.createElement("script");
    script.src = "//cdn.trackdesk.com/tracking.js";
    script.async = true;
    document.body.appendChild(script);

    try {
      script.onload = () => {
        (function(t: any, d: any, k: any) {
          (t[k] = t[k] || []).push(d);
          t[d] =
            t[d] ||
            t[k].f ||
            function() {
              (t[d].q = t[d].q || []).push(arguments);
            };
        })(window, "trackdesk", "TrackdeskObject");

        window.trackdesk("sellthis", "externalCid", {
          externalCid: userProfile?.email,
          revenueOriginId: "0712623b-dba8-41b6-a121-d500856f28b3",
        });
      };
    } catch (e) {}
  }, [userProfile?.email]);

  useEffect(() => {
    if (status === "success") {
      // trackdesk
      loadAndExecuteTrackdesk();
    }
  }, [status, navigate, loadAndExecuteTrackdesk]);

  useEffect(() => {
    return () => {
      dispatch(resetEmailVerificationFlag());
    };
    // eslint-disable-next-line
  }, []);

  document.title = t("PageTitles.EmailVerification");
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoTransparent} alt="" height="50" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4 text-center">
                    <div className="avatar-lg mx-auto mt-2">
                      <div className={`avatar-title bg-light ${status === "failed" ? "text-danger" : "text-success"} display-3 rounded-circle`}>
                        <i className={status === "failed" ? "ri-close-circle-fill" : "ri-checkbox-circle-fill"}></i>
                      </div>
                    </div>
                    <div className="mt-4 pt-2">
                      <h4>{t("EmailVerification.Title")}</h4>
                      <p className="text-muted my-4">
                        {loading.verification ? (
                          <>
                            <Spinner size="sm" className="me-1"></Spinner>
                            <span>{t("EmailVerification.Message.Verifying")}</span>
                          </>
                        ) : status === "success" ? (
                          <>
                            <Spinner size="sm" className="me-1"></Spinner>
                            <span>{t("EmailVerification.Message.VerificationSuccessful")}</span>
                          </>
                        ) : status === "failed" ? (
                          <span className="text-danger">{t(error?.message as string)}</span>
                        ) : null}
                      </p>
                      <div>
                        {status === "failed" && (
                          <>
                            <Link to="/account/login" className="btn btn-success w-100">
                              {t("EmailVerification.SignIn")}
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default EmailVerification;
