import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {Link} from "react-router-dom";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import RenderNumber from "Components/Common/RenderNumber";
import { preventScrollUp } from "helpers/utilities";

const AnalyzeServices = () => {
  const {t} = useTranslation();
  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);

  const [fastAnalyzeStyle, setFastAnalyzeStyle] = useState("bg-success");
  const [restrictionCheckStyle, setRestrictionCheckStyle] = useState("bg-success");
  const [decisionStyle, setDecisionStyle] = useState("bg-success");
  
  type Range = {
    min: number;
    max: number;
  };

  type RangeLimits = {
    warning: [number, number];
    danger: [number, number];
  };

  const toRange = (rangeTuple: [number, number]): Range => ({
    min: rangeTuple[0],
    max: rangeTuple[1],
  });
  const getTextStyle = (value: number, successRange: [number, number], warningRange: [number, number], dangerRange: [number, number]): string => {
    const success = toRange(successRange);
    const warning = toRange(warningRange);
    const danger = toRange(dangerRange);

    if (value >= success.min && value <= success.max) {
      return "text-success";
    } else if (value > warning.min && value <= warning.max) {
      return "text-warning";
    } else if (value > danger.min && value <= danger.max) {
      return "text-danger";
    }
    return "";
  };

  const getCardStyle = (data: any, ranges: Record<string, RangeLimits>) => {
    if (!data) return "bg-success";
    
    const checks = Object.entries(ranges).map(([key, range]) => {
      if (data[key] !== undefined) {
        return checkDangerWarning(data[key], range.warning, range.danger);
      }
      return null;
    });

    const dangerCheck = checks.find(check => check === "bg-danger");
    if (dangerCheck) return dangerCheck;

    const warningCheck = checks.find(check => check === "bg-warning");
    if (warningCheck) return warningCheck;

    return "bg-success";
  };

  const checkDangerWarning = (value: number, warningRange: [number, number], dangerRange: [number, number]) => {
    const warning = toRange(warningRange);
    const danger = toRange(dangerRange);
    
    if (value > danger.min && value <= danger.max) {
      return "bg-danger";
    } else if (value > warning.min && value <= warning.max) {
      return "bg-warning";
    }
    return null;
  };

  useEffect(() => {
    if (systemMonitoring.analyzeServices) {
      setFastAnalyzeStyle(getCardStyle(systemMonitoring.analyzeServices.fastAnalyze, {
        overdue: { warning: [5000, 50000], danger: [50000, Infinity] },
        inProgress: { warning: [5000, 20000], danger: [20000, Infinity] },
        inQueue: { warning: [5000, 10000], danger: [10000, Infinity] },
        averageProcessTimeInMinute: { warning: [5, 10], danger: [10, Infinity] }
      }));

      setRestrictionCheckStyle(getCardStyle(systemMonitoring.analyzeServices.spapiRestrictionCheck, {
        overdue: { warning: [5000, 50000], danger: [50000, Infinity] },
        inProgress: { warning: [5000, 20000], danger: [20000, Infinity] },
        inQueue: { warning: [5000, 10000], danger: [10000, Infinity] },
        averageProcessTimeInMinute: { warning: [5, 10], danger: [10, Infinity] }
      }));

      setDecisionStyle(getCardStyle(systemMonitoring.analyzeServices.decision, {
        overdue: { warning: [5000, 50000], danger: [50000, Infinity] },
        inProgress: { warning: [5000, 20000], danger: [20000, Infinity] },
        inQueue: { warning: [5000, 10000], danger: [10000, Infinity] },
        averageProcessTimeInMinute: { warning: [5, 10], danger: [10, Infinity] }
      }));

    }
  }, [systemMonitoring.analyzeServices]); // eslint-disable-line

  return (
    <>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardBody>
              <h5 className="fs-17 fw-semibold">{t("Admin.SystemMonitoring.Services.Title.FastAnalyze")}</h5>
              <div className="d-flex flex-wrap mt-3">
                <ul className="list-group w-100">
                  <li className="mb-1 d-flex justify-content-between">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                      }}
                    ></span>
                    <span className="fw-medium">
                      <RenderNumber value={systemMonitoring.analyzeServices?.fastAnalyze?.completedCount} decimals={0} notation="decimal" />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.fastAnalyze?.overdue, [0, 5000], [5000, 50000], [50000, Infinity])}
                        value={systemMonitoring.analyzeServices?.fastAnalyze?.overdue}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.fastAnalyze?.inProgress, [0, 5000], [5000, 20000], [20000, Infinity])}
                        value={systemMonitoring.analyzeServices?.fastAnalyze?.inProgress}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.fastAnalyze?.inQueue, [0, 5000], [5000, 10000], [10000, Infinity])}
                        value={systemMonitoring.analyzeServices?.fastAnalyze?.inQueue}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.fastAnalyze?.averageProcessTimeInMinute, [-Infinity, 5], [5, 10], [10, Infinity])}
                        value={systemMonitoring.analyzeServices?.fastAnalyze?.averageProcessTimeInMinute}
                        decimals={0}
                        notation="decimal"
                        suffix={` ${t("min")}`}
                      />
                    </span>
                  </li>
                </ul>
                <Link
                  to={`/admin/system-monitoring/service-details/${systemMonitoring.analyzeServices?.fastAnalyze?.serviceId}`}
                  className="mt-2 link-secondary text-decoration-underline"
                  onClick={() => preventScrollUp()}
                >
                  {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
                </Link>
              </div>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${fastAnalyzeStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardBody>
              <h5 className="fs-17 fw-semibold">{t("Admin.SystemMonitoring.Services.Title.SPAPIRestrictionCheck")}</h5>
              <div className="d-flex flex-wrap mt-3">
                <ul className="list-group w-100" >
                  <li className="mb-1 d-flex justify-content-between">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                      }}
                    ></span>
                    <span className="fw-medium">
                      <RenderNumber value={systemMonitoring.analyzeServices?.spapiRestrictionCheck?.completedCount} decimals={0} notation="decimal" />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.spapiRestrictionCheck?.overdue, [0, 5000], [5000, 50000], [50000, Infinity])}
                        value={systemMonitoring.analyzeServices?.spapiRestrictionCheck?.overdue}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.spapiRestrictionCheck?.inProgress, [0, 5000], [5000, 20000], [20000, Infinity])}
                        value={systemMonitoring.analyzeServices?.spapiRestrictionCheck?.inProgress}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.spapiRestrictionCheck?.inQueue, [0, 5000], [5000, 10000], [10000, Infinity])}
                        value={systemMonitoring.analyzeServices?.spapiRestrictionCheck?.inQueue}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.spapiRestrictionCheck?.averageProcessTimeInMinute, [0, 5], [5, 10], [10, Infinity])}
                        value={systemMonitoring.analyzeServices?.spapiRestrictionCheck?.averageProcessTimeInMinute}
                        decimals={0}
                        notation="decimal"
                        suffix={` ${t("min")}`}
                      />
                    </span>
                  </li>
                </ul>
                <Link
                  to={`/admin/system-monitoring/service-details/${systemMonitoring.analyzeServices?.spapiRestrictionCheck?.serviceId}`}
                  className="mt-2 link-secondary text-decoration-underline"
                  onClick={() => preventScrollUp()}
                >
                  {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
                </Link>
              </div>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${restrictionCheckStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col xxl={3} xl={4} lg={6} sm={12} className="same-height">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <CardBody>
              <h5 className="fs-17 fw-semibold">{t("Admin.SystemMonitoring.Services.Title.Decision")}</h5>
              <div className="d-flex flex-wrap mt-3">
                <ul className="list-group w-100" >
                  <li className="mb-1 d-flex justify-content-between">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                      }}
                    ></span>
                    <span className="fw-medium">
                      <RenderNumber value={systemMonitoring.analyzeServices?.decision?.completedCount} decimals={0} notation="decimal" />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.OverDue")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.decision?.overdue, [0, 5000], [5000, 50000], [50000, Infinity])}
                        value={systemMonitoring.analyzeServices?.decision?.overdue}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.InProgress")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.decision?.inProgress, [0, 5000], [5000, 20000], [20000, Infinity])}
                        value={systemMonitoring.analyzeServices?.decision?.inProgress}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.InQueue")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.decision?.inQueue, [0, 5000], [5000, 10000], [10000, Infinity])}
                        value={systemMonitoring.analyzeServices?.decision?.inQueue}
                        decimals={0}
                        notation="decimal"
                      />
                    </span>
                  </li>
                  <li className="mb-1 d-flex justify-content-between">
                    <span>{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</span>
                    <span className="fw-medium text-success">
                      <RenderNumber
                        className={getTextStyle(systemMonitoring.analyzeServices?.decision?.averageProcessTimeInMinute, [0, 5], [5, 10], [10, Infinity])}
                        value={systemMonitoring.analyzeServices?.decision?.averageProcessTimeInMinute}
                        decimals={0}
                        notation="decimal"
                        suffix={` ${t("min")}`}
                      />
                    </span>
                  </li>
                </ul>
                <Link
                  to={`/admin/system-monitoring/service-details/${systemMonitoring.analyzeServices?.decision?.serviceId}`}
                  className="mt-2 link-secondary text-decoration-underline"
                  onClick={() => preventScrollUp()}
                >
                  {t("Admin.SystemMonitoring.Services.Link.ViewStatistics")}
                </Link>
              </div>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={`progress-bar rounded-0 ${decisionStyle}`} role="progressbar" style={{width: "100%"}}></div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
    </>
  );
};

export default AnalyzeServices;
