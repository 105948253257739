import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";

const GeneralCheckAndAlerts = () => {
  const {t} = useTranslation();

  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
    }),
  );
  const {details} = useSelector(productDetailsData);

  enum SameProduct {
    YES = 0,
    NO = 1,
  }

  enum CustomsRestricted {
    NO = 0,
    YES = 1,
  }

  const renderCustomsCheckReason = useCallback(
    (reason: "CustomsCheckKeywordCheckRecheck" | "CustomsCheckCategoryCheckRecheck"): string => {
      const customsCheckReason =
        reason === "CustomsCheckKeywordCheckRecheck"
          ? details?.generalCheckAndAlerts?.customsCheckKeywordCheckReason
          : reason === "CustomsCheckCategoryCheckRecheck"
          ? details?.generalCheckAndAlerts?.customsCheckCategoryCheckReason
          : "";
      let keywordLocation = "";
      let mainKeyword = "";
      let subKeyword = "";

      if (customsCheckReason) {
        if (customsCheckReason.startsWith("MAIN:") && customsCheckReason.includes("=> SUB:")) {
          keywordLocation = "MainAndSub";
          const [mainPart, subPart] = customsCheckReason.split("=> SUB:");
          mainKeyword = mainPart.replace("MAIN:", "").trim();
          subKeyword = subPart.trim();
        } else if (customsCheckReason.startsWith("MAIN:")) {
          keywordLocation = "Main";
          mainKeyword = customsCheckReason.replace("MAIN:", "").trim();
        } else if (customsCheckReason.startsWith("SUB:")) {
          keywordLocation = "Sub";
          subKeyword = customsCheckReason.replace("SUB:", "").trim();
        }
        return t(`DecisionReasons.${reason}.Reasons.${keywordLocation}`, {main: mainKeyword, sub: subKeyword});
      }

      return "";
    },
    [details.generalCheckAndAlerts], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
      <Card className="mt-3 shadow-lg">
        <CardBody>
          <h5 className="fs-15">{t("ProductDetails.GeneralChecksAndAlerts.Title")}</h5>
          <div className="mt-3">
            {/* SameProduct Check */}
            {details?.generalCheckAndAlerts?.sameProductCheckResult === SameProduct.NO && (
              <div className="row d-flex mt-1">
                <Col xxl={3} xl={4} sm={12}>
                  <span className="d-flex align-items-center">
                    <i className="ri-error-warning-fill fs-18 text-warning me-1"></i>
                    {t("ProductDetails.GeneralChecksAndAlerts.Label.SameProductCheck")}
                  </span>
                </Col>
                {details?.generalCheckAndAlerts?.sameProductCheckReason !== "" && (
                  <Col xxl={9} xl={8} sm={12}>
                    <span className="mb-0">{t(`DecisionReasons.SameProductCheckRecheck.Reasons.${details?.generalCheckAndAlerts?.sameProductCheckReason}.Name`)}</span>
                    <p className="text-muted mb-0 mt-1">{t(`DecisionReasons.SameProductCheckRecheck.Reasons.${details?.generalCheckAndAlerts?.sameProductCheckReason}.Description`)}</p>
                  </Col>
                )}
              </div>
            ) }

            {/* Customs Check */}
            {(details?.generalCheckAndAlerts?.customsCheckKeywordCheckResult === CustomsRestricted.YES || details?.generalCheckAndAlerts?.customsCheckCategoryCheckResult === CustomsRestricted.YES) && (
              <div className="row d-flex mt-1">
                <Col xxl={3} xl={4} sm={12}>
                  <span className="d-flex align-items-center">
                    <i className="ri-error-warning-fill fs-18 text-warning me-1"></i>
                    {t("ProductDetails.GeneralChecksAndAlerts.Label.CustomsCheck")}
                  </span>
                </Col>
                <Col xxl={9} xl={8} sm={12}>
                  {details?.generalCheckAndAlerts?.customsCheckKeywordCheckResult === CustomsRestricted.YES && (
                    <>
                      <span className="mb-0">{t("DecisionReasons.CustomsCheckKeywordCheckRecheck.Name")}</span>
                      <p className="text-muted mb-0 mt-1" dangerouslySetInnerHTML={{__html: renderCustomsCheckReason("CustomsCheckKeywordCheckRecheck")}}></p>
                    </>
                  )}
                  {details?.generalCheckAndAlerts?.customsCheckCategoryCheckResult === CustomsRestricted.YES && (
                    <>
                      <span className="mb-0">{t("DecisionReasons.CustomsCheckKeywordCheckRecheck.Name")}</span>
                      <p className="text-muted mb-0 mt-1" dangerouslySetInnerHTML={{__html: renderCustomsCheckReason("CustomsCheckCategoryCheckRecheck")}}></p>
                    </>
                  )}
                </Col>
              </div>
            )}

            {/* Product Size Check */}
            {details?.generalCheckAndAlerts.productSizeEligibleToFBA ? (
              <div className="row d-flex mt-1">
                <Col xxl={3} xl={4} sm={12}>
                  <span className="d-flex align-items-center">
                    <i className="ri-checkbox-circle-fill fs-18 text-success me-1"></i>
                    {t("ProductDetails.GeneralChecksAndAlerts.Label.ProductSizeCheck")}
                  </span>
                </Col>
                <Col xxl={9} xl={8} sm={12}>
                  <span className="mb-0">Ürün boyutu FBA için uygun.</span>
                </Col>
              </div>
            ) : (
              <div className="row d-flex mt-1">
                <Col xxl={3} xl={4} sm={12}>
                  <span className="d-flex align-items-center">
                    <i className="ri-error-warning-fill fs-18 text-warning me-1"></i>
                    {t("ProductDetails.GeneralChecksAndAlerts.Label.ProductSizeCheck")}
                  </span>
                </Col>
                <Col xxl={9} xl={8} sm={12}>
                  <span className="mb-0">{t("DecisionReasons.ProductSizeNotEligibleToFBA.Name")}</span>
                  <p className="text-muted mb-0 mt-1">{t("DecisionReasons.ProductSizeNotEligibleToFBA.Description")}</p>
                </Col>
              </div>
            )}

            {/* New Product Check */}
            {details?.generalCheckAndAlerts?.isNewProduct === true ? (
              <div className="row d-flex mt-1">
                <Col xxl={3} xl={4} sm={12}>
                  <span className="d-flex align-items-center">
                    <i className="ri-error-warning-fill fs-18 text-danger me-1"></i>
                    {t("ProductDetails.GeneralChecksAndAlerts.Label.NewProductAlert")}
                  </span>
                </Col>
                <Col xxl={9} xl={8} sm={12}>
                  <span className="mb-0">{t("ProductDetails.GeneralChecksAndAlerts.Label.NewProductAlertDescription")}</span>
                </Col>
              </div>
            ) : (
              <div className="row d-flex mt-1">
                <Col xxl={3} xl={4} sm={12}>
                  <span className="d-flex align-items-center">
                    <i className="ri-checkbox-circle-fill fs-18 text-success me-1"></i>
                    {t("ProductDetails.GeneralChecksAndAlerts.Label.TrustedListing")}
                  </span>
                </Col>
                <Col xxl={9} xl={8} sm={12}>
                  <span className="mb-0">{t("ProductDetails.GeneralChecksAndAlerts.Label.TrustedListingDescription")}</span>
                </Col>
              </div>
            )}

            {/* Product Rating Check */}
            {details?.generalCheckAndAlerts?.reviewRating &&
              (details?.generalCheckAndAlerts?.reviewRating >= 3 ? (
                <div className="row d-flex mt-1">
                  <Col xxl={3} xl={4} sm={12}>
                    <span className="d-flex align-items-center">
                      <i className="ri-checkbox-circle-fill fs-18 text-success me-1"></i>
                      {t("ProductDetails.GeneralChecksAndAlerts.Label.ProductRatingCheck")}
                    </span>
                  </Col>
                  <Col xxl={9} xl={8} sm={12}>
                    <span className="mb-0">{t("ProductDetails.GeneralChecksAndAlerts.Label.ProductRatingCheckSuccess")}</span>
                  </Col>
                </div>
              ) : (
                <div className="row d-flex mt-1">
                  <Col xxl={3} xl={4} sm={12}>
                    <span className="d-flex align-items-center">
                      <i className="ri-error-warning-fill fs-18 text-warning me-1"></i>
                      {t("ProductDetails.GeneralChecksAndAlerts.Label.ProductRatingCheck")}
                    </span>
                  </Col>
                  <Col xxl={9} xl={8} sm={12}>
                    <span className="mb-0">{t("ProductDetails.GeneralChecksAndAlerts.Label.ProductRatingCheckFailed")}</span>
                  </Col>
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
  );
};

export default GeneralCheckAndAlerts;
