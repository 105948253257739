import { get } from "lodash";
import { getRelativeDate, getToday } from "./utilities";
import i18n from "i18n";
import months from "common/month";

const generateDailyCategories = (filterDay: number): string[] => {
    const today: Date = getToday().toDate();
    let minDate: Date = getRelativeDate(filterDay - 1);
    const result: string[] = [];

    while (minDate <= today) {
      const month: string = get(months, i18n.language)[minDate.getMonth()];
      result.push(`${month} ${minDate.getDate()}`);
      minDate.setDate(minDate.getDate() + 1);
    }
    return result;
  };

  const generateWeeklyCategories = (): string[] => {
    const today: Date = getToday().toDate();
    let minDate: Date = getRelativeDate(180);
    const result: string[] = [];

    while (minDate <= today) {
      const month: string = get(months, i18n.language)[minDate.getMonth()];
      const weekNumber: number = Math.ceil(minDate.getDate() / 7);

      if (weekNumber <= 4 || minDate.getDate() > 28) result.push(`${month} ${i18n.t("Week")} ${weekNumber}`);

      minDate.setDate(minDate.getDate() + 7);
    }
    return result;
  };

  const generateMonthlyCategories = () => {
    const monthNames = get(months, i18n.language);
    const date = getToday().toDate();
    const currentMonth = date.getMonth();
    const categories = [];

    for (let i = 0; i < 12; i++) {
      const monthIndex = (currentMonth - i + 12) % 12;
      categories.unshift(monthNames[monthIndex]);
    }

    return categories;
  };


export {generateDailyCategories, generateWeeklyCategories, generateMonthlyCategories }