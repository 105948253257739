export enum Carrier {
  FEDEX_EXPRESS = 0,
  FEDEX_GROUND = 1,
  UPS_STANDARD = 2,
  UPS_ECONOMY = 3,
  PUROLATOR_GROUND = 4,
  APC = 5,
  USPS = 6,
  CUSTOM = 7,
}

export enum RateMode {
  PRE_DEFINED = 0,
  CUSTOM = 1,
}
