import { AdminGetUsersFilter } from "api/query";
import useSelectOptions from "Components/Hooks/useSelectOptions";

const InitialFilter = (): AdminGetUsersFilter => {
  const {subscriptionStatusSelectOptions, storeStatusSelectOptions} = useSelectOptions();

  return {
    search: undefined,
    registerDateRange: undefined,
    lastLoginDateRange: undefined,
    subscriptionIds: undefined,
    subscriptionStatus: subscriptionStatusSelectOptions[0].value,
    storeStatus: storeStatusSelectOptions[0].value,
    userTagIds: undefined
  } as AdminGetUsersFilter;
};

export default InitialFilter;
