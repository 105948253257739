import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getOrderStatusText} from "helpers/utilities";
import {getUserOrderDetails} from "slices/order-details/thunk";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/useProfile";
import Summary from "./_Summary";
import Content from "./_Content";
import CostAndFees from "./_CostAndFees";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import ShipmentList from "./_ShipmentList";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import ReassignShipment from "./Modals/ReassignShipment";

const PAGE_IDENTIFIER: ConstantPage = "orderDetails";
interface OrderDetailsProps {
  reAssignShipment?: boolean;
}
const OrderDetails = (props: OrderDetailsProps) => {
  const {t} = useTranslation();
  const [copied, setCopied] = useState(false);
  const {amazonOrderId} = useParams();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      order: state.OrderDetails.order,
    }),
  );
  const {order} = useSelector(ordersData);

  useEffect(() => {
    if (amazonOrderId && hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getUserOrderDetails(amazonOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amazonOrderId]);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(order.amazonOrderId || "");
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Orders.Title"),
      url: "/orders",
    },
    {
      label: `${t("OrderDetails.Title")} - ${order.amazonOrderId}`,
      url: "",
    },
  ];
  document.title = t("PageTitles.OrderDetails");

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={`${t("OrderDetails.Title")} - ${order.amazonOrderId}`} menus={breadcrumbMenus} />
          <Card>
            <CardBody>
              <Row className="d-flex align-items-center">
                <Col xs="auto" className="pe-0 d-flex align-items-center">
                  <span className="link-secondary link-offset-2 text-decoration-underline me-1" role="button">
                    {order.amazonOrderId}
                  </span>
                  <i className="ri-file-copy-line cursor-pointer fs-5" id={`CopyOrderIdTooltip`} onClick={handleCopyClick}></i>
                  <DefaultUncontrolledTooltip target={`CopyOrderIdTooltip`}>{copied ? t("Copied") : t("Copy")}</DefaultUncontrolledTooltip>
                </Col>
                <Col xs="auto">{t(getOrderStatusText(order.orderStatus))}</Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col lg={8}>
              <Summary />
              <Content />
              <ShipmentList />
            </Col>
            <Col lg={4}>
              <CostAndFees />
            </Col>
          </Row>
        </Container>
      </div>
      <ReassignShipment isOpen={props.reAssignShipment || false} />
    </Restricted>
  );
};

export default OrderDetails;
