import React, {useCallback, useEffect} from "react";
import {BroadcastChannels} from "helpers/broadcast_channels";
import Route from "./Routes";
import "./assets/scss/themes.scss";

function App() {
  const channel = new BroadcastChannel(BroadcastChannels.AUTH_CHANNEL);

  const loadBroadcastChannel = useCallback(() => {
    channel.onmessage = () => {
      window.location.reload();
    };
    return () => {
      channel.close();
    };
  }, []);//eslint-disable-line

  useEffect(() => {
    loadBroadcastChannel();
  }, [loadBroadcastChannel]);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
