import {Button, InputGroup} from "reactstrap";
import {FormikValidation} from "helpers/types";
import {useTranslation} from "react-i18next";
import DateInput from "./DateInput";

export type ValidatedDateRangeInputProps<T> = {
  validation: FormikValidation<T>;
  field: keyof T;
  className?: string;
};

const ValidatedDateRangeInput = <T,>({field, validation}: ValidatedDateRangeInputProps<T>) => {
  const {t} = useTranslation();
  const fieldName = field.toString();
  const {value} = validation.getFieldMeta(fieldName);

  return (
    <>
      <InputGroup>
        <DateInput
          name="start"
          placeholder={t("Start")}
          value={value.start}
          onChange={(date: Date) => {
            validation.setFieldValue(fieldName, {start: date, end: value.end});
          }}
        />
        <DateInput
          name="end"
          placeholder={t("End")}
          value={value.end}
          onChange={(date: Date) => {
            validation.setFieldValue(fieldName, {start: value.start, end: date});
          }}
        />
        <Button color="link" className="bg-info-subtle text-info" onClick={() => validation.setFieldValue(fieldName, {start: undefined, end: undefined})} style={{
            borderTop: "var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)",
            borderRight: "var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)",
            borderBottom: "var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)",
        }}>
          X
        </Button>
      </InputGroup>
    </>
  );
};

export default ValidatedDateRangeInput;
