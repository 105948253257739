import { AmazonMarketplaceType } from "Components/Common/AmazonMarketplaceInfos";
import CryptoJS from "crypto-js";

const getEncryptedData = (state: string) => {
    var key = CryptoJS.enc.Utf8.parse("connect-SellThis");
    var iv = CryptoJS.enc.Utf8.parse("amazonToSellThis");

    var encryptedState = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(state), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const encryptedString = CryptoJS.enc.Base64.stringify(encryptedState.ciphertext);

    return encryptedString;
  };

export const getConnectRequestUrl = (userId:string, marketplaces:AmazonMarketplaceType[], storeName: string, marketplace: string, status: string) => {
    let sellerCentral = "https://sellercentral.amazon." + marketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === marketplace)?.domain;
    let data = getEncryptedData(userId + "|" + storeName + "|" + marketplace + "|" + status);
    return `${sellerCentral}/apps/authorize/consent?application_id=amzn1.sp.solution.34223bef-0b90-4ba2-b760-1e402782fc33&state=${encodeURIComponent(data)}`;
  };
