import {postDeleteUserStore, postDisconnectUserStore, postGetUserStores, postSetUserStoreName} from "services/store_service";
import {apiError, loading, setStoresList} from "./reducer";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {ResponseModel} from "models/response_model";
import {UserStore} from "models/user_stores";
import camelcaseKeys from "camelcase-keys";
import i18n from "i18n";

export const getUserStores = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const response: ResponseModel = await postGetUserStores();
    const result = JSON.parse(response.data);
    const stores: UserStore[] = camelcaseKeys(result);
    dispatch(setStoresList(stores));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const deleteUserStore = (userStoreId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const response:ResponseModel = await postDeleteUserStore({userStoreId});
    const result = JSON.parse(response.data);
    const stores: UserStore[] = camelcaseKeys(result);
    dispatch(setStoresList(stores));
    renderSuccessToast(i18n.t("Stores.Toast.StoreDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const disconnectUserStore = (userStoreId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const response:ResponseModel = await postDisconnectUserStore({userStoreId});
    const stores: UserStore[] = response.data;
    renderSuccessToast(i18n.t("Stores.Toast.StoreDisconnectSuccess"));
    dispatch(setStoresList(stores));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const setUserStoreName = (userStoreId: string, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const response : ResponseModel = await postSetUserStoreName({userStoreId, name});
    const result = JSON.parse(response.data);
    const stores: UserStore[] = camelcaseKeys(result);
    dispatch(setStoresList(stores));
    renderSuccessToast(i18n.t("Stores.Toast.StoreNameUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};
