import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";

type ForgotPasswordLoadingStates = LoadingStates<"forgot">;
type ForgotPasswordStatus = "empty" | "success" | "failed";

export type ForgotPasswordState = {
  loading: ForgotPasswordLoadingStates;
  status: ForgotPasswordStatus;
  error?: ApiError;
};

const initialState: ForgotPasswordState = {
  loading: {
    forgot: false,
  },
  status: "empty",
  error: {} as ApiError,
};

const forgotPasswordSlice = createSlice({
  name: "ForgotPassword",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<ForgotPasswordStatus>) {
      state.status = action.payload;
    },
    loading(state, action: PayloadAction<[keyof ForgotPasswordLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {setStatus, loading, apiError} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
