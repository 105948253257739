import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserSearch} from "models/user_search";

type AdminSearchesLoadingStates = LoadingStates<"list" | "restart" | "cancel" | "delete" | "filter">;

export type AdminSearchesState = {
  searchesList: Partial<PagedList<UserSearch>>;
  loading: AdminSearchesLoadingStates;
  error?: ApiError;
};

const initialState: AdminSearchesState = {
  searchesList: {items: []},
  loading: {
    list: false,
    filter:true,
    restart: false,
    cancel: false,
    delete: false,
  },
  error: {} as ApiError,
};

const AdminSearchesSlice = createSlice({
  name: "AdminSearches",
  initialState,
  reducers: {
    setSearchesList(state, action: PayloadAction<PagedList<UserSearch>>) {
      state.searchesList = action.payload;
    },
    loading(state, action: PayloadAction<[keyof AdminSearchesLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.searchesList = initialState.searchesList;
      state.error = initialState.error;
    },
  },
});

export const {setSearchesList, loading, apiError, reset} = AdminSearchesSlice.actions;

export default AdminSearchesSlice.reducer;
