import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";

type ResetPasswordLoadingStates = LoadingStates<"change">;
type ResetPasswordStatus = "empty" | "success" | "failed";
export type ResetPasswordState = {
  loading: ResetPasswordLoadingStates;
  status: ResetPasswordStatus;
  error?: ApiError;
};

const initialState: ResetPasswordState = {
  loading: {
    change: false,
  },
  status: "empty",
  error: {} as ApiError,
};

const ResetPasswordSlice = createSlice({
  name: "ResetPassword",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<ResetPasswordStatus>) {
      state.status = action.payload;
    },
    loading(state, action: PayloadAction<[keyof ResetPasswordLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {loading, setStatus, apiError} = ResetPasswordSlice.actions;

export default ResetPasswordSlice.reducer;
