import {APIClient} from "helpers/api_helper";
import {ScanAndSaveQuery} from "api/query";
import {DeleteScanCommand, ExportAsinsCommand, ISaveNewScanDto} from "api/command";
import * as url from "../helpers/url_helper";

const api = new APIClient();

export const postGetScans = (data: ScanAndSaveQuery) => api.create(url.POST_GET_SCAN_AND_SAVE, data);

export const postSaveNewScan = (data: ISaveNewScanDto) => api.create(url.POST_SAVE_NEW_SCAN_AND_SAVE, data);

export const postExportAsins = (data: ExportAsinsCommand) => api.create(url.POST_EXPORT_ASIN, data);

export const postDeleteScan = (data: DeleteScanCommand) => api.create(url.POST_DELETE_SCAN, data);
