import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import FeatherIcon from "feather-icons-react";
import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import DisplayPrice from "Components/Common/DisplayPrice";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";

type ProjectedSalesAndProfitsData = {
  label: string;
  count: number;
  revenue: number;
  estimatedPayout: number;
  expectedProfit: number;
  profit: number;
  totalExpectedROI: number;
  totalExpectedMargin: number;
  totalROI: number;
  totalMargin: number;
  expectedRoi: number;
  roi: number;
  expectedMargin: number;
  margin: number;
  projectedTotalRoi: number;
  projectedTotalMargin: number;
  successRate: number;
};
const ProjectedSalesAndProfits = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [tableData, setTableData] = useState<ProjectedSalesAndProfitsData[]>([]);
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {currentUserShipment} = useSelector(shipmentsData);
  useEffect(() => {
    if (currentUserShipment) {
      let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setDestinationMarketplace(destination);

      const shipment = currentUserShipment;
      const soldItems: ProjectedSalesAndProfitsData = {
        label: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.SoldItems"),
        count: shipment?.soldItems,
        revenue: shipment?.userShipmentFinance.actualSoldRevenue,
        estimatedPayout: shipment?.userShipmentFinance.estimatedSoldPayout,
        expectedProfit: shipment?.userShipmentFinance.expectedSoldProfit,
        profit: shipment?.userShipmentFinance.actualSoldProfit,
        totalExpectedROI: shipment?.userShipmentFinance.expectedROI,
        totalExpectedMargin: shipment?.userShipmentFinance.expectedMargin,
        totalROI: shipment?.userShipmentFinance.actualROI,
        totalMargin: shipment?.userShipmentFinance.actualMargin,
        expectedRoi: shipment?.userShipmentFinance.expectedSoldROI,
        expectedMargin: shipment?.userShipmentFinance.expectedSoldMargin,
        roi: shipment?.userShipmentFinance.actualSoldROI,
        margin: shipment?.userShipmentFinance.actualSoldMargin,
        projectedTotalRoi: shipment?.userShipmentFinance.actualSoldROI,
        projectedTotalMargin: shipment?.userShipmentFinance.actualSoldMargin,
        successRate: SuccessRateCalculator(shipment?.userShipmentFinance.actualSoldProfit, shipment?.userShipmentFinance.expectedSoldProfit) || 0,
      };

      const leftItems: ProjectedSalesAndProfitsData = {
        label: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems"),
        count: shipment?.totalItems - shipment?.soldItems,
        revenue: shipment?.userShipmentFinance.actualLeftRevenue,
        estimatedPayout: shipment?.userShipmentFinance.estimatedLeftPayout,
        expectedProfit: shipment?.userShipmentFinance.expectedLeftProfit,
        profit: shipment?.userShipmentFinance.actualLeftProfit,
        totalExpectedROI: shipment?.userShipmentFinance.expectedROI,
        totalExpectedMargin: shipment?.userShipmentFinance.expectedMargin,
        totalROI: shipment?.userShipmentFinance.actualROI,
        totalMargin: shipment?.userShipmentFinance.actualMargin,
        expectedRoi: shipment?.userShipmentFinance.expectedLeftROI,
        expectedMargin: shipment?.userShipmentFinance.expectedLeftMargin,
        roi: shipment?.userShipmentFinance.actualLeftROI,
        margin: shipment?.userShipmentFinance.actualLeftMargin,
        projectedTotalRoi: shipment?.userShipmentFinance.actualSoldROI,
        projectedTotalMargin: shipment?.userShipmentFinance.actualSoldMargin,
        successRate: SuccessRateCalculator(shipment?.userShipmentFinance.actualLeftProfit, shipment?.userShipmentFinance.expectedLeftProfit) || 0,
      };

      const joined: ProjectedSalesAndProfitsData[] = [soldItems, leftItems];
      setTableData(joined);
    }
  }, [t, currentUserShipment]); //eslint-disable-line

  const columns = useMemo<ColumnDef<ProjectedSalesAndProfitsData, any>[]>(
    () => [
      {
        header: " ",
        size: 150,
        accessorKey: "label",
        cell: (cellProps) => {
          return <span className="fw-medium">{cellProps.getValue()}</span>;
        },
        footer: () => {
          return <span className="fw-bold">{t("Total")}</span>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Count"),
        size: 80,
        accessorKey: "count",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          return <>{cellProps.getValue()}</>;
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <span>{totalCount}</span>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Revenue"),
        size: 150,
        accessorKey: "revenue",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <>
              <DisplayPrice source={destinationMarketplace?.currency} value={row.revenue} decimals={2} notation="decimal" />
            </>
          );
        },
        footer: ({table, column}) => {
          const totalRevenue = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return (
            <>
              <DisplayPrice source={destinationMarketplace?.currency} value={totalRevenue} decimals={2} notation="decimal" />
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.EstimatedPayout"),
        size: 150,
        accessorKey: "estimatedPayout",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <>
              <DisplayPrice source={destinationMarketplace?.currency} value={row.estimatedPayout} decimals={2} notation="decimal" />
            </>
          );
        },
        footer: ({table, column}) => {
          const totalEstimatedPayout = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return (
            <>
              <DisplayPrice source={destinationMarketplace?.currency} value={totalEstimatedPayout} decimals={2} notation="decimal" />
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.ExpectedProfit"),
        size: 150,
        accessorKey: "expectedProfit",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <>
              <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedProfit} decimals={2} notation="decimal" />
            </>
          );
        },
        footer: ({table, column}) => {
          const totalExpectedProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return (
            <span>
              <DisplayPrice source={destinationMarketplace?.currency} value={totalExpectedProfit} decimals={2} notation="decimal" />
            </span>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Profit"),
        size: 150,
        accessorKey: "profit",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <span className="fw-medium">
              <DisplayPrice source={destinationMarketplace?.currency} value={row.profit} decimals={2} notation="decimal" />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return (
            <span className="fw-medium">
              <DisplayPrice source={destinationMarketplace?.currency} value={totalProfit} decimals={2} notation="decimal" />
            </span>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.ExpectedRoiMargin"),
        size: 150,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <>
              <span>%{row.expectedRoi.toFixed(0)}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>%{row.expectedMargin.toFixed(0)}</span>
            </>
          );
        },
        footer: ({table}) => {
          const expectedRoi = table.getPreFilteredRowModel().rows[0]?.original.totalExpectedROI.toFixed(0);
          const expectedMargin = table.getPreFilteredRowModel().rows[0]?.original.totalExpectedMargin.toFixed(0);
          return (
            <>
              <span>%{expectedRoi}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>%{expectedMargin}</span>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.RoiMargin"),
        size: 120,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <>
              <span>%{row.roi.toFixed(0)}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>%{row.margin.toFixed(0)}</span>
            </>
          );
        },
        footer: ({table}) => {
          const totalRoi = table.getPreFilteredRowModel().rows[0]?.original.totalROI.toFixed(0);
          const totalMargin = table.getPreFilteredRowModel().rows[0]?.original.totalMargin.toFixed(0);
          return (
            <>
              <span>%{totalRoi}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>%{totalMargin}</span>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.SuccessRate"),
        size: 120,
        accessorKey: "successRate",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const value = row.successRate;
          let style = "";
          if (value >= 50 && value <= 70) {
            style = "warning";
          } else if (value > 70) {
            style = "success";
          } else {
            style = "danger";
          }
          return (
            <div className="flex-shrink-0 ">
              <FeatherIcon icon="check-circle" className={`text-${style} icon-dual-${style} icon-xs me-2`} />
              <span>{`%${value.toFixed(0)}`}</span>
            </div>
          );
        },
        footer: ({table, column}) => {
          const totalProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue("profit") as number) + sum, 0);
          const totalExpectedProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue("expectedProfit") as number) + sum, 0);

          const totalRate = SuccessRateCalculator(totalProfit, totalExpectedProfit) ?? 0;
          let style = "";
          if (totalRate >= 50 && totalRate <= 70) {
            style = "warning";
          } else if (totalRate > 70) {
            style = "success";
          } else {
            style = "danger";
          }
          return (
            <div className="flex-shrink-0 ">
              <FeatherIcon icon="check-circle" className={`text-${style} icon-dual-${style} icon-xs me-2`} />
              <span>{`%${totalRate.toFixed(0)}`}</span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, destinationMarketplace],
  );
  return (
    <Card className="card-animate">
      <CardHeader>
        <h5 className="mb-0 border-0 align-items-center d-flex">
          <i className="ri-bank-fill me-1"></i>
          {t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Title")}
        </h5>
      </CardHeader>
      <CardBody>
        <DataTable thClass="px-3" trClass="text-start" tdClass="text-start p-3" ref={tableRef} busy={false} columns={columns} data={tableData} totalDataLength={0} hovered />
      </CardBody>
    </Card>
  );
};

export default ProjectedSalesAndProfits;
