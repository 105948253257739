import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import config from "config";
import translationENG from "./locales/en.json";
import translationTR from "./locales/tr.json";

// the translations
const resources = {
  en: {
    translation: translationENG,
  },
  tr: {
    translation: translationTR,
  },
};

const language = localStorage.getItem('I18N_LANGUAGE');

if (!language) {
  const browserLanguage = navigator.language || "en";
    if (browserLanguage.startsWith("tr")) {
      localStorage.setItem('I18N_LANGUAGE', "tr");
    } else {
      localStorage.setItem('I18N_LANGUAGE', "en");
    }
} 

const detectLanguage = () => {
  const localLanguage = localStorage.getItem("I18N_LANGUAGE");
  if (localLanguage === "en" || localLanguage === "tr") {
    return localLanguage;
  } 
  else {
    const browserLanguage = navigator.language;
    if (browserLanguage.startsWith("tr")) {
      return "tr";
    } else {
      return "en";
    }
  }
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: detectLanguage(),
    fallbackLng: config.defaults.LANGUAGE, // use en if detected lng is not available
    keySeparator: ".", // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
