import React from 'react';

const Footer = () => {
    return (
        <React.Fragment>
            {/* <footer className="footer">
            </footer> */}
        </React.Fragment>
    );
};

export default Footer;