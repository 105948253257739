import {formatCurrency, generateError, getCurrencySymbol, renderSuccessToast} from "helpers/utilities";
import {apiError, loading, removeFromList, reset, setSelectAllUserInventories, setUserInventories, updateItem} from "./reducer";
import {ResponseModel} from "models/response_model";
import {postArchiveInventories, postDownloadInventory, postGetUserInventories, postUnarchiveInventories, postUpdateInventory, postUpdateInventoryCost} from "services/store_service";
import {UploadUserInventory, UserInventory} from "models/user_inventory";
import {PagedList} from "helpers/types";
import {FilterInventoryQuery} from "api/query";
import i18n from "i18n";
import {DownloadInventoryCommand, UpdateInventoryCommand} from "api/command";
import {Workbook} from "exceljs";
import {saveAs} from "file-saver";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import { Maybe } from "yup";

export const getUserInventories = (payload: FilterInventoryQuery) => async (dispatch: any) => {
  const loadingType = payload.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserInventories(payload);
    const pagedResult: PagedList<UserInventory> = result.data;
    dispatch(setUserInventories(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const archiveUserInventories = (inventoryIdList: string[]) => async (dispatch: any) => {
  try {
    dispatch(loading(["archive", true]));
    await postArchiveInventories({inventoryIdList});
    renderSuccessToast(i18n.t("Inventory.Toast.InventoryArchiveSuccess"));
    dispatch(setSelectAllUserInventories(false));
    dispatch(removeFromList(inventoryIdList));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["archive", false]));
  }
};

export const unarchiveUserInventories = (inventoryIdList: string[]) => async (dispatch: any) => {
  try {
    dispatch(loading(["archive", true]));
    await postUnarchiveInventories({inventoryIdList});
    renderSuccessToast(i18n.t("Inventory.Toast.InventoryUnarchiveSuccess"));
    dispatch(setSelectAllUserInventories(false));
    dispatch(removeFromList(inventoryIdList));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["archive", false]));
  }
};

export const updateUserInventoryCost = (userInventoryId: string, currencyCode: Maybe<string>, cost?: Maybe<number>) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postUpdateInventoryCost({userInventoryId, cost, currencyCode});
    const item: UserInventory = result.data;
    dispatch(updateItem(item));
    renderSuccessToast(i18n.t("Inventory.Toast.InventoryUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const updateInventory = (command: UpdateInventoryCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    await postUpdateInventory(command);
    renderSuccessToast(i18n.t("Inventory.Dialog.UpdateInventory.Toast.InventoryUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const downloadInventory = (command: DownloadInventoryCommand, fileName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["download", true]));
    const result: ResponseModel = await postDownloadInventory(command);
    const inventoryList: UploadUserInventory[] = result.data;
    await downloadInventoryList(inventoryList, fileName, true);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["download", false]));
  }
};

export const resetInventoryState = () => async (dispatch: any) => {
  dispatch(reset());
};

const downloadInventoryList = async (dataList: UploadUserInventory[], fileName: string, adjustColumns?: boolean): Promise<void> => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");

  // Define the headers explicitly
  const headers = ["Marketplace", "ASIN", "SKU", "Cost", "CostCurrency", "ProductName", "TotalStock", "Proceeds", "Fees", "Profit", "Margin", "ROI"];

  // Add headers to the worksheet
  worksheet.addRow(headers);

  // Make headers bold
  worksheet.getRow(1).getCell(2).font = {bold: true}; // ASIN
  worksheet.getRow(1).getCell(3).font = {bold: true}; // SKU
  worksheet.getRow(1).getCell(4).font = {bold: true}; // Cost

  worksheet.getColumn(1).width = 15; // MARKETPLACE
  worksheet.getColumn(2).width = 15; // ASIN
  worksheet.getColumn(3).width = 20; // SKU
  worksheet.getColumn(4).width = 10; // COST
  worksheet.getColumn(5).width = 15; // COST CURRENCY
  worksheet.getColumn(6).width = 50; // PRODUCT NAME
  worksheet.getColumn(7).width = 10; // TOTAL_STOCK
  worksheet.getColumn(8).width = 20; // PROCEEDS
  worksheet.getColumn(9).width = 20; //  FEES
  worksheet.getColumn(10).width = 20; // PROFIT

  // Adjust column alignment if specified
  if (adjustColumns) {
    headers.forEach((_, index) => {
      worksheet.getColumn(index + 1).alignment = {horizontal: "left"}; // Align text to the left
    });
  }

  // Add data rows dynamically
  dataList.forEach((item) => {
    const currencySymbol = getCurrencySymbol(item.costCurrency);
    const proceedsValue = formatCurrency(currencySymbol, 2, "abbreviated", item.proceeds, item.costCurrency);
    const feesValue = formatCurrency(currencySymbol, 2, "abbreviated", item.fees, item.costCurrency);
    const profitValue = formatCurrency(currencySymbol, 2, "abbreviated", item.profit, item.costCurrency);
    const name = item.marketplace + " - " + item.name;
    const row = [
      name ?? null,
      item.asin ?? null,
      item.sku ?? null,
      item.cost ?? null,
      item.costCurrency ?? null,
      item.productName ?? null,
      item.totalStock ?? null,
      proceedsValue,
      feesValue,
      profitValue,
      item.margin ?`${item.margin}%` : null,
      item.roi ? `${item.roi}%` : null,
    ];
    worksheet.addRow(row);
  });

  // Define the dropdown values for the Currency column
  const currencies = AmazonMarketplaceInfos()
    .filter((x) => x.active)
    .map((x) => x.currency); // eslint-disable-line
  currencies.push("USD");
  // Apply dropdown list to the Currency column (index 4)
  const currencyColumnIndex = 5; // 1-based index for the 'CostCurrency' column
  worksheet.getColumn(currencyColumnIndex).eachCell({includeEmpty: true}, (cell, rowNumber) => {
    if (rowNumber > 1) {
      // Skip the header row
      cell.dataValidation = {
        type: "list",
        formulae: [`"${currencies.join(",")}"`], // Use a comma-separated string for the dropdown values
        showErrorMessage: true,
        errorTitle: "Invalid Input",
        error: "Please select a value from the dropdown list.",
        promptTitle: "Select from List",
        prompt: "Please select a value from the dropdown list.",
      };
    }
  });

  // Write to a file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${fileName}.xlsx`);
};
