import { FormikValidation, ValidatableComponentProps } from "helpers/types";
import React, { FunctionComponent, ReactElement } from "react";
import { FormFeedback } from "reactstrap";

type ValidationWrapperProps<T extends Record<string, any>, F extends keyof T> = {
    validation: FormikValidation<T>,
    field: F,
    children: ReactElement<ValidatableComponentProps<T[F]>, FunctionComponent<ValidatableComponentProps<T[F]>>>
    submitOnChange?: boolean,
    disabled?: boolean
}

const ValidationWrapper = <T extends Record<string, any>, F extends keyof T>(
    props: ValidationWrapperProps<T, F>
) => {
    const { validation, field, children } = props;
    
    const { error, touched, value } = validation.getFieldMeta(field as string);
    const isValid = touched ? !error : undefined;

    const onChanged = (val?: T[F]) => {
        children.props.onChange?.(val as any);
        validation.setFieldValue(field as string, val).then(r => {
            validation.setFieldTouched(field as string, true, true).then(()=>{
                if(props.submitOnChange) {
                    validation.handleSubmit();
                }
            });
        });
    }

    const inputProps: ValidatableComponentProps<T[F]> = {
        ...children.props, 
        isValid: isValid,
        name: field,
        value: value,
        onChange: onChanged,
        disabled: props.disabled
    };
    
    return (
        <>
            {React.cloneElement(children, inputProps)}
            {isValid === false && <FormFeedback type="invalid" tooltip>{error}</FormFeedback>}
        </>
    );
};

export default ValidationWrapper;
