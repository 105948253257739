export enum OrderStatus {
    PENDING = 1,
    UNSHIPPED = 2,
    PARTIALLY_SHIPPED = 3,
    SHIPPED = 4,
    CANCELED = 5,
    UNFULFILLABLE = 6,
    INVOICE_UNCONFIRMED = 7,
    PENDING_AVAILABILITY = 8
}
    