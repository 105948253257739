import AmazonMarketplaceInfos, {AmazonMarketplaces, AmazonMarketplaceType} from "Components/Common/AmazonMarketplaceInfos";
const useAmazonMarketplace = () => {
	
/**
 * Retrieves a list of active marketplaces from the AmazonMarketplaceInfos.
 *
 * @returns {AmazonMarketplaceInfosType[]} An array of active Amazon marketplace information objects.
 */
  const activeMarketplaces = (): AmazonMarketplaceType[] => {
    return AmazonMarketplaces().filter((x) => x.active);
  };

  const defaultActiveMarketplaceOptions = AmazonMarketplaceInfos().filter((x) => x.active || x.isDefaultUs).map((x) => {
      return {
        value: x.marketplace,
        label: x.countryName,
      };
    });

  const defaultActiveMarketplaceCurrencyOptions = AmazonMarketplaceInfos().filter((x) => x.active || x.isDefaultUs).map((x) => {
      return {
        value: x.currency,
        label: x.currency,
      };
  })
  return {activeMarketplaces, defaultActiveMarketplaceOptions, defaultActiveMarketplaceCurrencyOptions};
};

export {useAmazonMarketplace};
