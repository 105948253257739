import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {useMemo} from "react";

type RestrictedProps = {
  require: ConstantPage;
  requireAll?: boolean;
  fallback?: () => JSX.Element;
  children: React.ReactNode;
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
};
const Restricted = (props: RestrictedProps) => {
  const {canCreate, canRead, canUpdate, canDelete, hasPermission} = useProfile();

  const checkCreate = (page: ConstantPage) => !props.create || canCreate(page);
  const checkRead = (page: ConstantPage) => !props.read || canRead(page);
  const checkUpdate = (page: ConstantPage) => !props.update || canUpdate(page);
  const checkDelete = (page: ConstantPage) => !props.delete || canDelete(page);
  const check = (r: ConstantPage) => hasPermission(r) && (checkCreate(r) && checkUpdate(r) && checkRead(r) && checkDelete(r));
 
  const required = useMemo(() => (Array.isArray(props.require) ? props.require : [props.require]), [props.require]);
  const allowed = props.requireAll ? required.every(check) : required.some(check);

  return allowed ? props.children : typeof props.fallback === "function" ? props.fallback() : props.fallback;
};

export default Restricted;
