import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {ApiError, LoadingStates} from "helpers/types";
import {Currencies} from "models/currencies";
import {UserShipment} from "models/user_shipment";
import {UserStore} from "models/user_stores";
import {fetchUserStores} from "services/store_service";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import { Tag } from "models/tag";

export type CommonLoadingState = LoadingStates<"userStore" | "userShipment" | "loggedUser" | "reanalyze" | "save" | "update" | "delete" | "search" | "tag">;

type AsinList = {
  asin: string;
  quantity: string;
}

export type ShipmentInfo = {
  userStores: UserStore[];
  userShipments: UserShipment[];
  asinList: AsinList[];
};

export type CommonState = {
  loading: CommonLoadingState;
  userStores: UserStore[];
  activeUserStoreOptions: SelectOptionsType[];
  passiveUserStoreOptions: SelectOptionsType[];
  activeMarketplaceOptions: SelectOptionsType[];
  shipmentInfo: ShipmentInfo;
  currencies: Currencies[];
  searchResponse: SearchResponse;
  tags?: Tag[];
  error: ApiError;
};

type Product = {
  asin: string;
  title: string;
  image: string;
};

type Order = {
  amazonOrderId: string;
};

type SearchResponse = {
  products: Product[];
  orders: Order[];
};

const initialState: CommonState = {
  loading: {
    userStore: true,
    userShipment: false,
    loggedUser: false,
    reanalyze: false,
    save: false,
    update: false,
    delete: false,
    search: false,
    tag: false
  },
  userStores: [] as UserStore[],
  activeUserStoreOptions: [] as SelectOptionsType[],
  passiveUserStoreOptions: [] as SelectOptionsType[],
  activeMarketplaceOptions: [] as SelectOptionsType[],
  shipmentInfo: {} as ShipmentInfo,
  currencies: [] as Currencies[],
  searchResponse: {} as SearchResponse,
  tags: [] as Tag[],
  error: {} as ApiError,
};

const CommonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {
    setShipmentInfo(state, action: PayloadAction<ShipmentInfo>) {
      state.shipmentInfo = action.payload;
    },
    setCurrencies(state, action: PayloadAction<Currencies[]>) {
      state.currencies = action.payload;
    },
    setSearchResponse(state, action: PayloadAction<SearchResponse>) {
      state.searchResponse = action.payload;
    },
    setTags(state, action: PayloadAction<Tag[]>) {
      state.tags = action.payload;
    },
    loading(state, action: PayloadAction<[keyof CommonLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.userStores = initialState.userStores;
      state.shipmentInfo = initialState.shipmentInfo;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStores.pending, (state) => {
        state.loading.userStore = true;
      })
      .addCase(fetchUserStores.fulfilled, (state, action) => {
        state.userStores = action.payload;
        let activeAmazonMarketplaces: SelectOptionsType[] = AmazonMarketplaceInfos()
          .filter((x) => x.active)
          .map((x) => {
            return {
              value: x.marketplace,
              label: x.countryName,
            };
          });
        let activeUserStores = action.payload.filter((x) => x.status).map((store) => {
          let mp = AmazonMarketplaceInfos().find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)!;
          return {
            value: store.userStoreId, label: mp.countryName + " - " + store.name
          }
        }).sort((a, b) => a.label.localeCompare(b.label));
        let passiveUserStores = action.payload.filter((x) => x.status === false).map((store) => {
          let mp = AmazonMarketplaceInfos().find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)!;
          return {
            value: store.userStoreId, label: mp.countryName + " - " + store.name
          }
        }).sort((a, b) => a.label.localeCompare(b.label));

        state.activeUserStoreOptions = [...activeUserStores];
        state.passiveUserStoreOptions = [...passiveUserStores];
        state.activeMarketplaceOptions = [...activeAmazonMarketplaces];
        state.loading.userStore = false;
      })
      .addCase(fetchUserStores.rejected, (state, action) => {
        state.error = action.error as ApiError;
        state.loading.userStore = false;
      })
  },
});

export const {setShipmentInfo, setCurrencies, setSearchResponse, setTags, loading, apiError, reset} = CommonSlice.actions;

export default CommonSlice.reducer;
