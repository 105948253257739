import React, {useEffect, useState} from "react";
import {RemainingItems, ShippedItems} from "models/dashboard";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import CountUp from "react-countup";
import {formatDate, getRelativeDate, getToday} from "helpers/utilities";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const ShipmentStatistic = () => {
  const {t} = useTranslation();
  const [draftShipmentCount, setDraftShipmentCount] = useState<number>(0);
  const [shippedShipmentCount, setShippedShipmentCount] = useState<number>(0);
  const [completedShipmentCount, setCompletedShipmentCount] = useState<number>(0);
  const [filterStartDate, setFilterStartDate] = useState<Date>(getToday().toDate());
  const filterEndDate: Date = getToday().toDate(); // Today

  const [shippedItems, setShippedItems] = useState<ShippedItems>({} as ShippedItems);
  const [remainingItems, setRemainingItems] = useState<RemainingItems>({} as RemainingItems);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
      filter: state.Dashboard.filter,
    }),
  );

  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics && statistics.shipmentStatistics) {
      setDraftShipmentCount(statistics.shipmentStatistics.draft);
      setShippedShipmentCount(statistics.shipmentStatistics.shipped);
      setCompletedShipmentCount(statistics.shipmentStatistics.completed);
      setShippedItems(statistics.shippedItems);
      setRemainingItems(statistics.remainingItems);
    }
  }, [statistics]);

  useEffect(() => {
    if (filter) {
      switch (filter.dateRange) {
        case 1:
          setFilterStartDate(getRelativeDate(0));
          break;
        case 7:
          setFilterStartDate(getRelativeDate(6));
          break;
        case 14:
          setFilterStartDate(getRelativeDate(13));
          break;
        case 30:
          setFilterStartDate(getRelativeDate(29));
          break;
        case 90:
          setFilterStartDate(getRelativeDate(89));
          break;
        case 180:
          setFilterStartDate(getRelativeDate(179));
          break;
        case 365:
          setFilterStartDate(getRelativeDate(364));
          break;
        default:
          setFilterStartDate(getRelativeDate(0));
          break;
      }
    }
  }, [filter]);

  return (
    <React.Fragment>
      <Col xl={4} className="same-height">
        <Card className="card-animate">
          <CardBody>
            <h5 className="fs-15 fw-semibold">{t("Dashboard.Widgets.Label.ShipmentStatistics")}</h5>
            <p className="text-muted">{t("Dashboard.Widgets.Label.ShipmentStatisticsDescription")}</p>
            <div className="d-flex flex-wrap justify-content-evenly mt-5">
              <div className="mb-1 text-center">
                <a
                  href={`/shipments?createDateStart=${formatDate(filterStartDate)}&createDateEnd=${formatDate(filterEndDate)}&status=Draft`}
                  className="text-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  <div className="avatar-sm d-block mx-auto">
                    <span className="avatar-title rounded-circle text-white bg-warning fs-18">{draftShipmentCount}</span>
                  </div>
                  <div className="mt-1 d-block">{t("Dashboard.Widgets.Label.ShipmentStatus.Draft")}</div>
                </a>
              </div>
              <div className="mb-1">
                <a
                  href={`/shipments?shipDateStart=${formatDate(filterStartDate)}&shipDateEnd=${formatDate(filterEndDate)}&status=Shipped`}
                  className="text-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  <div className="avatar-sm d-block mx-auto">
                    <span className="avatar-title rounded-circle text-white bg-success fs-18">{shippedShipmentCount}</span>
                  </div>
                  <div className="mt-1 d-block">{t("Dashboard.Widgets.Label.ShipmentStatus.Shipped")}</div>
                </a>
              </div>
              <div className="mb-1">
                <a
                  href={`/shipments?shipDateStart=${formatDate(filterStartDate)}&shipDateEnd=${formatDate(filterEndDate)}&status=Completed`}
                  className="text-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  <div className="avatar-sm d-block mx-auto">
                    <span className="avatar-title rounded-circle text-white bg-secondary fs-18">{completedShipmentCount}</span>
                  </div>
                  <div className="mt-1 d-block">{t("Dashboard.Widgets.Label.ShipmentStatus.Completed")}</div>
                </a>
              </div>
            </div>
          </CardBody>
          <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
            <div className={"progress-bar rounded-0 bg-warning"} role="progressbar" style={{width: "30%"}}></div>
            <div className={"progress-bar rounded-0 bg-success"} role="progressbar" style={{width: "40%"}}></div>
            <div className={"progress-bar rounded-0 bg-secondary"} role="progressbar" style={{width: "30%"}}></div>
          </div>
        </Card>
      </Col>

      <Col xl={2} className="same-height">
        <Row style={{flex: 1}}>
          <Col xl={12}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-2">{t("Dashboard.Widgets.Label.ShippedItems")}</p>
                    <div className="mb-0 h4 pb-1">
                      <span className="counter-value">
                        <CountUp start={0} end={shippedItems.skuValue} decimals={0} duration={2} /> {shippedItems.skuValue > 1 ? t("SKUs") : t("SKU")}
                      </span>
                      <span className="badge rounded-pill bg-success-subtle text-success ms-2 fs-15">
                        <CountUp start={0} prefix="" suffix={` ${t("Units")}`} separator="" decimals={0} duration={2} end={shippedItems.unitValue} />
                      </span>
                    </div>
                    <p className="text-muted mb-0 mt-1">{t("Dashboard.Widgets.Label.ShippedItemsDescription")}</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{flex: 1}}>
          <Col xl={12}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p className="text-uppercase fw-semibold fs-12 text-muted mb-2">
                      {t("Dashboard.Widgets.Label.RemainingItems")}
                      <i className="mdi mdi-information align-bottom ms-1 text-secondary" id="pemaining-items-info"></i>
                      <DefaultUncontrolledTooltip target="pemaining-items-info">{t("Dashboard.Widgets.Tooltip.RemainingItems")}</DefaultUncontrolledTooltip>
                    </p>
                    <div className="mb-0 h4 pb-1">
                      <span className="counter-value">
                        <CountUp start={0} end={remainingItems.skuValue} decimals={0} duration={2} /> {remainingItems.skuValue > 1 ? t("SKUs") : t("SKU")}
                      </span>
                      <span className="badge rounded-pill bg-success-subtle text-success ms-2 fs-15">
                        <CountUp start={0} suffix={` ${t("Units")}`} duration={2} end={remainingItems.unitValue} />
                      </span>
                    </div>
                    <p className="text-muted mb-0 mt-1">{t("Dashboard.Widgets.Label.RemainingItemsDescription")}</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default ShipmentStatistic;
