import { PropsWithChildren } from "react";
import { Spinner } from "reactstrap";
import classNames from "classnames";

export interface BusyOverlayProps extends PropsWithChildren {
    busy: Boolean,
    spinnerColor?: string,
    backgroundColor?: string,
    opaque?: boolean,
    size?: "sm",
    blur?: boolean,
    overlayClassName?: string,
    radius?: string;
}

const BusyOverlay = (props: BusyOverlayProps) => {
    const overlayStyle: React.CSSProperties = {
        borderRadius: props.radius || "0", // Varsayılan değer "0"
    };

    return (<>
        {props.busy ?
        <div className={classNames("position-relative", props.overlayClassName)}>
            <div className={classNames(`bg-${props.backgroundColor || "black"}`, "h-100 position-absolute w-100 z-3", {
                "bg-opacity-25": !props.opaque
            })} style={overlayStyle}>
                <div className="align-items-center d-flex h-100 justify-content-center">
                    <Spinner size={props.size} type="border" className={classNames(`text-${props.spinnerColor || "light"}`)} />
                </div>
            </div>
            <div className={classNames({
                "blur-1": props.blur
            })}>{props.children}</div>
        </div> 
        : props.children}
    </>);
}

export default BusyOverlay;