import React, {useState} from "react";
import {formatCurrency} from "helpers/utilities";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import getChartColorsArray from "Components/Common/ChartsDynamicColor";
import i18n from "i18n";
import ReactApexChart from "react-apexcharts";

export type DashboardSaleHistorySeries = {
  name: string;
  data: number[];
  type: "line" | "area" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "rangeArea";
};

interface SaleHistoryChartProps {
  dataColors: any;
  series: DashboardSaleHistorySeries[];
  categories: string[];
  currency?: string;
}
export const DashboardSaleHistoryChart = (props: SaleHistoryChartProps) => {
  var lineChartCustomerColors = getChartColorsArray(props.dataColors);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const soldItemsCountSeries = props.series.find((series) => series.name === i18n.t("Dashboard.SalesHistory.Label.SoldItemsCount"));
  const revenueSeries = props.series.find((series) => series.name === i18n.t("Dashboard.SalesHistory.Label.Revenue"));
  const profitSeries = props.series.find((series) => series.name === i18n.t("Dashboard.SalesHistory.Label.Profit"));
  const maxRevenue = revenueSeries ? Math.max(...revenueSeries.data) : 0;
  const maxProfit = profitSeries ? Math.max(...profitSeries.data) : 0;

  const maxSoldItemsCount = soldItemsCountSeries ? Math.max(...soldItemsCountSeries.data) : 0;
  const maxRevenueAndProfit = maxRevenue > maxProfit ? maxRevenue * 1.1 : maxProfit * 1.1;

  const yAxisMax_SOLD_ITEMS_COUNT = maxSoldItemsCount > 0 ? maxSoldItemsCount * 1.1 : 10; // Adding 10% padding or default to 10 if no data
  const yAxisMax_REVENUE_AND_PROFIT = maxRevenueAndProfit > 0 ? maxRevenueAndProfit * 1.1 : 10; // Adding 10% padding or default to 10 if no data

  var options: any = {
    chart: {
      height: 370,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      dashArray: [0, 0, 0],
      width: [2, 0, 0],
    },
    fill: {
      opacity: [0.1, 0.9, 1],
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: props.categories,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: [
      {
        show: true,
        showAlways: true,
        tickAmount: 3,
        title: {
          text: i18n.t("Sold Items Count"),
          offsetX: -5,
        },
        axisBorder: {
          show: true,
          color: "#405189",
          offsetX: -25,
        },
        labels: {
          formatter: function(val: any) {
            return val?.toFixed(0);
          },
        },
        max: yAxisMax_SOLD_ITEMS_COUNT + 5,
      },
      {
        show: true,
        showAlways: true,
        opposite: true,
        title: {
          text: i18n.t("Revenue & Profit"),
          offsetX: 5,
        },
        axisBorder: {
          show: true,
          color: "#50c3e6",
          offsetX: -55,
        },
        labels: {
          formatter: function(val: any) {
            return val.toFixed(2);
          },
        },
      },
      {
        show: false,
        showAlways: true,
        opposite: true,
        axisBorder: {
          show: true,
          color: "#f06548",
          offsetX: -55,
        },
        labels: {
          formatter: function(val: any) {
            return val.toFixed(2);
          },
        },
        max: yAxisMax_REVENUE_AND_PROFIT,
      },
    ],
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        barHeight: "70%",
      },
    },
    colors: lineChartCustomerColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function(y: any) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
        {
          formatter: function(y: any) {
            if (typeof y !== "undefined") {
              const sign = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === props.currency)?.priceSign || "";
              return formatCurrency(sign, 2, "abbreviated", y, props.currency);
            }
            return y;
          },
        },
        {
          formatter: function(y: any) {
            if (typeof y !== "undefined") {
              const sign = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === props.currency)?.priceSign || "";
              return formatCurrency(sign, 2, "abbreviated", y, props.currency);
            }
            return y;
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr" options={options} series={props.series} type="line" height="370" className="apex-charts" />
    </React.Fragment>
  );
};
