import {RefObject, useCallback, useEffect} from "react";
import {SearchesListRef} from "..";
import {Button, Col, Form, Modal, ModalBody, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {ReAnalyzeSearchCommand} from "api/command";
import {reAnalyzeSearch} from "slices/thunks";
import {preventScrollUp} from "helpers/utilities";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface ReAnalyzeSearchProps {
  isOpen: boolean;
  listRef?: RefObject<SearchesListRef>;
}
const ReAnalyzeSearch = (props: ReAnalyzeSearchProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {searchId} = useParams();
  const searchesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      searchesList: state.Searches.searchesList,
      loading: state.Searches.loading,
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );
  const {loading, searchesList, activeUserStoreOptions, activeMarketplaceOptions} = useSelector(searchesData);

  const validation = useFormik({
    initialValues: {
      searchName: "",
      marketplace: "",
    } as ReAnalyzeSearchCommand,
    validationSchema: Yup.object().shape({
      searchName: Yup.string().required(t("Searches.Dialog.ReAnalyze.Validation.SearchName")),
      marketplace: Yup.string().required(t("Searches.Dialog.ReAnalyze.Validation.Marketplace")),
    }),
    onSubmit: (values: ReAnalyzeSearchCommand) => {
      const payload: ReAnalyzeSearchCommand = {
        marketplace: values.marketplace,
        searchId: searchId ?? "",
        searchName: values.searchName,
      };
      const reAnalyzePromise = reAnalyzeSearch(payload)(dispatch);
      reAnalyzePromise.then((isSuccess) => {
        if (isSuccess) {
          props.listRef?.current?.reload();
          toggle();
        }
      });
    },
  });

  const toggle = () => {
    validation.resetForm();
    if (window.location.pathname.startsWith("/searches/search-results")) {
      navigate(`/searches/search-results/${searchId}`);
    } else {
      navigate("/searches");
    }

    preventScrollUp();
  };

  const initModal = useCallback(() => {
    if (props.isOpen) {
      if (searchesList.items && searchesList.items.length > 0) {
        const item = searchesList.items.find((x) => x.userSearchId === searchId);
        if (item) {
          let marketplace = item.userStoreId ?? item.marketplaceTarget;
          validation.setFieldValue("searchName", item.name);
          validation.setFieldValue("marketplace", marketplace);
        }
      } else {
        const queryParams = new URLSearchParams(window.location.search);
        const searchName = queryParams.get("searchName");
        const store = queryParams.get("store");

        if (searchName) validation.setFieldValue("searchName", decodeURIComponent(searchName));
        if (store) validation.setFieldValue("marketplace", store);
      }
    }
  }, [props.isOpen, searchesList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  return (
    <>
      <Modal backdrop="static" isOpen={props.isOpen} toggle={toggle} centered={true}>
        <ModalBody className="py-3 px-5">
          <Form onSubmit={validation.handleSubmit}>
            <div className="mt-2 text-center">
              <i className={"mdi mdi-chart-timeline-variant-shimmer display-5 text-info"}></i>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-4">
                <h4>{t("Searches.Dialog.ReAnalyze.Title")}</h4>
                <p className="text-muted mx-4 mb-0 mt-3">{t("Favorites.Dialog.Reanalyze.Description")}</p>
              </div>
            </div>
            <div className="mt-4">
              <Row className="g-3">
                <Col xs={12} md={6}>
                  <ValidatedInput validation={validation} type="text" field="searchName" maxLength={100} placeholder={t("Searches.Name")} disableValidationUI />
                </Col>
                <Col xs={12} md={6}>
                  <ValidatedSelect
                    className={`new-search filter-input ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                    options={[...activeUserStoreOptions, ...activeMarketplaceOptions]}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    errorStyle="container"
                    optionStyle="country"
                    valueStyle="country"
                  />
                </Col>
              </Row>
            </div>

            <div className="mt-4 mb-2">
              <div className="d-flex gap-2 justify-content-center">
                <Button type="submit" color={"success"} disabled={loading.reAnalyzeSearch}>
                  {loading.reAnalyzeSearch && <Spinner size="sm" color="light" className="me-2" />}
                  {t("Favorites.Dialog.Reanalyze.SubmitButton")}
                </Button>
                <Button type="button" color={"light"} onClick={toggle} disabled={loading.reAnalyzeSearch}>
                  {t("Favorites.Dialog.Reanalyze.CancelButton")}
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReAnalyzeSearch;
