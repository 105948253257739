import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserSearch} from "models/user_search";

type SearchesLoadingStates = LoadingStates<"list" | "update" | "save" | "delete" | "filter" | "reAnalyzeSearch">;

export type SearchesState = {
  searchesList: Partial<PagedList<UserSearch>>;
  loading: SearchesLoadingStates;
  error?: ApiError;
};

const initialState: SearchesState = {
  searchesList: {items: []},
  loading: {
    list: false,
    filter:true,
    update: false,
    save: false,
    delete: false,
    reAnalyzeSearch: false,
  },
  error: {} as ApiError,
};

const SearchesSlice = createSlice({
  name: "Searches",
  initialState,
  reducers: {
    setSearchesList(state, action: PayloadAction<PagedList<UserSearch>>) {
      state.searchesList = action.payload;
    },
    loading(state, action: PayloadAction<[keyof SearchesLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.searchesList = initialState.searchesList;
      state.error = initialState.error;
    },
  },
});

export const {setSearchesList, loading, apiError, reset} = SearchesSlice.actions;

export default SearchesSlice.reducer;
