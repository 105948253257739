import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import {Dashboard, SalesHistory, SaleSummary} from "models/dashboard";
import { DashboardFilter } from "models/dashboard_filter";

export type DashboardLoadingState = LoadingStates<"list">;

export type DashboardState = {
  loading: DashboardLoadingState;
  statistics: Dashboard;
  filter?: DashboardFilter;
  error: ApiError;
};

const initialState: DashboardState = {
  loading: {
    list: false,
  },
  statistics: {
    totalRevenue: {
      changeRate: 0,
      value: 0,
    },
    totalOrders: {
      changeRate: 0,
      units: 0,
      value: 0,
    },
    profitRoiMargin: {
      margin: 0,
      roi: 0,
      profit: 0,
    },
    inventoryWidget: {
      inStock: 0,
      totalSku: 0,
    },
    shippedItems: {
      skuValue: 0,
      unitValue: 0,
    },
    remainingItems: {
      skuValue: 0,
      unitValue: 0,
    },
    searchAndScans: {
      asinsFound: 0,
      linkScanned: 0,
      recheckAsins: 0,
      scan: 0,
      search: 0,
      sellableAsins: 0,
      totalAsins: 0,
    },
    finance: {
      estimatedPayouts: 0,
      estimatedStockValue: 0,
      totalCosts: 0,
      totalFees: 0,
    },
    orderSummary: {
      canceledCount: 0,
      pendingCount: 0,
      shippedCount: 0,
    },
    saleSummary: [] as SaleSummary[],
    salesHistories: [] as SalesHistory[],
    salesOverview: {
      soldItemsCount: 0,
      totalCosts: 0,
      totalFees: 0,
      revenue: 0,
      profit: 0,
      profitPerItem: 0,
      margin: 0,
      roi: 0,
    },
    dashboardFilter: {}
  } as Dashboard,
  filter: {} as DashboardFilter,
  error: {} as ApiError,
};

const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    setStatistics(state, action: PayloadAction<Dashboard>) {
      state.statistics = action.payload;
      state.filter = action.payload.dashboardFilter;
    },
    loading(state, action: PayloadAction<[keyof DashboardLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.statistics = initialState.statistics;
      state.filter = initialState.filter;
      state.error = initialState.error;
    },
  },
});

export const {setStatistics, loading, apiError, reset} = DashboardSlice.actions;

export default DashboardSlice.reducer;
