import sizeMe, {SizeMeProps} from "react-sizeme";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Card, CardBody, Col, Collapse, Row} from "reactstrap";
import {UserSearchProduct} from "models/user_search_product";
import {StackGridInstance} from "interfaces/stackGridTypes";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {useAmazonMarketplace} from "Components/Hooks/useAmazonMarketplace";
import {AmazonMarketplaceType} from "Components/Common/AmazonMarketplaceInfos";
import {useTranslation} from "react-i18next";
import {markInspect} from "slices/search-result/thunk";
import ProfitAnalysis from "./_ProfitAnalysis";
import PriceAnalysis from "./_PriceAnalysis";
import CheckListItems from "./_CheckList";
import SaleAnalysis from "./_SaleAnalysis";
import ProductIsExist from "./_ProductIsExist";
import CompetitorAnalysis from "./_CompetitorAnalysis";
import VariationAnalysis from "./_VariationAnalysis";
import SellerAnalysis from "./_SellerAnalysis";
import RestrictionCheck from "./_RestrictionCheck";
import ProductSize from "./_ProductSize";
import Summary from "./_Summary";
import StackGrid from "react-stack-grid";
import { preventScrollUp } from "helpers/utilities";

type Props = SizeMeProps & {
  item: UserSearchProduct;
};
const SearchResultItem = ({item, size}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [autoMarkUsed, setAutoMarkUsed] = useState<boolean>(false);
  const {activeMarketplaces} = useAmazonMarketplace();
  const dispatch = useDispatch();
  const stackGridRef = useRef<StackGridInstance>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceType>();
  const {t} = useTranslation();
  const {searchId} = useParams();

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      showDetails: state.SearchResult.showDetails,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );
  const {showDetails, pageUrl} = useSelector(searchResultsData);
  var width = size.width || 0;

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setIsCollapsed(isCollapsed);
  }, [isCollapsed]);

  useMemo(() => {
    return setIsCollapsed(!showDetails);
  }, [showDetails]);

  const handleMarkReviewed = async () => {
    if (!autoMarkUsed && item.isReviewed !== true) {
      await markInspect({userSearchProductId: item.userSearchProductId, isReviewed: true})(dispatch);
      setAutoMarkUsed(true);
    }
  };

  const onLoad = useCallback(() => {
    let source = activeMarketplaces().find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === item.marketplaceSource);
    let destination = activeMarketplaces().find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === item.marketplaceTarget);
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div key={`SearchResultItem-${item.userSearchProductId}`} ref={containerRef} onClick={handleMarkReviewed} onTouchEnd={handleMarkReviewed}>
      <Card className="border">
        <Summary item={item}></Summary>
        <Link to="#" onClick={handleCollapse} className="link-primary vstack text-center border-0 mt-2">
          <i className={!isCollapsed ? "bx bxs-chevrons-up fs-22" : "bx bxs-chevrons-down fs-22"}></i>
        </Link>

        <Collapse isOpen={!isCollapsed}>
          <CardBody className="pb-0">
            <Row>
              <Col>
                <StackGrid
                  ref={stackGridRef}
                  component="div"
                  itemComponent="div"
                  className="StackGrid-component"
                  columnWidth={width <= 700 ? "100%" : width <= 1100 ? "50%" : width <= 1460 ? "33.33%" : "25%"}
                  gutterWidth={20}
                  duration={200}
                  easing="ease-out"
                >
                  <Col>
                    <ProfitAnalysis item={item} destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} ></ProfitAnalysis>
                  </Col>
                  <Col>
                    <PriceAnalysis item={item}></PriceAnalysis>
                  </Col>
                  <Col>
                    <CheckListItems item={item}></CheckListItems>
                  </Col>
                  <Col>
                    <SaleAnalysis item={item}></SaleAnalysis>
                  </Col>
                  <Col>
                    <ProductIsExist item={item}></ProductIsExist>
                  </Col>
                  <Col>
                    <CompetitorAnalysis item={item}></CompetitorAnalysis>
                  </Col>
                  <Col>
                    <VariationAnalysis item={item}></VariationAnalysis>
                  </Col>
                  <Col>
                    <SellerAnalysis item={item}></SellerAnalysis>
                  </Col>
                  <Col>
                    <RestrictionCheck item={item}></RestrictionCheck>
                  </Col>
                  <Col>
                    <ProductSize item={item}></ProductSize>
                  </Col>
                </StackGrid>
              </Col>
            </Row>
            <Row>
              {searchId && (
                <Col className="fs-11 float-end d-flex justify-content-end">
                  <em className="mb-3 me-2 ">
                    <Link to={`${pageUrl}/report-error?asin=${item.asin}`} onClick={() => preventScrollUp()} className="mt-3 link-dark link-offset-2 text-decoration-underline text-muted">
                      {t("SearchResults.ReportError")}
                    </Link>
                  </em>
                </Col>
              )}
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default sizeMe()(SearchResultItem);
