// action
import {postForgotPassword} from "services/identity_service";
import {IForgotPasswordDto} from "models/application_user";
import {apiError, loading, setStatus} from "./reducer";
import {ApiError} from "helpers/types";

// Is user forgotPassword successful then direct plot user in redux.
export const forgotPasswordUser = (user: IForgotPasswordDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["forgot", true]));
    dispatch(setStatus("empty"));
    await postForgotPassword(user);
    dispatch(setStatus("success"));
    return true;
  } catch (error:any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error?.response?.data as ApiError));
    return false;
  } finally {
    dispatch(loading(["forgot", false]));
  }
};

export const resetForgotPasswordFlag = () => (dispatch: any) => {
  try {
    dispatch(setStatus("empty"));
    return true;
  } catch (error:any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error?.response?.data as ApiError));
    return false;
  }
};
