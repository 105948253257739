import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";

interface SellerAnalysisProps {
  item: UserSearchProduct;
}
const SellerAnalysis = ({item}: SellerAnalysisProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.SellerAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <span>
              {t("SearchResults.Analyzes.SellerAnalysis.CommonFBASeller")}
              {item.brandInfo?.potentialBrandOwner ? <i className="mdi mdi-alert align-middle text-warning ms-1"></i> : ""}
            </span>

            {item.brandInfo?.commonFBASellerCount ? (
              <span className={item.brandInfo.commonFBASellerCount === 0 ? "text-success" : "text-danger"}>{item.brandInfo.commonFBASellerCount}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.SellerAnalysis.CommonRemoteFBASeller")}</span>

            {item.brandInfo?.commonFBARemoteSellerCount ? (
              <span className={item.brandInfo.commonFBARemoteSellerCount === 0 ? "text-success" : "text-danger"}>{item.brandInfo.commonFBARemoteSellerCount}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.SellerAnalysis.PotentialBrandOwner")}</span>

            {item.brandInfo?.potentialBrandOwner ? (
              <span className="text-danger">{t("Yes")}</span>
            ) : item.brandInfo?.potentialBrandOwner === false ? (
              <span className="text-success">{t("No")}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default SellerAnalysis;
