import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import {Subscription} from "models/subscription";

type NewSubscriptionLoadingStates = LoadingStates<"list" | "coupon" | "create">;
type CouponStatus = "empty" | "applied" | "invalid";
export type CouponDetails = {
  duration: string;
  amount: string;
  durationInMonth?: number;
};

export type NewSubscriptionState = {
  loading: NewSubscriptionLoadingStates;
  list: Subscription[];
  error?: ApiError;
  couponStatus: CouponStatus;
  couponDetails: CouponDetails;
};

export const initialState: NewSubscriptionState = {
  loading: {
    coupon: false,
    list: false,
    create: false,
  },
  list: [] as Subscription[],
  error: {} as ApiError,
  couponStatus: "empty",
  couponDetails: {} as CouponDetails,
};

const NewSubscriptionSlice = createSlice({
  name: "NewSubscription",
  initialState,
  reducers: {
    setList(state, action) {
      state.list = action.payload.data;
    },
    setCouponStatus(state, action: PayloadAction<CouponStatus>) {
      state.couponStatus = action.payload;
    },
    setCouponDetails(state, action: PayloadAction<CouponDetails>) {
      state.couponDetails = action.payload;
    },
    loading(state, action: PayloadAction<[keyof NewSubscriptionLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError | null>) {
      if (action.payload === null) state.error = {} as ApiError;
      else state.error = action.payload;
    },
  },
});

export const {setList, setCouponStatus, setCouponDetails, loading, apiError} = NewSubscriptionSlice.actions;

export default NewSubscriptionSlice.reducer;
