import {APIClient} from "helpers/api_helper";
import {IAddToUserFavoriteListDto, IDeleteUserFavoriteListDto, RemoveFromUserFavoriteListCommand, IRenameUserFavoriteListDto, ISaveUserFavoriteListDto} from "models/user_favorites";
import {UserSearchesQuery, ISaveUserSearchRequest} from "models/user_search";
import {IGetUserFavoriteItemsDto, GetUserFavoriteListQuery, GetProductDetailsQuery, SearchQuery, SingleSearchQuery} from "api/query";
import {
  DeleteSearchResultsFilterCommand,
  SubmitForAnalysisCommand,
  SetUserShowCheckListOptionChoiceCommand,
  ReAnalyzeSearchCommand,
  DeleteSearchCommand,
  AnalyzeShipmentCommand,
  SaveOrUpdateSearchResultsFilterCommand,
  MarkReviewedCommand,
} from "api/command";
import {SearchResultQuery} from "models/search_result";
import * as url from "../helpers/url_helper";

const api = new APIClient();

export const postGetUserFavorites = (data: GetUserFavoriteListQuery): Promise<any> => api.create(url.POST_GET_USER_FAVORITES, data);

export const postGetUserFavoriteItems = (data: IGetUserFavoriteItemsDto): Promise<any> => api.create(url.POST_GET_USER_FAVORITE_ITEMS, data);

export const postSaveUserFavorite = (data: ISaveUserFavoriteListDto): Promise<any> => api.create(url.POST_SAVE_USER_FAVORITE, data);

export const postDeleteUserFavoriteList = (data: IDeleteUserFavoriteListDto): Promise<any> => api.create(url.POST_DELETE_USER_FAVORITE_LIST, data);

export const postRenameUserFavoriteList = (data: IRenameUserFavoriteListDto): Promise<any> => api.create(url.POST_RENAME_USER_FAVORITE_LIST, data);

export const postAddToUserFavoriteList = (data: IAddToUserFavoriteListDto): Promise<any> => api.create(url.POST_ADD_TO_USER_FAVORITE_LIST, data);

export const postRemoveFromUserFavoriteList = (data: RemoveFromUserFavoriteListCommand): Promise<any> => api.create(url.POST_REMOVE_FROM_USER_FAVORITE_LIST, data);

export const postGetUserSearches = (data: UserSearchesQuery) => api.create(url.POST_GET_USER_SEARCHES, data);

export const postSaveNewSearch = (data: ISaveUserSearchRequest) => api.create(url.POST_SAVE_NEW_SEARCH, data);

export const postReAnalyzeSearch = (data: ReAnalyzeSearchCommand) => api.create(url.POST_REANALYZE_SEARCH, data);

export const postDeleteSearch = (data: DeleteSearchCommand) => api.create(url.POST_DELETE_SEARCH, data);

export const postSubmitForAnalysis = (data: SubmitForAnalysisCommand) => api.create(url.POST_SUBMIT_FOR_ANALYSIS, data);

export const postGetFilters = () => api.create(url.POST_GET_SEARCH_RESULTS_FILTERS, []);

export const postGetSearchResults = (data: SearchResultQuery) => api.create(url.POST_GET_SEARCH_RESULTS, data);

export const postSaveFilter = (data: SaveOrUpdateSearchResultsFilterCommand) => api.create(url.POST_SAVE_SEARCH_RESULTS_FILTER, data);

export const postDeleteFilter = (data: DeleteSearchResultsFilterCommand) => api.create(url.POST_DELETE_SEARCH_RESULTS_FILTER, data);

export const postSetUserShowCheckListOptionChoice = (data: SetUserShowCheckListOptionChoiceCommand) => api.create(url.POST_SET_USER_SHOW_CHECKLIST_OPTION_CHOICE, data);

export const postAnalyzeShipment = (data: AnalyzeShipmentCommand) => api.create(url.POST_ANALYZE_SHIPMENT, data);

export const postGetProductDetails = (data: GetProductDetailsQuery) => api.create(url.POST_GET_PRODUCT_DETAILS, data);

export const postGetProductDetailsForAnonymUsers = (data: SingleSearchQuery) : Promise<any> => api.create(url.POST_GET_SINGLE_SEARCH, data);

export const getSearch = (data: SearchQuery) => api.get(url.GET_SEARCH, data);

export const postMarkInspect = (data: MarkReviewedCommand) => api.create(url.POST_MARK_INSPECT, data);
