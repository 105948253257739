import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import CountUp from "react-countup";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import RenderNumber from "Components/Common/RenderNumber";
import {numberFormatter} from "helpers/utilities";

const SearchQueues = () => {
  const {t} = useTranslation();
  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);
  return (
    <PlaceholderGlow busy={loading.list}>
      <Card className="card-animate mb-0">
        <CardBody className="p-0">
          <div className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center alert alert-secondary fade show" role="alert">
            <span className="d-flex align-items-center fs-16 fw-medium">
              <i className="bx bx-search-alt me-1"></i>
              {t("Admin.SystemMonitoring.Section.Title.SearchQueues")}
            </span>
          </div>
          <Row className="row-cols-md-3 row-cols-1">
            <Col xxl={3} xl={6} sm={12} className="same-height">
              <div className="mt-3 mt-md-0 py-3 px-3">
                <h5 className="fs-15 nowrap">{t("Admin.SystemMonitoring.Stats.Label.ActiveAnalyses")}</h5>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 fs-2">
                    <i className={"text-secondary mdi mdi-chart-timeline-variant-shimmer"}></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h4 className="mb-0">
                      <span className="counter-value">
                        <CountUp end={systemMonitoring.searchQueue?.activeAnalysesCount} duration={2} />
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="text-muted fs-13">
                  <span className="nowrap">
                    {t("Total")}:{" "}
                    <strong>
                      <RenderNumber value={systemMonitoring.searchQueue?.activeAnalysesCompletedProductsCount + systemMonitoring.searchQueue?.activeAnalysesNotCompletedProductsCount} decimals={0} notation="abbreviated"/>
                    </strong>
                    <i className="mdi mdi-slash-forward"></i>
                    {t("Pending")}:{" "}
                    <strong>
                      <RenderNumber value={systemMonitoring.searchQueue?.activeAnalysesNotCompletedProductsCount} decimals={0} notation="abbreviated"/>
                    </strong>
                  </span>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={6} sm={12} className="same-height">
              <div className="mt-3 mt-md-0 py-3 px-3">
                <h5 className="fs-15 nowrap">{t("Admin.SystemMonitoring.Stats.Label.ExceedsThreshold")}</h5>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 fs-2">
                    <i className={`mdi ${systemMonitoring.searchQueue?.exceedsThresholdAnalysesCount > 0 ? "text-danger mdi-speedometer" : "text-success mdi-speedometer-slow"}`}></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h4 className="mb-0">
                      <span className={`counter-value ${systemMonitoring.searchQueue?.exceedsThresholdAnalysesCount > 0 && "text-danger"}`}>
                        <CountUp end={systemMonitoring.searchQueue?.exceedsThresholdAnalysesCount} duration={2} />
                      </span>
                      <span className="text-muted fs-12">
                        {" "}
                        <i className="mdi mdi-slash-forward"></i> {t("XMinuteShort", {value: 30})}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="text-muted fs-13">
                  <span className="nowrap">
                    {t("Total")}:{" "}
                    <strong>
                      <RenderNumber value={systemMonitoring.searchQueue?.exceedsThresholdAnalysesNotCompletedProductsCount} decimals={0} notation="abbreviated"/>
                    </strong>
                    <i className="mdi mdi-slash-forward"></i>
                    {t("Pending")}:{" "}
                    <strong>
                      <RenderNumber value={systemMonitoring.searchQueue?.exceedsThresholdAnalysesNotCompletedProductsCount} decimals={0} notation="abbreviated"/>
                    </strong>
                  </span>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={6} sm={12} className="same-height">
              <div className="mt-3 mt-md-0 py-3 px-3">
                <h5 className="fs-15 nowrap">{t("Admin.SystemMonitoring.Stats.Label.Completed")}</h5>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 fs-2">
                    <i className={"text-success mdi mdi-clipboard-text-search-outline"}></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h4 className="mb-0">
                      <span className="counter-value">
                        <CountUp end={systemMonitoring.searchQueue?.completedAnalysesCountInLast24Hours} duration={2} />
                      </span>
                      <span className="text-muted fs-12">
                        {" "}
                        <i className="mdi mdi-slash-forward"></i> {t("XHourShort", {value: 24})}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="text-muted fs-13">
                  <span className="nowrap">
                    {t("Total")}:{" "}
                    <strong>
                      <RenderNumber value={systemMonitoring.searchQueue?.completedAnalysesProductsCountInLast24Hours} decimals={0} notation="abbreviated"/>
                    </strong>
                  </span>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={6} sm={12} className="same-height">
              <div className="mt-3 mt-md-0 py-3 px-3">
                <h5 className="fs-15 nowrap">{t("Admin.SystemMonitoring.Stats.Label.RestrictionCheck")}</h5>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 fs-2">
                    <i className={"text-warning mdi mdi-check-all"}></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h4 className="mb-0">
                      <span className="counter-value">
                        <CountUp end={systemMonitoring.searchQueue?.activeSpapiRestrictionCheckAnalysesCount} duration={2} />
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="text-muted fs-13">
                  <span
                    className="nowrap"
                    dangerouslySetInnerHTML={{
                      __html: t("Admin.SystemMonitoring.WithinXSearches", {value: numberFormatter(systemMonitoring.searchQueue?.activeSpapiRestrictionCheckProductsCount)}),
                    }}
                  ></span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default SearchQueues;
