import {useTranslation} from "react-i18next";
import AnalyzeOptionsEdit from "./_Edit";
import AnalyzeOptionsList from "./_List";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";

interface IAnalyzeOptionsProps {
  edit?: boolean;
}
const AnalyzeOptions = (props: IAnalyzeOptionsProps) => {
  const {t} = useTranslation();
  document.title = t("PageTitles.AnalyzeOptions");
  return (
      <Restricted require="settings.analyzeOptions" read fallback={() => <Unauthorized />}>
        {props.edit ? <AnalyzeOptionsEdit /> : <AnalyzeOptionsList />}
      </Restricted>
  );
};

export default AnalyzeOptions;
