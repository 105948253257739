import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useFormik} from "formik";
import {preventScrollUp} from "helpers/utilities";
import {SearchResultFilter} from "models/search_result";
import {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {Button, Col, Form, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserSearchResultsFilter} from "models/user_search_result_filter";
import {deleteFilter, getUserSearchResultsFilters, saveFilter} from "slices/search-result/thunk";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface DefinedFiltersProps {
  isOpen: boolean;
}
const DefinedFilters = ({isOpen}: DefinedFiltersProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteFilterDialogRef = useRef<DialogRef>(null);
  const [filter, setFilter] = useState<UserSearchResultsFilter>();
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.SearchResult.loading,
      filters: state.SearchResult.filter,
      definedFilters: state.SearchResult.definedFilters,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );
  const {loading, definedFilters, pageUrl, filters} = useSelector(searchResultsData);

  const initModal = useCallback(() => {
    if (isOpen) {
      const queryParams = new URLSearchParams(window.location.search);
      const filterName = decodeURIComponent(queryParams.get("filterName") || "");

      const filterResult = definedFilters.find((filter) => filter.filterName === filterName);
      if (filterResult) {
        // Update existing filter
        setFilter(filterResult);
        validation.setFieldValue("filter", filters);
        validation.setFieldValue("filterName", filterResult.filterName);
        validation.setFieldValue("originalFilterName", filterResult.filterName);
      } else {
        // Create new filter
        validation.setFieldValue("filter", filters);
        validation.setFieldValue("originalFilterName", null);
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      originalFilterName: "",
      filterName: "",
      filter: {} as SearchResultFilter,
    },
    validationSchema: Yup.object({
      originalFilterName: Yup.string().notRequired(),
      filterName: Yup.string(),
      filter: Yup.object<SearchResultFilter>(),
    }),
    onSubmit: (values) => {
      const savePromise = saveFilter({originalFilterName: values.originalFilterName, filterName: values.filterName, filter: values.filter})(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          toggle();
        }
      });
    },
  });

  const toggle = () => {
    navigate(pageUrl);
    preventScrollUp();
    setFilter(undefined);
    validation.resetForm();
  };

  useEffect(() => {
    if (filter && validation.values.filterName && !validation.values.originalFilterName) {
      validation.setFieldValue("newFilterName", validation.values.filterName);
    }
  }, [filter, validation.values.filterName]); // eslint-disable-line

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} fade={true} centered={true}>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("SearchResults.Filters.Dialog.SaveFilter.Title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <ValidatedInput validation={validation} field="filterName" maxLength={100} placeholder={t("SearchResults.Filters.Dialog.SaveFilter.FilterName")} disableValidationUI />
            <Row className="mt-3 d-flex align-items-center">
              <Col>
                {filter && (
                  <div>
                    <Link
                      color="link"
                      className="link-danger link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={() => deleteFilterDialogRef.current?.show()}
                      to={""}
                    >
                      {t("SearchResults.Filters.Button.Delete", {value: filter.filterName})}
                    </Link>
                  </div>
                )}
                {/* {definedFilter !== null && (
              <div>
                <Link
                  color="link"
                  className="link-danger link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                  onClick={() => deleteDialogRef.current?.show()}
                  to={""}
                >
                  {t("SearchResults.Filters.Button.Delete", {value: definedFilter.value})}
                </Link>
              </div>
            )} */}
              </Col>
              <Col xs="auto" className="pe-0">
                <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.save}>
                  {t("SearchResults.Filters.Dialog.SaveFilter.CloseButton")}
                </Button>
              </Col>
              <Col xs="auto">
                <Button type="submit" className="btn btn-success" disabled={loading.save}>
                  {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
                  {t("SearchResults.Filters.Dialog.SaveFilter.SubmitButton")}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Dialog
        ref={deleteFilterDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("SearchResults.Filters.Dialog.DeleteFilter.Description")}
        title={t("SearchResults.Filters.Dialog.DeleteFilter.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && filter) {
            await deleteFilter(filter.filterName)(dispatch);
            await getUserSearchResultsFilters()(dispatch);
            toggle();
          }
          deleteFilterDialogRef.current?.hide();
        }}
      />
    </>
  );
};

export default DefinedFilters;
