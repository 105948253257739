import {ResponseModel} from "models/response_model";
import {apiError, loading, renameItem, setList, setRenameModalVisibility, setSendToAnalyzeModalVisibility} from "./reducer";
import {postDeleteUserFavoriteList, postGetUserFavoriteItems, postGetUserFavorites, postRenameUserFavoriteList, postSaveNewSearch} from "services/search_service";
import {UserFavorite, UserFavoriteItem} from "models/user_favorites";
import {renderShowAnalyzeToast} from "Components/Common/ToastShowAnalyzeResult";
import {AnyAction, Dispatch} from "redux";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {PagedList} from "helpers/types";
import { ISaveUserSearchRequest, UserSearch } from "models/user_search";
import { GetUserFavoriteListQuery } from "api/query";
import i18n from "i18n";
import { AmazonBusinessModel } from "models/enums/user_search_type";

export const getUserFavorites = (data: GetUserFavoriteListQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetUserFavorites(data);
    const pagedResult: PagedList<UserFavorite> = result.data;
    dispatch(setList(pagedResult));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const deleteUserFavoriteList = (userFavoriteId: string, query: GetUserFavoriteListQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteUserFavoriteList({userFavoriteId});
    const result: ResponseModel = await postGetUserFavorites(query);
    const pagedResult: PagedList<UserFavorite> = result.data;
    dispatch(setList(pagedResult));
    renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListDeleteSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const renameFavoriteList = (userFavoriteId: string, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["rename", true]));
    const result: ResponseModel = await postRenameUserFavoriteList({userFavoriteId, name});
    const updatedItem: UserFavorite = result.data;
    dispatch(renameItem(updatedItem));
    renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListRenameSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["rename", false]));
  }
};

export const changeRenameModalVisibility = (visibility: boolean) => async (dispatch: any) => {
  dispatch(setRenameModalVisibility(visibility));
};

export const sendToAnalyze = (userFavoriteId: string, searchName: string, marketplace: string, history: any) => async (dispatch: any) => {
  try {
    dispatch(loading(["analyze", true]));
    const response: ResponseModel = await postGetUserFavoriteItems({userFavoriteId});
    const list: UserFavoriteItem[] = response.data;
    const asinList: string[] = list.map((item) => item.asin);

    const searchParameters : ISaveUserSearchRequest = {
      searchName: searchName,
      marketplace: marketplace,
      categoryPageList: [],
      identifierList: asinList,
      amazonBusinessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
    };

    const result:ResponseModel = await postSaveNewSearch(searchParameters);
    const search: UserSearch = result.data;
    renderShowAnalyzeToast(i18n.t("Favorites.Toast.AnalyzeStarted"), `/searches/search-results/${search.userSearchId}`,history);
    dispatch(setSendToAnalyzeModalVisibility(false));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["analyze", false]));
  }
};

export const changeSendToAnalyzeModalVisibility = (visibility: boolean) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setSendToAnalyzeModalVisibility(visibility));
};
