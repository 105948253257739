import {useProfile} from "Components/Hooks/useProfile";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Alert, Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {renderToString} from "react-dom/server";
import {getToday} from "helpers/utilities";
import Moment from "react-moment";
import RenderNumber from "Components/Common/RenderNumber";
import i18n from "i18n";
import { UserLimitType } from "models/enums/user_limit_type";

const UsageSummary = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [dailyLimitSearch, setDailyLimitSearch] = useState<number>();
  const [dailyLimitScan, setDailyLimitScan] = useState<number>();
  const [usageSearch, setUsageSearch] = useState<number>();
  const [usageScan, setUsageScan] = useState<number>();
  const [remainingSearch, setRemainingSearch] = useState<number>();
  const [remainingScan, setRemainingScan] = useState<number>();

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Login.loading,
    }),
  );
  const {loading} = useSelector(commonData);

  useEffect(() => {
    if (userProfile) {
      setDailyLimitSearch(userProfile.remainingLimits.daily.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue);
      setDailyLimitScan(userProfile.remainingLimits.daily.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue);
      setUsageSearch(userProfile.remainingLimits.today.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue);
      setUsageScan(userProfile.remainingLimits.today.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue);
      setRemainingSearch(userProfile.remainingLimits.remaining.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue);
      setRemainingScan(userProfile.remainingLimits.remaining.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue);
    }
  }, [userProfile]);

  const getRemainingTimeToRenew = (): string => {
    return renderToString(
      <Moment locale={i18n.language} tz={userProfile?.timezone} fromNow>
        {getToday()
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate()}
      </Moment>,
    );
  };
  return (
    <div>
      <Row>
        <Col xl={4} className="same-height">
          <Card className={`card-animate ${loading.limit ? "placeholder w-100" : ""}`}>
            <CardBody>
              <h5 className="fs-15 fw-semibold">{t("Account.LimitUsageHistory.Label.DailyLimit")}</h5>
              <p className="text-muted">{t("Account.LimitUsageHistory.Label.DailyLimitInfo")}</p>
              <div className="d-flex flex-wrap justify-content-evenly mt-5">
                <div className="col text-left">
                  <h6 className="text-muted">{t("Account.LimitUsageHistory.Label.Analysis")}</h6>
                  <h4>{<RenderNumber value={dailyLimitSearch} />}</h4>
                </div>
                <div className="col text-end">
                  <h6 className="text-muted">{t("Account.LimitUsageHistory.Label.ScanAndSave")}</h6>
                  <h4>{<RenderNumber value={dailyLimitScan} />}</h4>
                </div>
              </div>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={"progress-bar rounded-0 bg-success"} role="progressbar" style={{width: "50%"}}></div>
              <div className={"progress-bar rounded-0 bg-warning"} role="progressbar" style={{width: "50%"}}></div>
            </div>
          </Card>
        </Col>
        <Col xl={4} className="same-height">
          <Card className={`card-animate ${loading.limit ? "placeholder w-100" : ""}`}>
            <CardBody>
              <h5 className="fs-15 fw-semibold">{t("Account.LimitUsageHistory.Label.Usage")}</h5>
              <p className="text-muted">{t("Account.LimitUsageHistory.Label.UsageInfo")}</p>
              <div className="d-flex flex-wrap justify-content-evenly mt-5">
                <div className="col text-left">
                  <h6 className="text-muted">{t("Account.LimitUsageHistory.Label.Analysis")}</h6>
                  <h4>{<RenderNumber value={usageSearch} />}</h4>
                </div>
                <div className="col text-end">
                  <h6 className="text-muted">{t("Account.LimitUsageHistory.Label.ScanAndSave")}</h6>
                  <h4>{<RenderNumber value={usageScan} />}</h4>
                </div>
              </div>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={"progress-bar rounded-0 bg-success"} role="progressbar" style={{width: "50%"}}></div>
              <div className={"progress-bar rounded-0 bg-warning"} role="progressbar" style={{width: "50%"}}></div>
            </div>
          </Card>
        </Col>
        <Col xl={4} className="same-height">
          <Card className={`card-animate ${loading.limit ? "placeholder w-100" : ""}`}>
            <CardBody>
              <h5 className="fs-15 fw-semibold">{t("Account.LimitUsageHistory.Label.Remaining")}</h5>
              <p className="text-muted">{t("Account.LimitUsageHistory.Label.RemainingInfo")}</p>
              <div className="d-flex flex-wrap justify-content-evenly mt-5">
                <div className="col text-left">
                  <h6 className="text-muted">{t("Account.LimitUsageHistory.Label.Analysis")}</h6>
                  <h4>{<RenderNumber value={remainingSearch} />}</h4>
                </div>
                <div className="col text-end">
                  <h6 className="text-muted">{t("Account.LimitUsageHistory.Label.ScanAndSave")}</h6>
                  <h4>{<RenderNumber value={remainingScan} />}</h4>
                </div>
              </div>
            </CardBody>
            <div className="progress animated-progess rounded-bottom rounded-0" style={{height: "6px"}}>
              <div className={"progress-bar rounded-0 bg-success"} role="progressbar" style={{width: "50%"}}></div>
              <div className={"progress-bar rounded-0 bg-warning"} role="progressbar" style={{width: "50%"}}></div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert color="info" className="alert-label-icon label-arrow ">
            <i className="ri-information-line label-icon"></i>
            <span
              dangerouslySetInnerHTML={{
                __html: t("Account.LimitUsageHistory.Info.DailyResetMessage", {
                  value: getRemainingTimeToRenew(),
                }),
              }}
            ></span>
          </Alert>
        </Col>
      </Row>
    </div>
  );
};

export default UsageSummary;
