import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {components, CSSObjectWithLabel, StylesConfig} from "react-select";
import {Col, Label, Row} from "reactstrap";
import AmazonMarketplaceInfos from "../AmazonMarketplaceInfos";
import i18n from "i18n";
import {store} from "index";
import {UserStore} from "models/user_stores";
import {TagColor} from "models/enums/tag_color";

export interface Option {
  value: string | number | object;
  label: string;
  color?: string;
  isFixed?: boolean;
  disabled?: boolean;
}

export const singleSelectStyles: StylesConfig<SelectOptionsType, false> = {
  control: (base: CSSObjectWithLabel, state) => ({
    ...base,
    border: "var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important",
    background: "var(--vz-input-bg-custom)",
  }),
  option: (base: CSSObjectWithLabel, state) => ({
    ...base,
    background: state.isSelected ? "var(--vz-tertiary-bg)" : state.isFocused ? "var(--vz-btn-hover-bg)" : "var(--vz-input-bg-custom)",
    color: "var(--vz-body-color)",
    "&:hover": {
      background: "var(--vz-dark-bg-subtle)",
      transition: "all 0.5s ease",
    },
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    background: "var(--vz-input-bg-custom)",
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
};
export const defaultOptionContainer = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex">
          <Label htmlFor={"name"} check>
            <span className="ellipsis-single-line" title={props.label}>
              {props.label}
            </span>
          </Label>
        </div>
      </components.Option>
    </div>
  );
};

export const defaultValueContainer = (props: any) => {
  const data: SelectOptionsType = props.data;
  return <components.SingleValue {...props}>{data.label}</components.SingleValue>;
};

export const countryValueContainer = (props: any) => {
  const data: SelectOptionsType = props.data;
  let countryCode = data.value;
  if (data.value.length > 2) {
    // If value is user store id
    countryCode = store.getState().Common.userStores.find((store: UserStore) => store.userStoreId === data.value)!.marketplace;
  } else {
    // If value is marketplace
    countryCode = data.value;
  }
  const marketplace = AmazonMarketplaceInfos().find((info) => info.marketplace === countryCode)!;
  return (
    <components.SingleValue {...props}>
      <img src={marketplace.flag} alt={i18n.t(marketplace.countryName)} width={25} height={20} className="me-2" />
      {data.label}
    </components.SingleValue>
  );
};

export const tagColorValueContainer = (props: any) => {
  const data: SelectOptionsType = props.data;
  let color = "";
  if (data.value === TagColor.PRIMARY.toString()) {
    color = "--vz-primary";
  } else if (data.value === TagColor.SECONDARY.toString()) {
    color = "--vz-secondary";
  } else if (data.value === TagColor.SUCCESS.toString()) {
    color = "--vz-success";
  } else if (data.value === TagColor.DANGER.toString()) {
    color = "--vz-danger";
  } else if (data.value === TagColor.WARNING.toString()) {
    color = "--vz-warning";
  } else if (data.value === TagColor.INFO.toString()) {
    color = "--vz-info";
  } else if (data.value === TagColor.DARK.toString()) {
    color = "--vz-dark";
  } else {
    color = "";
  }
  return (
    <components.SingleValue {...props}>
      <Row>
        <Col xs="auto" className="pe-0"><div style={{height: "20px", width: "30px", backgroundColor: `var(${color})`}}></div></Col>
        <Col><span>{data.label}</span></Col>
      </Row>
      
      
    </components.SingleValue>
  );
};

export const countryOptionContainer = (props: any) => {
  const data: SelectOptionsType = props.data;
  let countryCode = data.value;
  if (data.value.length > 2) {
    // If value is user store id
    countryCode = store.getState().Common.userStores?.find((store: UserStore) => store.userStoreId === data.value)?.marketplace || "";
  } else {
    // If value is marketplace
    countryCode = data.value;
  }
  const marketplace = AmazonMarketplaceInfos().find((info) => info.marketplace === countryCode)!;
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex">
          <img src={marketplace.flag} alt={i18n.t(marketplace.countryName)} width={25} height={25} className="me-2" />
          <Label htmlFor={"name"} check>
            <span className="ellipsis-single-line" title={props.label}>
              {props.label}
            </span>
          </Label>
        </div>
      </components.Option>
    </div>
  );
};

export const tagColorOptionContainer = (props: any) => {
  const data: SelectOptionsType = props.data;
  let color = "";
  if (data.value === TagColor.PRIMARY.toString()) {
    color = "--vz-primary";
  } else if (data.value === TagColor.SECONDARY.toString()) {
    color = "--vz-secondary";
  } else if (data.value === TagColor.SUCCESS.toString()) {
    color = "--vz-success";
  } else if (data.value === TagColor.DANGER.toString()) {
    color = "--vz-danger";
  } else if (data.value === TagColor.WARNING.toString()) {
    color = "--vz-warning";
  } else if (data.value === TagColor.INFO.toString()) {
    color = "--vz-info";
  } else if (data.value === TagColor.DARK.toString()) {
    color = "--vz-dark";
  } else {
    color = "";
  }
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex">
          <div style={{height: "20px", width: "20px", backgroundColor: `var(${color})`}} className="me-2"></div>
          <Label htmlFor={"name"} check>
            <span className="ellipsis-single-line" title={props.label}>
              {props.label}
            </span>
          </Label>
        </div>
      </components.Option>
    </div>
  );
};

export const inputContainer = (props: any) => {
  return (
    <div>
      <components.Input {...props} className="d-none"></components.Input>
    </div>
  );
};

export const multiValueContainer = (props: any) => {
  const {selectProps, data} = props;
  const label = data.label;
  const allSelected = selectProps.value as Option[];
  const index = allSelected.findIndex((selected) => selected.label === label);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
  return `${label}${labelSuffix}`;
};
