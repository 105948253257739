import { TagColor } from "models/enums/tag_color";
import { Badge, Col, Row } from "reactstrap";
import TagPreview from "./TagPreview";
import { useTranslation } from "react-i18next";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import { generateRandomGuid } from "helpers/utilities";

interface TagListProps {
  tags: { id: string; color: TagColor; name: string }[];
  maxVisible: number;
}

const TagListPreview = ({ tags, maxVisible}: TagListProps) => {
  const {t} = useTranslation();
  const visibleTags = tags ? tags.slice(0, maxVisible) : [];
  const hiddenTags = tags && tags.length > maxVisible ? tags.slice(maxVisible) : [];

  const random = generateRandomGuid();
  return (
    <Row className="d-flex flex-nowrap">
      {visibleTags.map((tag) => (
        <Col key={tag.id} xs="auto" className="me-0 pe-0 mb-2">
          <TagPreview color={tag.color} value={tag.name} className="fs-12" />
        </Col>
      ))}
      {hiddenTags.length > 0 && (
        <>
        <Col id={`TagTooltip-${random}`} xs="auto" className="me-0">
          <Badge className="bg-light text-muted fs-12">+{hiddenTags.length} {t("more")}</Badge>
        </Col>
        <DefaultUncontrolledTooltip target={`TagTooltip-${random}`}>{hiddenTags.map(t => t.name).join(", ")}</DefaultUncontrolledTooltip>
        </>
      )}
    </Row>
  );
};

export default TagListPreview;