import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {UserSearchProductCheckList} from "models/user_search_product_check_list";
import {useNavigate} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {preventScrollUp} from "helpers/utilities";
import {useEffect, useState} from "react";
import DecisionsExtendedInfo from "./Components/DecisionsExtendedInfo";
import {UserSearchProduct} from "models/user_search_product";

interface DecisionReasonsProps {
  isOpen: boolean;
}
const DecisionReasons = ({isOpen}: DecisionReasonsProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const decisionsExtendedInfoItems = ["SameProductCheckRecheck", "CustomsCheckKeywordCheckRecheck", "CustomsCheckCategoryCheckRecheck"];
  const [decisionReasonsList, setDecisionReasonsList] = useState<string[]>();
  const [checkList, setCheckList] = useState<UserSearchProductCheckList>({} as UserSearchProductCheckList);
  const [selectedItem, setSelectedItem] = useState<UserSearchProduct | null>(null);
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      result: state.SearchResult.result,
      pageUrl: state.SearchResult.pageUrl,
    }),
  );
  const {result, pageUrl} = useSelector(searchResultsData);

  const toggle = () => {
    navigate(pageUrl);
    preventScrollUp();
  };

  useEffect(() => {
    if (isOpen) {
      const queryParams = new URLSearchParams(window.location.search);
      const asin = queryParams.get("asin");
      if (asin) {
        const item = result.products.items?.find((x) => x.asin === asin);
        if (item) {
          setSelectedItem(item); // Update the selected item state
          setCheckList(item.checkList);
          setDecisionReasonsList(item.summary.decisionReasons?.split(","));
        } else {
          navigate(pageUrl);
        }
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal id="showDecisionReasonsModal" isOpen={isOpen} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {t("SearchResults.Dialog.DecisionReasons.Title")}
      </ModalHeader>
      <ModalBody>
        {decisionReasonsList ? (
          decisionReasonsList?.map((reason: string, index: number) => {
            // By default, use the normal name
            let reasonName = t(`DecisionReasons.${reason}.Name`);
            let reasonDescription = t(`DecisionReasons.${reason}.Description`);

            // If reason is "VariationCountRecheck" and variationCount is between 2 and 5, use the special name
            if (
              reason === "VariationCountRecheck" &&
              selectedItem?.variationAnalysis?.variationCount != null &&
              selectedItem.variationAnalysis.variationCount >= 2 &&
              selectedItem.variationAnalysis.variationCount <= 5
            ) {
              reasonName = t(`DecisionReasons.${reason}.Low.Name`);
              reasonDescription = t(`DecisionReasons.${reason}.Low.Description`);
            }

            return (
              <div key={index} className="d-flex">
                <div className="flex-shrink-0 me-2">
                  <i className={`bx ${reason.endsWith("Recheck") || reason.endsWith("Warning") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
                </div>
                <div className="flex-grow-1">
                  <h5>{reasonName}</h5>
                  <p className="text-muted">{reasonDescription}</p>
                  {decisionsExtendedInfoItems.includes(reason) && <DecisionsExtendedInfo reason={reason} checkList={checkList} />}
                </div>
              </div>
            );
          })
        ) : (
          <div>{t("No reason")}</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DecisionReasons;
