import {useRef, useState} from "react";
import {Button, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap";
import {FormikErrors, FormikValues} from "formik";
import {useTranslation} from "react-i18next";

export interface ErrorPopoverProps {
  validationErrors: FormikErrors<FormikValues>;
}

const ErrorPopover = (props: ErrorPopoverProps) => {
  const {t} = useTranslation();
  const errorDialogRef = useRef<HTMLButtonElement>(null);
  const [errorsPopoverIsOpen, setErrorsPopoverIsOpen] = useState(false);

  return (
    <>
      <UncontrolledPopover target={errorDialogRef} placement="top" isOpen={errorsPopoverIsOpen} autohide={true}>
        <PopoverHeader className="text-danger">{t("You need to fix these errors")}</PopoverHeader>
        <PopoverBody>
          <div className="vstack">
            {Object.values(props.validationErrors).map((m, i) => (
              <li>
                <span key={i}>{m as string}</span>
              </li>
            ))}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
      <Button
        innerRef={errorDialogRef}
        type="button"
        color="link"
        className="btn-icon"
        onTouchStart={() => setErrorsPopoverIsOpen(true)}
        onTouchEnd={() => setErrorsPopoverIsOpen(false)}
        onMouseEnter={() => setErrorsPopoverIsOpen(true)}
        onMouseLeave={() => setErrorsPopoverIsOpen(false)}
      >
        <i className="ri-alert-fill align-middle fs-24 text-danger "></i>
      </Button>
    </>
  );
};

export default ErrorPopover;
