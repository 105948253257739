import {FormFeedback} from "reactstrap";
import {Turkish} from "flatpickr/dist/l10n/tr.js";
import {english} from "flatpickr/dist/l10n/default.js";
import {FormikValidation} from "helpers/types";
import Flatpickr from "react-flatpickr";
import moment from "moment";

interface DateFilterProps<T> {
  value: any;
  field: keyof T;
  validation: FormikValidation<T>;
  className?: string;
  max?: Date;
  min?: Date;
}
const ValidatedDate = <T,>(props: DateFilterProps<T>) => {
  const fieldName = props.field.toString();
  const {error, touched, value} = props.validation.getFieldMeta(fieldName);

  const locale = localStorage.getItem("I18N_LANGUAGE") === "tr" ? Turkish : english;
  const options = {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    minDate: props.min || new Date("2023-01-01"),
    maxDate: props.max || undefined,
    locale: {...locale},
    disableMobile: true,
  };

  const handleChange = (date: any) => {
    props.validation.setFieldValue(fieldName, date.length === 0 ? "" : moment(new Date(date)).format("Y-MM-DD"));
  };

  return (
    <>
      <Flatpickr
        id={fieldName}
        name={fieldName}
        className={`form-control form-control-sm common-css filter-flatpickr filter-input flatpickr-input flatpickr-mobile border border-1 ${props.className}`}
        value={value}
        onChange={handleChange}
        options={options}
      />
      {touched && error ? (
        <FormFeedback type="invalid" className="searches validation-width">
          {error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedDate;
