import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import {OrderShipments, UserOrder} from "models/user_order";

export type OrderDetailsLoadingState = LoadingStates<"list" | "updateCost" | "reAssignShipmentList" |"reassignShipment">;
export type OrderDetailsState = {
  loading: OrderDetailsLoadingState;
  order: UserOrder;
  editableShipments: OrderShipments[];
  defaultShipments: OrderShipments[];
  shipmentsWithoutInfo: OrderShipments[];
  error: ApiError;
};

const initialState: OrderDetailsState = {
  loading: {
    list: false,
    updateCost: false,
    reAssignShipmentList: false,
    reassignShipment: false,
  },
  order: {} as UserOrder,
  editableShipments: [] as OrderShipments[],
  defaultShipments: [] as OrderShipments[],
  shipmentsWithoutInfo: [] as OrderShipments[],
  error: {} as ApiError,
};

const OrderDetailsSlice = createSlice({
  name: "OrderDetails",
  initialState,
  reducers: {
    setOrder(state, action: PayloadAction<UserOrder>) {
      state.order = action.payload;
    },
    setShipments(state, action: PayloadAction<OrderShipments[]>) {
      state.editableShipments = action.payload;
      state.defaultShipments = action.payload;
    },
    setShipmentsWithoutInfo(state, action: PayloadAction<OrderShipments[]>) {
      state.shipmentsWithoutInfo = action.payload;
      state.editableShipments.push(...action.payload);
    },
    updateQuantity(state, action: PayloadAction<{orderShipment: OrderShipments; quantity: number}>) {
      const itemToUpdate = action.payload;
      state.editableShipments = state.editableShipments.map((shipment) => {
        if (shipment.shipment === itemToUpdate.orderShipment.shipment && shipment.asin === itemToUpdate.orderShipment.asin) {
          var defaultShipment = state.defaultShipments.find((s) => s.shipment === itemToUpdate.orderShipment.shipment && s.asin === itemToUpdate.orderShipment.asin );
          if(defaultShipment) {
            shipment.quantity = itemToUpdate.quantity ;
            shipment.availableCount = defaultShipment.availableCount + defaultShipment.quantity - itemToUpdate.quantity
          } else {
            var list = state.shipmentsWithoutInfo.find((s) => s.shipment === itemToUpdate.orderShipment.shipment && s.asin === itemToUpdate.orderShipment.asin );
            shipment.quantity = itemToUpdate.quantity ;
            shipment.availableCount = list!.availableCount + list!.quantity - itemToUpdate.quantity
          }
        }
        return shipment;
      });
    },
    loading(state, action: PayloadAction<[keyof OrderDetailsLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {setOrder, setShipments, setShipmentsWithoutInfo, updateQuantity, loading, apiError} = OrderDetailsSlice.actions;

export default OrderDetailsSlice.reducer;
