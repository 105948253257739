interface CheckListItemProps {
  labelName: string;
  status: any;
}
const CheckListItem = ({labelName, status}: CheckListItemProps) => {
  return (
    <li>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-1">
          {status === false ? (
            <i className="ri-close-circle-fill fs-18 text-danger"></i>
          ) : status === null ? (
            <i className="ri-donut-chart-line fs-18 text-muted"></i>
          ) : status === undefined ? (
            <i className="ri-error-warning-fill fs-18 text-warning"></i>
          ) : (
            <i className="ri-checkbox-circle-fill fs-18 text-success"></i>
          )}
        </div>
        <div className="flex-grow-1">
          <h6 className={"mb-0" + (status === null ? " text-muted " : "")}>{labelName}</h6>
        </div>
      </div>
    </li>
  );
};

export default CheckListItem;
