// action
import {postResetPassword} from "services/identity_service";
import {apiError, loading, setStatus} from "./reducer";
import {IResetPasswordDto} from "models/application_user";
import {ApiError} from "helpers/types";

// Is user resetPassword successful then direct plot user in redux.
export const resetPassword = (user: IResetPasswordDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["change", true]));
    dispatch(setStatus("empty"));
    await postResetPassword(user);
    dispatch(setStatus("success"));
    return true;
  } catch (error:any) {
    dispatch(setStatus("failed"));
    dispatch(apiError(error.response.data as ApiError));
    return false;
  } finally {
    dispatch(loading(["change", false]));
  }
};

export const clearResetPassword = () => async (dispatch: any) => {
  dispatch(setStatus("empty"));
  dispatch(loading(["change", false]));
};
