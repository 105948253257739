import {useCallback, useEffect, useState} from "react";
import {Button, Col, Form, Modal, ModalBody, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import {ReAnalyzeProductCommand} from "api/command";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import updateUrlParams from "helpers/utilities";
import _ from "lodash";

const ReAnalyzeProduct = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const searchParams = new URLSearchParams(location.search);
  const searchesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Common.loading,
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );
  const {loading, activeUserStoreOptions, activeMarketplaceOptions} = useSelector(searchesData);

  const validation = useFormik({
    initialValues: {
      searchName: "",
      marketplace: "",
    } as ReAnalyzeProductCommand,
    validationSchema: Yup.object().shape({
      searchName: Yup.string().required(t("Common.Dialog.ReAnalyzeProduct.Validation.SearchName")),
      marketplace: Yup.string().required(t("Common.Dialog.ReAnalyzeProduct.Validation.Marketplace")),
    }),
    onSubmit: (values: ReAnalyzeProductCommand) => {
      const payload: ReAnalyzeProductCommand = {
        marketplace: values.marketplace,
        asin: searchParams.get("asin") ?? "",
        searchName: values.searchName,
      };

      window.location.href = `/dp/${payload.asin}?marketplace=${payload.marketplace}`;
    },
  });

  const toggle = () => {
    validation.resetForm();
    updateUrlParams(navigate, location, {
      "re-analyze-product": null,
      "asin": null,
      "marketplace": null
    });
  };

  const initModal = useCallback(() => {
    if (isOpen) {
      let asinValue = _.toUpper(searchParams.get("asin")!);
      let marketplace = searchParams.get("marketplace");
      validation.setFieldValue("searchName", asinValue);
      validation.setFieldValue("marketplace", marketplace);
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  
  const onUrlChange = useCallback(() => {
    if(searchParams.has("re-analyze-product") && searchParams.has("asin") && searchParams.has("marketplace")) {
      setIsOpen(true);
    }else{
      setIsOpen(false);
    }
  }, [location.search]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onUrlChange();
  }, [onUrlChange])


  useEffect(() => {
    initModal();
  }, [initModal]);

  return (
    <>
      <Modal backdrop="static" isOpen={isOpen} toggle={toggle} centered={true}>
        <ModalBody className="py-3 px-5">
          <Form onSubmit={validation.handleSubmit}>
            <div className="mt-2 text-center">
              <i className={"mdi mdi-chart-timeline-variant-shimmer display-5 text-info"}></i>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-4">
                <h4>{t("Common.Dialog.ReAnalyzeProduct.Title")}</h4>
                <p className="text-muted mx-4 mb-0 mt-3">{t("Common.Dialog.ReAnalyzeProduct.Description")}</p>
              </div>
            </div>
            <div className="mt-4">
              <Row className="g-3">
                <Col xs={12} md={6}>
                  <ValidatedInput validation={validation} type="text" field="searchName" maxLength={100} placeholder={t("Searches.Name")} disableValidationUI />
                </Col>
                <Col xs={12} md={6}>
                  <ValidatedSelect
                    className={`new-search filter-input ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                    options={[...activeUserStoreOptions, ...activeMarketplaceOptions]}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    errorStyle="container"
                    optionStyle="country"
                    valueStyle="country"
                  />
                </Col>
              </Row>
            </div>

            <div className="mt-4 mb-2">
              <div className="d-flex gap-2 justify-content-center">
                <Button type="submit" color={"success"} disabled={loading.save}>
                  {loading.save && <Spinner size="sm" color="light" className="me-2" />}
                  {t("Common.Dialog.ReAnalyzeProduct.SubmitButton")}
                </Button>
                <Button type="button" color={"light"} onClick={toggle} disabled={loading.save}>
                  {t("Common.Dialog.ReAnalyzeProduct.CancelButton")}
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReAnalyzeProduct;
