import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "Components/Common/ChartsDynamicColor";
import {EChartsOption} from "echarts";
import moment from "moment";
import i18n from "i18n";

interface PriceHistoryChartProps {
  dataColors: any;
  series: any[];
  categories: string[];
  currency?: string;
}
export const PriceHistoryChart = (props: PriceHistoryChartProps) => {
  var chartStepLineColors = getChartColorsArray(props.dataColors);
  var option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      show: true,
      valueFormatter: function(value) {
        return value + " " + props.currency;
      },
      axisPointer: {
        label: {
          formatter: function({value}:any) {
            return moment(value as any).locale(i18n.language).format("DD MMM - hh:mm");
          },
        }
      }
    },
    legend: {
      data: [""],
      textStyle: {
        //The style of the legend text
        color: "#858d98",
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      data: props.categories,
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
      axisLabel: {
        show: true,
        formatter: function(value) {
          return moment(value).locale(i18n.language).format("DD MMM");
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#858d98",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(133, 141, 152, 0.1)",
        },
      },
    },
    textStyle: {
      fontFamily: "Poppins, sans-serif",
    },
    color: chartStepLineColors,
    series: [
      {
        name: i18n.t("Price"),
        type: "line",
        step: "start",
        data: props.series,
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{height: "250px"}} option={option} />
    </React.Fragment>
  );
};
