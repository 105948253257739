import {AmazonMarketplaceType} from "Components/Common/AmazonMarketplaceInfos";
import {UserSearchProduct} from "models/user_search_product";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ProfitCalculator from "Components/Common/Calculator/_ProfitCalculator";

interface ProfitAnalysisProps {
  item: UserSearchProduct;
  sourceMarketplace?: AmazonMarketplaceType;
  destinationMarketplace?: AmazonMarketplaceType;
}
const ProfitAnalysis = ({item, sourceMarketplace, destinationMarketplace}: ProfitAnalysisProps) => {
  const {t} = useTranslation();
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      search: state.SearchResult.result.search,
    }),
  );
  const {search} = useSelector(searchResultsData);

  return (
    <ProfitCalculator
      title={t("SearchResults.Analyzes.ProfitAnalysis.Title")}
      amazonBusinessModel={search?.amazonBusinessModel}
      itemProfitAnalysis={item.profitAnalysis}
      buyBoxType={item.competitorAnalysis?.buyBoxType}
      offerCount={item.competitorAnalysis?.offerCount}
      userSearchProductId={item.userSearchProductId}
      sourceMarketplace={sourceMarketplace}
      destinationMarketplace={destinationMarketplace}
    />
  );
};

export default ProfitAnalysis;
