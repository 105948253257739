import React, {useEffect, useState} from "react";
import {UserShipment} from "models/user_shipment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Progress, Row} from "reactstrap";
import {getShipmentSpeed} from "helpers/utilities";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/useProfile";
import SimpleBar from "simplebar-react";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import DomainToFlag from "Components/Common/DomainToFlag";
import RenderNumber from "Components/Common/RenderNumber";
import NoData from "Components/Common/NoData";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayDate from "Components/Common/DisplayDate";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";

const LatestShipments = () => {
  const {t} = useTranslation();
  const [shipmentList, setShipmentList] = useState<UserShipment[]>([]);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const {userProfile} = useProfile();
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
    }),
  );

  const {statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics.latestShipments) {
      setShipmentList(statistics.latestShipments);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="card-height-100 card-animate">
          <div className="align-items-center card-header">
            <Row>
              <Col>
                <h4 className="card-title mb-0 ">{t("Dashboard.LatestShipments.Title")}</h4>
              </Col>
              <Col xs="auto">
                <div>
                  <span className="text-muted me-2">
                    <i className="mdi mdi-information align-bottom me-1"></i>
                    {t("Dashboard.LatestShipments.Info")}
                  </span>
                  <Link className="link-body-emphasis text-decoration-underline link-underline-opacity-25 link-underline-opacity-75-hover mb-0" to={`/shipments`}>
                    {t("Dashboard.LatestShipments.Link.ViewShipments")}
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody>
            {shipmentList.length > 0 ? (
              <div className="table-responsive table-card">
                <SimpleBar style={{height: "500px"}}>
                  <table className="table-hover table table-centered align-middle mb-0 border-top-0">
                    <thead className="text-muted table-light sticky-top">
                      <tr>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.NameDate")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.SkuUnits")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.SoldLeftItems")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.Speed")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.RevenueProfit")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.RoiMargin")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.TotalCosts")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.CurrentPayouts")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.CostRecovery")}</th>
                        <th scope="col">{t("Dashboard.LatestShipments.TableColumn.SuccessRate")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shipmentList &&
                        shipmentList.length > 0 &&
                        shipmentList.map((shipment: UserShipment, index: number) => {
                          const {dayPassed, shipmentSpeed} = getShipmentSpeed(shipment);
                          const sourceCurrency = amazonMarketplaceInfos.find((info) => info.marketplace === shipment.userStore.marketplace)?.currency;
                          return (
                            <tr key={index}>
                              <td>
                                <div className="mb-1 d-flex">
                                  <Link className="link-secondary link-offset-2 text-decoration-underline me-1" target="_blank" to={`/shipments/shipment-details/${shipment.userShipmentId}`}>
                                    {shipment.name}
                                    <i className="ri-arrow-right-up-line"></i>
                                  </Link>
                                </div>
                                <span className="fs-14 my-1 text-nowrap">
                                  <DisplayDate id={`DisplayDateShipment-${shipment.userShipmentId}`} value={shipment.shipDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                                </span>
                                <div className="d-flex align-items-center text-nowrap text-muted">
                                  <DomainToFlag marketplace={shipment.userStore.marketplace} />
                                  <span>{`${shipment.userStore.marketplace} - ${shipment.userStore.name}`}</span>
                                </div>
                              </td>
                              <td>
                                <h5 className="fs-14 my-1 align-items-center d-flex">{`${shipment.totalSKU} ${shipment.totalSKU <= 1 ? t("SKU") : t("SKUs")}`}</h5>
                                <span className="text-muted">{`${shipment.totalItems} ${shipment.totalItems <= 1 ? t("Unit") : t("Units")}`}</span>
                              </td>
                              <td>
                                <h5 className="fs-13 align-items-center d-flex">
                                  {`${shipment.soldItems} ${t("Dashboard.LatestShipments.Label.Sold")}`} <i className="mdi mdi-slash-forward"></i>{" "}
                                  {`${shipment.totalItems - shipment.soldItems} ${t("Dashboard.LatestShipments.Label.Left")}`}
                                </h5>
                                <Progress color="secondary" value={(shipment.soldItems / shipment.totalItems) * 100} className="progress-sm" />
                              </td>
                              <td>
                                <div className="align-items-center d-flex">
                                  <i className="text-info mdi mdi-rocket-launch me-1 fs-14"></i>
                                  {t("Dashboard.LatestShipments.Label.SpeedPerItem", {value: shipmentSpeed.toFixed(2)})}
                                </div>
                                <div className="align-items-center d-flex">
                                  <i className="text-secondary mdi mdi-calendar-clock me-1 fs-14"></i>
                                  {t("Dashboard.LatestShipments.Label.DayPassed", {value: dayPassed})}
                                </div>
                              </td>
                              <td>
                                <span className="text-muted text-nowrap">
                                  <DisplayPrice source={sourceCurrency!} value={shipment.userShipmentFinance.actualSoldRevenue} decimals={2} notation="abbreviated" />
                                </span>
                                <h5 className="fs-14 my-1 text-nowrap">
                                  <DisplayPrice source={sourceCurrency!} value={shipment.userShipmentFinance.actualSoldProfit} decimals={2} notation="abbreviated" />
                                </h5>
                              </td>
                              <td>
                                <span className="text-muted">
                                  <RenderNumber value={shipment.userShipmentFinance.actualSoldROI} decimals={0} prefix="%" suffix="" />
                                </span>
                                <h5 className="fs-14 my-1">
                                  <RenderNumber value={shipment.userShipmentFinance.actualSoldMargin} decimals={0} prefix="%" suffix="" />
                                </h5>
                              </td>
                              <td>
                                <h5 className="fs-14 my-1 text-nowrap">
                                  <DisplayPrice source={sourceCurrency!} value={shipment.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="abbreviated" />
                                </h5>
                              </td>
                              <td>
                                <h5 className="fs-14 my-1 text-nowrap">
                                  <DisplayPrice source={sourceCurrency} value={shipment.userShipmentFinance.estimatedSoldPayout} decimals={2} notation="abbreviated" />
                                </h5>
                              </td>
                              <td>
                                <h5 className="fs-13">
                                  <RenderNumber value={shipment.userShipmentFinance.costRecoveryStatusRate} decimals={0} prefix="%" suffix={` ${t("Dashboard.LatestShipments.Label.Recovered")}`} />
                                </h5>
                                <Progress color="secondary" value={shipment.userShipmentFinance.costRecoveryStatusRate} className="progress-sm" />
                              </td>
                              <td>
                                <h5 className="my-1 text-nowrap align-items-center d-flex">
                                  <i className="text-secondary mdi mdi-trophy me-1 fs-16"></i>
                                  <RenderNumber
                                    value={SuccessRateCalculator(shipment.userShipmentFinance.actualSoldProfit, shipment.userShipmentFinance.expectedSoldProfit)}
                                    decimals={2}
                                    prefix="%"
                                    suffix=" "
                                  />
                                </h5>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LatestShipments;
