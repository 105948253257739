import {ResponseModel} from "models/response_model";
import {loading, setList, apiError, setUserSubscription} from "./reducer";
import {postChangeUserSubscription, postCreateUserSubscription, postGetSubscriptions} from "services/payment_service";
import {getUserSubscription} from "services/identity_service";
import {IChangeUserSubscriptionDto, ICreateUserSubscriptionDto} from "models/user_subscription";
import {AnyAction, Dispatch} from "redux";
import {generateError, renderSuccessToast} from "helpers/utilities";
import i18n from "i18n";

export const getAllSubscriptionData = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetSubscriptions();
    dispatch(setList({data: result.data}));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const getUserSubscriptionData = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await getUserSubscription();

    if(result.data && result.data.userSubscription)
      dispatch(setUserSubscription({data: result.data.userSubscription}));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const changeUserSubscription = (changeUserSubscriptionDto: IChangeUserSubscriptionDto) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(loading(["change", true]));
    const result: ResponseModel = await postChangeUserSubscription(changeUserSubscriptionDto);
    dispatch(setUserSubscription({data: result.data.userSubscription}));
    renderSuccessToast(i18n.t("Account.Subscription.Toast.SubscriptionChangedSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["change", false]));
  }
};

export const createUserSubscription = (createUserSubscriptionDto: ICreateUserSubscriptionDto) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(loading(["create", true]));
    const result: ResponseModel = await postCreateUserSubscription(createUserSubscriptionDto);
    dispatch(setUserSubscription({data: result.data.userSubscription}));
    renderSuccessToast(i18n.t("Account.Subscription.Toast.SubscriptionCreatedSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["create", false]));
  }
};