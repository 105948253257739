import {useTranslation} from "react-i18next";
import CostAndFeesEdit from "./_Edit";
import CostAndFeesList from "./_List";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";

interface ICostAndFeesProps {
  edit?: boolean;
}
const CostAndFees = (props: ICostAndFeesProps) => {
  const {t} = useTranslation();
  document.title = t("PageTitles.CostAndFees");
  return (
      <Restricted require="settings.costAndFees" read fallback={() => <Unauthorized />}>
        {props.edit ? <CostAndFeesEdit /> : <CostAndFeesList />}
      </Restricted>
  );
};

export default CostAndFees;
