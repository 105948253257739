import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import i18n from "i18n";

//import Components
import Sidebar from "../Sidebar";
import Footer from "../Footer";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changeSidebarVisibility,
} from "../../slices/thunks";

//redux
import {useSelector, useDispatch} from "react-redux";
import {createSelector} from "reselect";
import Header from "./Header";
import SingleSearch from "Components/Common/Modals/SingleSearch";
import { useLocation } from "react-router-dom";

const VerticalLayout = (props: any) => {
  const [headerClass, setHeaderClass] = useState("");
  const [language, setLanguage] = useState(i18n.language);
  const [isSingleSearchModalOpen, setIsSingleSearchModalOpen] = useState<boolean>(false)
  const location = useLocation();
  const dispatch = useDispatch();

  const selectLayoutState = (state: any) => state.Layout;
  const selectLayoutProperties = createSelector(selectLayoutState, (layout) => ({
    layoutType: layout.layoutType,
    leftSidebarType: layout.leftSidebarType,
    layoutModeType: layout.layoutModeType,
    layoutWidthType: layout.layoutWidthType,
    layoutPositionType: layout.layoutPositionType,
    topbarThemeType: layout.topbarThemeType,
    leftSidebarSizeType: layout.leftSidebarSizeType,
    leftSidebarViewType: layout.leftSidebarViewType,
    leftSidebarImageType: layout.leftSidebarImageType,
    preloader: layout.preloader,
    sidebarVisibilitytype: layout.sidebarVisibilitytype,
  }));

  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftSidebarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
  } = useSelector(selectLayoutProperties);

  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftSidebarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event("resize"));
      changeLeftsidebarViewType(leftSidebarViewType)(dispatch);
      changeLeftsidebarSizeType(leftSidebarSizeType)(dispatch);
      changeSidebarTheme(leftSidebarType)(dispatch);
      changeLayoutMode(layoutModeType)(dispatch);
      changeLayoutWidth(layoutWidthType)(dispatch);
      changeLayoutPosition(layoutPositionType)(dispatch);
      changeTopbarTheme(topbarThemeType)(dispatch);
      changeLayout(layoutType)(dispatch);
      changeSidebarImageType(leftSidebarImageType)(dispatch);
      changeSidebarVisibility(sidebarVisibilitytype)(dispatch);
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftSidebarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
    dispatch,
  ]);

  const onChangeLayoutMode = (value: any) => {
    if (changeLayoutMode) {
      changeLayoutMode(value)(dispatch);
    }
  };

  const onChangeLanguage = (value: string) => {
    setLanguage(value);
  };

  

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  };

  useEffect(() => {
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
    if (sidebarVisibilitytype === "show" || layoutType === "vertical" || layoutType === "twocolumn") {
      humberIcon.classList.remove("open");
    } else {
      humberIcon && humberIcon.classList.add("open");
    }
  }, [sidebarVisibilitytype, layoutType]);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });


  const onUrlChange = useCallback(
    () => {
      const hasSingleSearch = location.search.includes("singleSearch");
      setIsSingleSearchModalOpen(hasSingleSearch);
    },
    [location.search],
  )

  useEffect(() => {
    onUrlChange();
  }, [onUrlChange]);
  
  
  return (
    <React.Fragment>
      <div id="layout-wrapper" lang={language}>
        <Header headerClass={headerClass} layoutModeType={layoutModeType} onChangeLanguage={onChangeLanguage} onChangeLayoutMode={onChangeLayoutMode} />
        <Sidebar layoutType={"vertical"} />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
        <SingleSearch isOpen={isSingleSearchModalOpen} />
      </div>
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.object,
};

export default VerticalLayout;
