import {useProfile} from "Components/Hooks/useProfile";
import {getToday} from "helpers/utilities";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const PublicRoutes = () => {
  const {userProfile} = useProfile();
  const location = useLocation();
  const isTokenValid = userProfile && new Date(userProfile.tokenInfo.expiresAt) > getToday().toDate();
  const queryParams = new URLSearchParams(window.location.search);
  const auth = queryParams.get("auth");

  if (location.pathname === "/account/logout") {
    return <Outlet />;
  }
  if(isTokenValid && location.pathname === "/account/login" && auth) {
    return <Outlet />;
  }
  if (isTokenValid && location.pathname === "/account/login") {
    return <Navigate to="/" />;
  }

  // Redirect to target page
  return <Outlet />;
};

export default PublicRoutes;
