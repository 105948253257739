import _ from "lodash";
import {useEffect, useMemo, useRef} from "react";

const useDebounce = (callback: VoidFunction, timeout: number, deps: unknown[] = []) => {
  const ref = useRef<VoidFunction>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return _.debounce(func, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, ...deps]);

  return debouncedCallback;
};

export {useDebounce};
