export type UserAction = "create" | "read" | "update" | "delete";
export const PageList = [
  "dashboard",
  "search",
  "searchResult",
  "productDetails",
  "scanAndSave",
  "favorites",
  "stores",
  "shipments",
  "shipmentDetails",
  "inventory",
  "orders",
  "orderDetails",
  "reports",
  "account.profile",
  "account.changePassword",
  "account.subscription",
  "account.paymentHistory",
  "account.team",
  "account.notification",
  "account.limitUsageHistory",
  "settings.costAndFees",
  "settings.analyzeOptions",
  "settings.exchangeRates",
  "support",
  "admin",
  "adminSearch",
  "adminSearchLogs",
  "adminScanAndSave",
  "adminUsers",
  "adminSystemMonitoring",
] as const;
export const RoleList = ["Owner", "Accounting", "Analyzer", "Reporting", "Admin", "SupportSpecialist"] as const;
export type ConstantRole = typeof RoleList[number];
export type ConstantPage = typeof PageList[number];

export interface RolePermission {
  name: ConstantRole;
  permissions: UserAction[];
}
export interface PagePermissionItem {
  page: ConstantPage;
  roles: RolePermission[];
}

export const Permissions: PagePermissionItem[] = [
  {
    page: "dashboard",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["read", "update"],
      },
    ],
  },
  {
    page: "search",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "searchResult",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "productDetails",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "scanAndSave",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "favorites",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "stores",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "shipments",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read"],
      },
      {
        name: "Accounting",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "shipmentDetails",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["read"],
      },
      {
        name: "Reporting",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "inventory",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["read"],
      },
      {
        name: "Accounting",
        permissions: ["read"],
      },
      {
        name: "Reporting",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "orders",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["read"],
      },
      {
        name: "Accounting",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Reporting",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "orderDetails",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["read"],
      },
      {
        name: "Accounting",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "reports",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "account.profile",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Reporting",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "account.changePassword",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Reporting",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "account.subscription",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "account.paymentHistory",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "account.team",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "account.notification",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "account.limitUsageHistory",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Reporting",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "settings.costAndFees",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "settings.analyzeOptions",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "settings.exchangeRates",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "support",
    roles: [
      {
        name: "Owner",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Accounting",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Analyzer",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "Reporting",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "admin",
    roles: [
      {
        name: "Admin",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "SupportSpecialist",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "admin",
    roles: [
      {
        name: "Admin",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
  {
    page: "adminSearch",
    roles: [
      {
        name: "Admin",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "SupportSpecialist",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "adminSearchLogs",
    roles: [
      {
        name: "Admin",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "SupportSpecialist",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "adminScanAndSave",
    roles: [
      {
        name: "Admin",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "SupportSpecialist",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "adminSystemMonitoring",
    roles: [
      {
        name: "Admin",
        permissions: ["create", "read", "update", "delete"],
      },
      {
        name: "SupportSpecialist",
        permissions: ["read"],
      },
    ],
  },
  {
    page: "adminUsers",
    roles: [
      {
        name: "Admin",
        permissions: ["create", "read", "update", "delete"],
      },
    ],
  },
];
