import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/useProfile";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import i18n from "i18n";
import Moment from "react-moment";
import moment from "moment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const Others = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();

  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);

  const CustomMoment = ({date, service}: {date: Date; service: "inventory" | "order" | "finance"}) => {
    const diffInMinutes = moment().diff(date, "minutes");
    const textStyle = service === "inventory" && diffInMinutes > 240 ? "text-danger" : service !== "inventory" && diffInMinutes > 15 ? "text-danger" : "text-success";
    return (
      <>
        <span className="counter-value" id={`${service}`}>
          {diffInMinutes < 60 ? (
            <>
              <strong className={textStyle}>{diffInMinutes}</strong>
              <small className="text-muted fs-15 ms-1">{t("MinutesAgo")}</small>
            </>
          ) : (
            <>
              <strong className={textStyle}>{Math.floor(diffInMinutes / 60)}</strong> <small className="text-muted fs-15 ms-1">{t("HoursAgo")}</small>
            </>
          )}
        </span>
        <DefaultUncontrolledTooltip target={`${service}`}>
          <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} date={date} />
        </DefaultUncontrolledTooltip>
      </>
    );
  };

  return (
    <Row className="g-4">
      <Col className="col-12">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h5 className="mb-0 fs-15 fw-semibold">{t("Admin.SystemMonitoring.Section.Title.InventoryService")}</h5>
                  <h3 className="mt-2 ff-secondary fw-semibold">
                    <span className="counter-value">
                      <CustomMoment date={systemMonitoring.amazonServices?.inventoryServiceLastSuccessDate} service="inventory" />
                    </span>
                  </h3>
                  <p className="mb-0 text-muted">{t("LastSuccessfulRun")}</p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    {(() => {
                      const diff = moment().diff(systemMonitoring.amazonServices?.inventoryServiceLastSuccessDate, "minutes");
                      const cssStyle = diff > 240 ? "danger" : "success"; // 4 hour
                      const icon = diff > 240 ? "mdi mdi-close-circle" : "mdi mdi-check-circle"; // 4 hour
                      return (
                        <>
                          <span className={`avatar-title rounded-circle fs-2 bg-${cssStyle}-subtle`}>
                            <i className={`${icon} text-${cssStyle}`}></i>
                          </span>
                        </>
                      );
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col className="col-12">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h5 className="mb-0 fs-15 fw-semibold">{t("Admin.SystemMonitoring.Section.Title.OrderService")}</h5>
                  <h3 className="mt-2 ff-secondary fw-semibold">
                    <span className="counter-value">
                      <CustomMoment date={systemMonitoring.amazonServices?.orderServiceLastSuccessDate} service="inventory" />
                    </span>
                  </h3>
                  <p className="mb-0 text-muted">{t("LastSuccessfulRun")}</p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    {(() => {
                      const diff = moment().diff(systemMonitoring.amazonServices?.orderServiceLastSuccessDate, "minutes");
                      const cssStyle = diff > 15 ? "danger" : "success";
                      const icon = diff > 15 ? "mdi mdi-close-circle" : "mdi mdi-check-circle";
                      return (
                        <>
                          <span className={`avatar-title rounded-circle fs-2 bg-${cssStyle}-subtle`}>
                            <i className={`${icon} text-${cssStyle}`}></i>
                          </span>
                        </>
                      );
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
      <Col className="col-12">
        <PlaceholderGlow busy={loading.list}>
          <Card className="card-animate mb-0">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h5 className="mb-0 fs-15 fw-semibold">{t("Admin.SystemMonitoring.Section.Title.FinanceService")}</h5>
                  <h3 className="mt-2 ff-secondary fw-semibold">
                    <span className="counter-value">
                      <CustomMoment date={systemMonitoring.amazonServices?.financeServiceLastSuccessDate} service="inventory" />
                    </span>
                  </h3>
                  <p className="mb-0 text-muted">{t("LastSuccessfulRun")}</p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    {(() => {
                      const diff = moment().diff(systemMonitoring.amazonServices?.financeServiceLastSuccessDate, "minutes");
                      const cssStyle = diff > 15 ? "danger" : "success";
                      const icon = diff > 15 ? "mdi mdi-close-circle" : "mdi mdi-check-circle";
                      return (
                        <>
                          <span className={`avatar-title rounded-circle fs-2 bg-${cssStyle}-subtle`}>
                            <i className={`${icon} text-${cssStyle}`}></i>
                          </span>
                        </>
                      );
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </PlaceholderGlow>
      </Col>
    </Row>
  );
};

export default Others;
