import React, {useEffect, useState} from "react";
import {Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {clearResetPassword, resetPassword} from "../../slices/thunks";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import logoTransparent from "../../assets/images/logo-dark-transparent.png";
import ParticlesAuth from "./ParticlesAuth";
import * as Yup from "yup";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const {t} = useTranslation();
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    if (!token) {
      navigate("/account/login");
    }
  }, [navigate]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("ResetPassword.Validation.Password"))
        .min(8, " ")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, t("ResetPassword.Validation.PasswordPattern")),
      confirmPassword: Yup.string()
        .required(t("ResetPassword.Validation.ConfirmPassword"))
        .min(8, t("ResetPassword.Validation.PasswordLength"))
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, " ")
        .oneOf([Yup.ref("password"), ""], t("ResetPassword.Validation.PasswordMatch")),
    }),
    onSubmit: (values) => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");
      dispatch(
        resetPassword({
          token: token,
          password: values.password,
        }),
      );
    },
  });

  const resetPasswordPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      status: state.ResetPassword.status,
      loading: state.ResetPassword.loading,
      error: state.ResetPassword.error,
    }),
  );
  // Inside your component
  const {status, loading, error} = useSelector(resetPasswordPageData);

  useEffect(() => {
    if (status && status === "success") {
      setTimeout(() => {
        navigate("/account/login");
      }, 3000);
    }
  }, [status]); //eslint-disable-line

  document.title = t("PageTitles.ResetPassword");

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoTransparent} alt="" height="50" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("ResetPassword.Title")}</h5>
                    </div>
                    <div className="p-2 mt-4">
                      {status === "success" ? (
                        <>
                          <Alert color="success" className="mb-0">
                            {t("ResetPassword.Message.PasswordResetSuccessful")}
                          </Alert>
                        </>
                      ) : status === "failed" ? (
                        <>
                          <Alert color="danger" className="mb-0" toggle={() => clearResetPassword()(dispatch)}>
                            {t(error?.message as string)}
                          </Alert>
                        </>
                      ) : null}

                      {status !== "success" && (
                        <Form onSubmit={validation.handleSubmit}>
                          <Row className="g-3">
                            <Col lg={12}>
                              <Label htmlFor="password" className="form-label">
                                {t("ResetPassword.Password")} <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative auth-pass-inputgroup">
                                <Input
                                  name="password"
                                  type={passwordShow ? "text" : "password"}
                                  placeholder={t("ResetPassword.PasswordPlaceholder")}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  tabIndex={1}
                                  invalid={validation.touched.password && validation.errors.password ? true : false}
                                />
                                {validation.touched.password && validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation.errors.password}</div>
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>

                              <div className="form-text">{t("ResetPassword.Validation.PasswordLength")}.</div>
                            </Col>

                            <Col lg={12}>
                              <Label htmlFor="confirmPassword" className="form-label">
                                {t("ResetPassword.ConfirmPassword")} <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative auth-pass-inputgroup">
                                <Input
                                  name="confirmPassword"
                                  type={passwordShow ? "text" : "password"}
                                  placeholder={t("ResetPassword.ConfirmPasswordPlaceholder")}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.confirmPassword || ""}
                                  tabIndex={2}
                                  invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                                />
                                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation.errors.confirmPassword}</div>
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>
                            </Col>
                            <Col lg={12} className="mt-4">
                              <Button color="success" className="btn btn-success w-100" type="submit" tabIndex={3} disabled={loading.change}>
                                {loading.change ? <Spinner size="sm" className="me-2"></Spinner> : null}
                                {t("ResetPassword.SubmitButton")}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    <Link to="/account/login" className="fw-semibold text-primary text-decoration-underline">
                      {t("ResetPassword.SignIn")}
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default ResetPassword;
