import {UserSearchProduct} from "models/user_search_product";
import {Card, CardBody} from "reactstrap";
import {YesNoBadge} from "./Components/YesNoBadge";
import {SellerTypeBadge} from "./Components/SellerTypeBadge";
import {useTranslation} from "react-i18next";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

interface ProductIsExistProps {
  item: UserSearchProduct;
}
const ProductIsExist = ({item}: ProductIsExistProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.ProductIsExist.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.ProductIsExist.AmazonCom")}</span>
            {item.existAtSource ? <YesNoBadge text="Yes" /> : item.existAtSource === false ? <YesNoBadge text="No" /> : <span>{t("NA")}</span>}
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.ProductIsExist.HasBuybox")}</span>
            <span>
              {item.competitorAnalysis?.buyBoxTypeSource === null ? (
                <YesNoBadge text="No" />
              ) : (
                <div>
                  <SellerTypeBadge type={item.competitorAnalysis?.buyBoxTypeSource ?? null} hideIfNA={true} />
                  <span className="ms-1">
                    <YesNoBadge text="Yes" />
                  </span>
                </div>
              )}
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.ProductIsExist.OfferCount")}</span>
            </div>
            {item.competitorAnalysis?.offerCountSource ? <span>{item.competitorAnalysis.offerCountSource.toLocaleString()}</span> : <span>{t("NA")}</span>}
          </li>
          {item.domainTarget ? (
            <>
              <hr />
              <li className="d-flex justify-content-between">
                <span>Amazon.{item.domainTarget}</span>
                {item.existAtTarget ? <YesNoBadge text="Yes" /> : item.existAtTarget === false ? <YesNoBadge text="No" /> : <span>{t("NA")}</span>}
              </li>
              <li className="d-flex justify-content-between">
                <span>{t("SearchResults.Analyzes.ProductIsExist.HasBuybox")}</span>
                <span>
                  {item.competitorAnalysis?.buyBoxType === null ? (
                    <YesNoBadge text="No" />
                  ) : (
                    <div>
                      <SellerTypeBadge type={item.competitorAnalysis?.buyBoxType ?? null} hideIfNA={true} />
                      <span className="ms-1">
                        <YesNoBadge text="Yes" />
                      </span>
                    </div>
                  )}
                </span>
              </li>
              <li className="d-flex justify-content-between">
                <span>
                  {t("SearchResults.Analyzes.ProductIsExist.OfferCount")}
                  {item.competitorAnalysis?.offerCount === 0 && (
                    <>
                      <i id={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert align-middle text-warning"></i>
                      <DefaultUncontrolledTooltip target={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`}> {t("NoSellerInTheTargetMarketplace")}</DefaultUncontrolledTooltip>
                    </>
                  )}
                </span>
                <span>{item.competitorAnalysis?.offerCount?.toLocaleString()}</span>
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
      </CardBody>
    </Card>
  );
};

export default ProductIsExist;
