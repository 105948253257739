import {UserSearchesQuery, ISaveUserSearchRequest, UserSearch} from "models/user_search";
import {apiError, loading, reset, setSearchesList} from "./reducer";
import {ResponseModel} from "models/response_model";
import {postDeleteSearch, postGetUserSearches, postReAnalyzeSearch, postSaveNewSearch} from "services/search_service";
import {PagedList} from "helpers/types";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {refreshRemainingLimits} from "slices/thunks";
import {ReAnalyzeSearchCommand} from "api/command";
import i18n from "i18n";

export const getUserSearchesList = (query: UserSearchesQuery) => async (dispatch: any) => {
  const loadingType = query.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserSearches(query);
    const pagedResult: PagedList<UserSearch> = result.data;
    dispatch(setSearchesList(pagedResult));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const saveNewSearch = (request: ISaveUserSearchRequest) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    await postSaveNewSearch(request);
    dispatch(refreshRemainingLimits());
    renderSuccessToast(i18n.t("Searches.SaveSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const reAnalyzeSearch = (command: ReAnalyzeSearchCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["reAnalyzeSearch", true]));
    await postReAnalyzeSearch(command);
    dispatch(refreshRemainingLimits());
    renderSuccessToast(i18n.t("Searches.ReAnalyzeSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["reAnalyzeSearch", false]));
  }
};


export const deleteSearch = (search: UserSearch) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteSearch({searchId: search.userSearchId});
    renderSuccessToast(i18n.t("Searches.DeleteSuccess", {value: search.name}));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const resetSearchesState = () => async (dispatch: any) => {
  dispatch(reset());
};
