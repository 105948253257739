interface NoResultProps {
  title: string;
  description: string;
  icon?: string;
}
const NoResult = (props: NoResultProps) => {
  return (
    <div className="p-4">
      <div className="text-center">
        {props.icon ? <i className={`${props.icon} display-2 text-muted`}></i> : <i className=" ri-search-2-line display-2 text-muted"></i>}
        <h3 className="mt-4 fw-semibold">{props.title}</h3>
        <p className="text-muted  fs-14">{props.description}</p>
      </div>
    </div>
  );
};

export default NoResult;
