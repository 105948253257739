import {useTranslation} from "react-i18next";

interface CheckboxItemProps {
    status?: boolean;
}
export const CheckboxItem = ({status}: CheckboxItemProps) => {
  const {t} = useTranslation();
  return status === false ? (
    <>
      <i className="ri-close-circle-fill fs-18 text-danger"></i>
      <span className="ms-1">{t("No")}</span>
    </>
  ) : status === null || status === undefined ? (
    <i className="ri-donut-chart-line fs-18 text-muted"></i>
  ) : (
    <>
      <i className="ri-checkbox-circle-fill fs-18 text-success"></i>
      <span className="ms-1">{t("Yes")}</span>
    </>
  );
};
