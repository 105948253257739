import React, {useEffect, useMemo, useRef, useState} from "react";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {TeamUser, UserRole, UserStatus} from "models/user";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {deleteSubUser, getSubUsers, getUserSubscriptionPackage, resendSubUserInvitation} from "slices/account/thunk";
import {ColumnDef} from "@tanstack/react-table";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {Link} from "react-router-dom";
import defaultAvatar from "../../../assets/images/user-dummy-img.jpg";
import CreateSubUser from "./Modals/CreateSubUser";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import EditSubUser from "./Modals/EditSubUser";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import bgImage from "assets/images/modal-bg.png";
import DisplayDate from "Components/Common/DisplayDate";

const PAGE_IDENTIFIER: ConstantPage = "account.team";
const Team = () => {
  const {t} = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const tableRef = useRef<DataTableRef>(null);
  const dispatch: any = useDispatch();
  const {userProfile, hasPermission} = useProfile();
  const deleteSubUserDialogRef = useRef<DialogRef>(null);
  const resendInvitationDialogRef = useRef<DialogRef>(null);
  const [selectedUser, setSelectedUser] = useState<TeamUser>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const teamData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
      subUsers: state.Account.team,
      userSubscription: state.Account.userSubscription,
    }),
  );

  const {loading, subUsers, userSubscription} = useSelector(teamData);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getSubUsers()(dispatch);
      getUserSubscriptionPackage()(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo<ColumnDef<TeamUser, any>[]>(
    () => [
      {
        id: "email",
        header: t("Account.Team.TableColumn.Member"),
        size: 100,
        accessorKey: "email",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as TeamUser;
          const blurredStyle: React.CSSProperties =
            userSubscription?.subscription?.name !== "Premium"
              ? {
                  filter: row.userId !== userProfile?.userId ? "blur(4px)" : "none",
                  WebkitFilter: row.userId !== userProfile?.userId ? "blur(4px)" : "none",
                }
              : {};

          const emailTitle = userSubscription?.subscription?.name !== "Premium" ? row.email : undefined;
          return (
            <>
              {row.fullName !== "" ? (
                <div className="d-flex align-items-center" style={blurredStyle}>
                  <div className="flex-shrink-0 me-2">
                    <img src={row.avatar || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image" alt="user-profile" />
                  </div>
                  <div className="flex-grow-1 ellipsis-two-lines">
                    <div className="fw-semibold">
                      {row.fullName}
                      {row.userId === userProfile?.userId && <Badge className="ms-1">{t("You")}</Badge>}
                    </div>
                    <div className="text-muted" title={emailTitle}>
                      {row.email}
                    </div>
                  </div>
                </div>
              ) : (
                <Row className="d-flex justify-content-center align-items-center" style={blurredStyle}>
                  <Col xs="auto">
                    <div className="profile-user position-relative d-inline-block mx-auto ">
                      <img src={row.avatar || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image" alt="user-profile" />
                    </div>
                  </Col>
                  <Col>
                    <span className="fw-bold">{row.email}</span>
                  </Col>
                  {row.isCodeExpired && (
                    <Col>
                      <i className="ri-alert-fill cursor-pointer fs-5 text-danger " id={`VerificationTooltip-${row.userId}`}></i>
                      <DefaultUncontrolledTooltip target={`VerificationTooltip-${row.userId}`}>{t("Account.Team.Tooltip.VerificationCodeExpired")}</DefaultUncontrolledTooltip>
                    </Col>
                  )}
                </Row>
              )}
            </>
          );
        },
      },
      {
        header: t("Account.Team.TableColumn.Roles"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as TeamUser;
          const blurredStyle: React.CSSProperties =
            userSubscription?.subscription?.name !== "Premium"
              ? {
                  filter: row.userId !== userProfile?.userId ? "blur(4px)" : "none",
                  WebkitFilter: row.userId !== userProfile?.userId ? "blur(4px)" : "none",
                }
              : {};
          return (
            <div style={blurredStyle}>
              {row.userRoles.map((userRole: UserRole, index: number) => (
                <Badge key={index} className="me-1" color="success">
                  {t(`Roles.${userRole.role.name}`)}
                </Badge>
              ))}
            </div>
          );
        },
      },
      {
        id: "lastLogin",
        header: t("Account.Team.TableColumn.LastLogin"),
        size: 100,
        accessorFn: (item) => item.lastLogin,
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as TeamUser;
          const isOwner = row.userRoles.filter((user) => user.role.name === "Owner").length > 0;
          const blurredStyle: React.CSSProperties =
            userSubscription?.subscription?.name !== "Premium"
              ? {
                  filter: row.userId !== userProfile?.userId ? "blur(4px)" : "none",
                  WebkitFilter: row.userId !== userProfile?.userId ? "blur(4px)" : "none",
                }
              : {};
          return (
            <div style={blurredStyle}>
              {!isOwner && !row.emailConfirmed ? (
                <>
                  <Badge color="info">{t("Account.Team.Badge.InvitationSent")}</Badge>
                  <Button
                    color="link"
                    className="btn me-2"
                    onClick={() => {
                      setSelectedUser(row);
                      resendInvitationDialogRef.current?.show();
                    }}
                    disabled={userSubscription?.subscription?.name !== "Premium"}
                  >
                    <i className="mdi mdi-restart align-bottom me-1"></i>
                    {t("Account.Team.Button.ResendInvitation")}
                  </Button>
                </>
              ) : row.lastLogin ? (
                <DisplayDate id={`DisplayDateTeamsLastLogin-${row.userId}`} value={row.lastLogin} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        id: "action",
        header: t("Actions"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as TeamUser;
          const isOwner = row.userRoles.filter((user) => user.role.name === "Owner").length > 0;

          return (
            <>
              {!isOwner && userSubscription?.subscription?.name === "Premium" && (
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn btn-soft-secondary btn-sm" tag="button">
                    <i className="ri-more-fill" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {
                      <DropdownItem
                        onClick={() => {
                          setSelectedUser(row);
                          setIsEditing(true);
                        }}
                      >
                        {t("Account.Team.ContextMenu.Edit")}
                      </DropdownItem>
                    }
                    {!isOwner && !row.emailConfirmed && (
                      <DropdownItem
                        onClick={() => {
                          setSelectedUser(row);
                          resendInvitationDialogRef.current?.show();
                        }}
                      >
                        {t("Account.Team.ContextMenu.ResendInvitation")}
                      </DropdownItem>
                    )}

                    <DropdownItem divider />
                    {row.userId !== userProfile?.userId && (
                      <DropdownItem
                        onClick={() => {
                          setSelectedUser(row);
                          deleteSubUserDialogRef.current?.show();
                        }}
                      >
                        {t("Account.Team.ContextMenu.Remove")}
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, userSubscription],
  );

  document.title = t("PageTitles.Team");
  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/team",
    },
    {
      label: t("Account.Team.Title"),
      url: "",
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
          <ProfileLayout hasAnyChanges={false} navigateTo="">
            <>
              {userSubscription?.subscription?.name !== "Premium" && (
                <Card className="bg-secondary-subtle" style={{background: `url(${bgImage})`}}>
                  <CardBody className="p-0">
                    <Row>
                      <Col sm={8}>
                        <div className="p-3">
                          <p className="fs-16 lh-base alert-heading">{t("Account.Team.Upgrade.Title")}</p>
                          <ul className="list-unstyled">
                            <li className="fs-14">
                              <i className="ri-checkbox-circle-fill fs-15 align-middle me-2 text-success"></i>
                              {t("Account.Team.Upgrade.Description1")}
                            </li>
                            <li className="fs-14">
                              <i className="ri-checkbox-circle-fill fs-15 align-middle me-2 text-success"></i>
                              {t("Account.Team.Upgrade.Description2")}
                            </li>
                            <li className="fs-14">
                              <i className="ri-checkbox-circle-fill fs-15 align-middle me-2 text-success"></i>
                              {t("Account.Team.Upgrade.Description3")}
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col sm={4} className="d-flex justify-content-center align-items-center">
                        <div className="px-3">
                          <div className="hstack flex-wrap gap-2">
                            <Link to="/account/subscription/change" className="btn btn-primary btn-label rounded-pill">
                              <i className=" bx bx-up-arrow-circle label-icon align-middle rounded-pill fs-16 me-2"></i>
                              {t("Account.Team.Upgrade.Button")}
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}

              <Card className="ribbon-box overflow-hidden">
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle className="fw-medium">
                        <i className="mdi mdi-account-group fs-18 align-middle me-1"></i>
                        {t("Account.Team.Title")}
                      </CardTitle>
                    </Col>
                    <Col xs="auto">
                      <div id="CreateTeamUserButton">
                        <Button type="button" color="primary" onClick={() => setIsCreateModalOpen(true)} disabled={userSubscription?.subscription?.name !== "Premium"}>
                          <i className="mdi mdi-plus align-middle"></i> {t("Account.Team.Button.Create")}
                        </Button>
                      </div>
                      <UncontrolledTooltip placement="top" target="CreateTeamUserButton">
                        {t("Account.Team.Tooltip.Upgrade")}
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="text-center">
                  <DataTable trClass="text-start" tdClass="text-start" ref={tableRef} busy={loading.list} columns={columns} data={subUsers} totalDataLength={subUsers.length} hovered />
                </CardBody>
              </Card>
            </>
          </ProfileLayout>
        </Container>
      </div>
      <CreateSubUser show={isCreateModalOpen} toggle={() => setIsCreateModalOpen(!isCreateModalOpen)} />
      <EditSubUser show={isEditing} toggle={() => setIsEditing(!isEditing)} subUser={selectedUser!} />
      <Dialog
        ref={deleteSubUserDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-alert-fill"
        message={selectedUser && selectedUser.status === UserStatus.ACTIVE ? t("Account.Team.Dialog.Delete.DescriptionForOldUser") : t("Account.Team.Dialog.Delete.DescriptionForNewUser")}
        title={t("Account.Team.Dialog.Delete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await deleteSubUser(selectedUser!.userId)(dispatch);
            // navigate(redirectUrl);
          }
          deleteSubUserDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={resendInvitationDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-alert-fill"
        message={t("Account.Team.Dialog.ResendInvitation.Description")}
        title={t("Account.Team.Dialog.ResendInvitation.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await resendSubUserInvitation(selectedUser!.userId)(dispatch);
          }
          resendInvitationDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default Team;
