import ShipmentInfo from "./_ShipmentInfo";
import Summary from "./_Summary";
import ProjectedSalesAndProfits from "./_ProjectedSalesAndProfits";
import CostAndProfitAnalysis from "./_CostAndProfitAnalysis";
import SaleHistory from "./_SaleHistory";
import ProductList from "./_ProductList";
import SoldOutProducts from "./_SoldOutProducts";
import Orders from "./_Orders";
import { Row } from "reactstrap";
import RemainingProducts from "./_RemainingProducts";

const ShippedOrCompleted = () => {
  return (
    <>
      <ShipmentInfo  />

      <Summary />

      <ProjectedSalesAndProfits />

      <CostAndProfitAnalysis />

      <SaleHistory />

      <ProductList />

      <Row className="mb-4 g-4">
        <RemainingProducts />
        <SoldOutProducts />
      </Row>
      <Orders />
    </>
  );
};

export default ShippedOrCompleted;
