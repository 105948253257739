import {ValidatableInputProps} from "helpers/types";
import {InputHTMLAttributes} from "react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-flatpickr";
import classNames from "classnames";
// Define the type DateInputProps
export type DateInputProps = ValidatableInputProps<Date> &
  Pick<InputHTMLAttributes<"input">, "id" | "disabled" | "className" | "style" | "placeholder"> & {
    max?: Date;
    min?: Date;
  };

const DateInput = (props: DateInputProps) => {
  const {value, onChange, className, min, max, isValid, ...rest} = props;
  const {i18n} = useTranslation();

  const onChangeDate = ([date]: Date[]) => {
    onChange?.(date);
  };

  return (
    <>
      <div className="form-icon form-control p-0">
        <DatePicker
          value={value}
          className={classNames("form-control form-control-icon border-0", className)}
          width={"auto"}
          onChange={onChangeDate}
          locale={i18n.language}
          options={{
            minDate: min,
            maxDate: max,
            formatDate: (date, format, locale) => {
              return new Intl.DateTimeFormat(i18n.language, {
                year: "numeric",
                month: "short",
                day: "2-digit",
              }).format(date);
            },
            locale: i18n.language,
          }}
          {...rest}
        />
        <i className="ri-calendar-2-line"></i>
      </div>
    </>
  );
};

export default DateInput;
