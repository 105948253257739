import {generateError} from "helpers/utilities";
import {apiError, loading, reset, setStatistics} from "./reducer";
import {ResponseModel} from "models/response_model";
import {Dashboard} from "models/dashboard";
import {GetStatisticsQuery} from "api/query";
import {postGetDashboardStatistics} from "services/store_service";
import {setCurrencies} from "slices/common/reducer";
import axios, { CancelTokenSource } from "axios";

let currentStatisticsRequestSource: CancelTokenSource | null = null;

export const getStatistics = (payload: GetStatisticsQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    if (currentStatisticsRequestSource) {
      (currentStatisticsRequestSource as CancelTokenSource).cancel('Operation canceled due to new request');
    }

    currentStatisticsRequestSource = axios.CancelToken.source();
    const result: ResponseModel = await postGetDashboardStatistics(payload, currentStatisticsRequestSource.token);
    const statistics: Dashboard = result.data;
    dispatch(setStatistics(statistics));
    dispatch(setCurrencies(statistics.currencies));
    return true;
  } catch (error) {
    if(axios.isCancel(error)) 
    {
      return false;
    }

    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};


export const resetDashboardState = () => async (dispatch: any) => {
  dispatch(reset());
}