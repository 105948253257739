import React from "react";
import {Card, CardBody, Col} from "reactstrap";
import giftbox from "assets/images/giftbox.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import i18n from "i18n";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";

const InviteNewSellers = () => {
  const {t} = useTranslation();
  const inviteLink = `https://sellthis.com/${i18n.language === "en" ? "" : "tr/"}affiliate`;

  const userData = createSelector(
    (state: RootState) => state,
    (state) => ({
      storeList: state.Common.userStores,
      loading: state.Common.loading,
    }),
  );
  const {storeList, loading} = useSelector(userData);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
    }),
  );

  const {statistics} = useSelector(dashboardData);

  return (
    <React.Fragment>
      <Col xl={2}>
        {!loading.userStore && storeList && storeList.length === 0 ? (
          <Card className="card-height-100 card-animate text-center bg-danger-subtle bg-opacity-50">
            <CardBody className="d-flex flex-column justify-content-center">
              <div className="avatar-sm icon-effect mx-auto mb-3">
                <div className="avatar-title bg-transparent text-success rounded-circle h1">
                  <i className="mdi mdi-connection"></i>
                </div>
              </div>
              <h5>{t("ConnectYourStore")}</h5>
              <p className="text-muted">{t("ConnectYourStoreDesc")}</p>
              <div className="mt-2">
                <Link to="/stores/connect" className="fs-13 fw-medium link-secondary" target="_blank">
                  {t("ConnectYourStore")} <i className="ri-arrow-right-s-line align-bottom"></i>
                </Link>
              </div>
            </CardBody>
          </Card>
        ) : statistics.inventoryWidget.itemsMissingCost > 0 ? (
          <Card className="card-height-100 card-animate text-center bg-danger-subtle bg-opacity-50">
            <CardBody className="d-flex flex-column justify-content-center">
              <div className="mx-auto mb-2">
                <i className="mdi mdi-bank-remove bg-transparent text-danger h1"></i>
              </div>
              <h5>{t("Dashboard.Widgets.Label.XProductsMissingCosts", {value: statistics.inventoryWidget.itemsMissingCost})}</h5>
              <p className="text-muted">{t("Dashboard.Widgets.Info.ItemsMissingCost")}</p>

              <div className="mt-2">
                <Link to="/inventory/updateInventory" className="fw-medium link-secondary" target="_blank">
                  {t("Dashboard.Widgets.Button.UpdateInventory")} <i className="ri-arrow-right-s-line align-bottom"></i>
                </Link>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Card className="card-height-100 card-animate text-center">
            <CardBody>
              <img src={giftbox} alt="" style={{width: "85px"}} />
              <div className="mt-3">
                <h5>{t("Dashboard.Widgets.Label.InviteNewSeller")}</h5>
                <p className="text-muted lh-base mb-2">{t("Dashboard.Widgets.Label.InviteNewSellerDescription")}</p>
                <Link to={inviteLink} className="btn btn-sm btn-primary btn-label rounded-pill" target="_blank">
                  <i className="ri-mail-fill label-icon align-middle rounded-pill fs-16 me-2"></i>
                  {t("Dashboard.Widgets.Button.InviteNow")}
                </Link>
              </div>
            </CardBody>
          </Card>
        )}
      </Col>
    </React.Fragment>
  );
};

export default InviteNewSellers;
