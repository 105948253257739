import {FormikValidation} from "helpers/types";
import React, {useState, ChangeEvent, useRef, useEffect} from "react";
import {FormFeedback, Input} from "reactstrap";

interface ValidatedLineNumberedTextareaProps<T> {
  validation: FormikValidation<T>;
  field: keyof T;
  className?: string;
  placeholder: string;
  maxLines: number;
  value?: string;
  disableValidationUI?: boolean;
}

const ValidatedLineNumberedTextarea = <T,>({maxLines, field, validation, placeholder, disableValidationUI}: ValidatedLineNumberedTextareaProps<T>) => {
  const fieldName = field.toString();
  const {error, touched, value} = validation.getFieldMeta(fieldName);

  const [text, setText] = useState<string>(value || "");
  const [lines, setLines] = useState<number>(1);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [text]);

  const scrollToBottom = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const currentLines = inputValue.split("\n").length || 1;
    if (currentLines <= maxLines) {
      setText(inputValue);
      setLines(currentLines);
      validation.handleChange(e);
      if (currentLines <= 1) {
        setLines(1);
      }
    }
  };
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const lines = pastedText.split("\n").filter((line) => line.trim() !== ""); // Boş satırları filtrele

    const newText = text ? `${text}\n${lines.join("\n")}` : lines.join("\n");
    const newLines = newText.split("\n").length;

    if (newLines <= maxLines) {
      setText(newText);
      setLines(newLines);
      validation.setFieldValue(fieldName, newText);
    } else {
      const limitedLines = newText
        .split("\n")
        .slice(0, maxLines)
        .join("\n");
      setText(limitedLines);
      setLines(maxLines);
      validation.setFieldValue(fieldName, limitedLines);
    }
  };

  return (
    <div>
      {touched && error ? (
        <FormFeedback type="invalid">
          <div>{error}</div>
        </FormFeedback>
      ) : null}
      <div className="h-100" style={{overflowY: "auto", maxHeight: "330px"}} ref={divRef}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div
            className="text-center rounded-0 rounded-start bg-light border-end-0 text-muted"
            style={{
              userSelect: "none",
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "9px",
              border: touched && error ? "solid 1px #f06548" : "var(--vz-border-style) var(--vz-border-width)  var(--vz-border-color)",
            }}
          >
            {Array.from({length: Math.min(maxLines, lines)}, (_, index) => (
              <div key={index + 1}>{index + 1}</div>
            ))}
          </div>
          <div style={{flex: 1}}>
            <Input
              name={fieldName}
              valid={disableValidationUI ? undefined : touched ? !error : undefined}
              invalid={touched && error ? true : false}
              type="textarea"
              placeholder={placeholder}
              value={text}
              onPaste={handlePaste}
              onChange={handleChange}
              onBlur={validation.handleBlur}
              style={{
                height: "100%",
                resize: "none",
                minHeight: "300px",
                overflowY: "hidden",
              }}
              className="rounded-0 rounded-end border-start-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatedLineNumberedTextarea;
