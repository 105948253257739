import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import {UserStore} from "models/user_stores";

type StoresLoadingStates = LoadingStates<"list" | "update" | "save" | "delete">;

export type StoresState = {
  list: UserStore[];
  currentUserStore: UserStore;
  loading: StoresLoadingStates;
  error?: ApiError;
};

const initialState: StoresState = {
  list: [] as UserStore[],
  currentUserStore: {} as UserStore,
  loading: {
    list: false,
    update: false,
    save: false,
    delete: false,
  },
  error: {} as ApiError,
};

const StoresSlice = createSlice({
  name: "Stores",
  initialState,
  reducers: {
    setStoresList(state, action: PayloadAction<UserStore[]>) {
      state.list = action.payload;
    },
    setCurrentUserStore(state, action: PayloadAction<UserStore>) {
      state.currentUserStore = action.payload;
    },
    loading(state, action: PayloadAction<[keyof StoresLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.list = initialState.list;
      state.error = initialState.error;
    },
  },
});

export const {setStoresList, setCurrentUserStore, loading, apiError, reset} = StoresSlice.actions;

export default StoresSlice.reducer;
