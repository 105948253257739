import {useEffect, useState} from "react";
import {useProfile} from "Components/Hooks/useProfile";
import {DateRange} from "helpers/types";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useTranslation} from "react-i18next";
import {Button, DropdownToggle, DropdownMenu, DropdownItem, Form, Row, Col, Label, UncontrolledDropdown} from "reactstrap";
import {UserLimitUsageHistoryQuery} from "api/query";
import {useFormik} from "formik";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import moment from "moment";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedDateRangeInput from "Components/Common/ValidatedDateRangeInput";
import * as Yup from "yup";
import { UserLimitType } from "models/enums/user_limit_type";

type FilterType = "Today" | "Last7Days" | "Last1Month" | "Custom";
interface FiltersProps {
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: UserLimitUsageHistoryQuery) => void;
}

const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [selectedFilter, setSelectedFilter] = useState<FilterType>("Today");
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const {limitUsageHistoryOperationSelectOptions} = useSelectOptions();
  const [defaultStartDate] = useState<Date>(getToday().tz(userProfile?.timezone!).startOf("day").utcOffset(0).toDate());
  const [defaultEndDate] = useState<Date>(getToday().endOf("day").tz(userProfile?.timezone!).utcOffset(0).toDate());

  useEffect(() => {
    handleDateRangeChange("Today");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateRangeChange = (filter: FilterType) => {
    setSelectedFilter(filter);
    if (filter === "Custom") {
      setShowCustomDateRange(true);
    } else {
      var startDate: Date = getToday().toDate();
      var dateMax: Date = getToday().endOf("day").tz(userProfile?.timezone!).utcOffset(0).toDate();
      if (filter === "Today") {
        startDate = getToday().tz(userProfile?.timezone!).startOf("day").utcOffset(0).toDate();
      } else if (filter === "Last7Days") {
        startDate = getRelativeDateByTimezone(7 - 1, userProfile?.timezone);
      } else if (filter === "Last1Month") {
        startDate = getRelativeDateByTimezone(30 - 1, userProfile?.timezone);
      }

      validation.setFieldValue("dateRange", {
        start: startDate,
        end: dateMax,
      });
      setShowCustomDateRange(false);
    }
  };

  useEffect(() => {
    validation.handleSubmit();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      dateRange: {start: defaultStartDate, end: defaultEndDate},
      operationType: undefined,
    } as UserLimitUsageHistoryQuery,
    validationSchema: Yup.object({
      dateRange: Yup.object<DateRange>(),
      operationType: Yup.mixed<UserLimitType>(),
    }),
    onSubmit: (values) => {
      if (values.dateRange.start !== undefined && values.dateRange.end !== undefined) {
        props.handleFilter({
          dateRange: {
            start: moment
              .tz(validation.values.dateRange.start, userProfile?.timezone!)
              .startOf("day")
              .utcOffset(0)
              .toDate(),
            end: moment
              .tz(validation.values.dateRange.end, userProfile?.timezone!)
              .endOf("day")
              .utcOffset(0)
              .toDate(),
          },
          operationType: Number(validation.values.operationType),
          page: 1,
          pageSize: props.pageSize,
        });
      }
    },
  });

  return (
    <Form onSubmit={validation.handleSubmit}>
      <Row className="d-flex align-items-end mb-4">
        <Col xs={12} md={3}>
          <Label htmlFor="operationType">{t("Account.LimitUsageHistory.Label.OperationType")}</Label>
          <ValidatedSelect
            validation={validation}
            field="operationType"
            errorStyle="solid"
            value={validation.values.operationType}
            options={limitUsageHistoryOperationSelectOptions}
            isClearable
          ></ValidatedSelect>
        </Col>
        <Col xs={12} md="auto">
          <UncontrolledDropdown>
            <DropdownToggle className="border-primary" color="link" caret>
              {t(selectedFilter)}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleDateRangeChange("Today")}>{t("Today")}</DropdownItem>
              <DropdownItem onClick={() => handleDateRangeChange("Last7Days")}>{t("Last7Days")}</DropdownItem>
              <DropdownItem onClick={() => handleDateRangeChange("Last1Month")}>{t("Last1Month")}</DropdownItem>
              <DropdownItem onClick={() => handleDateRangeChange("Custom")}>{t("Account.LimitUsageHistory.Dropdown.Custom")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        {showCustomDateRange && (
          <>
            <Col xs={12} md={5}>
              <ValidatedDateRangeInput validation={validation} field="dateRange" />
            </Col>
          </>
        )}
        <Col xs={12} md={2}>
          <Button type="submit" color="primary" disabled={props.busy}>
            {t("Account.LimitUsageHistory.Button.ApplyFilter")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Filters;
