import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Form, Label, Input} from "reactstrap";
import {ColumnDef} from "@tanstack/react-table";
import {Tag} from "models/tag";
import {TagColor} from "models/enums/tag_color";
import {User} from "models/user";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {addUserTagItem, createTag, deleteTag, deleteUserTagItem, updateTag} from "slices/admin/users/thunk";
import {useFormik} from "formik";
import {useProfile} from "Components/Hooks/useProfile";
import updateUrlParams from "helpers/utilities";
import NoResult from "Components/Common/NoResult";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import DisplayDate from "Components/Common/DisplayDate";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import _ from "lodash";
import * as Yup from "yup";
import TagPreview from "Components/Common/TagPreview";

const AddTag = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const {tagColorSelectOptions} = useSelectOptions();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInnerModalOpen, setIsInnerModalOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [selectedTag, setSelectedTag] = useState<Tag>();
  const [user, setUser] = useState<User>();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tableRef = useRef<DataTableRef>(null);
  const deleteDialogRef = useRef<DialogRef>(null);

  const userData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
      userList: state.AdminUsers.list,
      tags: state.Common.tags,
    }),
  );
  const {loading, tags, userList} = useSelector(userData);

  const columns = useMemo<ColumnDef<Tag, any>[]>(
    () => [
      {
        header: " ",
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as Tag;
          let isExist = false;
          if (user) {
            isExist = userList?.items?.find((x) => x.userId === user!.userId && x.userTags?.find((t) => t.tagId === row.tagId)) ? true : false;
          }
          return (
            <>
              <Input className="form-check-input cursor-pointer" type="checkbox" checked={isExist} onChange={(event) => handleCheckBoxChange(event, row.tagId)} />
            </>
          );
        },
      },
      {
        header: t("Action"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as Tag;
          return (
            <div className="d-flex align-items-center">
              <Button
                id={`Edit-${row.tagId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedTag(row);
                  setMode("edit");
                  setIsInnerModalOpen(true);
                }}
              >
                <i className="ri-pencil-fill"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Edit-${row.tagId}`}>{t("Edit")}</DefaultUncontrolledTooltip>
              <Button
                id={`Delete-${row.tagId}`}
                className="btn btn-ghost-danger px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedTag(row);
                  deleteDialogRef.current?.show();
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Delete-${row.tagId}`}>{t("Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        header: t("Tag"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as Tag;
          return <TagPreview color={row.color as TagColor} value={row.name} className="ellipsis-single-line fs-14" />;
        },
      },
      {
        header: t("Create Date"),
        size: 220,
        cell: (cellProps) => {
          const row = cellProps.row.original as Tag;
          return <DisplayDate id={`DisplayDateTag-${row.tagId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />;
        },
      },
    ],
    [t, user, userList], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      tagName: "",
      tagColor: tagColorSelectOptions[0].value,
    },
    validationSchema: Yup.object({
      tagName: Yup.string().required(t("FieldRequired")),
      tagColor: Yup.string().required(t("FieldRequired")),
    }),
    onSubmit: (values) => {
      if (mode === "create") {
        createTag({
          color: values.tagColor,
          name: values.tagName,
        })(dispatch).then(() => {
          setIsInnerModalOpen(false);
        });
      } else {
        updateTag({
          tagId: selectedTag!.tagId,
          color: values.tagColor,
          name: values.tagName,
        })(dispatch).then(() => {
          setIsInnerModalOpen(false);
        });
      }
    },
  });

  const handleCheckBoxChange = (event: any, tagId: string) => {
    if (event.target.checked) {
      addUserTagItem({
        tagId: tagId,
        userId: user!.userId,
      })(dispatch);
    } else {
      deleteUserTagItem({
        tagId: tagId,
        userId: user!.userId,
      })(dispatch);
    }
  };

  const initModal = useCallback(() => {
    if (isOpen) {
      const userInList = userList.items?.find((u) => u.userId === searchParams.get("user"));
      if (userInList) {
        setUser(userInList);
      } else {
        toggle();
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const onUrlChange = useCallback(() => {
    if (searchParams.has("addTag") && searchParams.has("user")) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const onInnerModalOpen = useCallback(() => {
    if (isInnerModalOpen && mode === "edit") {
      validation.setFieldValue("tagName", selectedTag?.name);
      validation.setFieldValue("tagColor", selectedTag?.color.toString());
    } else {
      validation.resetForm();
    }
  }, [isInnerModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  useEffect(() => {
    onUrlChange();
  }, [onUrlChange]);

  useEffect(() => {
    onInnerModalOpen();
  }, [onInnerModalOpen]);

  const toggle = () => {
    validation.resetForm();
    setUser(undefined);
    setIsInnerModalOpen(false);
    updateUrlParams(navigate, location, {
      addTag: null,
      user: null,
    });
  };

  return (
    <Modal backdrop="static" isOpen={isOpen} toggle={toggle} fade={true} centered={true} size="md">
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {t("Admin.Users.Dialog.AddTag.Title")}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {_.isNil(tags) || tags?.length === 0 ? (
              <NoResult description={t("Admin.Users.Dialog.AddTag.NoAvailableTag")} title={""} icon="mdi mdi-tag-off-outline" />
            ) : (
              <DataTable
                ref={tableRef}
                columns={columns}
                maxHeight="50vh"
                data={tags || []}
                totalDataLength={tags?.length || 0}
                thClass="text-black"
                busy={loading.list || loading.save || loading.delete}
                hovered
                pagination={{
                  pageIndex: 0,
                  pageSize: Infinity,
                }}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-5 ">
          <Col>
            <Button
              type="submit"
              onClick={() => {
                setMode("create");
                setIsInnerModalOpen(true);
              }}
            >
              <i className="ri-add-line align-middle me-1"></i>
              {t("Admin.Users.Dialog.AddTag.Label.NewTag")}
            </Button>
          </Col>
        </Row>
        <Modal
          isOpen={isInnerModalOpen}
          toggle={() => {
            setIsInnerModalOpen(false);
            validation.resetForm();
          }}
          fade={true}
          centered={true}
          size="sm"
        >
          <ModalHeader toggle={() => setIsInnerModalOpen(false)}>{mode === "create" ? t("Admin.Users.Dialog.AddTag.Label.NewTag") : t("Admin.Users.Dialog.AddTag.Label.EditTag")}</ModalHeader>
          <ModalBody>
            <Form onSubmit={validation.handleSubmit}>
              <Row className="g-3">
                <Col xs={12}>
                  <Label htmlFor="tagName">{t("Tag Name")}</Label>
                  <ValidatedInput validation={validation} field="tagName" disableValidationUI />
                </Col>
                <Col xs={12}>
                  <Label htmlFor="tagColor">{t("Color")}</Label>
                  <ValidatedSelect validation={validation} field="tagColor" options={tagColorSelectOptions} value={validation.values.tagColor} optionStyle="tagColor" valueStyle="tagColor" />
                </Col>
                <Col xs={12}>
                  <Label>{t("Preview")}</Label>
                  <TagPreview color={Number(validation.values.tagColor) as TagColor} value={validation.values.tagName || t("Example")} className="ellipsis-single-line fs-14"/>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button color="success" type="submit" disabled={loading.save}>
                    {mode === "create" ? t("Create") : t("Edit")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" className="btn btn-light" onClick={toggle}>
            {t("Admin.Users.Dialog.AddTag.CancelButton")}
          </Button>
        </div>
      </ModalFooter>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Admin.Users.Dialog.AddTag.RemoveDescription", {value: selectedTag?.name})}
        title={t("Admin.Users.Dialog.AddTag.RemoveTitle")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await deleteTag({tagId: selectedTag!.tagId})(dispatch);
            deleteDialogRef.current?.hide();
          } else {
            deleteDialogRef.current?.hide();
          }
        }}
      />
    </Modal>
  );
};

export default AddTag;
