import updateUrlParams, {preventScrollUp} from "helpers/utilities";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";

const IneligibilityReasonList = () => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const {asin} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [ineligibilityReasonList, setIneligibilityReasonList] = useState<string[]>();

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
      pageUrl: state.ProductDetails.pageUrl,
    }),
  );
  const {details, pageUrl} = useSelector(searchResultsData);

  const toggle = () => {
    updateUrlParams(navigate, location, {
      "ineligibility-reasons": null
    })
    preventScrollUp();
  };

  useEffect(() => {
    if (isOpen) {
      const reasonList = details?.salesAnalysis?.ineligibilityReasonList?.split(",");
      if (asin && reasonList && reasonList.length > 0) {
        setIneligibilityReasonList(reasonList);
      } else {
        navigate(pageUrl);
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const onUrlChange = useCallback(() => {
    if(searchParams.has("ineligibility-reasons")) {
      setIsOpen(true);
    }else{
      setIsOpen(false);
    }
  }, [location.search]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onUrlChange();
  }, [onUrlChange])
  return (
    <Modal id="showIneligibilityReasonListModal" isOpen={isOpen} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {t("SearchResults.Dialog.IneligibilityReasonsList.Title")}
      </ModalHeader>
      <ModalBody>
        {ineligibilityReasonList ? (
          ineligibilityReasonList.map((reason: string, index: number) => (
            <div key={index} className="d-flex">
              <div className="flex-shrink-0 me-2">
                <i className={`bx ${reason.endsWith("Recheck") || reason.endsWith("Warning") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
              </div>
              <div className="flex-grow-1">
                <h5>{reason}</h5>
                <p className="text-muted">{t(`IneligibilityReasons.${reason}`)}</p>
              </div>
            </div>
          ))
        ) : (
          <div>{t("No reason")}</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default IneligibilityReasonList;
