import {User} from "models/user";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Modal, ModalBody, Spinner} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {generateUserLoginToken} from "slices/admin/users/thunk";

interface PaymentHistoryProps {
  show: boolean;
  toggle: () => void;
  user: User;
}
const LoginAsModal = ({show, toggle, user}: PaymentHistoryProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const adminUsersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
      loginToken: state.AdminUsers.loginToken,
    }),
  );

  const {loading, loginToken} = useSelector(adminUsersData);
  useEffect(() => {
    if (show) {
      generateUserLoginToken(user)(dispatch);
    }
  }, [show]); // eslint-disable-line

  return (
    <Modal id="success-Payment" tabIndex={1} isOpen={show} toggle={toggle} centered>
      <ModalBody className="text-center">
        <div className="text-end">
          <Button type="button" color="link" onClick={toggle} className="btn-close text-end" data-bs-dismiss="modal" aria-label="Close"></Button>
        </div>
        <div className="mt-2">
          {loading.loginAs ? (
            <>
              <Spinner size="sm" className="me-2"></Spinner>
              <h4 className="mb-3 mt-4">{t("Admin.Users.Dialog.LoginAs.Loading", {value: user.email})}</h4>
            </>
          ) : loginToken !== "" ? (
            <>
              <i className="ri-chat-smile-2-line display-2 text-success"></i>
              <h4 className="mb-3 mt-4">{t("Admin.Users.Dialog.LoginAs.Title", {value: user.email})}</h4>
              <p className="text-muted fs-15 mb-4">{t("Admin.Users.Dialog.LoginAs.Description", {value: user.email})}</p>
              <div className="hstack gap-2 justify-content-center">
                <Link to={`/account/login?auth=${loginToken}`} className="btn btn-soft-success">
                  <i className="ri-links-line align-bottom"></i> {t("Admin.Users.Dialog.LoginAs.Button.ClickHereForLogin")}
                </Link>
              </div>
            </>
          ) : (
            <>
              <i className="ri-alert-fill display-2 text-danger"></i>
              <h4 className="mb-3 mt-4">{t("Admin.Users.Dialog.LoginAs.Error")}</h4>
            </>
          )}
        </div>
      </ModalBody>
      {!loading.loginAs && loginToken !== "" && (
        <div className="modal-footer bg-light p-3 justify-content-center">
          <p className="mb-0 text-muted">{t("Admin.Users.Dialog.LoginAs.Footer")}</p>
        </div>
      )}
    </Modal>
  );
};

export default LoginAsModal;
