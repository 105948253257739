import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, LoadingStates } from 'helpers/types';
import { Subscription } from 'models/subscription';
import { UserSubscription } from 'models/user_subscription';

type ChangeSubscriptionLoadingStates = LoadingStates<'list' | 'change' | 'create'>;

export type ChangeSubscriptionState = {
    loading: ChangeSubscriptionLoadingStates;
    list: Subscription[];
    userSubscription: UserSubscription;
    error?: ApiError
};

const initialState: ChangeSubscriptionState = {
    loading: {
        change: false,
        list: false,
        create: false,
    },
    list: [],
    userSubscription: {} as UserSubscription,
    error: {} as ApiError
};

const changeSubscriptionSlice = createSlice({
    name: 'changeSubscription',
    initialState,
    reducers: {
        setList(state, action) {
            state.list = action.payload.data;
        },
        setUserSubscription(state, action) {
            state.userSubscription = action.payload.data;
        },
        loading(
            state,
            action: PayloadAction<[keyof ChangeSubscriptionLoadingStates, boolean]>
        ) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        },
    },
});

export const {
    setList,
    setUserSubscription,
    loading,
    apiError
} = changeSubscriptionSlice.actions;

export default changeSubscriptionSlice.reducer;