import React, {useEffect, useState} from "react";
import {SearchAndScans} from "models/dashboard";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import CountUp from "react-countup";
import RenderNumber from "Components/Common/RenderNumber";

const SearchAndScan = () => {
  const {t} = useTranslation();

  const [searchAndScans, setSearchAndScans] = useState<SearchAndScans>({} as SearchAndScans);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
      loading: state.Dashboard.loading,
    }),
  );

  const {statistics, loading} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics) {
      setSearchAndScans(statistics.searchAndScans);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="card-height-100 card-animate">
          <CardBody>
            <Row className="align-items-center">
              <Col xs={6}>
                <h4 className="fs-4 mb-3">
                  <CountUp start={0} suffix={` ${t("Dashboard.Widgets.Label.Searches")}`} separator={""} end={searchAndScans.search} duration={2} />
                </h4>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <h4 className="fs-4 mb-3">
                  <CountUp start={0} suffix={` ${t("Dashboard.Widgets.Label.Scans")}`} separator={""} end={searchAndScans.scan} duration={2} />
                </h4>
              </Col>
            </Row>

            <div>
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <i className="mdi mdi-basket-plus align-middle text-succes me-2"></i>
                    {t("Dashboard.Widgets.Label.SellableAsins")}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <RenderNumber value={searchAndScans.sellableAsins} busy={loading.list}/>
                  </p>
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <i className="mdi mdi-basket-fill align-middle text-warning me-2"></i>
                    {t("Dashboard.Widgets.Label.RecheckAsins")}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <RenderNumber value={searchAndScans.recheckAsins} busy={loading.list}/>
                  </p>
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <i className="mdi mdi-basket-check align-middle text-primary me-2"></i>
                    {t("Dashboard.Widgets.Label.TotalAsins")}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <RenderNumber value={searchAndScans.totalAsins} busy={loading.list}/>
                  </p>
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <i className="mdi mdi-link-variant align-middle text-info me-2"></i>
                    {t("Dashboard.Widgets.Label.LinkScanned")}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <p className="mb-0">
                    <RenderNumber value={searchAndScans.linkScanned} busy={loading.list}/>
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-truncate text-muted fs-14 mb-0">
                    <i className="mdi mdi-playlist-check align-middle text-danger me-2"></i>
                    {t("Dashboard.Widgets.Label.AsinsFound")}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span>
                    <RenderNumber value={searchAndScans.asinsFound} busy={loading.list}/>
                  </span>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SearchAndScan;
