import {useFormik} from "formik";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {SearchLogQuery} from "api/query";
import {useParams} from "react-router-dom";
import ValidatedInput from "Components/Common/ValidatedInput";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import * as Yup from "yup";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";

interface FiltersProps {
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: SearchLogQuery) => void;
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {serviceSelectOptions, commonProductSelectOptions} = useSelectOptions();
  const {searchId} = useParams();
  const searchLogsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminSearchLogs.loading,
    }),
  );
  const {loading} = useSelector(searchLogsData);

  const validation = useFormik({
    initialValues: {
      page: props.page,
      pageSize: props.pageSize,
    } as SearchLogQuery,
    validationSchema: Yup.object<SearchLogQuery>({
      asin: Yup.string(),
      completedServices: Yup.array<String>(),
      ongoingServices: Yup.array<String>(),
      durationMin: Yup.number(),
      durationMax: Yup.number(),
      existInSource: Yup.string(),
      existInTarget: Yup.string(),
      successCountMin: Yup.number(),
      successCountMax: Yup.number(),
    }),
    onSubmit: (values: SearchLogQuery) => {
      const payload: SearchLogQuery = {
        ...values,
        page: 1,
        pageSize: props.pageSize,
        searchId: searchId as string,
        existInSource: values.existInSource || undefined,
        existInTarget: values.existInTarget || undefined,
        asin: values.asin || undefined,
        completedServices: values.completedServices || undefined,
        ongoingServices: values.ongoingServices || undefined,
        durationMin: values.durationMin || undefined,
        durationMax: values.durationMax || undefined,
        successCountMax: values.successCountMax || undefined,
        successCountMin: values.successCountMin || undefined,
        filtering: true,
      };

      props.handleFilter(payload);
    },
  });

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <Card>
          <CardBody>
            <div className="live-preview">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="align-items-center">
                  <Col>
                    <div className="flex-shrink-0">
                      <Label className="form-label text-muted">{t("Admin.SearchLogs.Filters.Title")}</Label>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Row className=" mb-3">
                    <Col sm={3} className="mb-3">
                      <Label htmlFor="asin">{t("Admin.SearchLogs.Filters.Label.Asin")}</Label>
                      <ValidatedInput validation={validation} field="asin" maxLength={100} disableValidationUI />
                    </Col>

                    <Col sm={3} className="mb-3">
                      <Label htmlFor="completedServices">{t("Admin.SearchLogs.Filters.Label.CompletedServices")}</Label>
                      <ValidatedMultiSelect
                        options={serviceSelectOptions}
                        validation={validation}
                        field="completedServices"
                        placeholder={t("Admin.SearchLogs.Filters.Label.SelectServicePlaceholder")}
                        value={validation.values.completedServices}
                      />
                    </Col>

                    <Col sm={3} className="mb-3">
                      <Label htmlFor="ongoingServices">{t("Admin.SearchLogs.Filters.Label.OngoingServices")}</Label>
                      <ValidatedMultiSelect
                        options={serviceSelectOptions}
                        validation={validation}
                        field="ongoingServices"
                        placeholder={t("Admin.SearchLogs.Filters.Label.SelectServicePlaceholder")}
                        value={validation.values.ongoingServices}
                      />
                    </Col>

                    <Col sm={3} className="mb-3">
                      <Label htmlFor="durationMin">{t("Admin.SearchLogs.Filters.Label.Duration")}</Label>
                      <Row>
                        <Col>
                          <ValidatedInput validation={validation} field="durationMin" maxLength={100} type="number" placeholder={t("Min")} disableValidationUI />
                        </Col>
                        <Col xs="auto" className="d-flex align-items-center text-muted">
                          <Label>{t("and")}</Label>
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} field="durationMax" maxLength={100} type="number" placeholder={t("Max")} disableValidationUI />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={3}>
                      <Label htmlFor="existInSource">{t("Admin.SearchLogs.Filters.Label.ExistInSource")}</Label>
                      <ValidatedSelect
                        options={commonProductSelectOptions}
                        validation={validation}
                        field="existInSource"
                        placeholder={t("Select")}
                        value={validation.values.existInSource}
                        errorStyle="solid"
                      />
                    </Col>

                    <Col sm={3}>
                      <Label htmlFor="existInTarget">{t("Admin.SearchLogs.Filters.Label.ExistInTarget")}</Label>
                      <ValidatedSelect
                        options={commonProductSelectOptions}
                        validation={validation}
                        field="existInTarget"
                        placeholder={t("Select")}
                        value={validation.values.existInTarget}
                        errorStyle="solid"
                      />
                    </Col>
                    <Col sm={3}>
                      <Label htmlFor="successCountMin">{t("Admin.SearchLogs.Filters.Label.SuccessCount")}</Label>
                      <Row>
                        <Col>
                          <ValidatedInput validation={validation} field="successCountMin" type="number" maxLength={100} placeholder={t("Min")} disableValidationUI />
                        </Col>
                        <Col xs="auto" className="d-flex align-items-center text-muted">
                          <Label>{t("and")}</Label>
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} field="successCountMax" type="number" maxLength={100} placeholder={t("Max")} disableValidationUI />
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={3} className="d-flex align-items-end">
                      <Button type="submit" className="btn bg-primary me-0" disabled={loading.filter}>
                        {loading.filter ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                        {t("Admin.SearchLogs.Filters.Button.Apply")}
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default Filters;
