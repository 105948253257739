import React, {useCallback, useEffect} from "react";
import useSelectOptions, {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {AmazonMarketplaceType} from "Components/Common/AmazonMarketplaceInfos";
import {ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Row, UncontrolledDropdown} from "reactstrap";
import {useProfile} from "Components/Hooks/useProfile";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {getToday} from "helpers/utilities";
import {useAmazonMarketplace} from "Components/Hooks/useAmazonMarketplace";
import {useFormik} from "formik";
import {getStatistics} from "slices/thunks";
import {DashboardFilter} from "models/dashboard_filter";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";
import { getDemoModeInfo } from "helpers/local_storage";
import moment from "moment";

const TODAY = 1;
const YESTERDAY = 2;
const LAST_7_DAYS = 7;
const LAST_14_DAYS = 14;
const LAST_30_DAYS = 30;
const LAST_90_DAYS = 90;
const LAST_180_DAYS = 180;
const LAST_365_DAYS = 365;

const Section = () => {
  const {userProfile} = useProfile();
  const {t} = useTranslation();
  const {activeMarketplaces} = useAmazonMarketplace();
  const {multiSelectTranslations} = useSelectOptions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      filter: state.Dashboard.filter,
      statistics: state.Dashboard.statistics,
      loading: state.Dashboard.loading,
      commonLoading: state.Common.loading,
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      passiveUserStoreOptions: state.Common.passiveUserStoreOptions,
      layoutModeType: state.Layout.layoutModeType,
    }),
  );

  const {filter, statistics, activeUserStoreOptions, passiveUserStoreOptions, loading, commonLoading, layoutModeType} = useSelector(dashboardData);

  const storeOptions = [...activeUserStoreOptions, ...passiveUserStoreOptions];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userStoreIds: [],
      currencyCode: "USD",
      dateRange: 1,
    } as DashboardFilter,
    validationSchema: Yup.object({
      userStoreIds: Yup.array<String>(),
      currencyCode: Yup.string(),
      dateRange: Yup.number(),
    }),

    onSubmit: (values: DashboardFilter) => {
      let demoDate: Date | undefined = undefined
      const demo = getDemoModeInfo()
      if(demo && demo.active) {
        demoDate = moment(demo.date).tz(userProfile?.timezone!).toDate()
      } else {
        demoDate = undefined
      }
      getStatistics({
        currencyCode: values.currencyCode,
        dateRange: values.dateRange,
        userStoreIds: values.userStoreIds,
        demoDate
      },)(dispatch);
    },
  });

  const onLoad = useCallback(() => {
    let demoDate: Date | undefined = undefined
      const demo = getDemoModeInfo()
      if(demo && demo.active) {
        demoDate = moment(demo.date).tz(userProfile?.timezone!).toDate()
      } else {
        demoDate = undefined
      }
    getStatistics({demoDate})(dispatch);
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onStatisticsChange = useCallback(() => {
    validation.setFieldValue("userStoreIds", filter?.userStoreIds);
    validation.setFieldValue("currencyCode", filter?.currencyCode);
    validation.setFieldValue("dateRange", filter?.dateRange);
  }, [statistics]); // eslint-disable-line

  useEffect(() => {
    onStatisticsChange();
  }, [onStatisticsChange]);

  const getGreetingsTitle = () => {
    const hour = getToday()
      .toDate()
      .getHours();
    if (hour >= 0 && hour <= 6) {
      return t("Dashboard.Greetings.Night", {value: userProfile?.firstName});
    } else if (hour > 6 && hour <= 12) {
      return t("Dashboard.Greetings.Morning", {value: userProfile?.firstName});
    } else if (hour > 12 && hour <= 18) {
      return t("Dashboard.Greetings.Afternoon", {value: userProfile?.firstName});
    } else if (hour > 18 && hour <= 24) {
      return t("Dashboard.Greetings.Evening", {value: userProfile?.firstName});
    }
  };

  const getGreetingsDescription = useCallback(() => {
    switch (filter?.dateRange) {
      case TODAY:
        return t("Dashboard.Greetings.Description.Today");
      case YESTERDAY:
        return t("Dashboard.Greetings.Description.Yesterday");
      case LAST_7_DAYS:
        return t("Dashboard.Greetings.Description.Last7Days");
      case LAST_14_DAYS:
        return t("Dashboard.Greetings.Description.Last14Days");
      case LAST_30_DAYS:
        return t("Dashboard.Greetings.Description.Last30Days");
      case LAST_90_DAYS:
        return t("Dashboard.Greetings.Description.Last90Days");
      case LAST_180_DAYS:
        return t("Dashboard.Greetings.Description.Last180Days");
      case LAST_365_DAYS:
        return t("Dashboard.Greetings.Description.Last365Days");
      default:
        return t("Dashboard.Greetings.Description.Today");
    }
  }, [filter?.dateRange, t]);

  const currencyOptions = (): SelectOptionsType[] => {
    let options: SelectOptionsType[] = [];
    activeMarketplaces().forEach((info: AmazonMarketplaceType) => {
      options.push({value: info.currency, label: info.currency});
    });
    options.push({value: "TRY", label: "TRY"});
    return options;
  };

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">{getGreetingsTitle()}</h4>
              <p className="text-muted mb-0">{getGreetingsDescription()}</p>
            </div>
            <div className="mt-3 mt-lg-0">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="g-3 mb-0 align-items-center">
                  {!commonLoading.userStore && storeOptions && storeOptions.length === 0 ? (
                    <div className="col-sm-auto">
                      <Link to="/stores/connect" role="button" className="btn btn-soft-danger waves-effect w-100">
                        <i className="mdi mdi-connection me-1"></i>
                        {t("ConnectYourStore")}
                      </Link>
                    </div>
                  ) : (
                    <div className="col-sm-auto" style={{width: "250px"}}>
                      <ValidatedMultiSelect
                        validation={validation}
                        field={"userStoreIds"}
                        className={`w-100 ${layoutModeType}`}
                        options={storeOptions}
                        value={validation.values.userStoreIds}
                        busy={loading.list}
                        translations={{
                          ...multiSelectTranslations,
                          selectSomeItems: t("General.Select.SelectStore"),
                          allItemsAreSelected: t("General.Select.AllStoresSelected"),
                        }}
                        optionStyle="country"
                        submitOnChange
                      />
                    </div>
                  )}
                  <div className="col-sm-auto">
                    <ValidatedSelect validation={validation} field="currencyCode" options={currencyOptions()} value={validation.values.currencyCode} submitOnChange />
                  </div>
                  <div className="col-sm-auto">
                    <Row>
                      <Col className="pe-0">
                        <UncontrolledDropdown>
                          <DropdownToggle className="form-control user-input" color="white" style={{background: "var(--vz-secondary-bg)", borderColor: "var(--vz-border-color)"}} caret>
                            {validation.values.dateRange === TODAY
                              ? t("Today")
                              : validation.values.dateRange === YESTERDAY
                              ? t("Yesterday")
                              : validation.values.dateRange === LAST_7_DAYS
                              ? t("Last7Days")
                              : validation.values.dateRange === LAST_14_DAYS
                              ? t("Last14Days")
                              : validation.values.dateRange === LAST_30_DAYS
                              ? t("Last30Days")
                              : validation.values.dateRange === LAST_90_DAYS
                              ? t("Last90Days")
                              : validation.values.dateRange === LAST_180_DAYS
                              ? t("Last180Days")
                              : validation.values.dateRange === LAST_365_DAYS
                              ? t("Last365Days")
                              : ""}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", TODAY as number).then(() => validation.handleSubmit())}>{t("Today")}</DropdownItem>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", YESTERDAY as number).then(() => validation.handleSubmit())}>{t("Yesterday")}</DropdownItem>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", LAST_7_DAYS as number).then(() => validation.handleSubmit())}>{t("Last7Days")}</DropdownItem>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", LAST_14_DAYS as number).then(() => validation.handleSubmit())}>{t("Last14Days")}</DropdownItem>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", LAST_30_DAYS as number).then(() => validation.handleSubmit())}>{t("Last30Days")}</DropdownItem>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", LAST_90_DAYS as number).then(() => validation.handleSubmit())}>{t("Last90Days")}</DropdownItem>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", LAST_180_DAYS as number).then(() => validation.handleSubmit())}>{t("Last180Days")}</DropdownItem>
                            <DropdownItem onClick={() => validation.setFieldValue("dateRange", LAST_365_DAYS as number).then(() => validation.handleSubmit())}>{t("Last365Days")}</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                      <Col xs="auto">
                        <ButtonGroup>
                          <UncontrolledDropdown>
                            <DropdownToggle className="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn">
                              <i className="ri-pulse-line"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => navigate("/searches/new/bulk")}>
                                <i className="mdi mdi-chart-timeline-variant-shimmer text-muted fs-16 align-middle me-1"></i>
                                <span className="align-middle text-dark">{t("Dashboard.Widgets.Button.NewAnalyze")}</span>
                              </DropdownItem>
                              <DropdownItem onClick={() => navigate("/scan-and-save/new")}>
                                <i className="ri-bug-2-line text-muted fs-16 align-middle me-1"></i>
                                <span className="align-middle text-dark">{t("Dashboard.Widgets.Button.NewScan")}</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
