import {useTranslation} from "react-i18next";
import {Table as ReactTable} from "@tanstack/table-core";
import {Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, Label, Button} from "reactstrap";
import {PaginationState} from "@tanstack/react-table";
import Select from "react-select";
import Checkbox from "../Checkbox";
import exportExcelForTable from "../ExcelExport";
import ResponsivePagination from "react-responsive-pagination";
import DefaultUncontrolledTooltip from "../DefaultUncontrolledTooltip";
import {numberFormatter} from "helpers/utilities";

export const TablePagination = <D,>(props: {
  children: React.ReactNode;
  table: ReactTable<D>;
  totalDataLength: number;
  pagination: PaginationState;
  rowsCount: number;
  showColumnSelect?: boolean;
  showSizeSelect?: boolean;
  canExport?: boolean;
  showPagination?: boolean;
  hideTopPaginationStage?: boolean;
  hideBottomPaginationStage?: boolean;
  busy: boolean;
  renderSortingSelect?: () => JSX.Element;
  onPaginationChanged?: (paginationState: PaginationState) => void;
}) => {
  const pagingOptions = [5, 10, 25, 50].map((i) => ({
    value: i,
    label: `${i}`,
  }));
  const {getCanNextPage, getCanPreviousPage, setPageIndex, setPageSize, getAllLeafColumns} = props.table;
  const {t} = useTranslation();

  const selectedPagingOption = pagingOptions.find((p) => p.value === props.pagination.pageSize);

  return (
    <>
      {props.showPagination && !props.hideTopPaginationStage && (
        <>
          <Row className="mb-3 pb-3 d-flex align-items-end">
            <Col className="justify-content-start">
              <Row>
                {props.showSizeSelect && (
                  <Col xs="auto">
                    <Select
                      className="common-pagination pagination-result-limit-child"
                      value={selectedPagingOption}
                      onChange={(opt: any) => setPageSize(opt?.value || 10)}
                      options={pagingOptions}
                      menuPortalTarget={document.body}
                      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                    ></Select>
                  </Col>
                )}
                {props.renderSortingSelect && <Col xs="auto">{props.renderSortingSelect()}</Col>}
              </Row>
            </Col>
            <Col>
              {(getCanPreviousPage() || getCanNextPage()) && (
                <div className="mx-auto">
                  <ResponsivePagination
                    nextLabel=">"
                    previousLabel="<"
                    maxWidth={350}
                    activeItemClassName="active z-index-0"
                    total={Math.ceil(props.totalDataLength / props.pagination.pageSize)}
                    current={props.pagination.pageIndex + 1}
                    onPageChange={(page) => setPageIndex(page - 1)}
                  />
                </div>
              )}
            </Col>

            {props.canExport || props.showColumnSelect ? (
              <Col className="d-flex justify-content-end">
                <div className="d-flex flex-row">
                  {props.canExport && (
                    <div className="common-pagination pagination-result-limit-child">
                      <Button type="button" className="btn btn-success w-100" onClick={() => exportExcelForTable(props.table, "data")}>
                        <i className="ri-file-excel-line align-bottom me-1"></i> {t("Export")}
                      </Button>
                    </div>
                  )}
                  {props.showColumnSelect && (
                    <>
                      <DefaultUncontrolledTooltip target="columnSelectToggleButton">{t("Select Columns")}</DefaultUncontrolledTooltip>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-link p-0 ms-4 mb-n2" id="columnSelectToggleButton">
                          <i className="mdi mdi-table-headers-eye fs-2"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-md p-2">
                          <div>
                            {getAllLeafColumns().map((column) => (
                              <div className="d-flex justify-content-start" key={column.id}>
                                <div className="p-1">
                                  <Checkbox disabled={!column.getCanHide()} id={column.id} value={column.getIsVisible()} onChange={column.toggleVisibility} />
                                </div>
                                <div className="p-1">
                                  <Label htmlFor={column.id}>{typeof column.columnDef.header === "string" ? column.columnDef.header?.toString() : column.columnDef.id}</Label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </>
                  )}
                </div>
              </Col>
            ) : (
              <Col className="d-flex justify-content-md-end justify-content-center">
                <div
                  className={"text-muted " + (props.busy ? "placeholder" : "")}
                  dangerouslySetInnerHTML={{
                    __html: t("PaginationResultCountText", {
                      totalResultCount: numberFormatter(props.totalDataLength),
                      resultCount: numberFormatter(props.rowsCount),
                    }),
                  }}
                ></div>
              </Col>
            )}
          </Row>
        </>
      )}

      <Row className="d-flex">
        <Col>{props.children}</Col>
      </Row>

      {props.showPagination && !props.hideBottomPaginationStage && (
        <Row className="mt-3 pt-3 d-flex align-items-start" style={{position: "relative", zIndex: 3}}>
          <Col className="d-flex justify-content-start">
            {props.showSizeSelect && (
              <Select
                className="common-pagination pagination-result-limit-child"
                value={selectedPagingOption}
                onChange={(opt: any) => setPageSize(opt?.value || 10)}
                options={pagingOptions}
                menuPortalTarget={document.body}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
              ></Select>
            )}
          </Col>
          <Col className="d-flex">
            {(getCanPreviousPage() || getCanNextPage()) && (
              <div className="mx-auto">
                <ResponsivePagination
                  nextLabel=">"
                  maxWidth={350}
                  previousLabel="<"
                  activeItemClassName="active z-index-0"
                  total={Math.ceil(props.totalDataLength / props.pagination.pageSize)}
                  current={props.pagination.pageIndex + 1}
                  onPageChange={(page) => setPageIndex(page - 1)}
                />
              </div>
            )}
          </Col>
          <Col className="d-flex justify-content-end">
            <div
              className={"text-muted " + (props.busy ? "placeholder" : "")}
              dangerouslySetInnerHTML={{
                __html: t("PaginationResultCountText", {
                  totalResultCount: numberFormatter(props.totalDataLength),
                  resultCount: numberFormatter(props.rowsCount),
                }),
              }}
            ></div>
          </Col>
        </Row>
      )}
    </>
  );
};
