import {useMemo, useState} from "react";
import {Button, Col, Form, FormFeedback, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {saveUserPaymentMethod} from "slices/account/thunk";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/useProfile";
import Cleave from "cleave.js/react";
import countryList from "react-select-country-list";
import Restricted from "Components/Common/Restricted";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";

interface CreateUserPaymentMethodProps {
  reload: () => void;
}
const CreateUserPaymentMethod = (props: CreateUserPaymentMethodProps) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {userProfile} = useProfile();
  const countryOptions = useMemo(() => countryList().getData(), []);
  const dispatch: any = useDispatch();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const createUserPaymentMethodData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
    }),
  );

  const {loading} = useSelector(createUserPaymentMethodData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      cardName: "",
      cardNumber: "",
      expireDate: "",
      cvc: "",
      countryCode: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    validationSchema: Yup.object({
      cardName: Yup.string().required(t("Account.Subscription.Validation.CardName")),
      cardNumber: Yup.string().required(t("Account.Subscription.Validation.CardNumber")),
      expireDate: Yup.string().required(t("Account.Subscription.Validation.ExpiryDate")),
      cvc: Yup.string().required(t("Account.Subscription.Validation.CVC")),
      countryCode: Yup.string().required(t("Account.Subscription.Validation.Country")),
      addressLine1: Yup.string().required(t("Account.Subscription.Validation.AddressLine1")),
      addressLine2: Yup.string(),
      city: Yup.string().required(t("Account.Subscription.Validation.City")),
      state: Yup.string().required(t("Account.Subscription.Validation.State")),
      zipCode: Yup.string().required(t("Account.Subscription.Validation.ZipCode")),
    }),
    onSubmit: async (values) => {
      const result = await saveUserPaymentMethod({
        email: userProfile?.email!,
        name: userProfile?.firstName + " " + userProfile?.lastName,
        cardName: values.cardName,
        cardNumber: values.cardNumber,
        expireMonth: parseInt(values.expireDate.split("/")[0]),
        expireYear: parseInt(values.expireDate.split("/")[1]),
        cvc: values.cvc,
        countryCode: values.countryCode,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
      })(dispatch);
      if (result) {
        props.reload();
        toggle();
        validation.resetForm();
      }
    },
  });

  return (
    <Restricted require="account.subscription" update>
      <Button color="primary" className="btn-sm" onClick={toggle}>
        + {t("Account.Subscription.Button.AddNew")}
      </Button>
      <Modal id="createPaymentMethodModal" isOpen={isOpen} toggle={toggle} fade={true} centered size="lg">
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Account.Subscription.AddPaymentMethod")}
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            className="needs-validation"
            action="#"
          >
            <Row>
              <Col xs={12} md={6}>
                <h5 className="mb-4">{t("Account.Subscription.BillingAddress")}</h5>
                <div className="clearfix "></div>
                <Row className="mb-3 p-0">
                  <Col>
                    <Label htmlFor="countryCode" className="form-label">
                      {t("Account.Subscription.Country")}
                      <span className="text-danger">*</span>
                    </Label>
                    <ValidatedSelect
                      className="w-100"
                      options={countryOptions}
                      placeholder={t("Account.Subscription.CountryPlaceholder")}
                      validation={validation}
                      field="countryCode"
                      value={validation.values.countryCode}
                      errorStyle="container"
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="addressLine1" className="form-label">
                      {t("Account.Subscription.AddressLine1")}
                      <span className="text-danger">*</span>
                    </Label>
                    <ValidatedInput validation={validation} field="addressLine1" maxLength={60} placeholder={t("Account.Subscription.AddressLine1Placeholder")} upperFirst disableValidationUI />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="addressLine2" className="form-label">
                      {t("Account.Subscription.AddressLine2")}
                    </Label>
                    <ValidatedInput validation={validation} field="addressLine2" maxLength={60} placeholder={t("Account.Subscription.AddressLine2Placeholder")} upperFirst disableValidationUI />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="city" className="form-label">
                      {t("Account.Subscription.City")}
                      <span className="text-danger">*</span>
                    </Label>
                    <ValidatedInput validation={validation} field="city" maxLength={60} placeholder={t("Account.Subscription.CityPlaceholder")} upperFirst disableValidationUI />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label htmlFor="state" className="form-label">
                      {t("Account.Subscription.State")}
                      <span className="text-danger">*</span>
                    </Label>
                    <ValidatedInput validation={validation} field="state" maxLength={20} placeholder={t("Account.Subscription.StatePlaceholder")} upperFirst disableValidationUI />
                  </Col>
                  <Col>
                    <Label htmlFor="zipCode" className="form-label">
                      {t("Account.Subscription.ZipCode")}
                      <span className="text-danger">*</span>
                    </Label>
                    <Cleave
                      name="zipCode"
                      className={"form-control " + (validation.touched.zipCode && validation.errors.zipCode && "is-invalid")}
                      placeholder={t("Account.Subscription.ZipCodePlaceholder")}
                      value={validation.values.zipCode}
                      onChange={validation.handleChange}
                      options={{
                        blocks: [10],
                        numericOnly: false,
                        uppercase: true,
                      }}
                    />
                    {validation.touched.zipCode && validation.errors.zipCode ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.zipCode}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </Col>

              <Col className="mt-3 mt-md-0">
                <h5 className="mb-4">{t("Account.Subscription.CreditCardDetails")}</h5>
                <div className="clearfix "></div>
                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="cardName" className="form-label p-0">
                      {t("Account.Subscription.CardHolder")}
                      <span className="text-danger">*</span>
                    </Label>
                    <ValidatedInput validation={validation} field="cardName" placeholder={t("Account.Subscription.CardHolderPlaceholder")} disableValidationUI />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Label htmlFor="cardNumber" className="form-label p-0">
                      {t("Account.Subscription.CardNumber")}
                      <span className="text-danger">*</span>
                    </Label>
                    <Cleave
                      name="cardNumber"
                      className={"form-control " + (validation.touched.cardNumber && validation.errors.cardNumber && "is-invalid")}
                      placeholder="**** **** **** ****"
                      value={validation.values.cardNumber}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      options={{
                        creditCard: true,
                        creditCardStrictMode: true,
                      }}
                    />
                    {validation.touched.cardNumber && validation.errors.cardNumber ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.cardNumber}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col md={6}>
                    <Label htmlFor="expireDate" className="form-label">
                      {t("Account.Subscription.ExpireDate")}
                      <span className="text-danger">*</span>
                    </Label>
                    <Cleave
                      name="expireDate"
                      className={"form-control " + (validation.touched.expireDate && validation.errors.expireDate && "is-invalid")}
                      placeholder={t("Account.Subscription.ExpireDatePlaceholder")}
                      value={validation.values.expireDate}
                      onChange={validation.handleChange}
                      options={{
                        date: true,
                        datePattern: ["m", "y"],
                      }}
                    />
                    {validation.errors.expireDate ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.expireDate}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col md={6} className="mt-3 mt-md-0">
                    <Label htmlFor="cvc" className="form-label">
                      {t("Account.Subscription.CVC")}
                      <span className="text-danger">*</span>
                    </Label>
                    <Cleave
                      name="cvc"
                      className={"form-control " + (validation.touched.cvc && validation.errors.cvc && "is-invalid")}
                      placeholder="CVC"
                      value={validation.values.cvc}
                      onChange={validation.handleChange}
                      options={{
                        blocks: [4],
                        numericOnly: true,
                      }}
                    />
                    {validation.errors.cvc ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.cvc}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              type="button"
              className="btn btn-light"
              onClick={() => {
                validation.resetForm();
                setIsOpen(false);
                //props.onIsModal(false);
              }}
            >
              {t("General.Close")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={loading.paymentMethod}
              onClick={() => {
                if (validation.isValid) {
                  validation.handleSubmit();
                } else {
                  Object.keys(validation.errors).forEach((key) => {
                    validation.setFieldTouched(key, true);
                  });
                }
              }}
            >
              {loading.paymentMethod && <Spinner size="sm" className="me-2"></Spinner>}
              {t("Account.Subscription.Button.Save")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Restricted>
  );
};

export default CreateUserPaymentMethod;
