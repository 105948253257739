import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {UserShipmentItem} from "models/user_shipment_item";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {dayDifference, getToday} from "helpers/utilities";
import {useImmer} from "use-immer";
import {PagerQuery} from "helpers/types";
import DisplayPrice from "Components/Common/DisplayPrice";
import RenderNumber from "Components/Common/RenderNumber";
import errorImage from "assets/images/svg/product.svg";
import {Link} from "react-router-dom";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";

const ProductList = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);
  const [tableData, setTableData] = useState<UserShipmentItem[]>([]);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();
  const nosourceDialogRef = useRef<DialogRef>(null);
  const [query, updateQuery] = useImmer<PagerQuery>({
    page: 1,
    pageSize: 25,
  });

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {currentUserShipment} = useSelector(shipmentsData);

  useEffect(() => {
    if (currentUserShipment) {
      let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setDestinationMarketplace(destination);
      setTableData(currentUserShipment.userShipmentItems);
    }
  }, [currentUserShipment]); // eslint-disable-line

  const columns = useMemo<ColumnDef<UserShipmentItem, any>[]>(
    () => [
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Product"),
        size: 100,
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const imagePath = `https://m.media-amazon.com/images/I/${row.userInventory.imageSmall}`;
          return (
            <Row className="align-items-center" style={{minWidth: "250px"}}>
              <Col xs={"auto"}>
                <div className="avatar-sm rounded-circle flex-shrink-0 overflow-hidden">
                  <img
                    className="rounded w-100"
                    src={imagePath}
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = errorImage;
                    }}
                    alt=""
                  />
                </div>
              </Col>
              <Col xs={"auto"}>
                <div className="d-flex align-items-center">
                  {row.userSearchId !== undefined && row.userSearchId !== null ? (
                    <>
                      <CopyAsinWidget
                        asin={row.userInventory.asin}
                        index={row.userInventory.asin}
                        asLink={true}
                        customLink={`/searches/search-results/${row.userSearchId}?asin=${row.userInventory.asin}`}
                      />
                      <Link to={`https://www.amazon.${destinationMarketplace?.domain}/dp/${row.userInventory.asin}?th=1&psc=1`} target="_blank" className="ms-1">
                        <i className="bx bxl-amazon label-icon align-middle fs-18"></i>
                      </Link>
                    </>
                  ) : (
                    <>
                      <span
                        className="link-secondary link-offset-2 text-decoration-underline cursor-pointer"
                        onMouseDown={(e) => {
                          const LEFT_BUTTON_CLICK = 0;
                          const MIDDLE_BUTTON_CLICK = 1;
                          if (e.button === MIDDLE_BUTTON_CLICK || e.button === LEFT_BUTTON_CLICK) {
                            e.preventDefault();
                            nosourceDialogRef.current?.show();
                          }
                        }}
                        onTouchEnd={() => nosourceDialogRef.current?.show()}
                      >
                        {row.userInventory.asin}
                        <i className="ri-arrow-right-up-line"></i>
                      </span>
                      <Link to={`https://www.amazon.${destinationMarketplace?.domain}/dp/${row.userInventory.asin}?th=1&psc=1`} target="_blank" className="ms-1">
                        <i className="bx bxl-amazon label-icon align-middle fs-18"></i>
                      </Link>
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <CopyAsinWidget asin={row.userInventory.sku} index={row.userInventory.sku} />
                </div>
              </Col>
            </Row>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Total"),
        size: 50,
        accessorFn: (row) => row.totalItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return <>{row.totalItems}</>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Sold"),
        size: 50,
        accessorFn: (row) => row.soldItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return <>{row.soldItems}</>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Left"),
        size: 50,
        accessorFn: (row) => row.totalItems - row.soldItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return <>{row.totalItems - row.soldItems}</>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ActualUnitCost"),
        size: 120,
        accessorFn: (row) => row.actualTotalCostPerItemTarget,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return <>{row.actualTotalCostPerItemTarget && `${row.actualTotalCostPerItemTarget?.toFixed(2)} ${destinationMarketplace?.currency}`}</>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.InitialBuyBoxPriceAndCurrentBuyBoxPrice"),
        size: 120,
        accessorFn: (row) => row.initialBuyBoxPrice,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.initialBuyBoxPrice} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.currentBuyBoxPrice} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ExpectedSellPriceAndAvgSellPrice"),
        size: 120,
        accessorFn: (row) => row.expectedSellPrice,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedSellPrice} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.actualSellPrice} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ExpectedProfitAndAvgProfit"),
        size: 120,
        accessorFn: (row) => row.expectedProfitPerItem * row.soldItems,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const expectedProfit = row.soldItems === 0 ? row.expectedProfitPerItem : row.expectedProfitPerItem!;
          const avgProfit = row.soldItems === 0 ? row.expectedProfitPerItem : row.actualProfitPerItem!;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={expectedProfit} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={avgProfit} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.ExpectedRoiMarginAndActualRoiMargin"),
        size: 120,
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted d-flex">
                <RenderNumber value={row.expectedROI} decimals={0} prefix={`%`} suffix="" />
                <i className="mdi mdi-slash-forward"></i>
                <RenderNumber value={row.expectedMargin} decimals={0} prefix={`%`} suffix="" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap d-flex">
                <RenderNumber value={row.actualROI} decimals={0} prefix={`%`} suffix="" />
                <i className="mdi mdi-slash-forward"></i>
                <RenderNumber value={row.actualMargin} decimals={0} prefix={"%"} suffix="" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.InitialFbaSellerAndCurrentFbaSeller"),
        size: 120,
        accessorFn: (row) => row.initialFBASellerCount,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <RenderNumber value={row.initialFBASellerCount} prefix={""} suffix="" />
              </span>
              <h5 className="fs-14 my-1">
                <RenderNumber value={row.currentFBASellerCount} prefix={""} suffix="" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.InitialBsrAndCurrentBsr"),
        size: 120,
        accessorFn: (row) => row.initialBSR,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <i className="text-muted mdi mdi-trophy-variant-outline align-middle me-1 fs-16"></i>
                <RenderNumber value={row.initialBSR} prefix={""} suffix="" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <i className="text-primary mdi mdi-trophy-variant-outline align-middle me-1 fs-16"></i>
                <RenderNumber value={row.currentBSR} prefix={""} suffix="" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.Speed"),
        size: 100,
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const {currentUserShipment} = useSelector(shipmentsData); // eslint-disable-line
          const [speed, setSpeed] = useState<number>(0); // eslint-disable-line

          // eslint-disable-next-line
          useEffect(() => {
            const completedDate = row.completedDate ? new Date(row.completedDate) : getToday().toDate();
            const shipDate = currentUserShipment?.shipDate ? new Date(currentUserShipment?.shipDate) : getToday().toDate();
            let dayElapsed = dayDifference(completedDate, shipDate);
            setSpeed(row.soldItems / dayElapsed);
          }, [currentUserShipment]); // eslint-disable-line

          return (
            <h5 className="fs-14 my-1 text-nowrap">
              <i className="text-info mdi mdi-rocket-launch me-1 fs-14"></i>
              <span className="text-nowrap">{t("Shipments.ShippedOrCompleted.ProductList.Label.SpeedPerItem", {value: speed.toFixed(2)})}</span>
            </h5>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProductList.TableColumn.SuccessRate"),
        size: 150,
        accessorFn: (row) => SuccessRateCalculator(row.actualProfitPerItem, row.expectedProfitPerItem),
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: UserShipmentItem = cellProps.row.original;
          const rate = SuccessRateCalculator(row.actualProfitPerItem, row.expectedProfitPerItem);

          if (rate === 0) {
            return <span className="text-muted">{t("NA")}</span>;
          } else
            return (
              <h5 className="my-1 text-nowrap align-items-center d-flex">
                <i className="text-secondary mdi mdi-trophy me-1 fs-16"></i>
                <RenderNumber value={rate} decimals={2} prefix="%" suffix=" " />
              </h5>
            );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, destinationMarketplace],
  );

  const handlePaginationChange = useCallback(() => {
    const start = (query.page - 1) * query.pageSize;
    const end = start + query.pageSize;
    setTableData(currentUserShipment?.userShipmentItems?.slice(start, end));
  }, [query]); // eslint-disable-line

  useEffect(() => {
    handlePaginationChange();
  }, [handlePaginationChange]);

  return (
    <Card>
      <CardHeader>
        <h5 className="mb-0 border-0 align-items-center d-flex">
          <i className="bx bx-purchase-tag-alt me-1"></i>
          {t("Shipments.ShippedOrCompleted.ProductList.Title")}
        </h5>
      </CardHeader>
      <CardBody>
        <DataTable
          className="align-middle table-nowrap table table-hover"
          trClass="text-start"
          tdClass="text-start"
          ref={tableRef}
          busy={false}
          columns={columns}
          data={tableData}
          totalDataLength={currentUserShipment?.userShipmentItems?.length}
          pagination={{
            pageIndex: query.page - 1,
            pageSize: query.pageSize,
          }}
          onPaginationChanged={(pagination) =>
            updateQuery((q) => {
              q.page = pagination.pageIndex + 1;
              q.pageSize = pagination.pageSize;
            })
          }
          hovered
        />
      </CardBody>

      <Dialog
        ref={nosourceDialogRef}
        color="info"
        buttons={["ok"]}
        busy={false}
        iconClass="mdi mdi-refresh"
        message={t("Shipments.ShipmentItems.Dialog.NoSource.Description")}
        title={t("Shipments.ShipmentItems.Dialog.NoSource.Title")}
        onButtonClick={() => {
          nosourceDialogRef.current?.hide();
        }}
      />
    </Card>
  );
};

export default ProductList;
