import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";

import {RootState} from "slices";
import {logoutUser, resetDashboardState, resetLoginState} from "../../slices/thunks";
import {useSelector, useDispatch} from "react-redux";
import {createSelector} from "reselect";
import {resetExistSubscription} from "slices/account/thunk";
import {resetSettings} from "slices/settings/thunk";
import {resetCommonState} from "slices/common/thunk";
import withRouter from "../../Components/Common/withRouter";
import { BroadcastChannels } from "helpers/broadcast_channels";

const Logout = () => {
  const dispatch: any = useDispatch();
  const authChannel = new BroadcastChannel(BroadcastChannels.AUTH_CHANNEL);

  const logoutData = createSelector(
    (state: RootState) => state,
    (status) => ({
      status: status.Login.status,
    }),
  );

  // Inside your component
  const {status} = useSelector(logoutData);

  useEffect(() => {
    dispatch(logoutUser());
    dispatch(resetExistSubscription());
    dispatch(resetSettings());
    dispatch(resetDashboardState());
    dispatch(resetCommonState());
    dispatch(resetLoginState());
    authChannel.postMessage(null);
  }, [dispatch]); // eslint-disable-line

  if (status) {
    return <Navigate to="/account/login" />;
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
