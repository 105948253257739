import {SearchLogQuery} from "api/query";
import {apiError, loading, SearchLogStats, setSearchProductList, setStats} from "./reducer";
import {ResponseModel} from "models/response_model";
import {postGetSearchLogs, postResetSearchProductItem} from "services/admin_service";
import {generateError} from "helpers/utilities";
import { PagedList } from "helpers/types";
import { UserSearchProduct } from "models/user_search_product";
import { ResetSearchProductItemCommand } from "api/command";

export const getSearchLogs = (query: SearchLogQuery) => async (dispatch: any) => {
  const loadingType = query.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetSearchLogs(query);
    const pagedResult: PagedList<UserSearchProduct> = result.data.pagedList;
    const stats: SearchLogStats = result.data.stats;
    dispatch(setSearchProductList(pagedResult));
    dispatch(setStats(stats));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading([loadingType, false]));
  }
};


export const resetSearchProductItem = (command: ResetSearchProductItemCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["reset", true]));
    await postResetSearchProductItem(command);
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["reset", false]));
  }
};