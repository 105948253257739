import flagus from '../assets/images/flags/us.svg';
import flagturkey from '../assets/images/flags/tr.svg';

const languages = {
    en: {
        label: 'English',
        flag: flagus,
        locale: 'en-US',
    },
    tr: {
        label: 'Türkçe',
        flag: flagturkey,
        locale: 'tr-TR',
    },
};

export default languages;
