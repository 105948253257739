import { UserSearchProduct } from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

interface StatusReasonsProps {
  row: UserSearchProduct;
}
const StatusReasons = ({row}: StatusReasonsProps) => {
  const {t} = useTranslation();
  return (
    <Card className="border card-animate">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="card-title mb-0">{t("Admin.SearchLogs.Box.Results.StatusReasons.Title")}</h6>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs={12}>
            {row.statusReasons || <span className="text-muted">{t("NA")}</span>}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default StatusReasons;
