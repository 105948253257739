import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, CardHeader, Container, Form, FormFeedback, Input, Label, ListGroup, ListGroupItem, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {changePassword} from "slices/account/thunk";
import {RootState} from "slices";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import "react-phone-input-2/lib/high-res.css";
import * as Yup from "yup";

const ChangePassword = () => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const [oldPasswordShow, setOldPasswordShow] = useState<boolean>(false);
  const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);
  const paymentHistoryPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
    }),
  );

  // Inside your component
  const {loading} = useSelector(paymentHistoryPageData);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required(t("Account.ChangePassword.Validation.OldPassword")),
      password: Yup.string()
        .required(t("Account.ChangePassword.Validation.Password"))
        .notOneOf([Yup.ref("oldPassword"), null], t("Account.ChangePassword.Validation.PasswordDifferent"))
        .min(8, t("Account.ChangePassword.Validation.PasswordLength"))
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, t("Account.ChangePassword.Validation.PasswordPattern")),
      confirmPassword: Yup.string()
        .required(t("Account.ChangePassword.Validation.ConfirmPassword"))
        .min(8, " ")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, " ")
        .oneOf([Yup.ref("password"), ""], t("Account.ChangePassword.Validation.PasswordMatch")),
    }),
    onSubmit: (values) => {
      const changePromise = changePassword(values.oldPassword, values.password)(dispatch);

      changePromise.then((success) => {
        if (success) {
          validation.resetForm();
        }
      });
    },
  });

  document.title = t("PageTitles.ChangePassword");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/change-password",
    },
    {
      label: t("Account.ChangePassword.Title"),
      url: "",
    },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
        <ProfileLayout hasAnyChanges={validation.dirty} navigateTo="">
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 align-items-center d-flex">
                <i className="mdi mdi-lock-outline fs-18 me-1"></i>
                {t("Account.ChangePassword.Title")}
              </h4>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                className="needs-validation"
                action="#"
              >
                <div className="mb-3">
                  <Label htmlFor="password" className="form-label">
                    {t("Account.ChangePassword.OldPassword")} <span className="text-danger">*</span>
                  </Label>
                  <div className="position-relative auth-pass-inputgroup">
                    <Input
                      name="oldPassword"
                      type={oldPasswordShow ? "text" : "password"}
                      placeholder={t("Account.ChangePassword.OldPasswordPlaceholder")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.oldPassword || ""}
                      invalid={validation.touched.oldPassword && validation.errors.oldPassword ? true : false}
                    />
                    {validation.touched.oldPassword && validation.errors.oldPassword ? (
                      <FormFeedback type="invalid">
                        <div>{t(validation.errors.oldPassword)}</div>
                      </FormFeedback>
                    ) : null}
                    <Button
                      color="link"
                      onClick={() => setOldPasswordShow(!oldPasswordShow)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                      type="button"
                      id="password-addon"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </Button>
                  </div>
                </div>

                <div className="mb-3">
                  <Label htmlFor="password" className="form-label">
                    {t("Account.ChangePassword.Password")} <span className="text-danger">*</span>
                  </Label>
                  <div className="position-relative auth-pass-inputgroup">
                    <Input
                      name="password"
                      type={newPasswordShow ? "text" : "password"}
                      placeholder={t("Account.ChangePassword.PasswordPlaceholder")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={validation.touched.password && validation.errors.password ? true : false}
                    />
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">
                        <div>{t(validation.errors.password)}</div>
                      </FormFeedback>
                    ) : null}
                    <Button
                      color="link"
                      onClick={() => setNewPasswordShow(!newPasswordShow)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                      type="button"
                      id="password-addon"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </Button>
                  </div>
                </div>

                <div className="mb-3">
                  <Label htmlFor="confirmPassword" className="form-label">
                    {t("Account.ChangePassword.ConfirmPassword")} <span className="text-danger">*</span>
                  </Label>
                  <div className="position-relative auth-pass-inputgroup">
                    <Input
                      name="confirmPassword"
                      type={newPasswordShow ? "text" : "password"}
                      placeholder={t("Account.ChangePassword.ConfirmPasswordPlaceholder")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmPassword || ""}
                      invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                    />
                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                      <FormFeedback type="invalid">
                        <div>{t(validation.errors.confirmPassword)}</div>
                      </FormFeedback>
                    ) : null}
                    <Button
                      color="link"
                      onClick={() => setNewPasswordShow(!newPasswordShow)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                      type="button"
                      id="password-addon"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </Button>
                  </div>
                </div>

                <div className="mb-3">
                  <Label className="form-label">{t("Account.ChangePassword.PasswordRequirements")}:</Label>
                  <ListGroup>
                    <ListGroupItem className="border-0 py-1 text-muted">
                      <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.ChangePassword.PasswordRequirementsList.1")}
                    </ListGroupItem>
                    <ListGroupItem className="border-0 py-1 text-muted">
                      <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.ChangePassword.PasswordRequirementsList.2")}
                    </ListGroupItem>
                    <ListGroupItem className="border-0 py-1 text-muted">
                      <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.ChangePassword.PasswordRequirementsList.3")}
                    </ListGroupItem>
                    <ListGroupItem className="border-0 py-1 text-muted">
                      <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.ChangePassword.PasswordRequirementsList.4")}
                    </ListGroupItem>
                    <ListGroupItem className="border-0 py-1 text-muted">
                      <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.ChangePassword.PasswordRequirementsList.5")}
                    </ListGroupItem>
                  </ListGroup>
                </div>

                <div className="hstack gap-2 justify-content-start">
                  <Button color="primary" type="submit" disabled={loading.update}>
                    {loading.update ? <Spinner size="sm" className="me-2"></Spinner> : null}
                    {t("Account.ChangePassword.Button.SaveChanges")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </ProfileLayout>
      </Container>
    </div>
  );
};

export default ChangePassword;
