import React from 'react';

interface HighlightProps {
    text: string;
    highlight: string;
}
const Highlight = ({ text, highlight }: HighlightProps) => {
  if (!highlight.trim()) {
    return <span>{text}</span>; // Boş bir vurgulama arandığında metni olduğu gibi döndür
  }

  const regex = new RegExp(`(${highlight})`, 'gi'); // Aranan metni bulmak için regex
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className="text-warning-emphasis bg-warning-subtle">{part}</span>
        ) : (
          part
        )
      )}
    </span>
  );
};

export default Highlight;
