import {UpdateInventoryCommand} from "api/command";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {useFormik} from "formik";
import {formatBytes, getToday} from "helpers/utilities";
import {UserStore} from "models/user_stores";
import {CSSProperties, RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Card, Form, Spinner} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {downloadInventory, updateInventory} from "slices/inventory/thunk";
import {read, utils} from "xlsx";
import {UploadUserInventory} from "models/user_inventory";
import {InventoryListRef} from "..";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import Dropzone from "react-dropzone";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";

interface UpdateInventoryProps {
  isOpen: boolean;
  listRef: RefObject<InventoryListRef>;
}
const UpdateInventory = (props: UpdateInventoryProps) => {
  const {t} = useTranslation();
  const [storeSelectOptions, setStoreSelectOptions] = useState<SelectOptionsType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const amazonMarketplaceInfos = AmazonMarketplaceInfos();

  const inventoryData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Inventory.loading,
      commonUserStore: state.Common.userStores,
    }),
  );
  const {loading, commonUserStore} = useSelector(inventoryData);

  useEffect(() => {
    prepareOptions();
  }, [commonUserStore]); //eslint-disable-line

  const prepareOptions = async () => {
    const stores = commonUserStore.filter((store) => store.marketplace !== "US" && store.status);
    const storeOptions = stores.map((store: UserStore) => {
      return {
        value: store.userStoreId,
        label: amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)?.countryName + " - " + store.name,
      };
    });
    setStoreSelectOptions(storeOptions);
  };

  const validation = useFormik({
    initialValues: {
      storeId: "",
      inventories: [],
    } as any,
    validationSchema: Yup.object({
      storeId: Yup.string().required(t("Inventory.Dialog.UpdateInventory.Validation.StoreRequired")),
      inventories: Yup.array<UploadUserInventory>()
        .required(t("Inventory.Dialog.UpdateInventory.Validation.UploadFile"))
        .min(1, t("Inventory.Dialog.UpdateInventory.Validation.UploadFile")),
    }),
    onSubmit: async (values: UpdateInventoryCommand) => {
      const payload: UpdateInventoryCommand = {
        storeId: values.storeId,
        inventories: values.inventories,
      };
      if (values.inventories.length > 1) {
        await updateInventory(payload)(dispatch);
        props.listRef.current?.reload();
        toggle();
      }
    },
  });
  const disabledStyle: CSSProperties = {
    pointerEvents: "none",
    opacity: 0.5,
  };
  const toggle = () => {
    validation.resetForm();
    setSelectedFile(undefined);
    navigate("/inventory");
  };

  function handleAcceptedFile(file: File) {
    if (file instanceof Blob) {
      // Check if the file is indeed a Blob (or File)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          const data = e.target.result;
          const workbook = read(data, {type: "binary"});
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          // Predefined headers
          const headers = ["ASIN", "Cost", "CostCurrency"];
          // Extract the first row (headers) from the Excel file and type it as string[]
          const sheetData: any[][] = utils.sheet_to_json(worksheet, {header: 1});
          const excelHeaders: string[] = sheetData[0] as string[];

          const ASIN = excelHeaders.indexOf("ASIN"); //
          const COST = excelHeaders.indexOf("Cost"); //
          const COST_CURRENCY = excelHeaders.indexOf("CostCurrency"); //

          const headersMatch = headers.every((header) => excelHeaders.includes(header));
          const excelData = utils.sheet_to_json(worksheet, {header: 1});

          const list: any = excelData
            .slice(1)
            .filter((row: any) => row[ASIN] || row[COST] || row[COST_CURRENCY]) // Clear empty lines
            .map((row: any) => {
              return {
                asin: row[ASIN],
                cost: row[COST],
                costCurrency: row[COST_CURRENCY],
              };
            });

          const isValid = list.every((item: any) => {
            return typeof item.asin === "string" && (typeof item.cost === "number" || typeof item.cost === "undefined") && typeof item.costCurrency === "string";
          });

          if (isValid && headersMatch) {
            validation.setFieldValue("inventories", list);
            setSelectedFile(file);
          } else {
            validation.setFieldError("inventories", t("Inventory.Dialog.UpdateInventory.Validation.InvalidFile"));
            validation.setFieldTouched("inventories", true, false);
          }
        }
      };

      // Trigger the file read process
      reader.readAsBinaryString(file);
    } else {
      validation.setFieldError("inventories", t("Inventory.Dialog.UpdateInventory.Validation.InvalidFile"));
      setSelectedFile(undefined);
    }
    return true;
  }

  const getFileName = () => {
    if (validation.values.storeId) {
      const storeName = commonUserStore.find((store) => store.userStoreId === validation.values.storeId)?.name;
      return `${storeName}-${getToday().format("D MMM YYYY HH:mm")}`;
    }
    return getToday().toString();
  };

  return (
    <>
      <Modal id="showNewSearchModal" className="modal-md" isOpen={props.isOpen} toggle={toggle} centered={true}>
        <Form onSubmit={validation.handleSubmit}>
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {t("Inventory.Dialog.UpdateInventory.Title")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <p className="text-muted">{t("Inventory.Dialog.UpdateInventory.Description")}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="acitivity-timeline acitivity-main mt-2">
                  <div className="acitivity-item d-flex pb-5">
                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                      <div className="avatar-title bg-warning-subtle text-warning rounded-circle">
                        <i className="bx bx-store"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-2 lh-base">{t("Inventory.Dialog.UpdateInventory.SelectYourStore")}</h6>
                      <div className="lh-base">
                        <ValidatedSelect
                          className="w-100"
                          options={storeSelectOptions}
                          validation={validation}
                          field="storeId"
                          value={validation.values.storeId}
                          errorStyle="container"
                          optionStyle="country"
                          valueStyle="country"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="acitivity-item d-flex pb-5" style={validation.values.storeId ? {} : disabledStyle}>
                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                      <div className="avatar-title bg-secondary-subtle text-secondary rounded-circle">
                        <i className="ri-download-2-line"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-2 lh-base">{t("Inventory.Dialog.UpdateInventory.DownloadCurrentInventoryFile")}</h6>
                      <div className="lh-base">
                        <Button
                          type="button"
                          className="btn btn-soft-info waves-effect waves-light"
                          disabled={loading.download}
                          onClick={async () => {
                            await downloadInventory({storeId: validation.values.storeId}, getFileName())(dispatch);
                          }}
                        >
                          {loading.download ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-download-2-line align-bottom me-1"></i>}
                          {t("Inventory.Dialog.UpdateInventory.DownloadInventory")}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="acitivity-item d-flex" style={validation.values.storeId ? {} : disabledStyle}>
                    <div className="flex-shrink-0 avatar-sm acitivity-avatar">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle">
                        <i className="ri-upload-cloud-2-fill"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-2 lh-base">{t("Inventory.Dialog.UpdateInventory.UploadYourUpdatedInventoryFile")}</h6>
                      <Dropzone
                        maxFiles={1}
                        onDrop={(acceptedFiles: any) => {
                          handleAcceptedFile(acceptedFiles[0]); // Handle the first file only
                        }}
                        accept={{
                          "text/csv": [".csv"],
                          "application/vnd.ms-excel": [".xls"],
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                        }}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div className={"dz-clickable"}>
                            <div className="dz-message needsclick" {...getRootProps()}>
                              <div className="alert alert-light d-flex align-items-center mb-0">
                                <div className="flex-shrink-0">
                                  <i className="ri-upload-cloud-2-fill text-success align-bottom display-6"></i>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-muted mb-0">{t("Inventory.Dialog.UpdateInventory.UploaderDescription")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="list-unstyled mb-0" id="file-previews">
                        {selectedFile && (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  <Link to="#" className="text-muted font-weight-bold">
                                    {selectedFile?.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{formatBytes(selectedFile?.size)}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )}
                      </div>
                      {validation.values.storeId && validation.touched.inventories && validation.errors.inventories && <p className="mt-2 text-danger">{validation.errors.inventories.toString()}</p>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end mt-3">
              <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.update}>
                {t("Inventory.Dialog.UpdateInventory.CloseButton")}
              </Button>
              <Button type="submit" className="btn btn-success" disabled={loading.update}>
                {loading.update ? <Spinner size="sm" className="me-2"></Spinner> : <i className=" ri-save-line label-icon align-middle fs-16 me-2"></i>}
                {t("Inventory.Dialog.UpdateInventory.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateInventory;
