import {Slide, toast} from "react-toastify";
import {Col, Row} from "reactstrap";
import i18n from "i18n";
import { NavigateFunction } from "react-router-dom";

const ANALYZE_TOAST_ID = "favorite";
export const renderShowAnalyzeToast = (message: string, link: string, navigate:NavigateFunction) => {
  if (!toast.isActive(ANALYZE_TOAST_ID)) {
    toast(<ToastShowAnalyzeResult message={message} link={link} navigate={navigate} />, {
      toastId: ANALYZE_TOAST_ID,
      position: "top-center",
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      className: "bg-success text-white",
      transition: Slide,
    });
  } else {
    toast.update(ANALYZE_TOAST_ID, {
      render: <ToastShowAnalyzeResult message={message} link={link} navigate={navigate} />,
      position: "top-center",
      type: "success",
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      transition: Slide,
    });
  }
};

export type ToastShowAnalyzeResultType = "add" | "remove";
interface ToastShowAnalyzeResultProps {
  message: string;
  link: string;
	navigate:NavigateFunction
}
const ToastShowAnalyzeResult = (props: ToastShowAnalyzeResultProps) => {

  return (
    <>
      <Row>
        <Col>{i18n.t(props.message)}</Col>
        <Col xs="auto">
          <span
            role="button"
            className="text-white link-offset-2 text-decoration-underline hand"
            onClick={() => {
              toast.dismiss();
							props.navigate(props.link,{replace:false})
            }}
          >
            {i18n.t("Show")}
          </span>
        </Col>
      </Row>
    </>
  );
};

export default ToastShowAnalyzeResult;
