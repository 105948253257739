import React, {useState} from "react";
import {Popover, PopoverBody} from "reactstrap";

interface ImagePopupProps {
  identifier: string;
  imageSmall: string;
  imageBig: string;
  className?: string;
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const ImagePopup = (props: ImagePopupProps) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div>
      {/* Small image */}
      <div
        id={props.identifier}
        style={{
          position: "relative",
          display: "inline-block",
          width: "40px",
          height: "40px",
        }}
        onMouseEnter={() => setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
      >
        <img
          src={props.imageSmall}
          alt=""
          className={props.className}
          onError={props.onError}
          style={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
        />
      </div>

      {/* Popover */}
      <Popover placement="right" isOpen={showPopup} target={props.identifier} style={{pointerEvents: "none"}}>
        <PopoverBody style={{padding: 0}}>
          <img
            src={props.imageBig}
            alt=""
            onError={props.onError}
            style={{
              width: "200px",
              height: "auto",
            }}
          />
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default ImagePopup;
