import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";

type EmailVerificationLoadingStates = LoadingStates<"verification">;
type EmailVerificationStatus = "empty" | "success" | "failed";

export type EmailVerificationState = {
  loading: EmailVerificationLoadingStates;
  status: EmailVerificationStatus;
  error?: ApiError;
};

const initialState: EmailVerificationState = {
  loading: {
    verification: false,
  },
  status: "empty",
  error: {} as ApiError,
};

const emailVerificationSlice = createSlice({
  name: "EmailVerification",
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<EmailVerificationStatus>) {
      state.status = action.payload;
    },
    loading(state, action: PayloadAction<[keyof EmailVerificationLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {setStatus, loading, apiError} = emailVerificationSlice.actions;

export default emailVerificationSlice.reducer;
