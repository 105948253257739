import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Dropdown, DropdownMenu, DropdownToggle, Form, Label, Row, Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useFormik} from "formik";
import {sendSupportRequest} from "slices/account/thunk";
import {SendSupportRequest} from "models/user";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import ValidatedInput from "Components/Common/ValidatedInput";
import SelectOptions from "Components/Hooks/useSelectOptions";
import Dropzone from "react-dropzone";
import i18n from "i18n";

import * as Yup from "yup";
import {formatBytes} from "helpers/utilities";
import ValidatedSelect from "Components/Common/ValidatedSelect";

type SupportStatus = "initial" | "requestSent";
const Support = () => {
  const {t} = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const {supportIssueSelectOptions, supportIssuePrioritySelectOptions} = SelectOptions();
  const [status, setStatus] = useState<SupportStatus>("initial");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();
  const helpCenterLink = `https://help.sellthis.com/${i18n.language}`;
  const youtubeLink = i18n.language === "en" ? "https://www.youtube.com/@sellthiscom" : i18n.language === "tr" ? "https://www.youtube.com/@SellThisTR" : "";
  const faqLink = i18n.language === "en" ? "https://www.sellthis.com/faqs" : i18n.language === "tr" ? "https://www.sellthis.com/tr/faqs" : "";

  document.title = t("PageTitles.Support");
  const accountPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
    }),
  );

  // Inside your component
  const {loading} = useSelector(accountPageData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      description: "",
      issue: "",
      issueDetail: "",
      attachments: [],
      priority: "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required(t("Account.Support.Validation.Required")),
      issue: Yup.string().required(t("Account.Support.Validation.Required")),
      issueDetail: Yup.string().required(t("Account.Support.Validation.Required")),
      attachments: Yup.array(),
      priority: Yup.string().required(t("Account.Support.Validation.Required")),
    }),
    onSubmit: (values: SendSupportRequest) => {
      const sendPromise = sendSupportRequest(values)(dispatch);
      sendPromise.then((isSuccess) => {
        if (isSuccess) {
          setStatus("requestSent");
          validation.resetForm();
        }
      });
    },
  });

  function handleAcceptedFiles(files: any) {
    const handledFiles = files
      .map((file: any) => {
        if (file) {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          });
          return file;
        }
        return null;
      })
      .filter(Boolean);
    setSelectedFiles(handledFiles);
    validation.setFieldValue("attachments", handledFiles);
  }

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/subscription",
    },
    {
      label: t("Account.Support.Title"),
    },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
        <ProfileLayout hasAnyChanges={false} navigateTo="">
          <>
            <Card className="ribbon-box overflow-hidden">
              <CardHeader>
                <Row>
                  <Col className="d-flex align-items-center">
                    <CardTitle className="fw-medium mb-0">
                      <i className="mdi mdi-lifebuoy fs-18 align-middle me-1"></i>
                      {t("Account.Support.Title")}
                    </CardTitle>
                  </Col>
                  <Col xs="auto">
                    <Dropdown color="link" isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)} direction={"down"}>
                      <DropdownToggle color="link" caret className="d-flex align-items-center">
                        <i className="ri-question-line fs-16 me-1"></i>
                        {t("Account.Support.Dropdown.Title")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link to={helpCenterLink} className="dropdown-item" target="_blank">
                          <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                          <span className="align-middle">{t("Account.Support.Dropdown.HelpCenter")}</span>
                        </Link>
                        <Link to={youtubeLink} className="dropdown-item" target="_blank">
                          <i className="mdi mdi-youtube text-muted fs-16 align-middle me-1"></i>
                          <span className="align-middle">{t("Account.Support.Dropdown.YouTube")}</span>
                        </Link>
                        <Link to={faqLink} className="dropdown-item" target="_blank">
                          <i className="mdi mdi-frequently-asked-questions text-muted fs-16 align-middle me-1"></i>
                          <span className="align-middle">{t("Account.Support.Dropdown.FAQs")}</span>
                        </Link>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {status === "initial" ? (
                  <Row className="justify-content-center">
                    <Col>
                      <Card className="mb-0">
                        <div className="bg-success-subtle position-relative">
                          <CardBody className="pt-5 text-center">
                            <div>
                              <h3>{t("Account.Support.Heading")}</h3>
                            </div>
                            <span className="text-muted">{t("Account.Support.HeadingDescription")}</span>
                          </CardBody>

                          <div className="shape" style={{position: "relative"}}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xmlns-svgjs="http://svgjs.com/svgjs"
                              width="1440"
                              height="60"
                              preserveAspectRatio="none"
                              viewBox="0 0 1440 60"
                            >
                              <g mask='url("#SvgjsMask1001")' fill="none">
                                <path d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z" style={{fill: "var(--vz-secondary-bg)"}}></path>
                              </g>
                              <defs>
                                <mask id="SvgjsMask1001">
                                  <rect width="1440" height="60" fill="#FFFFFF"></rect>
                                </mask>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <div className="p-4">
                          <Form onSubmit={validation.handleSubmit}>
                            <Row className="mt-4 justify-content-center ">
                              <Col xs={12}>
                                <div className="mb-4">
                                  <Row className="g-2">
                                    <Col xs="12">
                                      <Label htmlFor="description" className="form-label">
                                        {t("Account.Support.ShortDescription")} <span className="text-danger">*</span>
                                      </Label>
                                      <p className="text-muted mb-0">{t("Account.Support.ShortDescriptionDescription")}</p>
                                    </Col>
                                    <Col xs="12">
                                      <ValidatedInput validation={validation} field="description" maxLength={100} upperFirst disableValidationUI />
                                    </Col>
                                  </Row>
                                </div>

                                <div className="mb-4">
                                  <Row className="g-2">
                                    <Col xs="12">
                                      <Label htmlFor="lastName" className="form-label">
                                        {t("Account.Support.WhereIsYourIssueHappening")} <span className="text-danger">*</span>
                                      </Label>
                                      <p className="text-muted mb-0">{t("Account.Support.WhereIsYourIssueHappeningDescription")}</p>
                                    </Col>
                                    <Col xs="12">
                                      <ValidatedSelect className="w-100" options={supportIssueSelectOptions} validation={validation} field="issue" value={validation.values.issue} errorStyle="container" />
                                    </Col>
                                  </Row>
                                </div>

                                <div className="mb-4">
                                  <Row className="g-2">
                                    <Col xs="12">
                                      <Label htmlFor="lastName" className="form-label">
                                        {t("Account.Support.PleaseDescribeYourIssue")} <span className="text-danger">*</span>
                                      </Label>
                                      <p className="text-muted mb-0">{t("Account.Support.PleaseDescribeYourIssueDescription")}</p>
                                    </Col>
                                    <Col xs="12">
                                      <ValidatedInput validation={validation} type="textarea" field="issueDetail" maxLength={1500} disableValidationUI />
                                    </Col>
                                  </Row>
                                </div>

                                <div className="mb-4">
                                  <Row className="g-2">
                                    <Col xs="12">
                                      <Label htmlFor="lastName" className="form-label">
                                        {t("Account.Support.AddAttachment")}
                                      </Label>
                                      <p className="text-muted mb-0">{t("Account.Support.AddAttachmentDescription")}</p>
                                    </Col>
                                    <Col xs="12">
                                      <Dropzone
                                        accept={{
                                          "image/jpeg": [],
                                          "image/png": [],
                                          "image/gif": [],
                                          "application/pdf": [],
                                        }}
                                        onDrop={(acceptedFiles: any) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                      >
                                        {({getRootProps}) => (
                                          <div className={"dropzone dz-clickable new-search upload-height form-control form-control"}>
                                            <div className="dz-message needsclick" {...getRootProps()}>
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                              </div>
                                              <h4>{t("Account.Support.Upload.Description")}</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div className="list-unstyled mb-0" id="file-previews">
                                        {selectedFiles.map((file: any, index) => {
                                          return (
                                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={index + "-file"}>
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    {file.type !== "application/pdf" && (
                                                      <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={file.name} src={file.preview} />
                                                    )}
                                                  </Col>
                                                  <Col>
                                                    <Link to="#" className="text-muted font-weight-bold">
                                                      {file.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{file.formattedSize}</strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="mb-4">
                                  <Row className="g-2">
                                    <Col xs="12">
                                      <Label htmlFor="lastName" className="form-label">
                                        {t("Account.Support.Priority.Title")} <span className="text-danger">*</span>
                                      </Label>
                                      <p className="text-muted mb-0">{t("Account.Support.PriorityDescription")}</p>
                                    </Col>
                                    <Col xs="12" >
                                      <ValidatedSelect className="w-100" options={supportIssuePrioritySelectOptions} validation={validation} field="priority" value={validation.values.priority} errorStyle="container" />
                                    </Col>
                                  </Row>
                                </div>

                                <div className="d-flex justify-content-start">
                                  <Button color="primary" type="submit" disabled={loading.save}>
                                    {loading.save ? <Spinner size="sm" className="me-2"></Spinner> : null}
                                    {t("Account.Support.Button.Submit")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <Row className="mt-4 justify-content-center text-center">
                    <Col xs={12}>
                      <div className="mb-4">
                        <i className=" bx bx-check-circle display-1 text-success"></i>
                      </div>
                      <h5>{t("Account.Support.Success.Title")}</h5>
                      <p className="text-muted mb-4">{t("Account.Support.Success.Description")}</p>

                      <div className="hstack justify-content-center gap-2 mb-4">
                        <Link className="btn btn-primary" to={helpCenterLink} target="_blank">
                          <i className="mdi mdi-lifebuoy fs-16 align-middle me-1"></i>
                          <span className="align-middle ">{t("Account.Support.Button.HelpCenter")}</span>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </>
        </ProfileLayout>
      </Container>
    </div>
  );
};

export default Support;
