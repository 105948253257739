import { CSSProperties, useEffect, useState } from "react";
import { InputGroup } from "reactstrap";
import { DateRange, ValidatableInputProps } from "helpers/types";
import DateInput from "./DateInput";
import { useTranslation } from "react-i18next";

export type DateRangeInputProps = ValidatableInputProps<DateRange> & {
    className?: string,
    style?: CSSProperties
}

const DateRangeInput = (props: DateRangeInputProps) => {
    const { t } = useTranslation();
    const [start, setStart] = useState(props.value?.start);
    const [end, setEnd] = useState(props.value?.end);

    const onChange = props.onChange;
    useEffect(() => {
        onChange?.({ start, end });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, end]);

    return <>
        <InputGroup>
            <DateInput name="start" placeholder={t("Start")} value={start} onChange={setStart}/>
            <DateInput name="end" placeholder={t("End")} value={end} onChange={setEnd} />
        </InputGroup>
    </>;
};

export default DateRangeInput;