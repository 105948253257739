import {ConsoleLogReportCommand} from "api/command";
import {postConsoleLogReport} from "services/identity_service";

export const setupConsoleLogging = () => {
  const originalConsoleWarn = console.warn;
  const originalConsoleError = console.error;

  const logToApi = async (logData: ConsoleLogReportCommand) => {
    try {
      const response = await postConsoleLogReport(logData);
			
      if (!response.status) {
        throw new Error("Log report error: " + response.message);
      }
    } catch (error) {
      originalConsoleError("Log report error while sending data:", error);
    }
  };

  console.warn = (...args) => {
    const warningMessage = args.join(" ");
    const logData: ConsoleLogReportCommand = {
      type: "Warning",
      message: warningMessage,
      url: window.location.href,
    };

    logToApi(logData);
    originalConsoleWarn(...args);
  };

  console.error = (...args) => {
    const errorMessage = args.join(" ");
    const logData: ConsoleLogReportCommand = {
      type: "Error",
      message: errorMessage,
      url: window.location.href,
    };

    logToApi(logData);
    originalConsoleError(...args);
  };
};
