import {LogDetails} from "./log_details";
import {User} from "./user";

export interface LogEntry {
  logEntryId: string;
  eventId: string;
  logGroup: LogGroup;
  name: string;
  ipAddress: string;
  createDate: Date;
  userAgent: string;
  user: User;
  logDetails: LogDetails;
}


export enum LogGroup {
  USER=0,
  SYSTEM=1
}