import bell from "assets/images/svg/bell.svg";

const NoNotifications = () => {
  return (
    <div>
      <div className="w-sm-50 pt-3 mx-auto" style={{width: "10%"}}>
        <img src={bell} className="img-fluid" alt="user-pic" />
      </div>
      <div className="text-center pb-5 mt-2">
        <h6 className="fs-18 fw-semibold lh-base">Hey! You have no any notifications </h6>
      </div>
    </div>
  );
};

export default NoNotifications;
