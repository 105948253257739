import {abbreviateNumber} from "helpers/utilities";
import i18n from "i18n";
import classNames from "classnames";
interface RenderNumberProps {
  className?: string;
  prefix?: string;
  suffix?: string;
  value?: number;
  decimals?: number;
  notation?: "wholeNumber" | "decimal" | "abbreviated";
  renderOnEmpty?: JSX.Element;
  busy?: boolean;
}
const RenderNumber = (props: RenderNumberProps) => {
  const currentLocale = i18n.language;
  const decimals = props.decimals ?? 0;
  const notation = props.notation ?? "decimal";
  const hasPlaceholder = props.busy !== undefined && props.busy ? "placeholder placeholder-glow" : "";
  const formatNumber = (num: number): string => {
    if (notation === "decimal") {
      return new Intl.NumberFormat(currentLocale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(num);
    } else if (notation === "abbreviated") {
      return abbreviateNumber(num);
    }
    return num.toFixed(decimals);
  };

  const content =
    props.value !== null && props.value !== undefined
      ? `${props.prefix || ""}${formatNumber(props.value)}${props.suffix || ""}`
      : props.renderOnEmpty
      ? props.renderOnEmpty
      : `${props.prefix || ""}${(0).toFixed(decimals)}${props.suffix || ""}`;
  return <span className={classNames(props.className, hasPlaceholder)}>{content}</span>;
};

export default RenderNumber;
