import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {Card, CardBody, Col, Row, UncontrolledAlert} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useMemo, useRef} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {Variation} from "models/product_details";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import RenderNumber from "Components/Common/RenderNumber";
import DisplayPrice from "Components/Common/DisplayPrice";
import errorImage from "assets/images/svg/product.svg";

interface VariationAnalysisProps {
  destinationMarketplace?: AmazonMarketplaceInfosType;
}
const VariationAnalysis = ({destinationMarketplace}: VariationAnalysisProps) => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);

  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ProductDetails.loading,
      details: state.ProductDetails.details,
      pageUrl: state.ProductDetails.pageUrl,
    }),
  );
  const {loading, details} = useSelector(productDetailsData);

  const columns = useMemo<ColumnDef<Variation, any>[]>(
    () => [
      {
        header: t("ProductDetails.VariationAnalysis.TableColumn.Asin"),
        cell: (cellProps) => {
          const row = cellProps.row.original as Variation;
          return (
            <Row className="align-items-center">
              <Col xs="auto" className="pe-1">
                <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                  <img
                    className="rounded w-100"
                    src={`https://m.media-amazon.com/images/I/${row.image}`}
                    alt=""
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = errorImage;
                    }}
                  />
                </div>
              </Col>
              <Col xs="auto">
                <div className="d-flex align-items-center">
                  <CopyAsinWidget asin={row.asin} index={row.asin} asLink={true} marketplace={destinationMarketplace?.marketplace} />
                  {row.isSelectedVariation && (
                    <>
                      <i id={`VariationTooltip-${row.asin}`} className="text-info align-middle ri-flashlight-fill ms-1 fs-14"></i>
                      <DefaultUncontrolledTooltip target={`VariationTooltip-${row.asin}`}>{t("ProductDetails.VariationAnalysis.Tooltip.SelectedVariation")}</DefaultUncontrolledTooltip>
                    </>
                  )}
                </div>
                <div className="d-flex gap-1 mt-1">
                  <span className="badge bg-danger-subtle text-danger fs-12">
                    <RenderNumber value={row.fbaCount} renderOnEmpty={<>0</>} /> FBA
                  </span>
                  <span className="badge bg-success-subtle text-success fs-12">
                    <RenderNumber value={row.fbmCount} renderOnEmpty={<>0</>} /> FBM
                  </span>
                </div>
              </Col>
            </Row>
          );
        },
      },
      {
        header: t("ProductDetails.VariationAnalysis.TableColumn.Product"),
        cell: (cellProps) => {
          const row = cellProps.row.original as Variation;
          return (
            <>
              <p className="ellipsis-two-lines mb-0" style={{maxWidth: "350px"}}>
                {row.productName}
              </p>
            </>
          );
        },
      },
      {
        header: t("ProductDetails.VariationAnalysis.TableColumn.Dimensions"),
        cell: (cellProps) => {
          const row = cellProps.row.original as Variation;
          return (
            <>
              <span className="text-nowrap">
                <span className="fs-14 fw-medium">{row.dimensions} inch</span>
                <h5 className="fs-14 my-1 text-muted">{row.weight} lb</h5>
              </span>
            </>
          );
        },
      },
      {
        header: t("ProductDetails.VariationAnalysis.TableColumn.Price"),
        cell: (cellProps) => {
          const row = cellProps.row.original as Variation;
          return (
            <>
              <span className="text-nowrap">
                <span className="fs-14 fw-medium">
                  {row.price ? <DisplayPrice source={destinationMarketplace?.currency} value={row.price} decimals={2} notation="abbreviated" /> : <span className="text-muted">{t("NA")}</span>}
                </span>
                {row.priceChangeRate && (
                  <span className="text-nowrap d-flex align-items-center fw-medium">
                    <i className="fs-13 align-middle text-success ri-arrow-right-up-line me-1"></i>
                    <span className={row.priceChangeRate > 0 ? "text-success" : "text-danger"}>
                      <RenderNumber value={row.priceChangeRate} suffix="%" decimals={2} />
                    </span>
                  </span>
                )}
              </span>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, details, destinationMarketplace],
  );

  return (
    <>
      {(details?.variationAnalysis?.variationCount ?? 0) > 0 && (
        <Card className="card-animate">
          <CardBody>
            <h5 className="fs-15">{t("ProductDetails.VariationAnalysis.Title")}</h5>
            <Row className="mt-4">
              <Col xl={8} sm={12}>
                <Card>
                  <CardBody>
                    <DataTable
                      ref={tableRef}
                      columns={columns}
                      data={details?.variationAnalysis?.variations || []}
                      totalDataLength={details?.variationAnalysis?.variations?.length || 0}
                      thClass="text-black"
                      busy={loading.list}
                      height="50vh"
                      hovered
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} sm={12}>
                {(details?.variationAnalysis?.variationCount ?? 0) > 0 && (
                  <UncontrolledAlert color="warning" className="alert-additional mb-4" toggle={null}>
                    <div className="alert-body">
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <i className="ri-alert-line fs-16 align-middle"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="alert-heading">{t("ProductDetails.VariationAnalysis.Alert.Title")}</h5>
                          <p className="mb-0">
                            {t("ProductDetails.VariationAnalysis.Alert.Description", {variationCount: details.variationAnalysis.variationCount, saleCount: details.variationAnalysis.saleCount})}
                          </p>
                        </div>
                      </div>
                    </div>
                  </UncontrolledAlert>
                )}

                <Card>
                  <CardBody>
                    <div className="fs-15">
                      <div className="d-flex pb-2">
                        <div className="flex-grow-1">
                          <p className="text-truncate mb-0">
                            <i className="mdi mdi-layers-triple-outline align-middle text-primary me-2"></i>
                            {t("ProductDetails.VariationAnalysis.Label.VariationCount")}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <p className="mb-0">{details.variationAnalysis.variationCount}</p>
                        </div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="flex-grow-1">
                          <p className="text-truncate mb-0">
                            <i className="bx bx-dollar-circle align-middle text-private me-2"></i>
                            {t("ProductDetails.VariationAnalysis.Label.PriceDifferenceWithOtherVariations")}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <p className="mb-0">
                            <RenderNumber value={details.variationAnalysis.priceDiffRate} prefix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                          </p>
                        </div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="flex-grow-1">
                          <p className="text-truncate mb-0">
                            <i className="mdi mdi-store align-middle text-private me-2"></i>
                            {t("ProductDetails.VariationAnalysis.Label.AreThereFbaSellersInThisVariation")}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <p className="mb-0">{details.variationAnalysis.isHasFBASeller ? t("Yes") : t("No")}</p>
                        </div>
                      </div>
                      <div className="d-flex pt-2">
                        <div className="flex-grow-1">
                          <p className="text-truncate mb-0">
                            <i className="mdi mdi-store align-middle text-primary me-2"></i>
                            {t("ProductDetails.VariationAnalysis.Label.AreThereFbaSellersInTheOtherVariations")}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <p className="mb-0">{details.variationAnalysis.othersHasFBASeller ? t("Yes") : t("No")}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default VariationAnalysis;
