import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Label, Row, Button, Form, Alert, Spinner} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {loginUser, loginUserWithToken, resetLoginFlag} from "../../slices/thunks";
import {createSelector} from "reselect";
import {useTranslation, withTranslation} from "react-i18next";
import {RootState} from "slices";
import ParticlesAuth from "./ParticlesAuth";
import withRouter from "../../Components/Common/withRouter";
import logoTransparent from "../../assets/images/logo-dark-transparent.png";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import Loader from "Components/Common/Loader";

const Login = (props: any) => {
  const {t} = useTranslation();
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const auth = queryParams.get("auth");
  const returnUrl = queryParams.get("return_url") || undefined;

  useEffect(() => {
    if (auth) {
      loginUserWithToken(auth, navigate,returnUrl)(dispatch);
    }
  }, []); //eslint-disable-line

  const loginPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Login.loading,
      status: state.Login.status,
      error: state.Login.error,
    }),
  );
  const {loading, status, error} = useSelector(loginPageData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Login.Validation.Email")),
      password: Yup.string().required(t("Login.Validation.Password")),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate, returnUrl));
    },
  });

  document.title = t("PageTitles.Login");

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoTransparent} alt="" height="50" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                </div>
              </Col>
            </Row>
            {auth ? (
              <>
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                      <CardBody className="p-4">
                        {loading.loginWithToken && <Loader />}
                        {status === "failed" && <Alert color="danger">{t(error?.message as string)}</Alert>}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                      <CardBody className="p-4">
                        <div className="text-center mt-2">
                          <h5 className="text-primary">{t("Login.Title")}</h5>
                          <p className="text-muted">{t("Login.Subtitle")}</p>
                        </div>
                        {status === "failed" && (
                          <Alert color="danger" toggle={() => dispatch(resetLoginFlag())}>
                            {t(error?.message as string)}
                          </Alert>
                        )}
                        <div className="p-2 mt-4">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <Row className="g-3">
                              <Col lg={12}>
                                <Label htmlFor="email" className="form-label">
                                  {t("Login.Email")}
                                </Label>
                                <ValidatedInput validation={validation} type="email" field="email" maxLength={100} placeholder={t("Login.EmailPlaceholder")} tabIndex={1} disableValidationUI />
                              </Col>
                              <Col lg={12} className="mb-3">
                                <div className="float-end">
                                  <Link to="/account/forgot-password" className="text-muted">
                                    {t("Login.ForgotPassword")}
                                  </Link>
                                </div>
                                <Label htmlFor="password" className="form-label">
                                  {t("Login.Password")}
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <ValidatedInput
                                    validation={validation}
                                    type={passwordShow ? "text" : "password"}
                                    field="password"
                                    placeholder={t("Login.PasswordPlaceholder")}
                                    tabIndex={2}
                                    disableValidationUI
                                  />
                                  <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => setPasswordShow(!passwordShow)}>
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </Col>

                              <Col lg={12}>
                                <Button color="success" className="btn btn-success w-100" type="submit" tabIndex={3} disabled={loading.login}>
                                  {loading.login ? <Spinner size="sm" className="me-2"></Spinner> : null}
                                  {t("Login.SignIn")}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        {t("Login.DoNotHaveAnAccount")}{" "}
                        <Link to="/account/register" className="fw-semibold text-primary text-decoration-underline">
                          {t("Login.SignUp")}
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));
