import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {Col, Progress, Row} from "reactstrap";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getShipmentSpeed} from "helpers/utilities";
import DisplayPrice from "Components/Common/DisplayPrice";
import CountUp from "react-countup";
import RenderNumber from "Components/Common/RenderNumber";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";

const Summary = () => {
  const {t} = useTranslation();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [shipmentSpeed, setShipmentSpeed] = useState<number>();
  const [dayElapsed, setDayElapsed] = useState<number>();
  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {currentUserShipment} = useSelector(shipmentsData);
  useEffect(() => {
    if (currentUserShipment) {
      let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setDestinationMarketplace(destination);

      const {dayPassed, shipmentSpeed} = getShipmentSpeed(currentUserShipment);

      setDayElapsed(dayPassed);
      setShipmentSpeed(shipmentSpeed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);
  return (
    <>
      <Row>
        <Col xxl={2} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-success fs-24">
                  <i className="ri-numbers-fill"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.Items")}</p>

                <h4 className="fs-22 fw-semibold ff-secondary mb-3 d-flex align-items-center">
                  <span className="counter-value">
                    <CountUp start={0} decimals={0} duration={2} end={currentUserShipment.totalItems} />
                  </span>
                  <span className="badge rounded-pill bg-success-subtle text-success ms-2 fs-16">
                    <CountUp start={0} decimals={0} duration={2} end={currentUserShipment.totalSKU} /> {currentUserShipment.totalSKU > 1 ? t("SKUs") : t("SKU")}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={2} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-danger fs-24">
                  <i className="ri-shopping-cart-2-fill"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.SoldItems")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-2 d-flex align-items-center">
                  {currentUserShipment.soldItems}
                  <span className="text-muted fs-16 ps-2">{`(%${((currentUserShipment.soldItems / currentUserShipment.totalItems) * 100).toFixed(2)})`}</span>
                </h4>
                <div className="mb-1">
                  <Progress className="progress-sm w-100" color="success" value={(currentUserShipment.soldItems / currentUserShipment.totalItems) * 100} />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={2} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-warning fs-24">
                  <i className="ri-pie-chart-fill"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.LeftItems")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-3 d-flex align-items-center">{currentUserShipment.totalItems - currentUserShipment.soldItems}</h4>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={3} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-success fs-24">
                  <i className="ri-switch-line"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.Revenue")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-3 d-flex align-items-center">
                  {destinationMarketplace && (
                    <DisplayPrice source={destinationMarketplace.currency} value={currentUserShipment.userShipmentFinance.actualSoldRevenue} decimals={2} notation="decimal" />
                  )}
                </h4>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={3} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-secondary fs-24">
                  <i className="ri-exchange-dollar-line"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.Profit")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-3 d-flex align-items-center">
                  <span className="counter-value">
                    {destinationMarketplace && (
                      <DisplayPrice source={destinationMarketplace.currency} value={currentUserShipment.userShipmentFinance.actualSoldProfit} decimals={2} notation="decimal" countUp />
                    )}
                  </span>
                  <span className="badge rounded-pill bg-success-subtle text-success ms-2 fs-13">
                    {currentUserShipment.userShipmentFinance.actualSoldROI && <CountUp start={0} suffix="%" decimals={0} duration={2} end={currentUserShipment.userShipmentFinance.actualSoldROI} />}
                    <span className="mx-1">
                      <i className="mdi mdi-slash-forward"></i>
                    </span>
                    {currentUserShipment.userShipmentFinance.actualSoldMargin && (
                      <CountUp start={0} suffix="%" decimals={0} duration={2} end={currentUserShipment.userShipmentFinance.actualSoldMargin} />
                    )}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xxl={3} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-danger fs-24">
                  <i className=" ri-list-check"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.SuccessRate")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-3 d-flex align-items-center">
                  {currentUserShipment.soldItems > 0 ? (
                    <RenderNumber
                      value={SuccessRateCalculator(currentUserShipment.userShipmentFinance.actualSoldProfit, currentUserShipment.userShipmentFinance.expectedSoldProfit)}
                      prefix="%"
                      decimals={0}
                      notation="decimal"
                    />
                  ) : (
                    "-"
                  )}
                </h4>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={3} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-warning fs-24">
                  <i className="bx bx-calendar"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.DaysElapsed")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-3 d-flex align-items-center">{dayElapsed}</h4>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={3} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-success fs-24">
                  <i className="ri-price-tag-3-fill"></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.CostRecoveryStatus")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-2 d-flex align-items-center">{`% ${currentUserShipment.userShipmentFinance.costRecoveryStatusRate}`}</h4>
                <div className="mb-1">
                  <Progress className="progress-sm w-100" color="success" value={currentUserShipment.userShipmentFinance.costRecoveryStatusRate} />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={3} sm={6}>
          <div className="p-2 border card card-animate rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-2 mb-3">
                <div className="avatar-title rounded bg-transparent text-secondary fs-24">
                  <i className="mdi mdi-speedometer "></i>
                </div>
              </div>
              <div className="flex-grow-1 me-3">
                <p className="text-muted mb-2 fs-18">{t("Shipments.ShippedOrCompleted.Summary.Label.Speed")}</p>
                <h4 className="fs-22 fw-semibold ff-secondary mb-3 d-flex align-items-center">
                  {shipmentSpeed?.toFixed(2)}
                  <span className="text-muted ms-2">
                    {t("items")}
                    <span className="mx-1">
                      <i className="mdi mdi-slash-forward"></i>
                    </span>
                    {t("day")}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Summary;
