import {apiError, loading, reset, setScanAndSaveList, updateScanAndSaveItemAsCanceled, updateScanAndSaveItemAsRestarted} from "./reducer";
import {ResponseModel} from "models/response_model";
import {PagedList} from "helpers/types";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {postCancelScanAndSave, postGetAdminScanAndSave, postRestartScanAndSave, postUndoDeletedScan} from "services/admin_service";
import {ScanAndSave} from "models/scan_and_save";
import {AdminScanAndSaveQuery} from "api/query";
import i18n from "i18n";

export const getAdminScanAndSaveList = (query: AdminScanAndSaveQuery) => async (dispatch: any) => {
  const loadingType = query.filtering ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetAdminScanAndSave(query);
    const pagedResult: PagedList<ScanAndSave> = result.data;
    dispatch(setScanAndSaveList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const restartScan = (scan: ScanAndSave) => async (dispatch: any) => {
  try {
    dispatch(loading(["restart", true]));
    await postRestartScanAndSave({userId: scan.user.userId, scanAndSaveId: scan.id});
    dispatch(updateScanAndSaveItemAsRestarted(scan))
    renderSuccessToast(i18n.t("Admin.ScanAndSave.Toast.RestartSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["restart", false]));
  }
};

export const cancelScan = (scan: ScanAndSave) => async (dispatch: any) => {
  try {
    dispatch(loading(["cancel", true]));
    await postCancelScanAndSave({userId: scan.user.userId, scanAndSaveId: scan.id});
    dispatch(updateScanAndSaveItemAsCanceled(scan))
    renderSuccessToast(i18n.t("Admin.ScanAndSave.Toast.CancelSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["cancel", false]));
  }
};

export const undoDeleteScan = (scan: ScanAndSave) => async (dispatch: any) => {
  try {
    dispatch(loading(["cancel", true]));
    await postUndoDeletedScan({userId: scan.user.userId, scanAndSaveId: scan.id});
    renderSuccessToast(i18n.t("Admin.ScanAndSave.Toast.UndoDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["cancel", false]));
  }
};

export const resetAdminScanAndSaveState = () => async (dispatch: any) => {
  dispatch(reset());
};
