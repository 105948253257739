import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {IEditUserShipmentDto, UserShipment, UserShipmentStatus} from "models/user_shipment";
import {useEffect} from "react";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {editUserShipment} from "slices/thunks";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import { getToday } from "helpers/utilities";

interface EditShipmentProps {
  isOpen: boolean;
  toggle: () => void;
  onReload: () => void;
  from: "SearchResults" | "Shipments" | "ShipmentDetails";
  shipment: UserShipment;
}
const EditShipment = (props: EditShipmentProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
    }),
  );
  const {loading} = useSelector(shipmentsData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipmentName: props.shipment?.name ?? "",
      amazonShipmentId: props.shipment?.amazonShipmentId ?? "",
      shipDate: props.shipment?.shipDate ?? "",
    },
    validationSchema: Yup.object().shape({
      shipmentName: Yup.string()
        .required(t("Shipments.NameRequired"))
        .max(100, t("Shipments.NameMaxLength")),
      amazonShipmentId: Yup.string().max(50, t("Shipments.AmazonShipmentIdMaxLength")),
      shipDate: Yup.date()
        .min(new Date("2023-01-01"), t("Start date must be later than January 01, 2023"))
        .max(getToday(), "End date cannot be later than today")
        .typeError(t("Invalid date type"))
        .test("required-ship-date", t("Shipments.ShipDateRequired"), (shipDate: any) => {
          if (props.shipment?.status === UserShipmentStatus.SHIPPED && !shipDate) {
            return false;
          } else {
            return true;
          }
        }),
    }),
    onSubmit: (values: any) => {
      let request: IEditUserShipmentDto = {
        userShipmentId: props.shipment?.userShipmentId,
        shipmentName: values.shipmentName,
        amazonShipmentId: values.amazonShipmentId,
        shipDate: values.shipDate,
        asin: "",
        from: props.from,
      };

      const updatePromise = editUserShipment(request)(dispatch);

      updatePromise.then(() => {
        props.onReload();
        validation.resetForm();
        props.toggle();
      });
      //props.onEditUserShipment(editUserShipment);
      //props.toggle();
    },
  });

  useEffect(() => {
    validation.resetForm();

    validation.initialValues = {
      shipmentName: props.shipment?.name,
      amazonShipmentId: props.shipment?.amazonShipmentId,
      shipDate: props.shipment?.shipDate,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shipment]);

  return (
    <Modal
      id="showEditShipmentStatusModal"
      isOpen={props.isOpen}
      toggle={() => props.toggle}
      fade={true}
      centered={true}
      size={props.from === "SearchResults" || props.shipment?.status !== UserShipmentStatus.SHIPPED ? "sm" : "lg"}
    >
      <ModalHeader className="bg-light p-3" toggle={props.toggle}>
        {t("Shipments.EditShipment")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="g-3">
            <Col xs={12} lg={props.from !== "SearchResults" && props.shipment?.status === UserShipmentStatus.SHIPPED ? 4 : 12}>
              <Label>{t("Shipments.Name")}</Label>
              <ValidatedInput validation={validation} type="text" field="shipmentName" maxLength={100} placeholder={t("Shipments.Name")} disableValidationUI />
            </Col>
            {props.from !== "SearchResults" && props.shipment?.status === UserShipmentStatus.SHIPPED ? (
              <>
                <Col xs={12} lg={4} md={6}>
                  <Label>{t("Shipments.AmazonShipmentId")}</Label>
                  <ValidatedInput validation={validation} type="text" field="amazonShipmentId" maxLength={50} placeholder={t("Shipments.AmazonShipmentId")} disableValidationUI />
                </Col>
                <Col xs={12} lg={4} md={6}>
                  <Label htmlFor="shipDate">{t("Shipments.ShipDate")}</Label>
                  <ValidatedDate field="shipDate" className="w-100" validation={validation} value={validation.values.shipDate}></ValidatedDate>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button
            type="button"
            className="btn btn-light"
            disabled={loading.update}
            onClick={() => {
              props.toggle();
            }}
          >
            {t("Close")}
          </Button>
          <Button
            type="submit"
            className="btn btn-success"
            disabled={loading.update}
            onClick={() => {
              validation.setFieldTouched("shipDate");
              validation.setFieldTouched("amazonShipmentId");

              if (validation.isValid) {
                validation.handleSubmit();
              }
            }}
          >
            {loading.update && <Spinner size="sm" className="me-2"></Spinner>}
            {t("Save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditShipment;
