import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import i18n from "i18n";
import {SellerList} from "models/product_details";

const getRatingPowerToolTip = (seller: SellerList) => {
  if (seller.ratingPercentage != null) {
    if (seller.isAmazon === true) {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-danger align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.StrongSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    }
    if (seller.ratingPercentage >= 0 && seller.ratingPercentage < 33) {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-success align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.WeakSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    } else if (seller.ratingPercentage >= 33 && seller.ratingPercentage < 66) {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.ModerateSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    } else {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-danger align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.StrongSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    }
  } else {
    return;
  }
};

export {getRatingPowerToolTip};
