import {Routes, Route} from "react-router-dom";
import {userRoutes, adminRoutes, publicRoutes, singleSearchRoutes} from "./allRoutes";
import React from "react";
import NonAuthLayout from "../layouts/NonAuthLayout";
import VerticalLayout from "../layouts/VerticalLayout/index";
import ProtectedRoutes from "./_ProtectedRoutes";
import PublicRoutes from "./_PublicRoutes";
import SingleSearchRoutes from "./_SingleSearchRoutes";

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
       
        <Route element={<PublicRoutes />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} key={idx} />
          ))}
        </Route>

        <Route element={<ProtectedRoutes />}>
          {userRoutes.map((route, idx) => (
            <Route path={route.path} element={route.path === "/account/subscription/new" ? route.component : <VerticalLayout>{route.component}</VerticalLayout>} key={idx} />
          ))}

          {adminRoutes.map((route, idx) => (
            <Route path={route.path} element={<VerticalLayout>{route.component}</VerticalLayout>} key={idx} />
          ))}
        </Route>

        <Route element={<SingleSearchRoutes />}>
          {singleSearchRoutes.map((route, idx) => (
            <Route path={route.path} element={route.component} key={idx} />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
